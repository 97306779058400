<template>
<div>
    <s-text 
        :placeholder="placeholder" 
        :label="label" 
        :readonly="readonly" 
        @click="filterCrud.ArtName = ''; dialogArticle = true;" 
        :clearable="clearable" 
        search-icon 
        v-model="textDescription" />

    <v-dialog width="80%" persistent v-model="dialogArticle" v-if="dialogArticle" hide-overlay transition="dialog-bottom-transition">
        <v-card outlined style="overflow: hidden !important">
            <s-toolbar label="Articulos" close dark color="#5175AF" @close="dialogArticle = false" :add="selectedItem != null" @add="doubleClick(selectedItem)" ></s-toolbar>

            <!-- <v-col> -->
                <!-- <v-row> -->
                    <v-col>
                        <s-crud noFull noToolbar @rowSelected="selectedRow($event)" title="Artículos " height="350" @doubleClick="doubleClick($event)" :config="configCrud" no-search :filter="filterCrud" sortable>
                            <template v-slot:filter>
                                <!-- <v-container style="margin-left: 0px"> -->
                                    <v-row style="margin-top: -16px">
                                        <v-col cols="12">
                                            <s-text  search clearable label="Buscar" autofocus v-model="filterCrud.ArtName"></s-text>
                                        </v-col>

                                        <!-- <v-col style="" class="" lg="2">
                                            <s-text label="Código" search clearable v-model="filterCrud.ArtCode"></s-text>
                                        </v-col> -->
                                    </v-row>
                                <!-- </v-container> -->
                            </template>

                            <template v-slot:ArtStockDisponible="{ row }">
                                <v-chip x-small :color="row.ArtStockDisponible <= 0 ? 'error': 'info'">
                                    {{ row.ArtStockDisponible <= 0 ? 0 : row.ArtStockDisponible }}
                                </v-chip>

                            </template>
                        </s-crud>
                    </v-col>
               <!--  </v-row> -->
            <!-- </v-col> -->
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import _sArticle from "@/services/Logistics/ArticleService";
export default {
    props: {
        value: null,
        readonly: {
            default: false
        },
        clearable: {
            default: false
        },
        label: {
            default: "Descripción de Artículo"
        },
        placeholder: {
            default: ""
        },
    },
    data() {
        return {
            selectedItem: null,
            article: null,
            textDescription: "",
            dialogArticle: false,
            parametersPagination: {
                filter: {
                    WhsCode: "01",
                    SecStatus: 1
                },
                draw: 1,
                start: 0,
                length: 10,
                order: [{
                    dir: "asc",
                    column: ""
                }],
                searchText: "",
            },
            filterCrud: {
                ArtCode: "",
                ArtName: "",
                WhsName: "",
                WhsCode: "",
            },
            configCrud: {
                model: {
                    ArtCode: "ID",
                    ArtName: "string",
                    SecStatus: "status",
                    ArtStockDisponible: "int"
                },

                service: _sArticle,
                headers: [{
                        text: "Código",
                        value: "ArtCode",
                        sortable: false
                    },
                    {
                        text: "Descripción Artículo",
                        value: "ArtName",
                        sortable: false
                    },
                    {
                        text: "Und.Medida",
                        value: "UnitOfMeasuarement",
                        sortable: false,
                        aling: "center",
                    },
                    {
                        text: "Stock Disponible",
                        value: "ArtStockDisponible",
                        sortable: false,
                        aling: "center",
                    },
                    {
                        text: "Stock Contable",
                        value: "ArtStockContable",
                        sortable: false,
                        aling: "center",
                    },
                ],
            },
        };
    },
    methods: {
        selectedRow(items) {
            if (items)
                if (items.length > 0) this.selectedItem = items[0];
        },
        doubleClick(item) {
            this.article = item;
            this.$emit("input", item);
            this.$emit("selected", item);
            this.textDescription = item.ArtName;

            this.dialogArticle = false;
        },
    },
    created() {
        this.article = this.value;
        if (this.article != null) {
            this.textDescription = this.article.ArtName;
        }
    },
    watch: {
        value() {
            this.textDescription = this.value == null ? "" : this.value.ArtName;
            this.article = this.value;
        },
    },
};
</script>
