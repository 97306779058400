<template>
<div>

   <!--  <v-dialog v-if="processing" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog> -->
    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">

            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row>
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-data-table dense item-key="AccID" :items="circuits" :items-per-page="-1" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center' },
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" noFull v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div>
        <s-toolbar close :label="orderLocal.DspDocumentNumber + ' - ' + orderLocal.NtpFullName
        " @close="$emit('onClose')" :transactionApproved="transactionApproved" :approve="optionApproved" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="1" cols="12">
                        <v-card elevation="0" height="auto">
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">Datos generales</v-card-title>
                                </v-card>
                                <v-col lg="3">
                                    <s-date label="Fecha entrega" style="font-family: Calibri" v-model="orderLocal.DspDate"></s-date>

                                </v-col>
                                <v-col lg="3">
                                    <s-select label="Usuario" placeholder="Digite Usuario" autofocus autocomplete clearable item-text="NtpFullName" v-model="orderLocal.UsrCreateID" item-value="UsrID" :items="itemsUsers"></s-select>
                                </v-col>

                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <div class="mt-2">
                                    <s-toolbar label="Detalle" :add="isEditable" @add="dialogAdd = true" :remove="isEditable" @removed="removeItem()" v-if="orderLocal.OrdStatus == 3"></s-toolbar>
                                </div>

                                <v-data-table style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="OdsID" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>

                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-col style="padding-top: 24px">
                            <v-row>
                                <v-col lg="9" cols="12">
                                    <v-row>
                                        <v-col class="s-col-text">
                                            <v-textarea auto-grow ref="DspObservation" :readonly="!isEditable" label="Observaciones Generales" v-model="orderLocal.DspObservation" />
                                            <span style="font-size: 9px; color:red">Max {{'100'}} Caracteres - {{orderLocal.DspObservation !== undefined && orderLocal.DspObservation !== null ? orderLocal.DspObservation.length : ''}}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sOutputProduct from "@/services/Logistics/LgsOutputProductService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sPermit from "@/services/Logistics/PermitService";


export default {
    components: {
    },
    props: {
        order: {},
        value: null,
        typeOrder: null,
        viewLoad: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
  
        processing: false,
        messageProcessing: "",

        selectedrow: [],
        circuits: [],
        selectedCircuit: [],
        filterLevels: {},
        configLevels: {
            model: {
                AccID: "int",
                AlvID: "ID",
                SecStatus: "boolean",
                PstID: "int",
                PstName: "string",
                OddAmount: "",
                OddUnitPrice: "",
                TaxCode: "",
            },
            service: _sApprovalLevel,
            headers: [{
                    text: "Cargo",
                    value: "PstName"
                },
                {
                    text: "Nivel",
                    value: "TypeLevelText"
                },
            ],
        },
        optionApproved: false,
        dateOrder: null,
        selectedSAP: [],
        transactionApproved: null,
        dialogCircuit: false,
        orderLocal: {
        },
        dialogAdd: false,

        itemsSAP: [],
        itemsRemove: [],
         headersSAP: [{
                text: "Descripción",
                value: "OdsDescription",
                sortable: false,
                width: 540,
            },

            {
                text: "Stock",
                value: "ArtStock",
                sortable: false,
                width: 50
            },

            {
                text: "Cant",
                value: "OdsQuantity",
                sortable: false,
                width: 50
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
                width: 50
            },

            {
                text: "Comentario",
                value: "OdsObservation",
                sortable: false,
                width: 400,
            },
        ],
        itemsUsers: [],


    }),
    computed: {
        isEditable() {
            /* return ((this.orderLocal.OrdStatus == 3 && this.$fun.getUserID() == this.orderLocal.UsrCreateID) || this.orderLocal.OrdID == null); */
            return (this.orderLocal.DspID == null);
        },
    },

    created() {

        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });

        if (this.value != null) {
            _sOutputProduct
                .byID({
                    DspID: this.value
                }, this.$fun.getUserID())
                .then((r) => {
                    this.orderLocal = r.data;
                    console.log("aaaaaaaaaaaaaaaa", this.orderLocal)

                    this.DspStatus = r.data.DspStatus;
                    this.orderLocal.UsrCreateID = this.orderLocal.UsrID
                    this.itemsSAP = r.data.Detail;

                    this.orderLocal.Transaction.DspStatus = this.orderLocal.DspStatus;
                    this.transactionApproved = this.orderLocal.Transaction;
                    if (this.orderLocal.Transaction != null)
                        if (this.orderLocal.Transaction.UsersApproved.length > 0) {
                            if (
                                this.orderLocal.Transaction.UsersApproved.find(
                                    (x) => x.UsrID == this.$fun.getUserID()
                                )
                            ) {
                                if (this.orderLocal.DspStatus == 4) {
                                    this.optionApproved = true;
                                } else this.optionApproved = false;
                            }
                        }
                });
        } 

    },

    mounted() {

        if (this.value != null) {
            this.processing = true;
        }

    },

    watch: {

        isEditable() {
            this.processing = false;
        },


    },

    methods: {


        //***************************************** Item Detalle ******************************************************************** */

        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        /***************************************** Fin Item Detalle ***************************************************************** */





        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {
            if (this.itemsSAP.length < 1) {
                this.$fun.alert("No se ha registrado ningún detalle", "warning");
                return;
            }
            this.$fun
                .alert("Guardar con " + this.selectedCircuit[0].AccName, "question")
                .then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true
                        this.orderLocal.UsrUpdateID = this.selectedCircuit[0].AccID;
                        this.dialogCircuit = false;
                        this.orderLocal.Detail = this.itemsSAP;
                        _sOrderPurchase
                            .save(
                                this.orderLocal,
                                this.$fun.getUserID(),
                                this.selectedCircuit[0].AccID
                            )
                            .then((r) => {
                                    this.uploadFile()
                                    this.processing = false
                                    this.$emit("onCloseClear");
                                    this.$fun.alert("Agregado Correctamente", "success");
                                    this.$emit("close");
                                    this.$emit("onClose");
                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = true;
                                });
                    }
                });
        },

    }
};
</script>
