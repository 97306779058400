let children = []

children.push({
    path: "/clicks/reporteventas",
    name: "CLC_RPT_SALES",
    component: () =>
        import ("../views/ReportClicks/ClcSales/ClcSalesIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Ventas", href: "/clicks/reporteventas" },
        ],
    },
});

children.push({
    path: "/clicks/cargamasiva",
    name: "CLC_LOAD",
    component: () =>
        import ("../views/ReportClicks/ClcConfigurations/ClcLoadMasive.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Carga Masiva", href: "/clicks/cargamasiva" },
        ],
    },
});


children.push({
    path: "/clicks/reporteventasdetalle",
    name: "CLC_RPTSALESDETAIL",
    component: () =>
        import ("../views/ReportClicks/ClcSalesDetail/ClcSalesDetailIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Ventas Detalle", href: "/clicks/reporteventasdetalle" },
        ],
    },
});

children.push({
    path: "/clicks/reporteclientes",
    name: "CLC_RPTCUSTOMER",
    component: () =>
        import ("../views/ReportClicks/ClcReportCustomer/ClcReportCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte de Clientes", href: "/clicks/reporteclientes" },
        ],
    },
});

children.push({
    path: "/clicks/reporteantiguamiento",
    name: "CLC_RPTANTICUA",
    component: () =>
        import ("../views/ReportClicks/ClcReportAntiquity/ClcReportAntiquity.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte de Antiguamiento", href: "/clicks/reporteantiguamiento" },
        ],
    },
});


children.push({
    path: "/clicks/reportecortolargo",
    name: "CLC_RPTCORTLARGE",
    component: () =>
        import ("../views/ReportClicks/ClcShortLong/ClcShortLong.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Corto Largo", href: "/clicks/reportecortolargo" },
        ],
    },
});


children.push({
    path: "/clicks/reporteresoluciones",
    name: "CLC_RPTRESOLUCTIONS",
    component: () =>
        import ("../views/ReportClicks/ClcReportResolutions/ClcReportResolution.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Resoluciones", href: "/clicks/reporteresoluciones" },
        ],
    },
});

children.push({
    path: "/clicks/reportemorosidad",
    name: "CLC_RPTMOROSIDAD",
    component: () =>
        import ("../views/ReportClicks/ClcReportMoor/ClcReportMoor.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Morosidad", href: "/clicks/reportemorosidad" },
        ],
    },
});


children.push({
    path: "/clicks/reportedevengado",
    name: "CLC_RPTDEVENGADO",
    component: () =>
        import ("../views/ReportClicks/ClcReportAccrued/ClcReportAccrued.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Devengado", href: "/clicks/reportedevengado" },
        ],
    },
});


children.push({
    path: "/clicks/reporteusos",
    name: "CLC_RPTUSE",
    component: () =>
        import ("../views/ReportClicks/ClcReportUse/ClcReportUseIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Usos", href: "/clicks/reporteusos" },
        ],
    },
});

children.push({
    path: "/clicks/reporterecursoshh",
    name: "CLC_RPTRRHH",
    component: () =>
        import ("../views/ReportClicks/ClcReportRRHH/ClcReportHumanResources.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Recursos H.", href: "/clicks/reporterecursoshh" },
        ],
    },
});

children.push({
    path: "/clicks/reporteexigibles",
    name: "CLC_RPTEXIGIBLE",
    component: () =>
        import ("../views/ReportClicks/ClcReportPayable/ClcReportPayable.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Clicks", disabled: true },
            { text: "Reporte Exigibles", href: "/clicks/reporteexigibles" },
        ],
    },
});


children.push({
    path: "/clicks/analisisventasupload",
    name: "CLC_ClcSalesAnalysis_Upload",
    component: () =>
        import ("../views/ReportClicks/ClcSalesAnalysis/ClcSalesAnalysisUpload.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Analisis de Ventas", disabled: true },
            { text: "Upload", href: "/clicks/analisisventasupload" },
        ],
    },
});

children.push({
    path: "/clicks/analisisventas",
    name: "CLC_SALESANALSYS_DASHBOARD",
    component: () =>
        import ("../views/ReportClicks/ClcSalesAnalysis/ClcSalesAnalysisDashboard.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Analisis de Ventas", disabled: true },
            { text: "Dashboard", href: "/clicks/analisisventas" },
        ],
    },
});



















export { children }