import Service from "../Service";

const resource = "cotization/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byId", parameters, {
            params: { requestID: requestID },
        });
    },

    itemsproviderbyID(CtpID, CttID, requestID) {
        return Service.post(resource + "itemsproviderbyid", {}, {
            params: { CtpID, CttID, requestID: requestID },
        });
    },

    saveParticipants(obj, requestID) {
        return Service.post(resource + "saveparticipants", obj, {
            params: { requestID: requestID },
        });
    },

    updateParticipants(obj, requestID) {
        return Service.post(resource + "updateparticipants", obj, {
            params: { requestID: requestID },
        });
    },

    sendCircuit(obj, requestID, AccID) {
        return Service.post(resource + "sendcircuit", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    saveGanador(obj, requestID) {
        return Service.post(resource + "saveganador", obj, {
            params: { requestID: requestID },
        });
    },

    changeStatus(obj, requestID) {
        return Service.post(resource + "changeStatus", obj, {
            params: { requestID: requestID },
        });
    }

    /* byRuc(document, requestID) {
        return Service.post(resource + "byRuc", {}, {
            params: { document, requestID: requestID },
        });
    } */
};