let children = [];
children.push({
    path: "/configuracion/circuitosaprobacionlegal",
    name: "LGL_APPROBALCIRCUIT",
    component: () =>
        import ("../views/Legal/LglAutorizeModel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Circuitos Aprobacion", href: "/configuracion/circuitosaprobacionlegal" },
        ],
    },
});

children.push({
    path: "/solicitudes/nuevasolicitud",
    name: "LGL_SOLICITUDNEW",
    component: () =>
        import ("../views/Legal/Requirement/LglRequirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Solicitud", href: "/solicitudes/nuevasolicitud" },
        ],
    },
});

children.push({
    path: "/legal/panel",
    name: "LGL_PANEL",
    component: () =>
        import ("../views/Legal/Requirement/LglPanel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Panel", href: "/legal/panel" },
        ],
    },
});

children.push({
    path: "/legal/files",
    name: "LGL_FILES",
    component: () =>
        import ("../views/Legal/LglFiles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Configuracion", href: "/legal/files" },
        ],
    },
});

children.push({
    path: "/legal/casoslegales",
    name: "LGL_COMPLAINTS",
    component: () =>
        import ("../views/Legal/Complaints/LglComplaints.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Casos Legales", href: "/legal/casoslegales" },
        ],
    },
});
children.push({
    path: "/legal/reportecasoslegales",
    name: "LGL_COMPLAINTS_REPORT",
    component: () =>
        import ("../views/Legal/Complaints/LglComplaintsReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Casos Legales - Reportes", href: "/legal/reportecasoslegales" },
        ],
    },
});

/* children.push({
    path: "/legal/reportesolicitudes",
    name: "LGL_REQUIREMENT_REPORT",
    component: () =>
        import ("../views/Legal/Requirement/LglRequirementReport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Casos Legales - Reportes", href: "/legal/reportesolicitudes" },
        ],
    },
}); */


children.push({
    path: "/legal/resumengeneral",
    name: "LGL_COMPLAINTS_RG",
    component: () =>
        import ("../views/Legal/Complaints/LglRequirementReportBI.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Casos Legales - Reportes", href: "/legal/resumengeneral" },
        ],
    },
});
children.push({
    path: "/legal/reporterequiremetbi",
    name: "LGL_REQUIREMENTREPORT_BI",
    component: () =>
        import ("../views/Legal/Requirement/LglRequirementReportBI.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Legal", disabled: true },
            { text: "Contratos - Reportes BI", href: "/legal/reporterequiremetbi" },
        ],
    },
});



export {
    children
}