<template>
<div>
    <div>
        <s-toolbar close @close="$emit('close')" />
        <v-card outlined>
            <v-col>
                <v-row>
                    <!--  -->
                    <v-col lg="3" md="3" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top: 0px" color="#eaf2ff">
                                    <v-card-title style="margin-top: -10px;margin-bottom: -10px;font-family: ;">
                                        Datos Generales
                                    </v-card-title>

                                </v-card>
                            </v-row>
                        </v-col>
                        <v-card outlined elevation="0" height="auto">
                            <v-col class="">
                                <v-row>
                                    <v-col cols="12" class="s-col-text">
                                        <s-select-definition label="Tipo Persona" :def="1192" v-model="supplier.TypePerson"></s-select-definition>
                                    </v-col>
                                    <v-col class="s-col-text" cols="12">
                                        <s-text :outlined="false" label="Ruc" number v-model="supplier.ProviderRUC" disabled>
                                            <!-- <template v-slot:prepend>
                                                <v-icon >mdi-magnify</v-icon>
                                            </template> -->
                                        </s-text>
                                    </v-col>
                                    <v-col cols="12" class="s-col-text">
                                        <s-textarea style="font-family: " label="Razon Social" v-model="supplier.ProviderName"></s-textarea>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: " label="Contacto" v-model="supplier.ConName" ref="ConName"></s-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: " number label="Telefono" v-model="supplier.ProviderTelefono" ref="ProviderTelefono"></s-text>
                                    </v-col>

                                    <v-col cols="12" class="s-col-text">
                                        <s-text style="font-family: " label="Email" v-model="supplier.ProviderEmail" ref="ProviderEmail"></s-text>
                                    </v-col>
                                    <v-col cols="6" class="s-col-text">
                                        <s-text style="font-family: " label="Nombre Comercial" v-model="supplier.PrvTradename" ref="PrvTradename"></s-text>
                                    </v-col>
                                    <v-col cols="6" class="s-col-text">
                                        <s-text style="font-family: " label="Giro Comercial" v-model="supplier.PrvCommercialBusiness" ref="PrvCommercialBusiness"></s-text>
                                    </v-col>
                                    <v-col cols="12" class="s-col-text">
                                        <s-textarea style="font-family: " label="Direccion Fiscal" v-model="supplier.ProviderAddress" ref="ProviderAddress"></s-textarea>
                                    </v-col>

                                    <v-col cols="12" class="s-col-text">
                                        <s-date label="Fecha en que se Constituyó" v-model="supplier.PrvDateConstitute"></s-date>
                                    </v-col>

                                    <v-divider></v-divider>

                                    <!-- <v-col cols="6" class="s-col-text">
                                        <s-text style="font-family: " label="Objeto Social" ></s-text>
                                    </v-col>
                                    <v-col cols="6" class="s-col-text">
                                        <s-text style="font-family: " label="Actividad Económica - Principal" ></s-text>
                                    </v-col> -->
                                    <v-col cols="12" class="s-col-text">
                                        <s-text style="font-family: " label="Rubro Principal" v-model="supplier.PrvMainItem" ref="PrvMainItem"></s-text>
                                    </v-col>

                                    <v-divider></v-divider>

                                    <v-col cols="12" class="s-col-text">
                                        <v-radio-group v-model="supplier.PrvWithholdingAgent" dense style="margin-top:0px;margin-bottom:-12px">
                                            <v-divider></v-divider>
                                            Agente Retencion
                                            <v-divider></v-divider>
                                            <v-radio value="1">
                                                <template v-slot:label>
                                                    <div><strong class="error--text">Si</strong></div>
                                                </template>
                                            </v-radio>
                                            <v-radio value="2" style="margin-top: -10px">
                                                <template v-slot:label>
                                                    <div><strong class="primary--text">No</strong></div>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12" class="s-col-text">
                                        <s-select-definition :def="1190" label="Tipo de Regimen Tributario" v-model="supplier.TypeofTaxRegime"></s-select-definition>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <b>Comprobantes de Pago</b>
                                        <v-divider></v-divider>
                                        <v-checkbox style="margin-bottom: -30px; margin-top: -10px" label="Boleta" color="red" hide-details v-model="supplier.PrvB"></v-checkbox>
                                        <v-checkbox style="margin-bottom: -30px" label="Factura" color="red" hide-details v-model="supplier.PrvF"></v-checkbox>
                                        <v-checkbox style="margin-bottom: -30px" label="Recibo por Honorarios" color="red" hide-details v-model="supplier.PrvRH"></v-checkbox>
                                    </v-col>
                                    <v-divider></v-divider>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                        <b>Venta</b>
                                        <v-divider></v-divider>
                                        <v-checkbox style="margin-bottom: -30px; margin-top: -10px" label="Bienes" color="red" hide-details v-model="supplier.PrvEstate"></v-checkbox>
                                        <v-checkbox style="margin-bottom: -30px" label="Servicios" color="red" hide-details v-model="supplier.PrvServices"></v-checkbox>
                                    </v-col>

                                    <v-col cols="12" class="mt-3" v-if="isView">
                                        <v-btn dark block class="capitalize btn-primary" color="info" small elevation="0" @click="save()">
                                            Actualizar Datos
                                        </v-btn>
                                        <v-divider></v-divider>
                                    </v-col>

                                </v-row>
                                <v-divider style="margin-top: 20px" horizontal></v-divider>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="9" md="9" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top: 0px">
                                    <v-card-title style="margin-top: -10px;margin-bottom: -10px;font-family: ;">
                                        Actividad(es) Económica(s):<v-btn v-if="isView" class="capitalize save" icon color="info" fab x-small elevation="0" @click="addEconomicActivities()">
                                            <v-icon style="font-size:14px; border-radius: 10px" class="mr-0">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>

                        <v-col cols="12">
                            <v-card outlined elevation="0" height="auto">

                                <v-col class="">
                                    <v-row v-if="isView">
                                        <v-col cols="4" class="s-col-text">
                                            <s-select-definition label="Tipo Actividad" :def="1193" v-model="economicActivities.TypeEconomicActivities"></s-select-definition>
                                        </v-col>
                                        <v-col cols="8" class="s-col-text">
                                            <s-text style="font-family: " label="Descripciòn" v-model="economicActivities.PeaDescription" ref="PeaDescription"></s-text>
                                        </v-col>
                                    </v-row>
                                    <v-divider :style="isView ? 'margin-top: 20px' : ''" horizontal></v-divider>
                                    <v-card outlined>
                                        <v-data-table disable-sort :headers="headersEconomicActivities" :items="itemsEconomicActivities" style="font-family: " dense :items-per-page="-1" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : 'auto'" hide-default-footer>
                                            <template v-slot:item.Deleted="{item}" v-if="isView">
                                                <v-btn icon text small @click="deleteItem(item, 1)">
                                                    <v-icon style="font-size:18px; color:red">mdi-minus-circle-outline</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-col>

                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top: 0px">
                                    <v-card-title style="margin-top: -10px;margin-bottom: -10px;font-family: ;">
                                        Datos Representante Legal<v-btn v-if="isView" class="capitalize save" icon color="info" fab x-small elevation="0" @click="addRepresentative()">
                                            <v-icon style="font-size:14px; border-radius: 10px" class="mr-0">mdi-plus-circle-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>

                        <v-col cols="12">
                            <v-card outlined elevation="0" height="auto">

                                <v-col class="">
                                    <v-row v-if="isView">
                                        <v-col cols="4" class="s-col-text">
                                            <s-select-definition style="font-family: " label="Tipo Documento" :def="1155" v-model="representative.TypeDocument"></s-select-definition>
                                        </v-col>
                                        <v-col class="s-col-text" cols="8">
                                            <s-text :outlined="false" label="Numero de Documento de Identidad" number :autofocus="true" v-model="representative.PlrDocumentNumber" ref="PlrDocumentNumber">
                                                <!--  <template v-slot:prepend>
                                                    <v-icon>mdi-magnify</v-icon>
                                                </template> -->
                                            </s-text>
                                        </v-col>
                                        <v-col cols="12" class="s-col-text">
                                            <s-text style="font-family: " label="Nombre Del Representante Legal" v-model="representative.PlrName" ref="PlrName"></s-text>
                                        </v-col>

                                        <v-col cols="4" class="s-col-text">
                                            <s-date style="font-family: " label="Fecha De Nacimiento " v-model="representative.PlrDateBirth"></s-date>
                                        </v-col>

                                        <v-col cols="4" class="s-col-text">
                                            <s-text style="font-family: " number label="Telefono" v-model="representative.PlrTelephone" ref="PlrTelephone"></s-text>
                                        </v-col>

                                        <v-col cols="4" class="s-col-text">
                                            <s-text style="font-family: " label="Email" v-model="representative.PlrEmail" ref="PlrEmail"></s-text>
                                        </v-col>

                                        <v-col cols="6" class="s-col-text">
                                            <s-text style="font-family: " label="Cargo" v-model="representative.PlrPosition" ref="PlrPosition"></s-text>
                                        </v-col>

                                        <v-col cols="6" class="s-col-text">
                                            <s-text style="font-family: " label="Nacionalidad" v-model="representative.PlrNationality" ref="PlrNationality"></s-text>
                                        </v-col>

                                    </v-row>
                                    <v-divider :style="isView ? 'margin-top: 20px' : ''" horizontal></v-divider>
                                    <v-card outlined>
                                        <v-data-table disable-sort :headers="headersRepresentative" :items="itemsRepresentative" style="font-family: " dense :items-per-page="-1" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : 'auto'" hide-default-footer>
                                            <template v-slot:item.PlrDateBirth="{item}">
                                                {{item.PlrDateBirth !== null ? $moment(item.PlrDateBirth).format($const.FormatDateDB) : ''}}
                                            </template>
                                            <template v-slot:item.Deleted="{item}" v-if="isView">
                                                <v-btn icon text small @click="deleteItem(item, 2)">
                                                    <v-icon style="font-size:18px; color:red">mdi-minus-circle-outline</v-icon>
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-card>
                        </v-col>
                        <v-row>

                            <v-col lg="6" md="6" cols="12">
                                <v-col cols="12" class="pr-0 pl-0">
                                    <v-row>
                                        <v-card outlined width="100%" style="margin-top: 10px">
                                            <v-card-title style="margin-top: -10px;margin-bottom: -10px;font-family: ;">
                                                Información Bancaria<v-btn v-if="isView" class="capitalize save" icon color="info" fab x-small elevation="0" @click="addBank()">
                                                    <v-icon style="font-size:14px; border-radius: 10px" class="mr-0">mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-card-title>
                                        </v-card>
                                    </v-row>
                                </v-col>

                                <v-col cols="12">
                                    <v-card outlined elevation="0" height="auto">

                                        <v-col class="">
                                            <v-row v-if="isView">
                                                <v-col class="s-col-text" cols="12">
                                                    <s-select-definition label="Banco" :def="1176" v-model="banks.TypeBank"></s-select-definition>
                                                </v-col>
                                                <v-col cols="12" class="s-col-text">
                                                    <s-text style="font-family: " label="Nombre Beneficiario" v-model="banks.PbkBeneficiaryName" ref="PbkBeneficiaryName"></s-text>
                                                </v-col>

                                                <v-col cols="4" class="s-col-form">
                                                    <s-text label="Número De Cuenta Bancaria Corriente" v-model="banks.PbkBill" ref="PbkBill"> </s-text>
                                                </v-col>
                                                <v-col cols="4" class="s-col-form">
                                                    <s-text label="Número De Cuenta Interbancario" v-model="banks.PbkCci" ref="PbkCci"> </s-text>
                                                </v-col>
                                                <v-col class="s-col-text" cols="4">
                                                    <s-select-definition label="Moneda" :def="1129" v-model="banks.TypeCurrency"></s-select-definition>
                                                </v-col>

                                            </v-row>
                                            <v-divider :style="isView ? 'margin-top: 20px' : ''" horizontal></v-divider>
                                            <v-card outlined>
                                                <v-data-table disable-sort :headers="headersBanks" :items="itemsBanks" style="font-family: " dense :items-per-page="-1" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : 'auto'" hide-default-footer>
                                                    <template v-slot:item.Deleted="{item}" v-if="isView">
                                                        <v-btn icon text small @click="deleteItem(item, 3)">
                                                            <v-icon style="font-size:18px; color:red">mdi-minus-circle-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-col>
                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col lg="6" md="6" cols="12">
                                <v-col cols="12" class="pr-0 pl-0">
                                    <v-row>
                                        <v-card outlined width="100%" style="margin-top: 10px">
                                            <v-card-title style="margin-top: -10px;margin-bottom: -10px;font-family: ;">
                                                Adjuntos <v-btn v-if="isView" class="capitalize save" icon color="info" fab x-small elevation="0" @click="addAttach()">
                                                    <v-icon style="font-size:14px; border-radius: 10px" class="mr-0">mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-card-title>
                                        </v-card>
                                    </v-row>
                                </v-col>

                                <v-col cols="12">
                                    <v-card outlined elevation="0" height="auto">

                                        <v-col class="">
                                            <v-row v-if="isView">
                                                <v-col class="s-col-text" cols="4">
                                                    <s-select-definition :def="1191" label="Tipo Archivo" return-object v-model="objTypeAttach"></s-select-definition>
                                                </v-col>
                                                <v-col cols="8" class="s-col-text">
                                                    <v-file-input accept=".pdf" show-size counter label="Archivo" v-model="Attach">
                                                    </v-file-input>
                                                </v-col>

                                            </v-row>
                                            <v-divider :style="isView ? 'margin-top: 20px' : ''" horizontal></v-divider>
                                            <v-card outlined>
                                                <v-data-table disable-sort :headers="headersAttach" :items="itemsAttach" style="font-family: " dense :items-per-page="-1" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : 'auto'" hide-default-footer>
                                                    <template v-slot:item.PbaAttach="{ item }">
                                                        <v-icon color="green" @click="openFile({name: item.PbaAttach})">mdi-download</v-icon>
                                                        {{item.PbaAttach}}
                                                    </template>
                                                    <template v-slot:item.Deleted="{item}" v-if="isView">
                                                        <v-btn icon text small @click="deleteItem(item, 4)">
                                                            <v-icon style="font-size:18px; color:red">mdi-minus-circle-outline</v-icon>
                                                        </v-btn>
                                                    </template>
                                                </v-data-table>

                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-col>
                            </v-col>

                        </v-row>
                        <v-row>

                        </v-row>
                        <v-col style="padding-top: 24px">
                        </v-col>
                    </v-col>
                    <v-divider vertical></v-divider>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sSupplier from "@/services/Logistics/SupplierService";
import _sGeneral from "@/services/HelperService.js";
//Components
import vSupplier from "@/views/Logistics/vSupplier.vue";

export default {
    components: {
        vSupplier
    },
    props: {
        value: {
            default: null,
            type: Number
        },
        view :{
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            headersBanks: [{
                    text: "Del",
                    value: "Deleted"
                },
                {
                    text: "Banco",
                    value: "TypeBankName"
                },
                {
                    text: "Beneficiario",
                    value: "PbkBeneficiaryName",
                   
                },
                {
                    text: "Cuenta Corriente",
                    value: "PbkBill",
                    
                },
                {
                    text: "Cuenta Interbancaria",
                    value: "PbkCci"
                },
                {
                    text: "Moneda",
                    value: "TypeCurrencyName"
                },
            ],
            headersRepresentative: [{
                    text: "Del",
                    value: "Deleted"
                }, {
                    text: "Tipo Documento",
                    value: "TypeDocumentName"
                },
                {
                    text: "Doc. de Identidad",
                    value: "PlrDocumentNumber"
                },
                {
                    text: "Representante",
                    value: "PlrName"
                },
                {
                    text: "Fecha De Nacimiento",
                    value: "PlrDateBirth"
                },
                {
                    text: "Telefono",
                    value: "PlrTelephone"
                },
                {
                    text: "Email",
                    value: "PlrEmail"
                },
                {
                    text: "Cargo",
                    value: "PlrPosition"
                },
                {
                    text: "Nacionalidad",
                    value: "PlrNationality"
                },
            ],
            headersAttach: [{
                    text: "Del",
                    value: "Deleted"
                }, {
                    text: "Tipo Archivo",
                    value: "TypeAttachName"
                },
                {
                    text: "Archivo",
                    value: "PbaAttach"
                },
            ],

            headersEconomicActivities: [{
                    text: "Del",
                    value: "Deleted"
                }, {
                    text: "Tipo",
                    value: "TypeEconomicActivitiesName",
                    width: 150
                },
                {
                    text: "Descripcion",
                    value: "PeaDescription"
                }
            ],
            itemsEconomicActivities: [],
            itemsBanks: [],
            itemsRepresentative: [],
            itemsAttach: [],
            supplier: {
                TypePerson: null,
                ProviderRUC: null,
                ProviderName: null,
                ConName: null,
                PrvTradename: null,
                ProviderAddress: null,
                PrvCountry: null,
                PrvDepartment: null,
                PrvProvince: null,
                PrvDistrict: null,
                ProviderTelefono: null,
                ProviderEmail: null,
                PrvDateConstitute: null,
                PrvCommercialBusiness: null,
                PrvSocialObject: null,
                PrvEconomicActivity: null,
                PrvMainItem: null,
                PrvEstate: false,
                PrvServices: false,
                PrvWithholdingAgent: "2",
                TypeofTaxRegime: null,
                PrvB: false,
                PrvF: false,
                PrvRH: false,
            },
            representative: {
                PlrName: null,
                PlrNationality: null,
                TypeDocument: null,
                PlrDocumentNumber: null,
                PlrPosition: null,
                PlrDateBirth: null,
                PlrTelephone: null,
                PlrEmail: null
            },
            banks: {
                TypeBank: null,
                PbkBill: null,
                PbkCci: null,
                PbkBeneficiaryName: null,
                TypeCurrency: null
            },
            attach: {
                TypeAttach: null,
                PbaAttach: null,
                PbaSize: null
            },
            objTypeAttach: null,
            economicActivities: {
                TypeEconomicActivities: null,
                PeaDescription: null
            },
            Attach: null

        }
    },
    computed: {
        isView(){
            return this.view 
        }
    },

    created() {
        if (this.value != null) {
            this.initialize()
        }
    },

    mounted() {

    },

    watch: {},

    methods: {

        initialize() {
            _sSupplier.byID({
                PrvID: this.value
            }, this.$fun.getUserID()).then((r) => {
                this.supplier = r.data
                this.itemsBanks = this.supplier.itemsBanks
                this.itemsRepresentative = this.supplier.itemsRepresentative
                this.itemsAttach = this.supplier.itemsAttach
                this.itemsEconomicActivities = this.supplier.itemsEconomicActivities

            });
        },
        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 7).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        save() {

            if (this.supplier.ConName == null || this.supplier.ConName.length <= 0) {
                this.$refs.ConName.error("Ingrese Contacto")
                return
            }

            if (this.supplier.ConName.length < 3) {
                this.$refs.ConName.error("Contacto Inválido,")
                return
            }

            if (
                this.supplier.ProviderTelefono == null ||
                this.supplier.ProviderTelefono == ""
            ) {
                this.$refs.ProviderTelefono.error("Ingrese Nro. Celular");
                return;
            }

            if (this.supplier.ProviderTelefono.length < 9) {
                this.$refs.ProviderTelefono.error("Nro. Celular Inválido,")
                return
            }

            if (this.supplier.PrvTradename == null || this.supplier.PrvTradename.length <= 0) {
                this.$refs.PrvTradename.error("Ingrese Nombre Comercial")
                return
            }

            if (this.supplier.PrvCommercialBusiness == null || this.supplier.PrvCommercialBusiness.length <= 0) {
                this.$refs.PrvCommercialBusiness.error("Ingrese Giro Comercial")
                return
            }

            if (this.supplier.ProviderAddress == null || this.supplier.ProviderAddress.length <= 0) {
                this.$refs.ProviderAddress.error("Ingrese Dirección Fiscal")
                return
            }

            if (this.supplier.PrvMainItem == null || this.supplier.PrvMainItem.length <= 0) {
                this.$refs.PrvMainItem.error("Ingrese Rubro Principal")
                return
            }

            if (this.$moment(this.supplier.PrvDateConstitute).format(this.$const.FormatDateDB) > this.$fun.getDate()) {
                alert("Fecha en que se Constituyó no puede mayor a la fecha actual.")
                return
            }

            this.supplier.UsrUpdateID = this.$fun.getUserID()

            if (!this.$fun.isValidEmail(this.supplier.ProviderEmail)) {
                this.$refs.ProviderEmail.error("Correo invalido");
                return;
            }

            console.log(this.supplier)
            _sSupplier.save(this.supplier, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.initialize()
                    alert("Datos Actualizado correctamente")
                }
            })
        },
        addEconomicActivities() {

            if (this.economicActivities.TypeEconomicActivities == null) {
                this.$fun.alert("Ingrese Tipo de Actividad", "warning")
                return
            }

            if (this.economicActivities.PeaDescription == null || this.economicActivities.PeaDescription.length <= 0) {
                this.$refs.PeaDescription.error("Ingrese Descripcion")
                return
            }

            this.economicActivities.PrvID = this.supplier.PrvID
            this.economicActivities.UsrCreateID = this.$fun.getUserID()

            _sSupplier.saveEcoactivities(this.economicActivities, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {

                    this.initialize()
                    alert("Datos registrados correctamente")
                    console.log("addEconomicActivities", resp)
                }
            })

        },
        addRepresentative() {

            if (this.representative.TypeDocument == null) {
                this.$refs.PlrName.error("Seleccione Tipo Documento")
                return
            }

            if (this.representative.PlrDocumentNumber == null || this.representative.PlrDocumentNumber.length <= 0) {
                this.$refs.PlrDocumentNumber.error("Ingrese Nro. Documento")
                return
            }
            if (this.representative.PlrDocumentNumber.length < 8) {
                this.$refs.PlrDocumentNumber.error("Nro. Documento Inválido")
                return
            }

            if (this.representative.PlrName == null || this.representative.PlrName.length <= 0) {
                this.$refs.PlrName.error("Ingrese Nombre Beneficiario")
                return
            }
            if (this.representative.PlrName.length < 3) {
                this.$refs.PlrName.error("Nombre Inválido,")
                return
            }

            console.log(this.$fun.calculateAge(this.representative.PlrDateBirth))
            if (!this.$fun.isValidDate(this.representative.PlrDateBirth)) {
                this.$fun.alert("Fecha Nacimiento Inválida", "warning")
                return
            }

            if (this.$fun.calculateAge(this.representative.PlrDateBirth) < 18) {
                this.$fun.alert("Fecha Nacimiento Inválida, Debe ser Mayor a 17 años", "warning")
                return
            }

            if (
                this.representative.PlrTelephone == null ||
                this.representative.PlrTelephone == ""
            ) {
                this.$refs.PlrTelephone.error("Ingrese Telefono");
                return;
            }

            if (!this.$fun.isValidEmail(this.representative.PlrEmail)) {
                this.$refs.PlrEmail.error("Correo invalido");
                return;
            }

            if (this.representative.PlrPosition == null || this.representative.PlrPosition.length <= 0) {
                this.$refs.PlrPosition.error("Ingrese Cargo")
                return
            }

            if (this.representative.PlrNationality == null || this.representative.PlrNationality.length <= 0) {
                this.$refs.PlrNationality.error("Ingrese Nacionalidad")
                return
            }

            this.representative.PrvID = this.supplier.PrvID
            this.representative.UsrCreateID = this.$fun.getUserID()

            _sSupplier.saveRepresentative(this.representative, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.initialize()
                    alert("Datos registrados correctamente")
                    console.log("addRepresentative", resp)
                }
            })
        },
        addBank() {

            console.log(this.banks)

            if (this.banks.PbkBeneficiaryName == null || this.banks.PbkBeneficiaryName.length <= 0) {
                this.$refs.PbkBeneficiaryName.error("Ingrese Nombre Beneficiario")
                return
            }
            if (this.banks.PbkBeneficiaryName.length <= 3) {
                this.$refs.PbkBeneficiaryName.error("Nombre Inválido,")
                return
            }
            if (this.banks.PbkBill == null || this.banks.PbkBill.length <= 0) {
                this.$refs.PbkBill.error("Ingrese Número De Cuenta Bancaria Corriente")
                return
            }
            if (this.banks.PbkCci == null || this.banks.PbkCci.length <= 0) {
                this.$refs.PbkCci.error("Ingrese Número De Cuenta Interbancario")
                return
            }
            if (this.banks.TypeCurrency == null) {
                this.$fun.alert("Seleccione Moneda", "warning")
                return
            }

            this.banks.PrvID = this.supplier.PrvID
            this.banks.UsrCreateID = this.$fun.getUserID()

            _sSupplier.saveBanks(this.banks, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.initialize()
                    alert("Datos registrados correctamente")
                }
            })
        },
        addAttach() {

            var array = []
            array.push({
                RqdDescription: this.objTypeAttach !== null ? this.objTypeAttach.DedDescription : '',
                RqdRequired: 1,
                RqdAttach: this.Attach,
                RqdSize: 10485760,
                RqdAccept: ".pdf",
            })
            if (this.$fun.isValidExtension(array)) {
                return
            }

            if (this.$fun.isValidTamanio(array)) {
                return
            }
            this.attach.TypeAttach = this.objTypeAttach !== null ? this.objTypeAttach.DedValue : null,
                this.attach.PrvID = this.supplier.PrvID
            this.attach.UsrCreateID = this.$fun.getUserID()

            let files = [];
            if (this.Attach) files.push(this.Attach);
            var formData = new FormData();
            let i = 0;
            files.forEach((element) => {
                formData.append(i.toString(), element);
                i++;
            });

            _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 7).then(resp => {

                if (resp.status == 200) {
                    this.attach.PbaAttach = this.Attach != null ? resp.data[0].RqdAttach : "";
                    this.attach.PbaSize = this.Attach.size;

                    _sSupplier.saveAttach(this.attach, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.initialize()
                            alert("Datos registrados correctamente")
                            console.log("addAttach", resp)
                        }
                    })
                }

                console.log("addAttach", this.attach)

            });

            return

        },

        deleteItem(item, op) {
            console.log(item, op)

            item.SecStatus = 0;
            item.UsrUpdateID = this.$fun.getUserID()

            if (item == null) return

            if (op == 1) {

                _sSupplier.saveEcoactivities(item, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {
                        this.initialize()
                        alert("Registro eliminado correctamente")
                    }
                })
            }

            if (op == 2) {
                _sSupplier.saveRepresentative(item, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {
                        this.initialize()
                        alert("Registro eliminado correctamente")
                    }
                })
            }

            if (op == 3) {
                _sSupplier.saveBanks(item, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {
                        this.initialize()
                        alert("Registro eliminado correctamente")
                    }
                })
            }

            if (op == 4) {
                _sSupplier.saveAttach(item, this.$fun.getUserID()).then(resp => {
                    if (resp.status == 200) {
                        this.initialize()
                        alert("Registro eliminado correctamente")
                    }
                })
            }
        }
    },
};
</script>
