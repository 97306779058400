<template>
<v-switch style="padding-top:8px !important;margin-top:0px;" hint="" hide-details="false" @input="input($event)" @change="input($event)" :color="color" :label="label" :true-value="trueValue" :false-value="falseValue" :disabled="disabled" :value="item" />
</template>

<script>
export default {
    components: {},
    props: {
        value: null,
        label: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: "accent"
        },
        trueValue: {
            default: 1
        },
        falseValue: {
            default: 0
        },
        disabled: {
            default: false
        },
    },
    watch: {
        value(value) {
            this.item = value;
            this.$emit("input", this.item);
        },
    },
    data: () => ({
        //ComboBox
        item: null,
    }),
    methods: {
        input(e) {
            // this.$emit("change", this.item);
            this.$emit("input", e);
        },
    },
    created() {},
    mounted() {
        // if(this.item)
        if (this.value) this.item = this.value;
        else this.item = 0;
        this.$emit("input", this.item);
    },
};
</script>
