let children = []

children.push({
    path: "/consultallamadas",
    name: "Mng_Asterisk",
    component: () =>
        import ("../views/Management/Asterisk.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Central Telefonica", disabled: true },
            { text: "Consultar Llamadas ", href: "/consultallamadas" },
        ],
    },
});

/* children.push({
    path: "/controlcliente",
    name: "MNG_ACCESSSUTOMER",
    component: () =>
        import ("../views/Management/AccessCustomer/AccessControlCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Acceso Clientes", disabled: true },
            { text: "Registro Visitas", href: "/controlcliente" },
        ],
    },
}); */




export { children }