import Service from "../Service";

const resource = "outputproduct/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID, AccID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byId", parameters, {
            params: { requestID: requestID },
        });
    },



};