import Service from "../Service";
import Vue from "vue";
const resource = "generic/";

export default {

    getUrlSearchArea() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/AreasSearch";
    },

    searcharea(area, type, requestID) {
        return Service.post(
            resource + "areassearch", {}, { params: { search: area, TypeArea: type, requestID: requestID } }
        );
    },

    getUrlPosition() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/PositionSearch";
    },

    getUrlSearchProperty() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/PropertySettings";
    },
    getUrlPropertyTables() {
        return (
            Vue.prototype.$http.defaults.baseURL + "/generic/propertysettingstables"
        );
    },
    getURLTransaction() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/Transaction";
    },
    getURLContract() {
        return Vue.prototype.$http.defaults.baseURL + "/generic/Contract";
    },
    searchPropertyValues(text, requestID) {
        return Service.post(
            resource + "propertysettingsvalues", {}, { params: { search: text, requestID: requestID } }
        );
    },

};