export default {

    //BASEURL: "http://localhost:63130/api",
    //URL: "http://localhost:63130/",

    BASEURL: "https://190.223.56.84/api",
    URL: "https://190.223.56.84/",

    //BASEURL: "http://172.21.1.213:8185/api",
    //URL: "http://172.21.1.213:8185/",

    FormatDateDB: "YYYY-MM-DD",
    FormatDateDBNiubiz: "YYYY-MM-DDTHH:mm:ss-0000",
    FormatDateTimeDB: "YYYY-MM-DD HH:mm",
    FormaTimeDB: "YYYY-MM-DD HH:mm",
    FormatDateView: "DD/MM/YYYY",
    FormatDateTimeView: "DD/MM/YYYY HH:mm",
    FormatTimeDB: " HH:mm",
    FormatTimeView: " HH:mm",
    FormatDateMask: "##/##/####",
    FormatDateTimeMask: "##/##/#### ##:##",
    TypeLevel: {
        Administrador: 1,
        Asistente: 2,
        Jefatura: 3,
        Gerencia: 4,
        PorPlanilla: 5,
        PorArea: 6,
    },
    TypeFile: {
        EXCEL: { ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", Extension: "xlsx" },
        PDF: { ContentType: "application/pdf", Extension: "pdf" },
        ZIP: { ContentType: "application/pdf", Extension: "zip" },
    },
};