<template>
<div>
    <v-dialog v-if="processing==true" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="white" dark>
            <v-progress-linear indeterminate color="red" class="mb-0 mt-2"></v-progress-linear>
            <v-card-text v-if="messageProcessing.length == 0">
                <v-img class="mt-4" style="margin-left: 20%;" src="../../assets/Loader.gif" height="200" width="200"></v-img>
                <h2 style="color:black;text-align: center" class="pt-5">Procesando</h2>
                <h4 style="color:black;text-align: center" class="pt-1">Por favor espere...</h4>
            </v-card-text>
            <v-card-text v-else>
                <div class="pt-4" style="color: black">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    props: {
        processing: {
            type: Boolean,
            default:false
        },
        messageProcessing:{
            type: String,
            default: ""
        }
    },
    data: () => ({
        /* return {
            processing2: false,
            messageProcessing2: "",
        }; */
    }),

   /*  created(){
        this.processing2 = this.processing
        this.messageProcessing2 = this.messageProcessing
    } */

};
</script>

<style lang="scss" scoped>

</style>
