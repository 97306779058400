<template>
<div>

    <!-- <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading> -->

    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <vc-modal-circuit close :circuits="circuits" :TrsID="TrsID" :dialog="dialogCircuit" @close="dialogCircuit = false;$emit('close')" :width="circuits.length > 1 ? 940 : 500" />

    <!--CIRCUITO DE APRBACIONES MODAL-->
   <!--  <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">
            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row style="">
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-data-table dense item-key="AccID" :items="circuits" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center'},
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div> -->

    <div v-if="dialogBuget">
        <v-dialog persistent v-model="dialogBuget" width="70%">
            <s-toolbar dark label="Lista de Errores" @close="dialogBuget = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row style="">
                        <v-col cols="12">
                            <h2>No se encontró Presupuesto asignado para las siguientes distribuciones:</h2>
                        </v-col>
                        <v-col cols="12">
                            <ul>
                                <!-- <li v-for="item in errorBuget">
                                    {{item}}
                                </li> -->

                                <li v-for="item in errorBuget">
                                    {{item.BgtPtdGFName}} || {{item.BgtPrjCecoName}}
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <v-dialog v-model="dialogDetail" v-if="dialogDetail" width="70%">
        <s-toolbar close @close="dialogDetail=false">
            <template v-slot:options>
                <div class="mt-0 mr-3">
                    <label><b>Seleccione Empresa:</b></label>
                </div>
                <div class="mt-1 mr-3">
                    <s-select-definition :outlined="false" style="border-color: seashell;border-radius: 7px;" @input="inputBussines($event)" :def="1142" return-object v-model="objBussiness">
                    </s-select-definition>
                </div>

            </template>
        </s-toolbar>
        <v-card outlined>
            <div class="mt-0 ml-3">
                <v-radio-group dense v-model="TypeP" style="margin-top:0px;margin-bottom:-12px" :disabled="!isEditable">
                    <v-radio value="1">
                        <template v-slot:label>
                            <div>-<strong class="error--text">Gasto</strong></div>
                        </template>
                    </v-radio>
                    <v-radio value="2" style="margin-top: -10px">
                        <template v-slot:label>
                            <div>-<strong class="primary--text">Proyecto</strong></div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>

        </v-card>

        <s-toolbar :label="TypeP == 1 ? 'PART.GASTO - PART.COSTO' : 'PROYECTO - PARTIDAS'" :acceptAdd="true" :add="isEditable" @add="addDistribution()" :clear="isEditable" @clear="clearFormDistribution()" :remove="isEditable" @removed="removeDistribution()"></s-toolbar>
        <v-card outlined>

            <v-row style="margin:auto" v-if="isEditable &&  TypeP == 1">
                <v-col lg="12" class="s-col-form" cols="12">
                    <c-projects clearable noDefault full label="Proyectos" return-object v-model="itemDistribution.ObjPrj" :business="typeBusinessDistribution"></c-projects>
                </v-col>

                <v-col lg="5" md="5" cols="5" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                    <c-type-spent clearable noDefault full label="Part. Gasto" return-object v-model="itemDistribution.ObjPtd_GF" :areID="AreID"></c-type-spent>
                </v-col>

                <v-col lg="5" md="5" cols="5" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                    <c-center-coste clearable noDefault full label="Part. Costo" return-object v-model="itemDistribution.ObjPrj_Ceco" :business="typeBusinessDistribution" :areID="AreID"></c-center-coste>
                </v-col>

                <v-col lg="2" md="2" class="" cols="2">
                    <s-text label="%" decimal ref="DepPorcentaje" v-model="itemDistribution.DepPorcentaje" :disabled="!isEditable" />
                </v-col>

            </v-row>
            <v-row style="margin:auto" v-if="isEditable && TypeP == 2">
                <v-col lg="12" class="s-col-form" cols="12">
                    <c-projects clearable noDefault full label="Proyectos" return-object v-model="itemDistribution.ObjPrj_Ceco" :business="typeBusinessDistribution"></c-projects>
                </v-col>

                <v-col lg="8" class="" cols="6">
                    <c-partida label="Partida" full clearable noDefault return-object v-model="itemDistribution.ObjPtd_GF" :business="typeBusinessDistribution"></c-partida>
                </v-col>

                <v-col lg="4" class="" cols="6">
                    <s-text label="%" decimal ref="DepPorcentaje" v-model="itemDistribution.DepPorcentaje" />
                </v-col>

            </v-row>
            <v-row style="margin:auto">
                <v-col lg="12" class="" cols="12">
                    <v-data-table item-key="DepLine" dense :items="itemsDistribution" :height="'auto'" :headers="headersDistribution" class="elevation-0 scroll table table-hover table-condensed" v-model="selectedDistribution" hide-default-footer :items-per-page="-1" disable-sort @click:row="rowClickDistribution">
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

    </v-dialog>

    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" dark @save="save()" :save="isEditable" :approve="optionApproved" close @close="$emit('close')" :transactionApproved="transactionApproved" :label="'Requerimiento  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)">
            <v-divider vertical></v-divider>
            <template v-slot:options>
                <div class="mt-0 mr-3">
                    <label><b>Seleccione Empresa:</b></label>
                </div>
                <div class="mt-1 mr-3">
                    <s-select-definition :outlined="false" style="border-color: seashell;border-radius: 7px;" :def="1142" v-model="requirement.typeBusiness">
                    </s-select-definition>
                </div>
            </template>
        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>

                <v-row>
                    <v-col lg="4" cols="12" v-if="isEditable">
                        <v-card elevation="0" height="auto">
                            <v-row>
                                <v-col lg="8" md="8" cols="8" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text disabled label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
                                </v-col>
                                <v-col lg="4" md="4" cols="4" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text number :min="1" label="Tiempo Duración" v-model="requirement.ReqDayExpiration" ref="ReqDayExpiration"></s-text>
                                </v-col>
                            </v-row>

                            <v-row>
                                <s-toolbar dark style="opacity:0.8; font-family: Calibri; border-radius: 4px; width:100%" label="Nuevo Item" :add="isEditable" @add="add()" @save="add()" @clear="clearForm()" :clear="isEditable">
                                    <template v-slot:options>
                                        <div class="mt-1 mr-3" v-if="isEditable">
                                            <s-select :outlined="false" style="border-color: seashell;border-radius: 7px;" :disabled="disabledTypeRequirement" :items="[
                                                        { value: 1, text: 'Compras' },
                                                        { value: 3, text: 'Servicio' },
                                                        ]" v-model="Type">
                                            </s-select>
                                        </div>
                                    </template>
                                </s-toolbar>
                                <v-divider></v-divider>
                                <v-card width="100%" outlined class="elevation-0" :disabled="!isEditable">
                                    <v-col style="padding-bottom: 24px">
                                        <v-row justify="center">
                                            <v-col class="s-col-form" :lg="Type == 1 ? 8: 12" :cols="Type == 1 ? 9: 12">
                                                <s-textarea v-if="Type == 3" :maxlength="100" autofocus label="Descripción" ref="DerDescription" v-model="item.DerDescription" />
                                                <span v-if="Type == 3" style="font-size: 9px; color:red">Max {{maxCaracteres}} Caracteres - {{item.DerDescription.length}}</span>
                                                <c-article-search v-else :readonly="editItem" v-model="article" />
                                            </v-col>
                                            <v-col class="s-col-form" :lg="Type == 1 ? 4: 6" :cols="Type == 1 ? 3: 6">
                                                <s-text label="Cantidad" decimal ref="DerQuantity" v-model="item.DerQuantity" />
                                            </v-col>
                                            <v-col class="s-col-form" :lg="Type == 1 ? 4: 6" :cols="Type == 1 ? 3: 6" v-if="Type==3">
                                                <s-select-definition :def="1160" label="Uni. Medida" v-model="objUnitOfMeasuarement" return-object></s-select-definition>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center">

                                            <v-col cols="12" class="s-col-form">
                                                <s-textarea label="Comentario" v-model="item.DerObservation" /><span style="font-size: 10px; color:red">{{this.item.DerObservation.length}} Caracteres</span>
                                            </v-col>

                                            <v-col cols="6" class="s-col-form" style="margin-top:-15px;margin-bottom:10px">
                                                <v-checkbox color="green" hide-details label="Requiere de visita a campo" v-model="item.RequiredVisit"></v-checkbox>
                                            </v-col>

                                            <v-col cols="6" class="s-col-form" style="margin-top:-15px;margin-bottom:10px">
                                                <v-checkbox color="green" hide-details label="Aplica Distribucion para Todos" v-model="apply"></v-checkbox>
                                            </v-col>

                                            <v-col cols="12" class="s-col-form">
                                                <v-btn elevation="0" small :color="'primary'" dark style="text-transform: capitalize; opacity:0.5" @click="openDetail()">Distribución</v-btn>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                </v-card>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                            <v-col cols="12" style="padding: 0px">
                                <s-select-definition :def="1183" v-model="requirement.TypeEAR" label="Tipo" :disabled="!isEditable"></s-select-definition>
                            </v-col>
                            <v-col cols="12" style="padding: 0px">
                                <s-textarea label="Motivo" ref="ReqReason" v-model="requirement.ReqReason" :disabled="!isEditable"></s-textarea><span style="font-size: 10px; color:red">Min: 20 y Max: 250 Caracteres - {{this.requirement.ReqReason.length}}</span>
                            </v-col>

                            <v-row style="margin-top:10px; margin-bottom: 10px">
                                <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Adjuntos</b>
                                </v-col>
                                <v-col lg="12" cols="12" class="s-col-text">

                                    <v-row>
                                        <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                            <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col :lg="isEditable ? 8 : 12" cols="12">
                        <v-row justify="center">

                            <v-col lg="3" md="6" cols="6" style="padding-top: 0px; padding-bottom: 16px" v-if="!isEditable">
                                <s-text :disabled="!isEditable" number :min="1" label="Tiempo Duración" v-model="requirement.ReqDayExpiration" ref="ReqDayExpiration"></s-text>
                            </v-col>

                            <v-col lg="3" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <!-- <c-select-area disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" :typeArea="3" /> -->
                                <s-text disabled label="Área" v-model="user.CtrContract.AreName"></s-text>
                            </v-col>
                            <v-col lg="3" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date disabled label="Fecha Solicitud" :value="requirement.ReqDate" />
                            </v-col>

                            <v-col lg="3" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
                                <s-select-definition :dgr="48" :def="1157" label="Estado" :disabled="!isEditable" v-model="requirement.ReqStatus" />
                            </v-col>

                            <v-col :lg="!isEditable ? 1 : 3" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-select-definition :def="1158" label="Prioridad" :disabled="!isEditable" v-model="requirement.TypePriority">
                                </s-select-definition>
                            </v-col>

                            <v-col cols="12" lg="2" md="12" style="padding-top: 0px; padding-bottom: 0px" v-if="!isEditable">
                                <s-select-definition :def="1183" v-model="requirement.TypeEAR" label="Tipo" :disabled="!isEditable"></s-select-definition>
                            </v-col>

                        </v-row>

                        <v-divider></v-divider>

                        <v-row style="margin-top:10px; margin-bottom: 10px" v-if="!isEditable">
                            <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri;text-decoration: underline;">Documentos Adjuntos</b>
                                <v-btn v-if="!isEditable" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadFile()">Subir Archivos
                                    <v-icon> mdi-arrow-up</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col lg="4" md="4" cols="12" class="s-col-text">

                                <v-row>
                                    <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                        <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                        </v-file-input>
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col lg="6" md="6" cols="12" class="s-col-text">
                                <v-card outlined>
                                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="requirement.itemsAttach">
                                        <template v-slot:item.RqdAttach="{ item }">
                                            <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                            {{item.RqdAttach}}
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row class="mt-3">
                            <v-col :lg="!isEditable ? 12 : 12">

                                <v-card elevation="2">
                                    <s-toolbar label="Detalle" style="opacity:0.8;" :edit="isEditable" :remove="isEditable" @edit="edit()" @removed="removeItem()" @save="add()">

                                    </s-toolbar>
                                </v-card>
                                <v-card outlined class="elevation-0">
                                    <v-col style="padding: 0px">
                                        <v-data-table class="elevation-0 table table-hover table-condensed table-bordered" item-key="DerNumLine" disable-sort dense :items="items" :height="!isEditable ? 'auto' : $vuetify.breakpoint.xs ? 'auto' : '262'" :headers="headersDetail" v-model="selected" hide-default-footer :items-per-page="-1" @click:row="rowClick">
                                            <!-- mobile-breakpoint="0" -->
                                            <template v-slot:item.TypeRequirement="{ item }">
                                                <v-icon :color="item.TypeRequirement == 1 ? 'red' : 'green'" style="font-size:12px;">
                                                    {{item.TypeRequirement == 1 ? 'fas fa-car': 'fas fa-street-view'}}

                                                </v-icon>
                                                <b style="margin-left:10px">{{item.TypeRequirement == 1 ? 'Compra': 'Servicio'}}</b>
                                            </template>

                                            <template v-slot:item.View="{ item }">
                                                <!-- <v-btn text icon @click="dialogDetailEditable(item)" v-if="!isEditable">

                                                </v-btn> -->
                                                <v-icon @click="dialogDetailEditable(item)" v-if="!isEditable" style="font-size:18px;color:green">
                                                    far fa-eye
                                                </v-icon>

                                                <v-icon v-if="(item.Details.length > 0) && isEditable" color="green darken-3" style="font-size: 12px"> fa-solid fa-check-double</v-icon>
                                                <v-icon v-if="(item.Details.length == 0) && isEditable" color="orange darken-3" style="font-size: 12px"> fas fa-exclamation</v-icon>

                                                <!-- <v-icon v-if="(item.DetailsProjects.length > 0 || item.DetailsGasto.length > 0) && isEditable" color="green darken-3" style="font-size: 12px"> fa-solid fa-check-double</v-icon>
                                                <v-icon v-if="(item.DetailsProjects.length == 0 && item.DetailsGasto.length == 0) && isEditable" color="orange darken-3" style="font-size: 12px"> fas fa-exclamation</v-icon> -->
                                            </template>
                                        </v-data-table>
                                    </v-col>

                                    <v-col lg="12" class="pt-0" v-if="transactionApproved != null">
                                        <h3 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                                            Desaprobado: {{ transactionApproved.AtsObservation }}
                                        </h3>
                                        <h3 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">Aprobado</h3>
                                    </v-col>
                                </v-card>
                            </v-col>

                            <v-col cols="12" style="padding-top: 0px; padding-bottom: 0px" v-if="!isEditable">
                                <s-textarea label="Motivo" ref="ReqReason" v-model="requirement.ReqReason" :disabled="!isEditable"></s-textarea>
                            </v-col>
                            <v-col cols="12" style="padding-top: 0px">
                                <label><b>OBSERVACIONES GENERALES</b></label>
                            </v-col>
                            <v-col cols="12" style="padding-top: 0px">
                                <ckeditor v-model="requirement.ReqObservation" :config="configCk"></ckeditor>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/RequirementService";
import _sRequirementConfig from "@/services/Logistics/LgsRequirementconfig";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";

//Components
import LgsRequirementCoste from "@/views/Logistics/Requirement/LgsRequirementCoste";
import cArticleSearch from "@/components/Logistics/cArticleSearch";
import cTypeSpent from '@/components/Logistics/cTypeSpent.vue';
import cProjects from '@/components/Logistics/cProjects.vue';
import cPartida from '@/components/Logistics/cPartida.vue';
import cCenterCoste from '@/components/Logistics/cCenterCoste.vue';

export default {
    name: "RequirementService",
    components: {
        cArticleSearch,
        cTypeSpent,
        cProjects,
        cPartida,
        cCenterCoste,
        LgsRequirementCoste
    },

    props: {
        value: {
            default: 1,
            type: Number
        },
    },

    data() {
        return {
            messageProcessing: "",
            configCk: {
                extraPlugins: 'autogrow',
                autoGrow_minHeight: 50,
            },

            processing: false,
            dialogCircuit: false,
            dialogDetail: false,
            alert: true,
            dialog: false,

            permit: [],
            editItem: false,
            selectedCircuit: [],
            filterLevels: {},
            configLevels: {
                model: {
                    AccID: "int",
                    AlvID: "ID",
                    SecStatus: "boleam",
                    PstID: "int",
                    PstName: "string",
                },
                service: _sApprovalLevel,
                headers: [{
                        text: "Cargo",
                        value: "PstName"
                    },
                    {
                        text: "Nivel",
                        value: "TypeLevelText"
                    },
                ],
            },
            viewLoad: true,
            circuits: [],

            Type: 1,
            isUserCreated: true,
            transactionApproved: null,
            dialogDissaproved: false,
            TypePriority: null,
            optionApproved: false,
            AcoID: null,
            itemsRemoved: [],
            article: null,
            selected: [],
            requirement: {
                AreID: 0,
                ReqDate: null,
                UsrID: null,
                ReqID: null,
                ReqCommitted: null,
                ReqObservation: "",
                ReqStatus: 0,
                typeBusiness: 1,
                ReqDayExpiration: 1,
                ReqReason: "",
            },

            item: {
                DerNumLine: 0,
                DerDescription: "",
                DerObservation: "",
                DerQuantity: "",
            },

            filterRequirement: {
                BeginDate: null,
                EndDate: null
            },
            items: [],
            headersDetail: [{
                    text: "Distrib.",
                    value: "View",
                    width: 10,
                    align: "center"
                },
                {
                    text: "Tipo",
                    value: "TypeRequirement",
                    width: 100
                },
                {
                    text: "Descripción",
                    value: "DerDescription",
                    width: 500
                },

                {
                    text: "Und",
                    value: "UnitMeasurement",
                    sortable: false,
                    width: 70
                },
                {
                    text: "Cant",
                    value: "DerQuantity",
                    sortable: false,
                    width: 70
                },

                {
                    text: "Comentario",
                    value: "DerObservation",
                    sortable: false,
                    width: 300
                },
            ],

            objUnitOfMeasuarement: 0,
            Attach1: null,
            Attach2: null,
            maxCaracteres: 100,
            disabledTypeRequirement: false,

            itemAttach: [],
            apply: false,
            headersFiles: [{
                text: "Archivo",
                value: "RqdAttach"
            }, {
                text: "Fec. Creaciòn",
                value: "SecCreate"
            }],
            /* selectedProyecto: [],
            itemsProyecto: [],
            headersDetailTest: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },
                {
                    text: "Proyecto",
                    value: "PrjDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "Partida",
                    value: "PtdDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "%",
                    value: "DerPorcentaje",
                    sortable: false,
                    width: 60,
                    align: "center"
                },
            ],
            itemProyecto: {
                DerNumLineTest: 0,
                DerPorcentaje: 100,
                objProjects: 0,
                objPartida: 0,
            },

            selectedGasto: [],
            itemsGasto: [],
            headersDetailGasto: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },

                {
                    text: "Gasto",
                    value: "SptDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "Costo",
                    value: "CceDescription",
                    sortable: false,
                    width: 200
                },
            ],
            itemGasto: {
                DerNumLineGasto: 0,
                objGasto: 0,
                objCosto: 0,
            }, */
            typeBusinessDistribution: 0,
            objBussiness: null,
            AreID: this.$fun.getUserInfo().CtrContract.AreID,
            TypeP: '1',

            itemDistribution: {
                DepLine: 0,
                ObjPrj: 0,
                ObjPrj_Ceco: 0,
                ObjPtd_GF: 0,
                DepPorcentaje: 100
            },
            itemsDistribution: [],
            selectedDistribution: [],
            headersDistribution: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },

                
                {
                    text: "Proyecto Gasto",
                    value: "PrjName"
                },
                {
                    text: "Prj_Ceco",
                    value: "Prj_Ceco_Name"
                },
                {
                    text: "Ptd_GF",
                    value: "Ptd_GF_Name"
                },
                {
                    text: "%",
                    value: "DepPorcentaje"
                }

            ],
            errorBuget: [],
            dialogBuget: false,
            errors:null,
            TrsID:null

        }

    },
    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        isEditable() {
            return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null;

        },
    },

    created() {
        this.Type = 1;
        this.requirement.AreID = this.user.CtrContract.AreID

        if (this.value != null) {
            this.initialize();
        }

        this.getConfigFiles();
    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {

        Type() {
            if (this.Type == 3) {
                this.item.DerQuantity = 1
            }

        },

        article() {
            this.$nextTick().then(() => {
                if (this.article) {
                    this.$refs.DerQuantity.focus();
                    this.item.DerDescription = this.article.ArtName;
                }
            });
        },

        isEditable() {
            this.processing = false;
        },

        TypeP() {
            this.clearFormDistribution()
        },

        /* 'item.DerObservation'() {
            if (this.item.DerObservation.length > this.maxCaracteres) {
                this.$fun.alert("Comentario maximo " + this.maxCaracteres + " Caracteres", "warning")
                return;
            }
        }, */

        'item.DerDescription'() {
            if (this.Type == 3) {
                if (this.item.DerDescription.length > this.maxCaracteres) {
                    this.$fun.alert("Descripción maximo " + this.maxCaracteres + " Caracteres", "warning")
                    return;
                }
            }
        },
        selected() {
            console.log("selected", this.selected);
        }
    },

    methods: {

        initialize() {
            this.viewLoad = false;
            _sRequirement.byID({
                ReqID: this.value
            }, this.$fun.getUserID()).then((r) => {

                r.data.itemsAttach.forEach(element => {
                    element.Attach = {
                        name: element.RqdAttach
                    };
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                });

                this.requirement = r.data;
                //this.itemAttach = this.requirement.itemsAttach

                console.log("al levantar", this.requirement)

                this.items = this.requirement.Detail;

                if (this.requirement.UsrCreateID == this.$fun.getUserID()) this.isUserCreated = true;
                else this.isUserCreated = false;
                this.transactionApproved = this.requirement.Transaction;
                if (this.requirement.Transaction != null)
                    if (this.requirement.Transaction.UsersApproved.length > 0)
                        if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                            if (this.requirement.ReqStatus == 4) this.optionApproved = true;
                this.Type = this.requirement.TypeRequirement;
                this.viewLoad = true;
            });
        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 1).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        uploadFile() {
            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemAttach.length; index++) {
                if (this.itemAttach[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemAttach[index].RqdID,
                        RqdAttach: this.itemAttach[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existen archivos para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivos?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 1).then(resp => {
                            if (resp.status == 200) {
                                this.requirement.itemsAttach = resp.data

                                this.requirement.itemsAttach.forEach(element => {
                                    element.ReqID = this.requirement.ReqID
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                                if (this.requirement.itemsAttach.length > 0) {
                                    _sRequirement.savefiles(this.requirement.itemsAttach, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                //this.$emit("close");
                                                this.initialize();
                                                this.processing = false;
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = true;
                                        });
                                }
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            })

        },

        getConfigFiles() {
            _sRequirementConfig.list(1, this.$fun.getUserID()).then(resp => {
                this.itemAttach = resp.data
            })
        },

        //Limpiar
        clearForm() {
            this.editItem = false;
            this.AcoID = null;
            this.item.DerDescription = "";
            this.item.DerObservation = "";
            this.item.DerQuantity = "";
            this.item.RequiredVisit = false
            this.item.DerNumLine = 0;
            this.disabledTypeRequirement = false
            if (this.Type == 3) {
                this.$refs.DerDescription.focus();
                this.item.DerQuantity = 1
                console.log("this.objUnitOfMeasuarement", this.objUnitOfMeasuarement)
                this.objUnitOfMeasuarement = {
                    DedValue: 1
                }
            } else this.article = null;

            this.itemsDistribution = []

        },
        //******************************* DETALLE ********************************************* */

        rowClick: function (item, row) {
            this.selected = [item];
        },

        //Remover item
        removeItem() {
            if (this.items.length > 0) {
                if (this.selected[0].ReqID > 0) {
                    this.selected[0].SecStatus = 0;
                    this.itemsRemoved.push(this.selected[0]);
                }
                this.items = this.items.filter((x) => x != this.selected[0]);
                this.selected = this.items.length > 0 ? [this.items[0]] : [];

                this.clearForm()
            }
        },

        //Agregar Item
        add() {

            if (
                this.items.find(
                    (x) =>
                    x.DerDescription == this.item.DerDescription
                ) &&
                this.item.DerNumLine == 0
            ) {
                this.$fun.alert("Descripción ya fue agregada", "warning");
                return;
            }

            if (this.item.DerQuantity <= 0 || this.item.DerQuantity.toString().trim() == "") {
                this.$fun.alert("Cantidad debe ser mayor a Cero", "warning");
                this.$refs.DerQuantity.focus();
                return;
            }

            if (this.item.DerNumLine == 0) {

                let item = {
                    DerDescription: this.item.DerDescription.toUpperCase(),
                    DerObservation: this.item.DerObservation,
                    ReqAccount: this.AcoID,
                    TypeRequirement: this.Type,
                    RequiredVisit: this.item.RequiredVisit,

                };

                item.Details = this.itemsDistribution

                if (this.itemsDistribution.length == 0 && this.apply == false) {
                    this.$fun.alert("[IMP] Necesita agregar distriubución para item.", "warning")
                    return
                }

                if (this.itemsDistribution.length == 0 && this.apply == true && this.items.length == 0) {
                    this.$fun.alert("A seleccionado aplicar la distribucion para todos los Items, al menos el primer item debe tener una distribución.", "warning")
                    return
                }

                if (this.Type != 3) {

                    if (this.article == null) {
                        this.$fun.alert("Especifique Articulo", "warning")
                        return
                    }

                    item.ArtCode = this.article.ArtCode;
                    item.DerDescription = this.article.ArtName;
                    item.DerQuantity = this.item.DerQuantity;
                    item.UnitMeasurement = this.article.UnitOfMeasuarement;

                    if (this.item.DerDescription.length > this.maxCaracteres) {
                        this.$fun.alert("Descripción maximo " + this.maxCaracteres + " Caracteres", "warning")
                        this.$refs.DerDescription.focus()
                        return;
                    }

                } else {
                    if (this.item.DerDescription.length == 0) {
                        this.$fun.alert("Ingrese Descripción", "warning")
                        this.$refs.DerDescription.focus()
                        return
                    }

                    if (this.item.DerDescription.length > this.maxCaracteres) {
                        this.$fun.alert("Descripción maximo " + this.maxCaracteres + " Caracteres", "warning")
                        this.$refs.DerDescription.focus()
                        return;
                    }

                    item.DerQuantity = this.item.DerQuantity;
                    item.UnitMeasurement = this.objUnitOfMeasuarement.DedDescription;
                    item.DedValue = this.objUnitOfMeasuarement.DedValue;
                }

                this.items.push(item);
                this.selected = [item];

                console.log("anadido", this.items)

            } else {

                if (this.itemsDistribution.length == 0 && this.apply == false) {
                    this.$fun.alert("[IMP] Necesita agregar distriubución para item.", "warning")
                    return
                }

                this.items[this.item.DerNumLine - 1].DerDescription = this.item.DerDescription;
                this.items[this.item.DerNumLine - 1].DerObservation = this.item.DerObservation;
                this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
                this.items[this.item.DerNumLine - 1].TypeRequirement = this.Type;
                this.items[this.item.DerNumLine - 1].RequiredVisit = this.item.RequiredVisit;

                if (this.Type != 3) {
                    this.items[this.item.DerNumLine - 1].ArtCode = this.article.ArtCode;
                    this.items[this.item.DerNumLine - 1].DerDescription = this.article.ArtName;
                    this.items[this.item.DerNumLine - 1].DerQuantity = this.item.DerQuantity;
                    this.items[this.item.DerNumLine - 1].UnitMeasurement = this.article.UnitOfMeasuarement;
                } else {
                    this.items[this.item.DerNumLine - 1].UnitMeasurement = this.objUnitOfMeasuarement.DedDescription;
                    this.items[this.item.DerNumLine - 1].DedValue = this.objUnitOfMeasuarement.DedValue;
                }

                this.selected = [this.items[this.item.DerNumLine - 1]];

                this.items[this.item.DerNumLine - 1].Details = this.itemsDistribution
            }

            for (let i = 0; i < this.items.length; i++) this.items[i].DerNumLine = i + 1;
            this.clearForm();

            this.itemsDistribution = []
            this.clearFormDistribution()
        },

        //editar item
        edit() {
            if (this.selected.length > 0) {
                this.editItem = true;
                let row = this.selected[0];

                this.Type = row.TypeRequirement
                this.disabledTypeRequirement = true

                this.$nextTick().then((r) => {
                    this.AcoID = row.ReqAccount;
                });
                this.item.DerDescription = row.DerDescription;
                this.item.DerObservation = row.DerObservation;
                this.item.DerNumLine = row.DerNumLine;

                this.item.DerID = row.DerID;

                if (this.Type == 1) {
                    this.article = {
                        ArtCode: row.ArtCode,
                        ArtName: row.DerDescription,
                        UnitOfMeasuarement: row.UnitMeasurement,
                    };
                } else {

                    console.log("edit", row)
                    this.objUnitOfMeasuarement = {
                        //DedDescription: row.UnitMeasurement,
                        DedValue: row.DedValue
                    }
                }

                this.item.DerQuantity = row.DerQuantity;
                this.item.RequiredVisit = row.RequiredVisit

                this.itemsDistribution = row.Details
            }
        },

        //******************************* FIN DETALLE ********************************************* */
        openDetail() {
            //Si es Compra
            if (this.Type == 1) {
                if (this.article == null) {
                    this.$fun.alert("Seleccione Articulo", "warning")
                    return
                }
            } else {
                if (this.item.DerDescription == null || this.item.DerDescription.length == 0) {
                    this.$fun.alert("Ingrese Descripcion", "warning")
                    return
                }
            }

            /* if(this.selected.length <= 0){
                this.$fun.alert("Seleccione Items para Distribucion", "warning")
                    return
            } */

            this.clearFormDistribution();
            this.dialogDetail = true
        },

        dialogDetailEditable(item) {
            this.itemsDistribution = item.Details == null ? [] : item.Details
            this.dialogDetail = true
        },

        save() {

            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            if (this.requirement.typeBusiness == null || this.requirement.typeBusiness <= 0) {
                this.$fun.alert("Especifique una Empresa", "warning");
                return
            }

            if (this.items.length < 1) {
                this.$fun.alert("No se ha registrado ningún detalle", "warning");
                return;
            }

            if (this.requirement.ReqDayExpiration < 1) {
                this.$refs.ReqDayExpiration.error("Tiempo duración Cotización no puede ser menor a 0")
                return
            }

            if (this.requirement.TypeEAR == null) {
                this.$fun.alert("Seleccione Tipo", "warning")
                return
            }

            if (this.requirement.ReqReason.length < 20) {
                this.$refs.ReqReason.focus()
                this.$fun.alert("Cantidad de caracteres no permitido, Min: 20. Campo [Motivo]", "warning")
                return
            }
            if (this.requirement.ReqReason.length > 250) {
                this.$refs.ReqReason.focus()
                this.$fun.alert("Cantidad de caracteres no permitido, Max: 250. Campo [Motivo]", "warning")
                return
            }
            /* if (this.ReqStatus == null) {
                this.$fun.alert("Seleccione Estado", "warning");
                return;
            } */

            /* if (this.TypePriority == null) {
                this.$fun.alert("Seleccione Prioridad", "warning");
                return;
            }
 */
            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.SecStatus = 1;

            this.requirement.Details = [...this.items, ...this.itemsRemoved];

            /* if (this.apply) { */
            if (this.requirement.Details.length > 0) {
                this.requirement.Details.forEach(element => {

                    if (element.Details.length == 0) {
                        element.Details = this.requirement.Details[0].Details
                    }
                });
            }
            /* } */

            console.log("save", this.requirement)

            this.$fun.alert("Seguro de continuar con la Solicitud?", "question", false).then((r) => {
                if (r.value) {

                    this.messageProcessing = ""
                    this.processing = true
                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemAttach.length; index++) {
                        if (this.itemAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemAttach[index].RqdID,
                                RqdAttach: this.itemAttach[index].RqdAttach
                            })
                        }
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 1).then(resp => {
                        if (resp.status == 200) {
                            this.requirement.itemsAttach = resp.data
                            this.requirement.UsrID = this.$fun.getUserID();
                            this.requirement.UsrCreateID = this.$fun.getUserID();
                            this.requirement.UsrUpdateID = this.$fun.getUserID();
                            this.requirement.SecStatus = 1;

                            _sRequirement.save(this.requirement, this.$fun.getUserID(), 0).then((r) => {

                                    if (r.status == 200) {
                                        console.log("datos del guardado", r.data);

                                        if (r.data.length > 0) {
                                            this.circuits = r.data;
                                            this.TrsID = 1
                                            this.dialogCircuit = true;
                                        } else {
                                            this.messageProcessing = "";
                                            this.processing = false;

                                            this.$fun.alert("Guardado Correctamente", "success");
                                            this.$emit("close");

                                        }
                                    }

                                    console.log("_sRequirement", r.data)

                                    /* if (r.data.length > 1) {
                                        this.circuits = r.data;
                                        this.dialogCircuit = true;
                                        this.processing = false
                                    } else {

                                        if(r.data.length > 0 && r.data[0].listGbt !== null){
                                            console.log("respuestaaaaaaa",r.data)
                                            this.errorBuget = r.data[0].listGbt
                                            this.dialogBuget = true
                                            this.processing = false
                                        }else{
                                            this.$fun.alert("Guardado Correctamente", "success");
                                            this.$emit("close");
                                            this.processing = false
                                        }

                                    } */

                                },
                                (e) => {
                                    this.errors = e.response.data.Message
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = false;
                                });

                        }
                    })
                }
            });
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        /* rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {
            if (this.items.length < 1) {
                this.$fun.alert("No se ha registrado ningún detalle", "warning");
                return;
            }
            this.requirement.TypeRequirement = this.Type;
            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.Details = [...this.items, ...this.itemsRemoved];

            this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sRequirement.save(this.requirement, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
                        
                            this.processing = false
                            this.$fun.alert("Guardado Correctamente", "success");
                            this.$emit("close");

                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });
        }, */
        //********************************** FIN CIRCUITOS DE APROBACION************************************************* */
        inputBussines(item) {
            if (item !== null) {
                this.typeBusinessDistribution = item.DedValue
            }
        },

        //******************************************** PROYECTO ********************************************************************
        /* clearFormProyecto() {
            this.itemProyecto.DerPorcentaje = 100;
            this.itemProyecto.DerNumLineTest = 0;
            this.itemProyecto = {
                DerNumLineTest: 0,
                DerPorcentaje: 100,
                objProjects: 0,
                objPartida: 0,
            }
        },
        addProyecto() {

            if (this.itemProyecto.objProjects == 0 || this.itemProyecto.objProjects == null) {
                this.$fun.alert("Seleccione Proyecto", "warning")
                return
            }

            if (this.itemProyecto.objPartida == 0 || this.itemProyecto.objPartida == null) {
                this.$fun.alert("Seleccione Partida", "warning")
                return
            }

            if (this.itemProyecto.DerPorcentaje == 0 || this.itemProyecto.DerPorcentaje == "") {
                this.$refs.DerPorcentaje.error("Ingrese Porcentaje")
                return
            }

            if (this.itemProyecto.DerPorcentaje > 100) {
                this.$refs.DerPorcentaje.error("Porcentaje no puede superar el 100%")
                return
            }

            if (
                (this.itemsProyecto.find((x) => x.PrjID == this.itemProyecto.objProjects.PrjID) && this.itemsProyecto.find((x) => x.PtdID == this.itemProyecto.objPartida.PtdID))
            ) {
                this.$fun.alert("Proyecto y Partida ya fueron agregadas", "warning");
                return;
            }

            var sumPorc = 0
            if (this.itemProyecto.DerNumLineTest == 0) {

                sumPorc = sumPorc + parseInt(this.itemProyecto.DerPorcentaje);

                this.itemsProyecto.forEach(element => {
                    sumPorc = sumPorc + parseInt(element.DerPorcentaje)
                });

                if (sumPorc > 100) {
                    this.$refs.DerPorcentaje.error("Porcentaje no puede superar el 100%")
                    return
                }

                let item = {
                    DerPorcentaje: this.itemProyecto.DerPorcentaje,

                    //Obj. Proyectos
                    PrjDescription: this.itemProyecto.objProjects == null ? "" : this.itemProyecto.objProjects.PrjDescriptionLong,
                    PrjID: this.itemProyecto.objProjects == null ? "" : this.itemProyecto.objProjects.PrjID,

                    //Obj. Partida
                    PtdDescription: this.itemProyecto.objPartida == null ? "" : this.itemProyecto.objPartida.PtdDescriptionLong,
                    PtdID: this.itemProyecto.objPartida == null ? "" : this.itemProyecto.objPartida.PtdID,
                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription

                };
                this.itemsProyecto.push(item);

                this.selectedProyecto = [item];
            }

            for (let i = 0; i < this.itemsProyecto.length; i++) {
                this.itemsProyecto[i].DerNumLineTest = i + 1;
            }

        },
        removeProyecto() {
            if (this.itemsProyecto.length > 0) {

                this.itemsProyecto = this.itemsProyecto.filter((x) => x != this.selectedProyecto[0]);
                this.selectedProyecto = this.itemsProyecto.length > 0 ? [this.itemsProyecto[0]] : [];

                this.clearFormProyecto()
            }
        }, */
        //********************************************* END PROYECTO******************************************************************

        //*********************************************GASTO ************************************************************************
        /* clearFormGasto() {
            this.itemGasto.DerNumLineGasto = 0;
            this.itemGasto = {
                DerNumLineGasto: 0,
                objGasto: 0,
                objCosto: 0,
            }
        },
        addGasto() {

            if (this.itemGasto.objGasto == 0 || this.itemGasto.objGasto == null) {
                this.$fun.alert("Seleccione Part. Gasto", "warning")
                return
            }

            if (this.itemGasto.objCosto == 0 || this.itemGasto.objCosto == null) {
                this.$fun.alert("Seleccione Part. Costo", "warning")
                return
            }

            if (
                (this.itemsGasto.find((x) => x.SptID == this.itemGasto.objGasto.SptID) && this.itemsGasto.find((x) => x.CceID == this.itemGasto.objCosto.CceID))
            ) {
                this.$fun.alert("Gasto y Costo ya fueron agregadas", "warning");
                return;
            }

            if (this.itemGasto.DerNumLineGasto == 0) {

                let item = {
                    //Obj. Gastos
                    SptDescription: this.itemGasto.objGasto == null ? "" : this.itemGasto.objGasto.SptDescriptionLong,
                    SptID: this.itemGasto.objGasto == null ? "" : this.itemGasto.objGasto.SptID,

                    //Obj. Costos
                    CceDescription: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceDescription,
                    CceID: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceID,
                    CceCenterCoste: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceCenterCoste,

                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription

                };
                this.itemsGasto.push(item);

                this.selectedGasto = [item];
            }

            for (let i = 0; i < this.itemsGasto.length; i++) this.itemsGasto[i].DerNumLineGasto = i + 1;
        },
        removeGasto() {
            if (this.itemsGasto.length > 0) {

                this.itemsGasto = this.itemsGasto.filter((x) => x != this.selectedGasto[0]);
                this.selectedGasto = this.itemsGasto.length > 0 ? [this.itemsGasto[0]] : [];

                this.clearFormGasto()
            }
        }, */
        //**********************************************FIN GASTO ************************************************************************

        //*********************************************** INICIO DISTRIBUCION ************************************************************
        rowClickDistribution: function (item, row) {
            this.selectedDistribution = [item];
        },

        clearFormDistribution() {
            this.itemDistribution.DepLine = 0;
            this.itemDistribution = {
                DepLine: 0,
                DepPorcentaje: 100,
                ObjPtd_GF: 0,
                ObjPrj_Ceco: 0,
                ObjPrj:0
            }
        },

        addDistribution() {

            if (this.itemDistribution.ObjPtd_GF == 0 || this.itemDistribution.ObjPtd_GF == null) {
                this.$fun.alert("Seleccione [ObjPtd_GF]", "warning")
                return
            }

            if (this.itemDistribution.ObjPrj_Ceco == 0 || this.itemDistribution.ObjPrj_Ceco == null) {
                this.$fun.alert("Seleccione [ObjPrj_Ceco]", "warning")
                return
            }

            if (this.itemDistribution.DepPorcentaje == 0 || this.itemDistribution.DepPorcentaje == "") {
                this.$refs.DepPorcentaje.error("Ingrese Porcentaje")
                return
            }

            if (this.itemDistribution.DepPorcentaje > 100) {
                this.$refs.DepPorcentaje.error("Porcentaje no puede superar el 100%")
                return
            }

            if (this.TypeP == 1) {

                if (this.itemDistribution.ObjPrj == 0 || this.itemDistribution.ObjPrj == null) {
                    this.$fun.alert("Seleccione [Proyecto]", "warning")
                    return
                }

               /*  if (
                    (this.itemsDistribution.find((x) => x.Ptd_GF == this.itemDistribution.ObjPtd_GF.SptID) &&
                        this.itemsDistribution.find((x) => x.Prj_Ceco == this.itemDistribution.ObjPrj_Ceco.CceCenterCoste))
                ) {
                    this.$fun.alert("[ObjPtd_GF] y [ObjPrj_Ceco] ya fueron agregadas", "warning");
                    return;
                } */
            }

            if (this.TypeP == 2) {
                if (
                    (this.itemsDistribution.find((x) => x.Ptd_GF == this.itemDistribution.ObjPtd_GF.PtdID) &&
                        this.itemsDistribution.find((x) => x.Prj_Ceco == this.itemDistribution.ObjPrj_Ceco.PrjID))
                ) {
                    this.$fun.alert("[ObjPtd_GF] y [ObjPrj_Ceco] ya fueron agregadas", "warning");
                    return;
                }
            }

            var sumPorc = 0.0
            if (this.itemDistribution.DepLine == 0) {

                sumPorc = sumPorc + parseFloat(this.itemDistribution.DepPorcentaje);

                this.itemsDistribution.forEach(element => {
                    sumPorc = sumPorc + parseFloat(element.DepPorcentaje)
                });

                if (sumPorc > 100) {
                    this.$refs.DepPorcentaje.error("Porcentaje no puede superar el 100%")
                    return
                }

                let item = {
                    DepPorcentaje: this.itemDistribution.DepPorcentaje,
                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription,
                    TypeP: this.TypeP
                };

                if (this.TypeP == 1) {
                    item.Ptd_GF_Name = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.SptDescriptionLong
                    item.Ptd_GF = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.SptID
                    item.Prj_Ceco = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.CceCenterCoste
                    item.Prj_Ceco_Name = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.CceDescription
                    item.PrjID = this.itemDistribution.ObjPrj == null ? "" : this.itemDistribution.ObjPrj.PrjID
                    item.PrjName = this.itemDistribution.ObjPrj == null ? "" : this.itemDistribution.ObjPrj.PrjDescriptionLong
                    item.AreParentID = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.AreParentID
                }

                if (this.TypeP == 2) {
                    item.Prj_Ceco = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.PrjID
                    item.Prj_Ceco_Name = this.itemDistribution.ObjPrj_Ceco == null ? "" : this.itemDistribution.ObjPrj_Ceco.PrjDescriptionLong

                    item.Ptd_GF_Name = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.PtdDescriptionLong
                    item.Ptd_GF = this.itemDistribution.ObjPtd_GF == null ? "" : this.itemDistribution.ObjPtd_GF.PtdID
                }

                this.itemsDistribution.push(item);

                this.selectedDistribution = [item];
                this.clearFormDistribution()

                this.itemDistribution.DepPorcentaje = 100 - sumPorc
                this.itemDistribution.DepPorcentaje = this.itemDistribution.DepPorcentaje > 0 ? this.itemDistribution.DepPorcentaje.toFixed(2) : 0
            }

            for (let i = 0; i < this.itemsDistribution.length; i++) this.itemsDistribution[i].DepLine = i + 1;

            console.log("distribution", this.itemsDistribution)
        },

        removeDistribution() {
            if (this.itemsDistribution.length > 0) {

                this.itemsDistribution = this.itemsDistribution.filter((x) => x != this.selectedDistribution[0]);
                this.selectedDistribution = this.itemsDistribution.length > 0 ? [this.itemsDistribution[0]] : [];

                this.clearFormDistribution()
            }
        },

    }

};
</script>

<style>
#customers {
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 1px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {

    background-color: #969696;
    color: white;
}
</style>
