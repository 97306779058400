import Service from "../Service";

const resource = "requirementacc/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID, AccID) {
        if (!AccID)
            return Service.post(resource + "save", obj, {
                params: { requestID: requestID },
            });
        //Guarda Circuito
        else return Service.post(resource + "saveCircuit", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },
    saveComments(obj, requestID) {
        return Service.post(resource + "savecomments", obj, {
            params: { requestID: requestID },
        });
    },

};