<template>
<v-row justify="center">
    <v-dialog v-model="dialog" max-width="390" persistent>
        <v-card>
            <v-card-title class="headline">Tiempo de inactividad</v-card-title>

            <v-card-text>
                La Aplicación tiene varios minutos de inactividad.
                <!-- en {{time/1000}} segundos se cerrara sesión -->
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="close()">
                    Sigo Aquí
                </v-btn>
                <v-btn color="red darken-1" text @click="closeSesion()">
                    Cerrar sesión
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-row>
</template>

<script>
import _sAuth from "@/services/AuthService";
export default {
    name: 'ModalTimeSession',

    components: {},
    methods: {

    },
    computed: {
        /* timeInactive() {
            console.log("timepo inactivo")
            return (localStorage.getItem('TimeToken') / 1000) / 60;
        } */

    },
    data: () => ({
        dialog: true,
        time: 10000
    }),
    /*  created() {
         let timerId = setInterval(() => {
             console.log(timerId, this.time, localStorage.getItem("isIdle"))

            if (!localStorage.getItem("isIdle")) {
                 this.time = 10000;
                 clearInterval(timerId);
             } else this.time -= 1000;

            if (this.time < 1) {
                 clearInterval(timerId);
                 document.onload = null;
                 document.onmousemove = null;
                 document.onmousedown = null;
                 document.ontouchstart = null;
                 document.onclick = null;
                 document.onscroll = null;
                 localStorage.clear();
                 this.$emit('exit')
                 this.$router.push("/micuenta")

             } 
         }, 1000);
     }, */

    methods: {
        close() {
            localStorage.setItem("isIdle", false)
            this.dialog = false

            if (localStorage.getItem("TimeTokenCount") < 1) {
                if (localStorage.getItem("token") != null) {

                    this.$fun.alert('Refrescando token desde modal time','success');
                    _sAuth.refreshToken(this.usrID).then((r) => {
                        localStorage.setItem("TimeTokenCount", 0);
                        localStorage.setItem("TimeTokenCount", localStorage.getItem("TimeToken"));
                        localStorage.setItem("token", "");
                        localStorage.setItem("token", r.data.token);

                    });
                }
            }

            //this.$router.go(0)

        },
        closeSesion() {
            document.onload = null;
            document.onmousemove = null;
            document.onmousedown = null;
            document.ontouchstart = null;
            document.onclick = null;
            document.onscroll = null;
            localStorage.clear();
            this.$emit('exit')
            this.$router.push("/micuenta")
        }
    }
}
</script>

<style scoped>

</style>
