<template>
<!-- <v-container grid-list-xs> -->
    
        <v-row style="margin:auto">
          <v-card width="100%" height="30px" dense style="background:; margin-left: 0px">
            <v-icon style="margin-top:6px; margin-left: 10px"> mdi-home </v-icon>
            <v-breadcrumbs :items="items" class="px-7 py-1" style="margin-top: -20px; font-size: 10px; font-family:Calibri" >
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            </v-card>
        </v-row>
        <!-- <v-row class="px-0 mx-0 pb-2">
            <v-icon> mdi-home </v-icon>
            <v-breadcrumbs :items="items" class="px-5 py-1" style="" >
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>

        </v-row> -->
    
<!-- 
</v-container> -->
</template>

<script>
export default {
    components: {},
    watch: {
        $route() {
            this.items = this.$route.meta.breadcrumb;
        },
    },
    mounted() {
        this.items = this.$route.meta.breadcrumb;
    },
    data() {
        return {
            items: [],
        };
    },
};
</script>
