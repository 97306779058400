<template>
<div style="padding-top: -2px; margin-top: -2px">
    <s-select-generic 
        ref="sarea" 
        autocomplete 
        class="text" 
        itemtext="GenDescription" 
        itemvalue="GenID" 
        :disabled="disabled" 
        id="GenDescription" 
        :value="value" 
        :return-object="returnObject" 
        @input="input($event)" 
        :config="config" 
        :label="label" 
        :full="full" 
        :autofocus="autofocus" 
        :text="text" 
        :clearable="clearable" 
        :noDefault="noDefault"
        :outlined="outlined"    
    >
    </s-select-generic>
</div>
</template>

<script>
import _sGenericService from "@/services/General/GenericService";
export default {
    name: "SelectArea",
    components: {},
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
       /*  centercost: {
            type: Boolean,
            default: false,
        }, */
        noDefault: {
            type: Boolean,
            default: false,
        },
        typeCost: {
            type: Number,
            default: 0,
        },
        typeArea: {
            type: Number,
            default: 0,
        },
        full: {
            type: Boolean,
            default: false,
        },

        outlined: {
              type: Boolean,
              default: true
            },
    },
    watch: {
        typeArea() {

            this.config.params.TypeArea = isNaN(this.typeArea) ? 0: this.typeArea
        },
        typeCost() {
            this.config.params.TypeCost = this.typeCost;
        },
        value(value) {
            this.item = value;
        },
    },
    methods: {
        focus() {
            this.$refs.sarea.focus();
        },
        input(val) {
            this.$emit("input", val);
        },
    },
    data: () => ({
        item: 0,
        val: "",
        items: [],
        config: {},
    }),

    created() {


        console.log(this.typeArea)

        this.config = {
            url: _sGenericService.getUrlSearchArea(),
            title: this.label,
            params: {
                TypeArea: isNaN(this.typeArea) ? 0: this.typeArea,
                search: "",
                requestID: this.$fun.getUserID(),
            },
        };
    },
};
</script>
