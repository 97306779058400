let children = []

children.push({
    path: "/cobranzas/buscarcliente",
    name: "COB_SEARCH_CUSTOMER",
    component: () =>
        import ("../views/Collection/CobSearchCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Buscar Cliente", href: "/cobranzas/buscarcliente" },
        ],
    },
});

children.push({
    path: "/cobranzas/gestionarclientes",
    name: "COB_MANAGECUSTOMER",
    component: () =>
        import ("../views/Collection/CobManageCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Gestionar Clientes", href: "/cobranzas/gestionarclientes" },
        ],
    },
});

children.push({
    path: "/cobranzas/listadogestiones",
    name: "COB_MANAGEMENT_LIST",
    component: () =>
        import ("../views/Collection/CobManagementList.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Listado Gestion", href: "/cobranzas/listadogestiones" },
        ],
    },
});

children.push({
    path: "/cobranzas/reportegeneral",
    name: "COB_REPORTGESTION",
    component: () =>
        import ("../views/Collection/CobReportView.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Reporte General", href: "/cobranzas/reportegeneral" },
        ],
    },
});








export { children }