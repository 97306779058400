import Service from "../Service";

const resource = "requirementmarketing/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });

    },

    anulated(obj, requestID) {
        return Service.post(resource + "anulated", obj, {
            params: { requestID: requestID },
        });

    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },

    saveComments(obj, requestID) {
        return Service.post(resource + "savecomments", obj, {
            params: { requestID: requestID },
        });
    },

    initiatedProcess(obj, requestID) {
        return Service.post(resource + "initiatedprocess", obj, {
            params: { requestID: requestID },
        });
    },

    delivery(obj, requestID) {
        return Service.post(resource + "delivery", obj, {
            params: { requestID: requestID },
        });
    },
};