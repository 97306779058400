<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <!-- <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" /> -->

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <!--  <vc-modal-circuit close :circuits="circuits" :TrsID="TrsID" :dialog="dialogCircuit" @close="dialogCircuit = false;$emit('close')" :width="circuits.length > 1 ? 940 : 500" />
 -->
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">
            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row>
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7 : 12" :md="this.filterLevels.AccID != null ? 7 : 12">
                            <v-data-table dense item-key="AccID" :items="circuits" :items-per-page="-1" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30, align: 'center' },
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" noFull v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div>
        <s-toolbar close :save="value == null" @save="save()" :label="
          this.value
            ? orderLocal.OrdDocumentNumber + ' - ' + orderLocal.NtpFullName
            : typeOrder == 1
            ? 'Orden de Compra'
            : 'Orden de Servicio'
        " @close="$emit('onClose')" :transactionApproved="transactionApproved" download @download="pdf(orderLocal)" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="3" cols="12">
                        <v-card elevation="0" height="auto">
                            <v-col class="">
                                <v-row>
                                    <v-col class="s-col-text" cols="12" lg="12">
                                        <v-supplier style="font-family: Calibri" :disabled="!isEditable" label="Proveedor" v-model="Supplier" :value="Supplier" />
                                        <span v-if="ProviderBloqueado > 0" style="color: red; font-family: Calibri; font-size: 10px">Proveedor en Lista Negra</span>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" label="Contacto" :disabled="!isEditable" v-model="ConName" ref="ConName"></s-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" number label="Telefono" :disabled="!isEditable" v-model="ConTelephone" ref="ConTelephone"></s-text>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" label="Email" :disabled="!isEditable" v-model="ConEmail" ref="ConEmail"></s-text>
                                    </v-col>

                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1129" label="Moneda" v-model="orderLocal.TypeCurrency" @input="inputCurrency($event)" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1159" label="Tipo Credito" v-model="orderLocal.TypeCredito" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1170" label="Forma de Pago" v-model="orderLocal.TypeFormaPago" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-text label="Tiempo de entrega" v-model="orderLocal.OrdDeliveryTime"></s-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-text label="Garantia" v-model="orderLocal.OrdTimeGarantia"></s-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-date label="Fecha" style="font-family: Calibri" v-model="dateOrder" :disabled="!isEditable"></s-date>
                                    </v-col>
                                    <v-col lg="12" cols="12" class="s-col-text" v-if="visibleContract">
                                        <s-text style="font-family: Calibri" label="Nro. Solicitud[Contrato]" ref="numberContract" :disabled="!isEditable" v-model="numberContract"></s-text>
                                    </v-col>
                                </v-row>
                                <v-divider style="margin-top: 20px" horizontal></v-divider>

                                <v-row style="margin-top: 10px; margin-bottom: 10px">
                                    <v-col lg="12" cols="12" class="s-col-text"><b style="font-family: Calibri">Adjuntos</b>
                                        <v-btn v-if="!isEditable" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadFile()">Subir Archivos
                                            <v-icon> mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <v-row>
                                            <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                                <v-file-input show-size counter :ref="'attach' + item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                </v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col lg="12" md="12" cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="orderLocal.itemsAttach">
                                                <template v-slot:item.RqdAttach="{ item }">
                                                    <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                    {{item.RqdAttach}}
                                                </template>
                                            </v-data-table>
                                        </v-card>
                                    </v-col>

                                    <!-- Cuando es Edicion -->
                                    <!-- <v-col lg="12" cols="12" class="s-col-text" v-if="!isEditable">
                                        <v-row>
                                            <v-col lg="12" cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                                <s-text :label="item.RqdDescription" :readonly="!isEditable" v-model="item.Attach.name" :outlined="false">
                                                    <template slot="append">
                                                        <v-icon v-if="item !== null" color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                    </template>
                                                </s-text>
                                            </v-col>
                                        </v-row>
                                    </v-col> -->
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="9" cols="12">
                        <v-col class="pr-0 pl-0">
                            <div v-if="orderLocal.OrdStatus == 8">
                                <h3 style="color: red">Orden Rechazada:</h3>
                                <br />
                                <b>{{ orderLocal.OrdObservationDecline }}</b>
                            </div>
                            <div>
                                <v-checkbox style="margin-top: -20px" color="blue" v-model="orderLocal.OrdApplyLetters" hide-details></v-checkbox>
                                <h4 style="color: blue">Aplica Letras: </h4>
                            </div>

                            <h2 style="color:red">{{messageProcessing}}</h2>

                            <br />
                            <div>
                                <v-col lg="12" class="pt-0" v-if="transactionApproved != null">
                                    <h2 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                                        Desaprobado: {{ transactionApproved.AtsObservation }}
                                    </h2>
                                    <h2 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">Aprobado</h2>
                                </v-col>
                            </div>
                            <v-row>
                                <v-card outlined width="100%" style="margin-top: 10px">
                                    <v-card-title style="
                        margin-top: -10px;
                        margin-bottom: -10px;
                        font-family: Calibri;
                      ">
                                        Sustentos Requeridos
                                        <span v-if="messageSustentos && !isEditable">
                                            -
                                            <b style="color: red">Esta OC no contiene sustentos registrados.</b></span>
                                    </v-card-title>
                                </v-card>

                                <v-col lg="3">
                                    <b>Obligatorios</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreCompPayment" label="Comp. de Pago" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreXml" label="XML" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreCdr" label="CDR" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreOcos" label="OC/OS" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -10px" v-model="SupportRequired.SreQuotation" label="Cotización" color="red" hide-details></v-checkbox>
                                </v-col>
                                <v-col lg="3">
                                    <b>Obra</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreContracts" label="Contratos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreAddenda" label="Adendas" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreValuations" label="Valorizaciones" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreRecordConformity" label="Acta de Conformidad" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Servicios</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreContracts_1" label="Contratos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreAddenda_1" label="Adendas" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreReports" label="Informes" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Bienes y Productos</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SreGuideRemission" label="Guia de Remision" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Demas Sustentos</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" v-model="SupportRequired.SrePhotos" label="Fotos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreLiquidations" label="Liquidaciones" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px" v-model="SupportRequired.SreRecordDelivery" label="Acta de entrega" color="red" hide-details></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <!-- <div class="mt-2">
                                    <s-toolbar label="Detalle" :add="isEditable" @add="dialogAdd = true" :remove="isEditable" @removed="removeItem()" v-if="orderLocal.OrdStatus == 3"></s-toolbar>
                                </div> -->

                                <v-card outlined>
                                    <v-data-table style="font-family: " dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>

                                        <template v-slot:item.OddUnitPrice="props">
                                            <s-text style="width: 125px" decimal @input="changeItem(props.item)" :disabled="!isEditable" v-model="props.item.OddUnitPrice">
                                                Precio Unitario
                                            </s-text>
                                        </template>

                                        <!-- modificando de descripcion si es en requerimiento de servicio -->
                                        <template v-slot:item.OddDescription="props">
                                            <s-text style="width: 400px" :disabled="!isEditable" :maxlength="100" v-model="props.item.OddDescription">
                                                Descripcion</s-text>
                                        </template>

                                        <!-- @input="changeItem(props.item)" -->
                                        <template v-slot:item.OddObservation="props">
                                            <s-text label="" v-if="props.item.ReqID == null" v-model="props.item.OddObservation"></s-text>
                                            <div v-else>
                                                <s-text label="" v-model="props.item.OddObservation"></s-text>
                                            </div>
                                        </template>

                                        <!-- <template v-slot:item.OddTaxAmount="{ item }">
                                            {{
                          item.OddTaxAmount > 0
                            ? item.OddTaxAmount.toFixed(2)
                            : item.OddTaxAmount
                        }}
                                        </template> -->

                                        <template v-slot:item.OddAmount="{ item }">
                                            {{
                          parseFloat(item.OddAmount) > 0 ? parseFloat(item.OddAmount).toFixed(2) : item.OddAmount
                        }}
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-col style="padding-top: 24px">
                            <v-row>
                                <v-col lg="9" cols="12">
                                    <v-row>
                                        <v-col class="s-col-text">
                                            <v-textarea auto-grow ref="OrdObservation" :readonly="!isEditable" label="Observaciones Generales" v-model="orderLocal.OrdObservation" />
                                            <span style="font-size: 9px; color: red">Max {{ "100" }} Caracteres -
                                                {{
                            orderLocal.OrdObservation !== undefined &&
                            orderLocal.OrdObservation !== null
                              ? orderLocal.OrdObservation.length
                              : ""
                          }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col lg="3">

                                    <s-select-definition v-if="orderLocal.CttID == null && isEditable" :outlined="false" id="DedAbbreviation" return-object :def="1150" v-model="tax" @input="changeTax($event)" />

                                    <v-divider class="mt-4 mb-4"></v-divider>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>V. NETO</b></v-label>
                                        </v-col>
                                        <v-col lg="6" cols="6" class="s-col-form">
                                            <h4 class="text-right">
                                                <!-- {{orderLocal.OrdTotalNeto}} -->
                                                <h4 class="text-right">
                                                    {{
                              orderLocal.OrdTotalNeto >= 1000
                                ? orderLocal.OrdTotalNeto.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : orderLocal.OrdTotalNeto.toFixed(2)
                            }}
                                                </h4>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-4"></v-divider>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>COSTOS DIRECTOS</b></v-label>
                                        </v-col>
                                        <v-col lg="6" cols="6" class="s-col-form">
                                            <h4 class="text-right" v-if="orderLocal.CttID !== null || !isEditable">
                                                {{
                            orderLocal.OrdDirectCosts >= 1000
                              ? orderLocal.OrdDirectCosts.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                })
                              : orderLocal.OrdDirectCosts == null
                              ? 0
                              : orderLocal.OrdDirectCosts.toFixed(2)
                          }}
                                            </h4>
                                            <h4 class="text-right" v-else>
                                                <s-text :outlined="false" decimal v-model="orderLocal.OrdDirectCosts" @input="changeTotal()"></s-text>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>GTO GENERALES</b></v-label>
                                        </v-col>
                                        <v-col lg="6" class="s-col-form">
                                            <h4 class="text-right" v-if="orderLocal.CttID !== null || !isEditable">
                                                {{
                            orderLocal.OrdGeneralgto >= 1000
                              ? orderLocal.OrdGeneralgto.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                })
                              : orderLocal.OrdGeneralgto == null
                              ? 0
                              : orderLocal.OrdGeneralgto.toFixed(2)
                          }}
                                            </h4>
                                            <h4 class="text-right" v-else>
                                                <s-text :outlined="false" decimal v-model="orderLocal.OrdGeneralgto" @input="changeTotal()"></s-text>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>UTILIDADES</b></v-label>
                                        </v-col>
                                        <v-col lg="6" class="s-col-form">
                                            <h4 class="text-right" v-if="orderLocal.CttID !== null || !isEditable">
                                                {{
                            orderLocal.OrdUtilities >= 1000
                              ? orderLocal.OrdUtilities.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                })
                              : orderLocal.OrdUtilities == null
                              ? 0
                              : orderLocal.OrdUtilities.toFixed(2)
                          }}
                                            </h4>
                                            <h4 class="text-right" v-else>
                                                <s-text :outlined="false" decimal v-model="orderLocal.OrdUtilities" @input="changeTotal()"></s-text>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>DESCUENTO</b></v-label>
                                        </v-col>
                                        <v-col lg="6" class="s-col-form">
                                            <h4 class="text-right" v-if="orderLocal.CttID !== null || !isEditable">
                                                {{
                            orderLocal.OrdPolicy >= 1000
                              ? orderLocal.OrdPolicy.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                })
                              : orderLocal.OrdPolicy == null
                              ? 0
                              : orderLocal.OrdPolicy.toFixed(2)
                          }}
                                            </h4>
                                            <h4 class="text-right" v-else>
                                                <s-text :outlined="false" decimal v-model="orderLocal.OrdPolicy" @input="changeTotal()"></s-text>
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-4"></v-divider>
                                    <v-row>
                                        <v-col lg="6" class="s-col-form">
                                            <v-label><b>SUB TOTAL</b></v-label>
                                        </v-col>
                                        <v-col lg="6" class="s-col-form">
                                            <h4 class="text-right">
                                                {{
                            orderLocal.OrdSubTotal >= 1000
                              ? orderLocal.OrdSubTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdSubTotal.toFixed(2)
                          }}
                                            </h4>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="s-col-form">
                                            <v-label><b>IMPUESTO{{ " (" + orderLocal.OrdTaxPercentage + "%)" }}</b></v-label>
                                        </v-col>
                                        <v-col class="s-col-form">
                                            <h4 class="text-right">
                                                {{
                            orderLocal.OrdTaxAmount >= 1000
                              ? orderLocal.OrdTaxAmount.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTaxAmount.toFixed(2)
                          }}
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mt-4 mb-4"></v-divider>
                                    <v-row>
                                        <v-col class="s-col-form">
                                            <v-label><b>TOTAL</b></v-label>
                                        </v-col>
                                        <v-col class="s-col-form">
                                            <h4 class="text-right">
                                                {{
                            orderLocal.OrdTotal >= 1000
                              ? orderLocal.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : orderLocal.OrdTotal.toFixed(2)
                          }}
                                            </h4>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/RequirementService";
import _sRequirementConfig from "@/services/Logistics/LgsRequirementconfig";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";

//Components
import vSupplier from "@/views/Logistics/vSupplier.vue";

export default {
    components: {
        vSupplier,
    },
    props: {
        order: {},
        value: null,
        typeOrder: null,
        viewLoad: {
            type: Boolean,
            default: true,
        },
        ofCotization: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        /* rules: [
            (value) =>
            !value ||
            value.size < 2000000 ||
            "El tamaño del archivo debe ser inferior a 2 MB!",
        ], */

        processing: false,
        messageProcessing: "",

        dialogTax: false,
        selectedTax: false,
        messageSustentos: false,
        selectedrow: [],
        circuits: [],
        selectedCircuit: [],
        filterLevels: {},
        configLevels: {
            model: {
                AccID: "int",
                AlvID: "ID",
                SecStatus: "boolean",
                PstID: "int",
                PstName: "string",
                OddAmount: "",
                OddUnitPrice: "",
                TaxCode: "",
            },
            service: _sApprovalLevel,
            headers: [{
                    text: "Cargo",
                    value: "PstName",
                },
                {
                    text: "Nivel",
                    value: "TypeLevelText",
                },
            ],
        },
        optionApproved: false,
        dateOrder: null,
        selectedSAP: [],
        transactionApproved: null,
        tax: null,
        dialogCircuit: false,
        orderLocal: {
            TypeCurrency: null,
        },
        dialogAdd: false,
        itemsConcept: [],
        itemsAccount: [],
        ConName: null,
        ConTelephone: null,
        ProviderBloqueado: 0,
        ConEmail: null,
        SupID: "",
        Supplier: null,
        itemsSAP: [],
        itemsRemove: [],
        Attach1: null,
        Attach2: null,
        headersSAP: [
            {
                text: "Descripción",
                value: "OddDescription",
                sortable: false,
                width: 540,
            },
            {
                text: "Prc. U.(Sin IGV)",
                value: "OddUnitPrice",
                sortable: false,
                width: 90,
            },
            {
                text: "Cant",
                value: "DerQuantity",
                sortable: false,
                width: 50,
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
                width: 50,
            },
            {
                text: "SubTotal",
                value: "OddAmount",
                sortable: false,
                width: 70,
                align: "right",
            },
            {
                text: "Comentario",
                value: "OddObservation",
                sortable: false,
                width: 400,
            },
        ],

        //itemsSupport: [],
        SupportRequired: {
            SreCompPayment: true,
            SreXml: true,
            SreCdr: true,
            SreOcos: true,
            SreQuotation: true,
            SreContracts: false,
            SreAddenda: false,
            SreValuations: false,
            SreRecordConformity: false,
            SreContracts_1: false,
            SreAddenda_1: false,
            SreReports: false,
            SreGuideRemission: false,
            SrePhotos: false,
            SreLiquidations: false,
            SreRecordDelivery: false,
        },
        montoContratoSol: 30000,
        montoContratoDoll: 10000,
        visibleContract: false,
        numberContract: null,
        itemAttach: [],
        sizeAttach: 2000000,
        headersFiles: [{
            text: "Archivo",
            value: "RqdAttach"
        }, {
            text: "Fec. C.",
            value: "SecCreate"
        }],
        objAmountGenerateContract: null,
        errors: null,
        TrsID: null

    }),
    computed: {
        isEditable() {
            /* return ((this.orderLocal.OrdStatus == 3 && this.$fun.getUserID() == this.orderLocal.UsrCreateID) || this.orderLocal.OrdID == null); */
            return this.orderLocal.OrdID == null;
        },
    },

    created() {

        if (this.value != null) {
            this.initialize()
        } else {
            if (this.order.CttID !== null) {
                this.orderLocal = this.order;

                this.Supplier = this.order.sup;
                this.ConName = this.order.sup.ConName;
                this.ConTelephone = this.order.sup.ConTelephone;
                this.ConEmail = this.order.sup.ConEmail;
                this.tax = {
                    DedValue: this.order.TypePercentaje,
                    DedHelper: this.order.OrdTaxPercentage,
                };

            } else {
                this.orderLocal = this.order;
                this.orderLocal.CttID = null
                this.orderLocal.OrdDeliveryTime = 0
                this.orderLocal.OrdTimeGarantia = 0
                this.orderLocal.TypeCurrency = 1
            }

            console.log("al levantar", this.orderLocal)

            this.orderLocal.TypeOrder = this.typeOrder;

            this.itemsSAP = this.order.detail;

            if (this.itemsSAP)
                for (let i = 0; i < this.itemsSAP.length; i++) this.itemsSAP[i].Line = i + 1;
        }

        this.getConfigFiles();
        this.changeTotal();
    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {
        Supplier() {
            if (this.Supplier != null && this.isEditable)
                if (this.Supplier.SupID != null) {
                    this.SupID = this.Supplier.SupID;
                    this.ConName = this.Supplier.ConName;
                    this.ConTelephone = this.Supplier.ConTelephone;
                    this.ConEmail = this.Supplier.ConEmail;
                    this.ProviderBloqueado = this.Supplier.ProviderBloqueado;
                }
        },
        isEditable() {
            this.processing = false;
        },

        'orderLocal.TypeCurrency'() {
            this.viewContract()
        },
    },

    methods: {

        initialize() {
            _sOrderPurchase
                .byID({
                        OrdID: this.value,
                    },
                    this.$fun.getUserID()
                )
                .then((r) => {
                    r.data.itemsAttach.forEach((element) => {
                        element.Attach = {
                            name: element.RqdAttach,
                        };

                        element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    });

                    this.orderLocal = r.data;
                    //this.itemAttach = this.orderLocal.itemsAttach;

                    let sup = {
                        SupID: this.orderLocal.SupCode,
                        SupName: this.orderLocal.SupName,
                    };

                    this.dateOrder = this.orderLocal.OrdDate;
                    this.ConName = this.orderLocal.ConName;
                    this.ConTelephone = this.orderLocal.ConTelephone;
                    this.ConEmail = this.orderLocal.ConEmail;
                    this.numberContract = this.orderLocal.NumberContract;

                    this.Supplier = sup;
                    this.OrdStatus = r.data.OrdStatus;
                    this.itemsSAP = r.data.Detail;

                    if (r.data.itemsSupport !== null) {
                        this.SupportRequired = r.data.itemsSupport;
                        this.messageSustentos = false;
                    } else {
                        this.messageSustentos = true;
                    }

                    this.orderLocal.Transaction.OrdStatus = this.orderLocal.OrdStatus;
                    this.transactionApproved = this.orderLocal.Transaction;
                    if (this.orderLocal.Transaction != null)
                        if (this.orderLocal.Transaction.UsersApproved.length > 0) {
                            if (
                                this.orderLocal.Transaction.UsersApproved.find(
                                    (x) => x.UsrID == this.$fun.getUserID()
                                )
                            ) {
                                if (this.orderLocal.OrdStatus == 4) {
                                    this.optionApproved = true;
                                } else this.optionApproved = false;
                            }
                        }
                });

        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 1).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        //***************************************** Item Detalle ******************************************************************** */

        getConfigFiles() {
            /* if (this.value == null) { */
            _sRequirementConfig.list(2, this.$fun.getUserID()).then((resp) => {
                this.itemAttach = resp.data;
            });
            /* } */
        },

        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        /***************************************** Fin Item Detalle ***************************************************************** */

        inputCurrency(item) {
            if (item > 0) {
                _sOrderPurchase.byTypeCurency({
                    TypeCurrency: item
                }, this.$fun.getUserID()).then(resp => {
                    this.objAmountGenerateContract = resp.data
                    this.viewContract()
                })
            }
        },
        viewContract() {

            if (this.objAmountGenerateContract !== null) {
                if (this.orderLocal.OrdTotal >= this.objAmountGenerateContract.AgcMin && this.orderLocal.OrdTotal <= this.objAmountGenerateContract.AgcMax) {
                    this.visibleContract = true;
                } else {
                    this.visibleContract = false;
                }

            }
        },

        changeItem(item) {
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTotalNeto = 0;
            this.orderLocal.OrdTaxAmount = 0;

            let edit = Object.assign({},
                //this.itemsSAP.find((x) => x.CtdID == item.CtdID)
                this.itemsSAP.find((x) => x.Line == item.Line)
            );

            let indexEdit = this.itemsSAP.indexOf(item);

            edit.OddAmount = edit.OddUnitPrice * edit.OddQuantity;

            this.itemsSAP.splice(indexEdit, 1, edit);

            this.itemsSAP.forEach((element) => {
                let unitPrice =
                    element.OddUnitPrice == null || element.OddUnitPrice == "" ?
                    0 :
                    element.OddUnitPrice;

                this.orderLocal.OrdTotalNeto =
                    parseFloat(this.orderLocal.OrdTotalNeto) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdSubTotal) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity)) +
                    (parseFloat(
                            this.orderLocal.OrdDirectCosts == "" ? 0 : this.orderLocal.OrdDirectCosts
                        ) +
                        parseFloat(
                            this.orderLocal.OrdGeneralgto == "" ? 0 : this.orderLocal.OrdGeneralgto
                        ) +
                        parseFloat(
                            this.orderLocal.OrdUtilities == "" ? 0 : this.orderLocal.OrdUtilities
                        ) +
                        parseFloat(this.orderLocal.OrdPolicy == "" ? 0 : this.orderLocal.OrdPolicy));
            });

            this.orderLocal.OrdTaxAmount =
                (parseFloat(this.orderLocal.OrdSubTotal) *
                    parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                100;
            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;

            this.viewContract()
        },

        //Al Seleccionar Tipo de Impuesto
        changeTax(item) {
            if (this.tax != null) {

                this.orderLocal.OrdTaxPercentage = this.tax.DedHelper
                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;
                this.changeTotal(item);
                this.viewContract()
            }
        },

        changeTotal() {
            this.orderLocal.OrdTotalNeto = 0;
            this.orderLocal.OrdSubTotal = 0;
            this.orderLocal.OrdTaxAmount = 0;

            this.itemsSAP.forEach((element) => {
                let unitPrice = element.OddUnitPrice == null ? 0 : element.OddUnitPrice;

                this.orderLocal.OrdTotalNeto =
                    parseFloat(this.orderLocal.OrdTotalNeto) +
                    parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity));

                this.orderLocal.OrdSubTotal =
                    parseFloat(this.orderLocal.OrdTotalNeto)
                    /* parseFloat(unitPrice) *
                    (element.OddQuantity == null ? 1 : parseFloat(element.OddQuantity)) */
                    +
                    (parseFloat(
                            this.orderLocal.OrdDirectCosts == "" ? 0 : this.orderLocal.OrdDirectCosts
                        ) +
                        parseFloat(
                            this.orderLocal.OrdGeneralgto == "" ? 0 : this.orderLocal.OrdGeneralgto
                        ) +
                        parseFloat(
                            this.orderLocal.OrdUtilities == "" ? 0 : this.orderLocal.OrdUtilities
                        ) +
                        parseFloat(this.orderLocal.OrdPolicy == "" ? 0 : this.orderLocal.OrdPolicy)
                    );

                this.orderLocal.OrdTaxAmount =
                    (parseFloat(this.orderLocal.OrdSubTotal) *
                        parseFloat(this.tax == null ? 0 : this.tax.DedHelper).toFixed(2)) /
                    100;
            });

            this.orderLocal.OrdSubTotal = parseFloat(
                parseFloat(this.orderLocal.OrdSubTotal).toFixed(2)
            );
            this.orderLocal.OrdTaxAmount = parseFloat(
                parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2)
            );

            this.orderLocal.OrdTotal =
                this.orderLocal.OrdSubTotal + this.orderLocal.OrdTaxAmount;
        },

        save() {
            if (this.Supplier == null) {
                this.$fun.alert("Falta proveedor", "warning");
                return;
            }

            if (this.ProviderBloqueado > 0) {
                this.$fun.alert(
                    "Proveedor en Lista negra, no se puede generar Orden. Consulte con el Área Encargada",
                    "error"
                );
                return;
            }

            if (
                this.ConName == null ||
                this.ConName == "" ||
                this.ConName.toUpperCase() == "ND"
            ) {
                this.$refs.ConName.focus();
                this.$refs.ConName.error("Ingrese Contacto");
                return;
            }

            if (
                this.ConTelephone == null ||
                this.ConTelephone == "" ||
                this.ConTelephone.toUpperCase() == "ND"
            ) {
                this.$refs.ConTelephone.focus();
                this.$refs.ConTelephone.error("Ingrese Telefono");
                return;
            }

            if (!this.$fun.isValidEmail(this.ConEmail)) {
                this.$refs.ConEmail.focus();
                this.$refs.ConEmail.error("Correo invalido");
                return;
            }

            if (this.orderLocal.TypeCurrency == null) {
                this.$fun.alert("Falta Tipo de Moneda", "warning");
                return;
            }

            if (this.visibleContract) {
                if (this.numberContract == null || this.numberContract.length == 0) {
                    this.$refs.numberContract.focus();
                    this.$refs.numberContract.error("Ingrese Nro. Solicitud de Contrato");

                    return;
                }
            }

            if (this.dateOrder < this.$fun.getDate()) {
                this.$fun.alert("Fecha no puede ser menor a fecha actual", "warning");
                return;
            }

            if (
                this.orderLocal.OrdObservation !== undefined &&
                this.orderLocal.OrdObservation.length > 100
            ) {
                this.$refs.OrdObservation.focus();
                this.$fun.alert("Maximo 100 Caracteres", "warning");
                return;
            }

            this.order.detail = [];
            for (let i = 0; i < this.itemsSAP.length; i++) {
                if (this.itemsSAP[i].OddUnitPrice == null || this.itemsSAP[i].OddUnitPrice == 0) {
                    this.$fun.alert(
                        "Precio inválido, " + this.itemsSAP[i].OddDescription,
                        "warning"
                    );
                    return;
                }

                this.itemsSAP[i].TypeCurrency = this.orderLocal.TypeCurrency;
                this.itemsSAP[i].UsrUpdateID = this.$fun.getUserID();
            }

            if (this.$fun.isValidExtension(this.itemAttach)) {
                return;
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return;
            }

            this.orderLocal.Detail = this.itemsSAP;

            this.orderLocal.ReqID = this.order.ReqID;
            this.orderLocal.SupCode = this.Supplier.SupID;
            this.orderLocal.ConName = this.ConName;
            this.orderLocal.ConTelephone = this.ConTelephone;
            this.orderLocal.ConEmail = this.ConEmail;

            this.orderLocal.CardName = this.Supplier.SupName;
            this.orderLocal.OrdDate = this.dateOrder;
            this.orderLocal.NumberContract = this.numberContract;

            this.orderLocal.itemSupport = this.SupportRequired;
            this.orderLocal.itemSupport.SecStatus = 1;
            this.orderLocal.itemSupport.UsrCreateID = this.$fun.getUserID();

            this.orderLocal.OrdSubTotal = parseFloat(this.orderLocal.OrdSubTotal) > 0 ? parseFloat(this.orderLocal.OrdSubTotal).toFixed(2) : this.orderLocal.OrdSubTotal
            this.orderLocal.OrdTaxAmount = parseFloat(this.orderLocal.OrdTaxAmount) > 0 ? parseFloat(this.orderLocal.OrdTaxAmount).toFixed(2) : this.orderLocal.OrdTaxAmount
            this.orderLocal.OrdTotal = parseFloat(this.orderLocal.OrdTotal) > 0 ? parseFloat(this.orderLocal.OrdTotal).toFixed(2) : this.orderLocal.OrdTotal
            this.orderLocal.OrdTotalNeto = parseFloat(this.orderLocal.OrdTotalNeto) > 0 ? parseFloat(this.orderLocal.OrdTotalNeto).toFixed(2) : this.orderLocal.OrdTotalNeto

            if (this.orderLocal.Detail.length > 0) {
                this.orderLocal.Detail.forEach(element => {
                    element.OddUnitPrice = parseFloat(element.OddUnitPrice) > 0 ? parseFloat(element.OddUnitPrice).toFixed(6) : element.OddUnitPrice
                    element.OddAmount = parseFloat(element.OddAmount) > 0 ? parseFloat(element.OddAmount).toFixed(2) : element.OddAmount
                });
            }

            console.log("SAVE ORD", this.orderLocal);

            /* this.$fun.alert("Seguro de guardar?", "question", false).then((r) => {
                this.$emit("clearItems");
                if (r.value) {
                    this.messageProcessing = "";
                    this.processing = true;
                    this.orderLocal.Detail.push(...this.itemsRemove);

                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemAttach.length; index++) {
                        if (this.itemAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemAttach[index].RqdID,
                                RqdAttach: this.itemAttach[index].RqdAttach,
                            });
                        }
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral
                        .uploadfilelogistics("123", this.$fun.getUserID(), formData, 1)
                        .then((resp) => {
                                if (resp.status == 200) {
                                    this.orderLocal.itemsAttach = resp.data;

                                    _sOrderPurchase.save(this.orderLocal, this.$fun.getUserID(), 0).then(
                                        (r) => {

                                            if (r.status == 200) {

                                                if (r.data.length > 1) {
                                                    this.circuits = r.data;
                                                    this.dialogCircuit = true;
                                                    this.processing = false;
                                                } else {
                                                    this.processing = false;
                                                    this.$fun.alert("Guardado Correctamente", "success");
                                                    this.$emit("close");
                                                    this.$emit("onCloseClear");
                                                    this.$emit("onClose");
                                                }
                                            }

                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        }
                                    );
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                }
            }); */

            this.$fun.alert("¿Seguro de grabar?", "question").then(val => {
                if (val.value) {
                    this.$fun.uploadFiles(this.itemAttach, 1).then(resp => {
                        if (resp.status == 200) {

                            console.log("resp.data", resp.data)

                            if (resp.data !== null && resp.data !== "No cargo ningún archivo") {
                                resp.data.forEach((element) => {
                                    element.OrdID = this.orderLocal.OrdID;
                                    element.SecStatus = 1;
                                    element.UsrCreateID = this.$fun.getUserID();
                                });
                            }

                            this.orderLocal.itemsAttach = resp.data;

                            this.messageProcessing = ""
                            this.processing = true

               /*              this.orderLocal.OrdSubTotal = OrdSubTotal
                            this.orderLocal.OrdTaxAmount = OrdTaxAmount
                            this.orderLocal.OrdTotal = OrdTotal
                            this.orderLocal.OrdTotalNeto = OrdTotalNeto */

                            _sOrderPurchase
                                .save(this.orderLocal, this.$fun.getUserID(), 0)
                                .then((r) => {
                                        if (r.status == 200) {

                                            if (r.data.length > 1) {
                                                this.circuits = r.data;
                                                this.dialogCircuit = true;
                                                this.processing = false;
                                            } else {
                                                this.processing = false;
                                                this.$fun.alert("Guardado Correctamente", "success");
                                                this.$emit("close");
                                                this.$emit("onCloseClear");
                                                this.$emit("onClose");
                                            }
                                        }
                                    },
                                    (e) => {
                                        this.messageProcessing = e.response.data.Message;
                                        this.processing = false;
                                    })

                        }
                    })
                }
            })
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID,
            };
        },

        createCircuit(item) {
            if (this.itemsSAP.length < 1) {
                this.$fun.alert("No se ha registrado ningún detalle", "warning");
                return;
            }
            this.$fun
                .alert("Guardar con " + this.selectedCircuit[0].AccName, "question")
                .then((r) => {
                    if (r.value) {
                        this.messageProcessing = "";
                        this.processing = true;
                        this.orderLocal.UsrUpdateID = this.$fun.getUserID() //this.selectedCircuit[0].AccID;
                        this.dialogCircuit = false;
                        this.orderLocal.Detail = this.itemsSAP;
                        _sOrderPurchase
                            .save(this.orderLocal, this.$fun.getUserID(), this.selectedCircuit[0].AccID)
                            .then(
                                (r) => {
                                    this.processing = false;
                                    this.$emit("onCloseClear");
                                    this.$fun.alert("Agregado Correctamente", "success");
                                    this.$emit("close");
                                    this.$emit("onClose");
                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = false;
                                }
                            );
                    }
                });
        },

        pdf(item) {

            if (item.OrdID == null) {
                this.$fun.alert("Orden no creada.", "warning");
                return;
            }

            this.processing = true;
            item.TypePrint = 1;

            console.log(item)

            _sOrderPurchase.PDF(item, this.$fun.getUserID()).then(
                (r) => {
                    if (r.status == 200) {
                        console.log("imprimir pdf",r)
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, item.TypeOrder == 3 ? "Orden_Servicio_" + item.OrdDocumentNumber : "Orden_Compra_" + item.OrdDocumentNumber);
                        this.processing = false;
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                }
            );
        },

        uploadFile() {
            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemAttach.length; index++) {
                if (this.itemAttach[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemAttach[index].RqdID,
                        RqdAttach: this.itemAttach[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existen archivos para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivos?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 1).then(resp => {
                            if (resp.status == 200) {
                                this.orderLocal.itemsAttach = resp.data

                                this.orderLocal.itemsAttach.forEach(element => {
                                    element.OrdID = this.orderLocal.OrdID
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                                if (this.orderLocal.itemsAttach.length > 0) {
                                    _sOrderPurchase.savefiles(this.orderLocal.itemsAttach, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                this.initialize();
                                                this.processing = false;
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        });
                                }
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });
                }
            })

        },
    },
};
</script>
