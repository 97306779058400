import Service from "../Service";

const resource = "alert/";

export default {
    check(requestID) {
        return Service.post(
            "alert/check", {}, {
                params: { requestID: requestID },
            }
        );
    },

    byAlert(alert, requestID) {
        return Service.post(
            "transaction/byalert",
            alert, {
                params: { requestID: requestID },
            }
        );
    },

    pagination(dtr, requestID) {
        return Service.post(
            resource + "alertPagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },

    paginationcomments(dtr, requestID) {
        return Service.post(
            resource + "alertcommentspagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },

};