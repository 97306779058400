<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">
            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row style="">
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-data-table dense item-key="AccID" :items="circuits" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center'},
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
    <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="requirement.ReqID" :TypeObject="requirement.TrsID" />
    </v-dialog>

    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" dark @save="save()" :save="isEditable" :approve="optionApproved" close @close="$emit('close')" :transactionApproved="transactionApproved" :label="'Solicitud  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)">

        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>

                <v-row>

                    <v-col lg="4" md="4" cols="12">
                        <v-card elevation="0" height="auto">

                            <v-row>
                                <v-col style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text disabled label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                            <v-row class="mt-1" v-if="visible">
                                <v-col lg="12" cols="12" class="s-col-text mb-2"><b style="font-family:Calibri;text-decoration: underline;">Datos del Cliente</b>
                                </v-col>
                                <v-col cols="6" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="Doc. Cliente" :value="requirement.DocCustomer"></s-text>
                                </v-col>

                                <v-col cols="6" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="Nombre del cliente" :value="requirement.CustomerName"></s-text>
                                </v-col>

                                <v-col cols="6" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="Codigo Venta" :value="requirement.CodSales"></s-text>
                                </v-col>

                                <v-col cols="6" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="NroLot" :value="requirement.NroLot"></s-text>
                                </v-col>

                                <v-col cols="12" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="Proyecto" :value="requirement.ProjectName"></s-text>
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                            <v-row style="margin-top:10px; margin-bottom: 10px">
                                <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri;text-decoration: underline;">Anexos</b>
                                    <v-btn v-if="!isEditable" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadFile()">Subir Archivos
                                        <v-icon> mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col lg="12" cols="12" class="s-col-text">

                                    <v-row>
                                        <v-col :lg="itemAttach.length == 1 ? 12:6" :cols="itemAttach.length == 1 ? 12:6" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                            <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                <template slot="append">
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 10px" horizontal></v-divider>
                            <v-divider style="margin-top:10px; margin-bottom: 10px" horizontal></v-divider>

                            <v-row v-if="!isEditable">
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-toolbar dense elevation="0" color="#7a8ca9" dark>
                                            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                                            <v-toolbar-title>Comentarios</v-toolbar-title>
                                        </v-toolbar>

                                        <v-list>
                                            <v-list-item-group :active-class="''">

                                                <template v-for="(item, index) in requirement.listComments">

                                                    <v-list-item :key="item.RccID">
                                                        <v-list-item-avatar>
                                                            <!--  <v-img :src="item.RccAvatar"></v-img> -->
                                                            <v-row justify="space-around">
                                                                <v-avatar color="red">
                                                                    <span class="white--text text-h5">{{item.NtpName !== undefined ? item.NtpName.split(" ")[0].substring(0,1) : ""}}{{item.NtpName !== undefined ? item.NtpName.split(" ")[1].substring(0,1) : ""}}</span>
                                                                </v-avatar>
                                                            </v-row>

                                                        </v-list-item-avatar>

                                                        <v-list-item-content>

                                                            <b>
                                                                <v-list-item-title>{{item.NtpName}}
                                                                    <v-spacer></v-spacer> {{item.SecCreate}}
                                                                </v-list-item-title>
                                                            </b>
                                                            <v-divider></v-divider>

                                                            {{item.RccSubtitleExtract}}

                                                            <v-list-group v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                                <template v-slot:activator>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>Ver mas</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </template>

                                                                <v-list-item v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                                    <v-list-item-content>
                                                                        {{item.RccSubtitle}}
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list-group>

                                                            <!-- <v-list-item-title style="font-size: 12px" v-html="item.RccSubtitle"></v-list-item-title> -->

                                                        </v-list-item-content>

                                                        <a style="color: red;font-size:10px" v-if="item.UsrCreateID == $fun.getUserID() && item.SecCreate >= $fun.getDate() && requirement.ReqStatus == 4" @click="deleteComments(item)">Eliminar</a>

                                                    </v-list-item>
                                                    <v-divider :key="index"></v-divider>
                                                </template>

                                            </v-list-item-group>
                                        </v-list>

                                        <!--  <v-list>
                                            <v-list-group v-for="item in requirement.listComments" :key="item.RccID" v-model="item.active" :prepend-icon="item.action">

                                                <template v-slot:activator>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item.NtpName"></v-list-item-title>
                                                    </v-list-item-content>
                                                </template>

                                                <v-list-item>

                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="item.RccSubtitle"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-group>
                                        </v-list> -->

                                    </v-card>
                                </v-col>

                            </v-row>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col :lg="isEditable ? 8 : 8" :md="isEditable ? 8 : 8" cols="12">
                        <v-row justify="center">

                            <v-col cols="5" v-if="!isEditable">
                                <b>ESTADO PROCESO:</b>
                                <h2 style="color: red"> {{requirement.AlvProcessName == null ? "PROCESO TERMINADO" : requirement.AlvProcessName}}</h2>
                                <h4 style="color: ">{{requirement.AlvDescription}}</h4>
                            </v-col>
                            <v-divider style="margin-top:-10px; margin-bottom: 0px" vertical></v-divider>
                            <v-col cols="5" v-if="!isEditable">
                                <b>PROCESO SIGUIENTE:</b>
                                <h2 style="color: red"> {{requirement.AlvProcessNameNext == null ? "-" : requirement.AlvProcessNameNext}}</h2>
                                <h4 style="color: ">{{requirement.AlvDescriptionNext}}</h4>
                            </v-col>
                            <v-divider style="margin-top:-10px; margin-bottom: 0px" vertical></v-divider>
                            <v-col cols="2" v-if="!isEditable">
                                <b>NIVELES DEL PROCESO</b>
                                <v-btn fab title="Ver Niveles" color="info" style="margin-left:10px" icon small elevation="0" @click="dialogApprovalRequirement= true"><i style="font-size:20px" color="info" class="fas fa-eye"></i></v-btn>

                                <!-- <h2 style="color: red"> {{requirement.AlvProcessNameNext == null ? "-" : requirement.AlvProcessNameNext}}</h2>
                                <h4 style="color: ">{{requirement.AlvDescriptionNext}}</h4> -->
                            </v-col>

                            <v-col cols="12">
                                <v-divider style="margin-top:-10px; margin-bottom: 0px" horizontal></v-divider>
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <c-select-area v-if="isEditable" disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
                                <s-text label="Área" :disabled="!isEditable" v-if="!isEditable" v-model="requirement.AreName"></s-text>
                            </v-col>
                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date disabled label="Fecha Solicitud" :value="requirement.ReqDate" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
                                <s-select-definition :dgr="50" :def="1187" label="Estado" :disabled="!isEditable" v-model="requirement.ReqStatus" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-select-definition :def="1158" label="Prioridad" :disabled="!isEditable" v-model="requirement.TypePriority">
                                </s-select-definition>
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="8" lg="8" md="8">
                                <s-select-transaction v-model="requirement.TrsID" label="Tipo de Solicitud" :typeprocess="3" :disabled="!isEditable" @input="getConfigFiles($event)" />
                            </v-col>

                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition :def="1168" label="Medio" v-model="requirement.TypeMedio" :disabled="!isEditable"></s-select-definition>
                            </v-col>
                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <!-- <v-row style="margin-top:10px; margin-bottom: 10px" v-if="!isEditable">
                            <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Archivos Adjuntos</b>
                            </v-col>
                            <v-col lg="12" cols="12" class="s-col-text">

                                <v-row>
                                    <v-col lg="4" cols="4" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                        <s-text :rules="rules" :label="item.RqdDescription" :readonly="!isEditable" v-model="item.Attach.name" :outlined="false">
                                            <template slot="append">
                                                <v-icon v-if="item!==null" color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                            </template>
                                        </s-text>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row> -->

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row style="margin-top:10px; margin-bottom: 10px" v-if="!isEditable">
                            <v-col lg="12" cols="12" class="s-col-text">
                                <h2 style="font-family:Calibri;">Documentos Adjuntos</h2>
                            </v-col>
                            <v-col lg="12" cols="12" class="s-col-text">
                                <v-card outlined>
                                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="requirement.itemsAttach">
                                        <template v-slot:item.RqdAttach="{ item }">
                                            <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                            {{item.RqdAttach}}
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row>

                            <v-col cols="12" style="padding-top: 0px" class="mt-4">
                                <label><b>OBSERVACIONES GENERALES</b></label>
                                <v-btn v-if="!isEditable" elevation="0" small class="ml-2" text color="info" @click="addComments()">Añadir Comentario</v-btn>
                            </v-col>
                            <v-col cols="12" style="padding-top: 0px">
                                <!-- <ckeditor v-model="requirement.ReqObservation" :config="configCk"></ckeditor> -->
                                <s-textarea v-model="requirement.ReqObservation" ref="ReqObservation" :height="100"></s-textarea>
                            </v-col>

                        </v-row>

                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/CustomerSupport/AtcRequirementService";
import _sRequirementConfig from "@/services/Logistics/LgsRequirementconfig";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";

export default {
    components: {
        ApprovalLevelsDocument
    },

    props: {
        value: {
            default: 1,
            type: Number
        },
        itemOC: null,
        visible: {
            default: false,
            type: Boolean
        }
    },

    data() {
        return {
            dialogApprovalRequirement: false,
            messageProcessing: "",
            configCk: {
                extraPlugins: 'autogrow',
                autoGrow_minHeight: 50,
            },
            processing: false,
            dialogCircuit: false,

            selectedCircuit: [],
            filterLevels: {},
            configLevels: {
                model: {
                    AccID: "int",
                    AlvID: "ID",
                    SecStatus: "boleam",
                    PstID: "int",
                    PstName: "string",
                },
                service: _sApprovalLevel,
                headers: [{
                        text: "Cargo",
                        value: "PstName"
                    },
                    {
                        text: "Nivel",
                        value: "TypeLevelText"
                    },
                ],
            },
            circuits: [],

            isUserCreated: true,
            transactionApproved: null,
            dialogDissaproved: false,
            optionApproved: false,
            requirement: {
                AreID: 0,
                ReqDate: null,
                UsrID: null,
                ReqID: null,
                ReqCommitted: null,
                ReqObservation: "",
                ReqStatus: 0,
                ReqDayExpiration: 1
            },

            //sizeAttach: 2000000,

            /* rules: [
                value => !value || value.size < 2000000 || 'El tamaño del archivo debe ser inferior a 2 MB!',
            ], */
            itemAttach: [],
            headersFiles: [{
                    text: "Archivo",
                    value: "RqdAttach"
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha",
                    value: "SecCreate"
                },
            ],
            listComments: [

            ],
            selectedNotification: []
        }

    },
    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        isEditable() {
            return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null;

        },
    },

    created() {

        if (this.value != null) {
            this.initialize()

        } else {
            if (this.itemOC !== undefined && this.itemOC !== null) {
                this.requirement.DocCustomer = this.itemOC.DocCustomer
                this.requirement.CustomerName = this.itemOC.CustomerName
                this.requirement.CodSales = this.itemOC.CodSales
                this.requirement.NroLot = this.itemOC.NroLot
                this.requirement.ProjectName = this.itemOC.ProjectName
            }

        }

        this.getConfigFiles();
    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {
        isEditable() {
            this.processing = false;
        },
    },

    methods: {
        initialize() {
            _sRequirement.byID({
                ReqID: this.value
            }, this.$fun.getUserID()).then((r) => {

                r.data.itemsAttach.forEach(element => {
                    element.Attach = {
                        name: element.RqdAttach
                    };
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                });
                r.data.listComments.forEach(element => {
                    element.divider = false
                    element.inset = false
                    element.active = false
                    element.action = 'mdi-tag'
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    element.RccSubtitleExtract = element.RccSubtitle.substring(0, 300)
                    element.RccLengthExtract = element.RccSubtitleExtract.length
                    element.RccLengthSubtitle = element.RccSubtitle.length
                });

                this.requirement = r.data;

                if (this.requirement !== null) {
                    if (this.requirement.CodSales == "0") {
                        this.visible = false
                    } else {
                        this.visible = true
                    }
                }

                if (this.requirement.UsrCreateID == this.$fun.getUserID()) this.isUserCreated = true;
                else this.isUserCreated = false;

                this.transactionApproved = this.requirement.Transaction;
                if (this.requirement.Transaction != null)
                    if (this.requirement.Transaction.UsersApproved.length > 0)
                        if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                            if (this.requirement.ReqStatus == 4) this.optionApproved = true;

            });
        },

        getConfigFiles(id) {
            if (id !== null && id !== undefined) {
                _sRequirementConfig.list(id, this.$fun.getUserID()).then(resp => {
                    this.itemAttach = resp.data
                })
            }

        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 6).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        uploadFile() {
            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemAttach.length; index++) {
                if (this.itemAttach[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemAttach[index].RqdID,
                        RqdAttach: this.itemAttach[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existen archivos para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivos?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 6).then(resp => {
                            if (resp.status == 200) {
                                this.requirement.itemsAttach = resp.data

                                this.requirement.itemsAttach.forEach(element => {
                                    element.ReqID = this.requirement.ReqID
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                                if (this.requirement.itemsAttach.length > 0) {
                                    _sRequirement.savefiles(this.requirement.itemsAttach, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                //this.$emit("close");
                                                this.initialize()
                                                this.processing = false;
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        });
                                }
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });
                }
            })

        },

        save() {

            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.SecStatus = 1;

            this.requirement.itemsAttach = []
            this.requirement.listComments = []

            if (this.requirement.ReqObservation.length > 0) {
                this.requirement.listComments = [{
                    RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                    RccTitle: "",
                    RccSubtitle: this.requirement.ReqObservation,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID()
                }]
                this.requirement.ReqObservation = "";
            }

            this.$fun.alert("Seguro de continuar con la Solicitud?", "question", false).then((r) => {
                if (r.value) {

                    this.messageProcessing = ""
                    this.processing = true
                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemAttach.length; index++) {
                        if (this.itemAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemAttach[index].RqdID,
                                RqdAttach: this.itemAttach[index].RqdAttach
                            })
                        }
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 6).then(resp => {
                        if (resp.status == 200) {
                            this.requirement.itemsAttach = resp.data

                            if (this.requirement.itemsAttach.length > 0 && this.requirement.itemsAttach !== "No cargo ningún archivo") {
                                this.requirement.itemsAttach.forEach(element => {
                                    element.UsrCreateID = this.$fun.getUserID()
                                });
                            }

                            _sRequirement.save(this.requirement, this.$fun.getUserID(), 0).then((r) => {
                                    if (r.data.length > 1) {
                                        this.circuits = r.data;
                                        this.dialogCircuit = true;
                                        this.processing = false
                                    } else {
                                        this.$fun.alert("Guardado Correctamente", "success");
                                        this.$emit("close");
                                        this.processing = false
                                    }
                                },
                                (e) => {
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = false;
                                });

                        }
                    })
                }
            });
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {

            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();

            this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sRequirement.save(this.requirement, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
                            //this.uploadFileContract()
                            this.processing = false
                            this.$fun.alert("Guardado Correctamente", "success");
                            this.$emit("close");

                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });
                }
            });
        },
        //********************************** FIN CIRCUITOS DE APROBACION************************************************* */

        addComments() {

            if (this.requirement.ReqObservation.length == 0) {
                /* this.$fun.alert("Digite Comentario", "warning") */
                this.$refs.ReqObservation.error("Digite Comentario")
                return
            }

            this.$fun.alert("¿Seguro de añadir comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [{
                        ReqID: this.requirement.ReqID,
                        RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                        RccTitle: "New Comments",
                        RccSubtitle: this.requirement.ReqObservation,
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID()
                    }]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        deleteComments(item) {

            item.SecStatus = 0
            item.UsrUpdateID = this.$fun.getUserID()

            this.$fun.alert("¿Seguro de eliminar comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [item]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

    }

};
</script>
