let children = [];

children.push({
    path: "/Atencionalcliente/circuitos",
    name: "ATC_CIRCUIT",
    component: () =>
        import ("../views/CustomerSupport/AtcAutorizeModel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Atencion al Cliente", disabled: true },
            { text: "Circuitos", href: "/Atencionalcliente/circuitos" },
        ],
    },
});


children.push({
    path: "/Atencionalcliente/registrosolicitud",
    name: "ATC_REGISTER_SOLICITUD",
    component: () =>
        import ("../views/CustomerSupport/Requirement/AtcRequirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Atencion al Cliente", disabled: true },
            { text: "Registro Solicitud", href: "/Atencionalcliente/registrosolicitud" },
        ],
    },
});

children.push({
    path: "/Atencionalcliente/busquedarapida",
    name: "ATC_REQUIREMENT_PENDING",
    component: () =>
        import ("../views/CustomerSupport/Requirement/AtcRequirementPending.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Atencion al Cliente", disabled: true },
            { text: "Busqueda Rapida", href: "/Atencionalcliente/busquedarapida" },
        ],
    },
});

children.push({
    path: "/Atencionalcliente/busquedarapidaporarea",
    name: "ATC_REQUIREMENTAREA",
    component: () =>
        import ("../views/CustomerSupport/Requirement/AtcRequirementPendingArea.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Atencion al Cliente", disabled: true },
            { text: "Busqueda Rapida", href: "/Atencionalcliente/busquedarapidaporarea" },
        ],
    },
});




/* children.push({
    path: "/Atencionalcliente/controlcliente",
    name: "MNG_ACCESSSUTOMER",
    component: () =>
        import ("../views/Management/AccessCustomer/AccessControlCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Atencion al Cliente", disabled: true },
            { text: "Acceso", href: "/Atencionalcliente/controlcliente" },
        ],
    },
}); */



export {
    children
}