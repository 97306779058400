import Service from "../Service";
import Vue from "vue";
const resource = "Supplier/";

export default {
    getContactSupplierURL() {
        return Vue.prototype.$http.defaults.baseURL + "/Supplier/SupplierContacts";
    },
    getSupplierSearch() { return Vue.prototype.$http.defaults.baseURL + "/Supplier/Suppliersearch"; },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    saveBanks(obj, requestID) {
        return Service.post(resource + "savebanks", obj, {
            params: { requestID: requestID },
        });
    },

    saveRepresentative(obj, requestID) {
        return Service.post(resource + "saverepresentative", obj, {
            params: { requestID: requestID },
        });
    },

    saveAttach(obj, requestID) {
        return Service.post(resource + "saveattach", obj, {
            params: { requestID: requestID },
        });
    },

    saveEcoactivities(obj, requestID) {
        return Service.post(resource + "saveecoactivities", obj, {
            params: { requestID: requestID },
        });
    },

    byRuc(document, requestID) {
        return Service.post(resource + "byRuc", {}, {
            params: { document, requestID },
        });
    },

    byID(obj, requestID) {
        return Service.post(resource + "byID", obj, {
            params: { requestID },
        });
    },

    listUbigeo(search, requestID) {
        return Service.post("generic/" + "ubigee", {}, {
            params: { requestID, search },
        });
    },

};