<template>
<div>
    <v-row justify="center">
        <v-col align="center" cols="12" lg="8" md="12">
            <i class="fas fa-camera" style="font-size: 35px; cursor: pointer" @click="dialogCamera = true"></i>
            <s-text label="Scannear QR" ref="textQR" v-model="param.CodeID" :autofocus="autofocus" @keyupEnter="toggleSaveData(param.CodeID)" :readonly="readonly">
            </s-text>
        </v-col>
    </v-row>

    <!-- MODAL PARA CAMARA @keypress="validation($event)"-->
    <v-dialog v-if="dialogCamera" v-model="dialogCamera" max-width="700">
        <v-card>
            <v-col>
                <qrcode-stream @decode="onDecode" @init="onInit" />
            </v-col>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    QrcodeStream
} from "vue-qrcode-reader";

export default {
    name: "sScannerQr",
    components: {
        QrcodeStream
    },
    props: {
        value: {
            default: 0
        },
        autofocus: {
            type: Boolean,
            default: true
        },
        isValidation: {
            type: Number,
            default: 0
        },
        noCamera: {
            type: Boolean,
            default: false
        },

        config: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({
        respon: {}
    }),
    created() {},
    watch: {},
    methods: {
        onDecode(result) {
            this.param.CodeID = result;
            this.respon = result;

            var obj = this.param.CodeID.split(",", 8);
            this.toggleSaveData(obj[0]);
            this.param.CodeID = obj[0];
            this.dialogCamera = false;
        },

        async onInit(promise) {
            try {
                await promise;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    this.error =
                        "ERROR: you need to grant camera access permisson";
                } else if (error.name === "NotFoundError") {
                    this.error = "ERROR: no camera on this device";
                } else if (error.name === "NotSupportedError") {
                    this.error =
                        "ERROR: secure context required (HTTPS, localhost)";
                } else if (error.name === "NotReadableError") {
                    this.error = "ERROR: is the camera already in use?";
                } else if (error.name === "OverconstrainedError") {
                    this.error = "ERROR: installed cameras are not suitable";
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error =
                        "ERROR: Stream API is not supported in this browser";
                }
            }
        },

        clickCamera() {
            this.dialogCamera = true;
        },

        validation(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        focus() {
            this.param.CodeID = null
            this.$refs.textQR.focus();
        },

        toggleSaveData(value) {
            if (value == null) return;

            if (value !== null) {
                if (this.config.service == undefined) {
                    this.$emit("onValue", value);
                    this.$emit("onData", this.respon);
                } else {

                    this.config.service
                        .getDataQR({
                            CodeID: value
                        }, this.$fun.getUserID())
                        .then(response => {
                            if (response.status == 200) {

                                //if (response.data != null) {
                                this.$emit("returnObj", response.data);
                                this.focus()
                                //}
                            }
                        });
                }
                //
            }

            this.$emit('keyupEnter')
        }
    },
    data: () => ({
        dialogCamera: false,
        color: "success",
        param: {
            CodeID: ""
        }
    })
};
</script>

<style scoped>
.error {
    font-weight: bold;
    color: red;
}
</style>
