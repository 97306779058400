<template>
<v-toolbar dense height="30" style="margin-bottom:4px; font-family: Calibri; border-radius: 4px;" :elevation="elevation" :dark="dark" :color="color">
    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al realizar busqueda <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="30%">
            <v-card>
                <s-toolbar label="Motivo"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="disapproved()">Desaprobar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <v-toolbar-title class="bold"> {{ label }}</v-toolbar-title>
    <v-spacer></v-spacer>

    <slot name="options"> </slot>
    <v-tooltip bottom="" v-if="approve || approvedTrs">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="approved()">
                <v-icon color="green" style="font-size:16px;"> far fa-thumbs-up</v-icon>
            </v-btn>
        </template>
        <span>Aprobar</span></v-tooltip>

    <v-tooltip bottom="" v-if="approve || approvedTrs">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="dialogDissaproved = true">
                <v-icon color="red" style="font-size:16px;"> far fa-thumbs-down</v-icon>
            </v-btn>
        </template>
        <span>Desaprobar</span></v-tooltip>
    <slot> </slot>
    <v-tooltip bottom="" v-if="load">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="$emit('load')">
                <v-icon> mdi-arrow-up</v-icon>
            </v-btn>
        </template>
        <span>Cargar</span></v-tooltip>

    <v-tooltip bottom="" v-if="view">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="$emit('view')">
                <v-icon style="font-size:14px;">far fa-eye</v-icon>
            </v-btn>
        </template>
        <span>Ver</span></v-tooltip>

    <v-tooltip bottom="" v-if="download">
        <template v-slot:activator="{ on }" x-small>
            <v-btn text v-on="on" x-small @click="$emit('download')">
                <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
        </template>
        <span>Descargar</span></v-tooltip>

    <v-tooltip bottom="" v-if="add">

        <template v-slot:activator="{ on }">
            <v-btn class="capitalize save" text v-on="on" x-small @click="showForm(true, false)" elevation="0" v-if="acceptAdd">
                <v-icon style="font-size:14px; border-radius: 10px" class="mr-1">mdi-plus-circle-outline</v-icon>

            </v-btn>

            <v-btn class="capitalize save" color="#2f5985" v-on="on" small @click="showForm(true, false)" elevation="0" v-else>
                <v-icon style="font-size:14px; border-radius: 10px" class="mr-1">mdi-plus-circle-outline</v-icon>
                Agregar
            </v-btn>

            <!--  <v-btn color="#2f5985" elevation="0"  small v-on="on" class="capitalize save" @click="saveF()">
              <v-icon style="font-size:14px; border-radius: 10px" class="mr-1">mdi-content-save</v-icon>
              Guardar

            </v-btn> -->
        </template>
        <!-- <span>Agregar</span> -->
    </v-tooltip>

    <v-tooltip bottom="" v-if="exit">
        <template v-slot:activator="{ on }">
            <v-btn text small style="margin-right:5px;" v-on="on" @click="cancel()">
                <v-icon style="font-size:18px;"> mdi-arrow-left-bold</v-icon>
            </v-btn>
        </template>
        <span>Cancelar</span></v-tooltip>

    <v-tooltip bottom="" v-if=" save">
        <template v-slot:activator="{ on }">
            <v-btn color="#2f5985" elevation="0" small v-on="on" class="capitalize save" @click="saveF()">
                <v-icon style="font-size:14px; border-radius: 10px" class="mr-1">mdi-content-save</v-icon>
                Guardar
                <!--text <v-icon style="font-size:16px;">mdi-content-save</v-icon> -->
            </v-btn>
        </template>
        <!-- <span>Guardar</span> -->
    </v-tooltip>

    <v-tooltip bottom="" v-if="off">
        <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="$emit('off')">
                <v-icon style="font-size:16px;">
                    mdi-link-variant-off</v-icon>
            </v-btn>
        </template>
        <span>Desabilitar</span></v-tooltip>

    <div>
        <template>
            <v-tooltip bottom="" v-if="restore">
                <template v-slot:activator="{ on }">
                    <v-btn text small v-on="on" @click="changeStatus(1)">
                        <v-icon style="font-size:18px;">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Habilitar</span></v-tooltip>

            <v-tooltip bottom="" v-if="remove">
                <template v-slot:activator="{ on }">
                    <v-btn text small v-on="on" @click="changeStatus(0)">
                        <v-icon style="font-size:18px;">mdi-minus-circle-outline</v-icon>
                    </v-btn>
                </template>
                <span>Eliminar</span></v-tooltip>
        </template>
    </div>
    <v-tooltip bottom="" v-if="edit && activeItem && !formPermanent">
        <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="showForm(false, true)">
                <v-icon style="font-size:14px;">mdi-file-edit-outline</v-icon>
            </v-btn>
        </template>
        <span>Editar</span></v-tooltip>

    <v-tooltip bottom v-if="clear">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="clearEvent()" elevation="0">
                <v-icon style="font-size:18px;"> mdi-broom</v-icon>
            </v-btn>
        </template>
        <span>Limpiar</span></v-tooltip>

    <v-tooltip bottom v-if="print">
        <template v-slot:activator="{ on }">
            <v-btn text v-on="on" small @click="printRow()" elevation="0">
                <v-icon style="font-size:18px;">mdi-printer</v-icon>
            </v-btn>
        </template>
        <span>Imprimir</span></v-tooltip>

    <v-tooltip bottom="" v-if="search">
        <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="clickSearch(0)">
                <v-icon style="font-size:18px;">mdi-magnify</v-icon>
            </v-btn>
        </template>
        <span>Buscar</span></v-tooltip>

    <v-tooltip bottom="" v-if="map">
        <template v-slot:activator="{ on }">
            <v-btn text small v-on="on" @click="$emit('map')">
                <v-icon style="font-size:18px;">mdi-sitemap</v-icon>
            </v-btn>
        </template>
        <span>Gráfico</span></v-tooltip>

    <v-menu v-if="opSearchText" v-model="menuSearch" :close-on-content-click="false" offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ ons }">
                    <div v-on="ons" style="display:inline;">
                        <v-btn text small v-on="on" v-bind="attrs">
                            <v-icon style="font-size:18px;">mdi-magnify</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>Buscar</span>
            </v-tooltip>
        </template>
    </v-menu>

    <v-menu left bottom v-if="pdf || excel">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" small>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item v-if="excel && !openForm" @click="excelReport()">
                <v-list-item-title>
                    <v-icon style="font-size:14px; margin-right:10px;" color="green">far fa-file-excel</v-icon>Reporte Excel
                </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="pdf && !openForm" @click="pdfReport()">
                <v-list-item-title>
                    <v-icon style="font-size:14px; margin-right:10px;" color="red">far fa-file-pdf</v-icon>Reporte PDF
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-tooltip bottom="" v-if="close">
        <template v-slot:activator="{ on }">
            <v-btn text small style="margin-right:5px;" v-on="on" @click="$emit('close')">
                <v-icon style="font-size:18px;"> mdi-close</v-icon>
            </v-btn>
        </template>
        <span>Cerrar</span></v-tooltip>
</v-toolbar>
</template>

<script>
import _sApprovalTransaction from "@/services/Security/ApprovalTransactionService";
export default {
    name: "Toolbar",
    props: {
        elevation: {
            type: Number,
            default: 0,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        approve: {
            type: Boolean,
            default: false,
        },
        transactionApproved: {
            type: Object,
            default: null,
        },
        map: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "#5175AF",
        },
        label: {
            type: String,
            default: "",
        },
        entity: {
            type: Object,
            default: null,
        },
        load: {
            type: Boolean,
            default: false,
        },
        view: {
            type: Boolean,
            default: false,
        },
        download: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: true,
        },
        saveList: {
            type: Boolean,
            default: false,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
        pdf: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Boolean,
            default: false,
        },
        excel: {
            type: Boolean,
            default: false,
        },
        remove: {
            type: Boolean,
            default: false,
        },
        print: {
            type: Boolean,
            default: false,
        },
        clear: {
            type: Boolean,
            default: false,
        },
        doubleClick: {
            type: Boolean,
            default: false,
        },
        off: {
            type: Boolean,
            default: false,
        },
        service: {
            type: Object,
            default: null,
        },
        search: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        exit: {
            type: Boolean,
            default: false,
        },
        opSearchText: {
            type: Boolean,
            default: false,
        },
        formPermanent: {
            type: Boolean,
            default: false,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        restore: {
            type: Boolean,
            default: false,
        },
        deleteNoFunction: {
            type: Boolean,
            default: false,
        },
        addNoFunction: {
            type: Boolean,
            default: false
        },
        noConfimationSave: {
            type: Boolean,
            default: false
        },
        acceptAdd: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        messageProcessing: "",
        processing: false,

        approvedTrs: false,
        dialogDissaproved: false,
        entityEditing: null,
        adding: false,
        activeItem: true,
        openForm: false,
        editing: false,
        modeForm: false,
        activeSave: false,
        activeEdit: true,
        activeRemove: true,
        activeAdd: true,
        entityBack: null,
        menuSearch: "",
        searchText: "",
    }),

    methods: {
        disapproved() {

            if (this.transactionApproved)

                this.$fun.alert("Seguro de desaprobar Documento?", "question", false).then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();
                        //this.transactionApproved.SendMail = 1; //1=Envia, 0= No envia

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.$fun.alert("Documento desaprobado", "success");
                                this.processing = false;
                                this.$emit("close");
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("disapproved");
        },
        approved() {
            if (this.transactionApproved)
                this.$fun.alert("Seguro de aprobar Documento?", "question", false).then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();
                        //this.transactionApproved.SendMail = 1; //1=Envia, 0= No envia

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.$fun.alert("Aprobado Correctamente", "success");
                                this.processing = false;
                                this.$emit("close");
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("approved");
        },
        printRow() {
            this.$emit("print");
        },
        clearEvent() {
            this.$emit("clear");
        },
        clickSearch() {
            this.$emit("searchBtnEvent");
        },
        getBorder() {
            if (this.noBorder) {
                return "none";
            } else return "1px solid rgba(215,215,215,0.5) !important";
        },
        getAnswer() {
            if (this.searchText == null) this.searchText = "";
            this.$emit("searchEvent", this.searchText);
        },
        excelReport() {
            this.$emit("excel");
        },
        pdfReport() {
            this.$emit("pdf");
        },

        changeStatus(value) {
            this.$emit("removed", value);
        },
        delete: function () {
            this.$emit("delete", this.objEdit, true);
        },
        saveF: function () {
            this.$emit("save");
        },
        cancel() {
            this.$emit("exit");
        },

        showForm: function (add, edit) {
            // if(this.addNoFunction){
            if (add) this.$emit('add');
            if (edit) this.$emit('edit')
            // }
            /* else{
      this.entityEditing = Object.assign({}, this.entity);
      if (add) {
        this.$emit("showFormEvent", {
          add: true,
          edit: false,
        });
        this.activeSave = true;
        this.editing = false;
        this.activeAdd = false;
        this.activeItem = false;
        this.activeRemove = false;
        this.adding = true;
        this.openForm = true;
      } else if (edit) {
        this.editing = true;
        this.activeSave = true;
        this.openForm = true;
        this.activeEdit = false;
        this.activeAdd = false;
        this.activeRemove = false;
        this.activeItem = false;
        this.$emit("showFormEvent", {
          add: false,
          edit: true,
        });
      } else {
        this.openForm = false;
        this.activeSave = false;
        this.editing = false;
        this.activeAdd = true;
        this.activeEdit = true;
        this.activeItem = true;
        this.activeRemove = true;
        this.$emit("showFormEvent", {
          add: false,
          edit: false,
        });
      }
    }*/
        }
    },
    watch: {
        transactionApproved() {
            if (this.transactionApproved != null)
                if (this.transactionApproved.UsersApproved.length > 0)
                    if (this.transactionApproved.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                        if (this.transactionApproved.OrdStatus == 4) {
                            this.approvedTrs = true;
                        }
        },
        searchText() {
            this.debouncedGetAnswer();
        },
        doubleClick() {
            this.showForm(false, true);
        },
        entity() {
            if (this.entity.SecStatus != null) {
                this.activeEdit = true;
                this.activeItem = true;
            }
            if (this.openForm && this.entity.SecStatus != null) {
                this.activeAdd = true;
                this.activeSave = false;
                this.activeRemove = true;
                this.openForm = false;
                this.$emit("showFormEvent", {
                    add: false,
                    edit: false,
                });
                this.activeEdit = true;
                this.activeItem = true;
            }
        },
    },
    created() {
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);

    },
};
</script>

<style>
.basil {
    background-color: #fffbe6 !important;
}

.font-20 {
    font-size: 18px !important;
    padding-right: 5px;
}

.save {
    margin-right: 7px;
}
</style>
