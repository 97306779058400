import Service from "./Service";
const resource = "helper/";
import Vue from "vue";

export default {
    getPhotoWorker(document, requestID) {
        return Service.get(resource + "HelperWorkerPhoto", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { PrsDocumentNumber: document, requestID: requestID },
        });
    },

    getPhotoWorkerFirma(PrsFirma, requestID) {
        return Service.get(resource + "HelperWorkerPhotoFirma", {
            responseType: "blob",
            headers: {
                "Content-Type": "image/jpg",
            },
            params: { PrsFirma: PrsFirma, requestID: requestID },
        });
    },

    uploadfile(document, requestID, files, parameter) {
        return Service.post(resource + "uploadfiles", files, {

            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
            },
        });


    },

    uploadfilelegal(document, requestID, files, parameter) {
        return Service.post(resource + "uploadfileslegal", files, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
            },
        });
    },

    uploadfilelogistics(document, requestID, files, parameter) {
        return Service.post(resource + "uploadfilelogistics", files, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
            },
        });
    },

    copyFiles(ruc, docEntry, path) {
        return Service.post(resource + "copyFile", path, {
            params: {
                ruc: ruc,
                docEntry: docEntry
            },
        });
    },

    getfile(document, requestID, file, parameter) {
        return Service.get(resource + "dowloadfiles", {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                filename: file,
                parameter: parameter,
            },
        });
    },

    download(path, requestID, type = 1) {
        return Service.post(resource + "download", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                path: path,
                requestID: requestID,
                type: type
            },
        });
    },

    delete(document, requestID, parameter, file) {
        /*  console.log(document, requestID, parameter, file); */
        return Service.post(resource + "deletefile", file, {
            params: {
                PrsDocumentNumber: document,
                requestID: requestID,
                parameter: parameter,
                fileName: file,
            },
        });
    },


};