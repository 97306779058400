let children = []

children.push({
    path: "/activosfijos",
    name: "INV_FIXED_ASSETS",
    component: () =>
        import ("../views/Inventory/InvFixedAssets.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Inventario", disabled: true },
            { text: "Activos Fijos", href: "/activosfijos" },
        ],
    },
});

children.push({
    path: "/buscaractivosfijos",
    name: "INV_SEARCH_FIXED",
    component: () =>
        import ("../views/Inventory/InvSearchFixed.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Inventario", disabled: true },
            { text: "Buscar Activos Fijos", href: "/buscaractivosfijos" },
        ],
    },
});


children.push({
    path: "/generarcodigo",
    name: "INV_GENERATEDCODE",
    component: () =>
        import ("../views/Inventory/InvGeneratedCode.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Inventario", disabled: true },
            { text: "Generar Codigo", href: "/generarcodigo" },
        ],
    },
});





export { children }