<template>
<v-app class="scroll">

    <pay-search-payment v-if="extern==1"></pay-search-payment>
    <!-- <iframe width="100%" height="640" frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowfullscreen scrolling="no" src="https://kuula.co/share/collection/79SN2?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"></iframe> -->

    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" transition="dialog-bottom-transition" persistent>
            <!-- Logistica -->
            <requirement-edit @close="dialog = false" v-if="this.htmlComponent == 'requirement-edit'" :value="this.DocEntry"></requirement-edit>
            <order-purchase-edit @onClose="dialog = false" v-if="this.htmlComponent == 'orderpurchase-edit'" :value="this.DocEntry" :typeOrder="1" :order="{}"></order-purchase-edit>
            <ord-cuardro-comparativo @onClose="dialog = false" v-if="this.htmlComponent == 'ord-cuardro-comparativo'" :value="this.DocEntry"></ord-cuardro-comparativo>
            <ord-output-product-edit @onClose="dialog = false" v-if="this.htmlComponent == 'ord-output-product-edit'" :value="this.DocEntry"></ord-output-product-edit>

            <!-- Legal -->
            <lgl-requirement-edit @close="dialog = false" v-if="this.htmlComponent == 'lgl-requirement-edit'" :value="this.DocEntry"></lgl-requirement-edit>

            <!-- AA.CC -->
            <atc-requirement-edit @close="dialog = false" v-if="this.htmlComponent == 'atc-requirement-edit'" :value="this.DocEntry"></atc-requirement-edit>

            <!-- Marketing -->
            <mkt-requirement-edit @close="dialog = false" v-if="this.htmlComponent == 'mkt-requirement-edit'" :value="this.DocEntry"/>
        </v-dialog>
    </div>

    <div v-if="dialogNotification">
        <v-dialog class="elevation-0" v-model="dialogNotification" width="80%" transition="dialog-bottom-transition">
            <s-notification />
        </v-dialog>
    </div>
    <!-- class="scroll"  -->
    <v-navigation-drawer class="scroll" app clipped v-model="drawer" fixed :mobile-breakpoint="600" absolute width="270" top :temporary="$vuetify.breakpoint.xs" :color="bck_navigation" hide-overlay :dark="darkMode" v-if="extern==0" style="background: #fbfbfb; position: fixed;border: 1px solid #d5d1d1;">

        <v-treeview outlined @update:active="updateMenu" :items="userMenu" :transition=true item-text="OptName" item-id="OptID" item-key="OptCode" item-children="SecOptionChild" open-on-click activatable off-icon="ds" on-icon="" :color="opt_nav" :style="opt_stylenav" class="v-treeview-node" id="tree">
            <template v-slot:prepend="{ item }">
                <v-icon x-small v-text="item.OptIcon" :style="'color:' + item.OptColor"></v-icon>
            </template>
            <!-- <template v-slot:label="{ item }">
                <div class="treeview-item">{{ item.OptName }}</div>{{active}}
            </template> -->

        </v-treeview>
    </v-navigation-drawer>
    <!-- -->
    <v-app-bar app clipped-left dense tile flat height="30" :style="bck_toolbar" class="elevation-0" :dark="darkMode">
        <v-app-bar-nav-icon small @click.stop="drawer = !drawer" :style="icon_tlb" />
        <v-toolbar-title :class="class_styleCompany">
            Inmobiliaria Miraflores Peru SAC
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- Comentarios -->
        <v-menu bottom offset-y :close-on-content-click="false" transition="slide-y-transition" v-if="extern==0">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small @click="clickNotificationComments()">
                    <v-badge dot color="error error-4" bottom left offset-x="5" offset-y="0">
                    </v-badge>
                    <v-icon x-small :style="icon_tlb">mdi-comment-text-outline</v-icon>
                </v-btn>
            </template>

            <v-card style="overflow: hidden;width: 600px">
                <v-list class="mx-auto">
                    <v-toolbar height="25" elevation="0">
                        <!-- <v-toolbar-title>Comentarios</v-toolbar-title> -->
                    </v-toolbar>
                    <v-row>
                        <v-col lg="6" style="margin-bottom: 12px">
                            <h5 style="color: var(--v-error-base);padding-left: 16px;margin-bottom: 0px;">
                                <!-- {{ itemsAlertCommentsPending.length }} Nuevos Comentarios -->
                            </h5>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>

                    <div style="height: 500px; overflow: auto" class="scroll" @scroll="handleScroll">
                        <v-list-item-group active-class="primary--text">

                            <template>
                                <v-divider></v-divider>
                                <div style="color:red;background: antiquewhite;">
                                    <h3 class="ml-2">Comentarios sin leer
                                        <span>
                                            <v-icon @click="showRead = !showRead">{{ showRead ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </span>
                                    </h3>
                                </div>
                                <v-divider></v-divider>

                                <v-expand-transition>
                                    <div v-show="showRead">
                                        <v-list subheader v-for="(item, index) in Object.keys(itemsAlertComments_NotRead)">
                                            <h4 class="ml-3">{{item}}</h4>

                                            <v-list-item dense v-for="d in itemsAlertComments_NotRead[item]">
                                                <v-list-item-avatar>
                                                    <v-row justify="space-around">
                                                        <v-avatar color="red">
                                                            <span class="white--text text-h5">{{d.UsrCreateName !== undefined ? d.UsrCreateName.split(" ")[0].substring(0,1) : ""}}{{d.UsrCreateName !== undefined ? d.UsrCreateName.split(" ")[1].substring(0,1): ""}}</span>
                                                        </v-avatar>
                                                    </v-row>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <h4 style="margin-bottom: 6px">
                                                        <a v-if="d.UsrCreateID != usrID" @click="showAlert(d, 2)"><b style="text-decoration:underline">Nro. Solicitud: {{ d.DocumentNumber }}</b></a>
                                                        <a v-else><b>Nro. Solicitud: {{ d.DocumentNumber }}</b></a>
                                                        <b v-if="d.ReqNroContract !==null"> | Contrato Nro.: {{d.ReqNroContract}}</b>

                                                    </h4>
                                                    <h4 v-if="d.NroLot !== null" class="text--primary" style="margin-bottom: 1px">
                                                        <span>{{ d.CodSales }} | {{d.NroLot}} | {{d.CustomerName}}</span>
                                                    </h4>
                                                    <h5 class="text--primary" style="margin-bottom: 3px">
                                                        <span>{{ d.AleMessageExtract }}</span> <span v-if="d.LengthAleMessage > d.LengthAleMessageExtract">...........</span><br>
                                                    </h5>
                                                    <h5 class="text--primary" style="margin-bottom: 0px">
                                                        <span>{{d.AleDate !== null ? $moment(d.AleDate).format($const.FormatDateTimeDB) : ''}}</span><br>
                                                    </h5>
                                                </v-list-item-content>

                                                <v-list-item-icon>
                                                    <v-icon v-if="d.UsrCreateID != usrID" @click="checkAlert(d, 2)" title="Marcar como Leido" :color="'deep-purple accent-4'">
                                                        mdi-message-outline
                                                    </v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-expand-transition>

                                <v-divider></v-divider>

                                <div style="color:red;background: antiquewhite;">
                                    <h3 class="ml-2">Comentarios Leidos
                                        <span>
                                            <v-icon @click="show = !show">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </span>
                                    </h3>
                                </div>
                                <v-divider></v-divider>

                                <v-expand-transition>
                                    <div v-show="show">
                                        <v-list subheader v-for="(item, index) in Object.keys(itemsAlertComments_Read)">
                                            <h4 class="ml-3">{{item}}</h4>

                                            <v-list-item dense v-for="d in itemsAlertComments_Read[item]" @click="showAlert(d, 2)">
                                                <v-list-item-avatar>
                                                    <v-row justify="space-around">
                                                        <v-avatar color="red">
                                                            <span class="white--text text-h5">{{d.UsrCreateName !== undefined ? d.UsrCreateName.split(" ")[0].substring(0,1) : ""}}{{d.UsrCreateName !== undefined ? d.UsrCreateName.split(" ")[1].substring(0,1): ""}}</span>
                                                        </v-avatar>
                                                    </v-row>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <h4 style="margin-bottom: 6px">
                                                        <b>Solicitud Nro.: {{ d.DocumentNumber }}</b><b v-if="d.ReqNroContract !==null"> | Contrato Nro.: {{d.ReqNroContract}}</b>
                                                    </h4>
                                                    <v-divider></v-divider>
                                                    <h4 v-if="d.NroLot !== null" class="text--primary" style="margin-bottom: 1px">
                                                        <span>{{ d.CodSales }} | {{d.NroLot}} | {{d.CustomerName}}</span>
                                                    </h4>

                                                    <h5 class="text--primary" style="margin-bottom: 3px">
                                                        <span>{{ d.AleMessageExtract }}</span> <span v-if="d.LengthAleMessage > d.LengthAleMessageExtract">...........</span><br>
                                                    </h5>
                                                    <h5 class="text--primary" style="margin-bottom: 0px">
                                                        <span>{{d.AleDate !== null ? $moment(d.AleDate).format($const.FormatDateTimeDB) : ''}}</span><br>
                                                    </h5>
                                                </v-list-item-content>

                                                <v-list-item-icon>
                                                    <v-icon title="Comentario Leido" :color="'deep-purple accent-4'">
                                                        mdi-message-outline
                                                    </v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </v-expand-transition>

                            </template>
                        </v-list-item-group>
                    </div>

                </v-list>

            </v-card>

        </v-menu>

        <!-- Notificaciones itemsAlert_byTrs-->
        <v-menu bottom offset-y transition="slide-y-transition" :close-on-content-click="false" v-if="extern==0">

            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small @click="clickNotification()">
                    <v-badge v-if="itemsAlertPending.length > 0" dot color="error error-4" bottom left offset-x="5" offset-y="0">
                    </v-badge>
                    <v-icon :style="icon_tlb">mdi-bell-ring-outline</v-icon>
                </v-btn>
            </template>

            <v-card style="overflow: hidden;width: 600px">
                <v-list class="mx-auto">
                    <v-toolbar height="25" elevation="0">
                        <v-toolbar-title>Notificaciones</v-toolbar-title>
                    </v-toolbar>
                    <v-row>
                        <v-col lg="6" style="margin-bottom: 12px">
                            <h5 style="color: var(--v-error-base);padding-left: 16px;margin-bottom: 0px;">
                                {{ itemsAlertPending.length }} Nuevas Notificaciones
                            </h5>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col lg="3" style="font-size; 10px">
                            <!-- <a @click="dialogNotification = true">Ver todas</a> -->
                        </v-col>
                    </v-row>

                    <div style="height: 500px; overflow: auto" class="scroll" @scroll="handleScroll">
                        <v-list-item-group :value="selected" active-class="primary--text">

                            <template>
                                <v-list subheader v-for="(item, index) in Object.keys(itemsAlert_byTrs)" :key="index">
                                    <v-divider></v-divider>
                                    <div style="color:;background: antiquewhite;">
                                        <v-row style="margin:auto">
                                            <h4 class="ml-3">{{item}}</h4><span>
                                                <!-- <v-icon @click="show = !show">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> -->
                                            </span>
                                        </v-row>
                                    </div>
                                    <v-divider></v-divider>
                                    <v-list-item dense v-for="d in itemsAlert_byTrs[item]" @click="showAlert(d)">
                                        <v-list-item-avatar style="overflow: unset !important">
                                            <v-avatar style="overflow: unset !important" :color="d.AleColor">
                                                <span class="white--text">{{ d.TrsAbbrTable }}</span>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <h4 style="margin-bottom: 0px">
                                                <!-- <b>{{ d.AleName }}</b> -->
                                                <b v-if="d.ReqNroContract !==null"> | Nro.: {{d.ReqNroContract}}</b>
                                            </h4>

                                            <h4 v-if="d.NroLot !== null" class="text--primary" style="margin-bottom: 1px">
                                                <span>{{ d.CodSales }} | {{d.NroLot}} | {{d.CustomerName}}</span>
                                            </h4>

                                            <h4 v-if="d.ReqDescriptionCtrc !== null" class="text--primary" style="margin-bottom: 1px">
                                                <span>{{ d.DescriptionCtrcExtract}}.......</span>

                                            </h4>

                                            <h5 class="text--primary" style="margin-bottom: 0px">
                                                <span>{{ d.AleMessage }} - {{d.AtsDateTransaction !== null ? $moment(d.AtsDateTransaction).format($const.FormatDateDB) : ''}}</span><br>
                                            </h5>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </v-list-item-group>
                    </div>
                </v-list>
            </v-card>
        </v-menu>

        <v-btn text small :class="class_styleCompany">
            {{ usrName }}

        </v-btn>

        <v-btn small v-if="extern==1" @click="logout($event)">
            {{ 'Cerrar Sesion' }}
        </v-btn>

        <v-menu bottom offset-y :close-on-content-click="false" v-model="configurationUser" transition="slide-y-transition" v-if="extern==0">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" small>
                    <v-icon x-small :style="icon_tlb">fas fa-cogs</v-icon>
                </v-btn>
            </template>
            <s-configuration :activate="configurationUser" :configUser="configUser" :user="user" :personImage="personImage" :personImageFirma="personImageFirma" @getBackground="getBackground($event)" @getToolbar="getToolbar($event)" @getNavigation="getNavigation($event)" @getReset="getReset($event)" @getLogout="logout($event)" />
        </v-menu>
    </v-app-bar>

    <v-main v-if="extern==0">
        <s-breadcrumb v-if="extern==0" />
        <v-container fluid>

            <router-view v-if="extern==0" />

            <modal-time-session @exit="exit()" v-if="isInactive == true" />

            <!-- style="box-shadow: 20px 0px 3px 0px;border-radius: 5px; border-left-style:;" style="box-shadow: 19px -18px 0px 18px;" :class="bck_main" <modal-time-session @exit="exit()" v-if="isIdle == true" />-->
        </v-container>
    </v-main>

    <!-- <v-footer app padless absolute dark style="background: #23397c;">
        <v-container fluid>
            <v-row>
                <v-col class="py-1 body-2" cols="6" v-if="extern == 0">
                    2022 — Equipo de desarrollo TI — Todos los derechos reservados.
                </v-col>
                <v-col class="py-1 body-2" cols="6" v-if="extern == 1">
                    2022 — Inmobiliaria Mirflores Peru — Todos los derechos reservados.
                </v-col>
                <v-col class="py-1 text-right body-2" cols="6">
                    <div v-if="userSAP != null && extern == 0">[SAP] {{ userSAP.UssUserCode }}</div>
                    <div v-if="userSAP == null && extern == 0">[SAP] Sin Usuario asociado</div>
                    <div v-if="extern == 1"></div>
                </v-col>
            </v-row>
        </v-container>
    </v-footer> -->
</v-app>
</template>

<script>
import SBreadcrumb from "@/components/Layout/SBreadcrumb.vue";
import sConfiguration from "@/views/Configurations/ConfigurationTool.vue";
import sNotification from "@/views/Security/Notification.vue";
import ModalTimeSession from "@/components/Layout/ModalTimeSession";
import _sAuth from "@/services/AuthService";
import _sTransaction from "@/services/Security/AlertService";
import _sHelper from "@/services/HelperService";

import PaySearchPayment from "@/views/PaymentEvolta/PaySearchPayment.vue";
import _sPayment from "@/services/Payment/PaymentService";

//Logistica
import RequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit.vue";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
import OrdCuardroComparativo from '@/views/Logistics/OrderCotization/OrdCuardroComparativo.vue';
import OrdOutputProductEdit from '@/views/Logistics/OrdOutput/OrdOutputProductEdit.vue';

//Legal
import LglRequirementEdit from "@/views/Legal/Requirement/LglRequirementEdit.vue";
//AA.CC
import AtcRequirementEdit from "@/views/CustomerSupport/Requirement/AtcRequirementEdit.vue";

//Marketing
import MktRequirementEdit from "@/views/Marketing/Requirement/MktRequirementEdit.vue";

export default {
    components: {
        SBreadcrumb,
        ModalTimeSession,
        sConfiguration,
        sNotification,
        PaySearchPayment,
        RequirementEdit,
        OrderPurchaseEdit,
        OrdCuardroComparativo,
        OrdOutputProductEdit,
        LglRequirementEdit,
        AtcRequirementEdit,
        MktRequirementEdit

    },
    data() {
        return {
            isInactive: false,
            AttachImg: null,
            indexAlert: 0,
            lengthItemsAlert: 20,
            mini: true,
            dialogNotification: false,
            itemsAlert: [],
            itemsAlert_byTrs: [],
            itemsAlertPending: [],
            itemsAlertComments: [],
            show: false,
            showRead:true,
            itemsAlertComments_NotRead: [],
            itemsAlertComments_Read: [],
            itemsAlertCommentsPending: [],
            dialog: false,
            htmlComponent: "",
            DocEntry: 0,
            selected: [],
            usrID: this.$fun.getUserID(),
            timeToken: null,
            drawer: true,
            time: 10000,
            timeInactiveShow: 0,
            usrName: "",
            personImage: null,
            personImageFirma: null,
            user: {},
            userMenu: [],
            managerSAP: false,
            userSAP: {},
            configurationUser: false,
            configurationTOOL: {},

            isNavigation: false,
            isToolbar: false,
            isMode: false,
            bck_navigation: "",
            icon_nav: "",
            opt_nav: "",
            opt_stylenav: "",
            bck_toolbar: "",
            class_styleCompany: "style-default",
            icon_tlb: "",
            bck_main: "",
            darkMode: false,
            configUser: {},
            configDefault: {
                bck_navDefault: "#E0E0E0",
                icon_navDefault: "black",
                opt_navDefault: "primary darken-1",
                opt_stylenavDefault: "black",
                bck_toolbarDefault: "#E0E0E0",
                class_styleCompanyDefault: "style-default",
                bck_tlbDefault: "#E0E0E0",
                icon_tlbDefault: "black",
                bck_Default: "background-body",
            },
            stylemodedark: "rgb(53, 54, 58)",
            extern: null,

        };

    },
    created() {
        this.usrName = localStorage.getItem("UsrName");
        this.initialize();
        this.configurateActivity();
        this.inizializeAlerts();
        this.drawer = !this.$vuetify.breakpoint.xs;
        this.extern = localStorage.getItem("extern")

        //this.logout()
    },

    methods: {

        checkAlert(al, type = 1) {
            al.TypeAlert = type
            _sTransaction.byAlert(al, this.$fun.getUserID());

            if (type == 2) {
                this.clickNotificationComments()
            }
        },
        showAlert(al, type = 1) {
            al.TypeAlert = type
            _sTransaction.byAlert(al, this.$fun.getUserID());

            this.DocEntry = al.DocEntry;
            this.htmlComponent = al.TrsComponentName;
            this.selected = [];
            if (type == 1) {
                this.itemsAlert.find((x) => x.AleID == al.AleID).AleStatus = 2;
                this.selectedNotification()
            }

            /* for (let i = 0; i < this.itemsAlert.length; i++)
                if (
                    this.itemsAlert[i].AleStatus == 3 ||
                    this.itemsAlert[i].AleStatus == 1
                )
                    this.selected.push(i);
            this.itemsAlertPending =
                this.itemsAlert != null ?
                this.itemsAlert.filter((x) => x.AleStatus == 1) : []; */

            this.dialog = true;
        },

        selectedNotification() {
            var i = 0
            for (i = 0; i < this.itemsAlert.length; i++) {

                if (
                    /* this.itemsAlert[i].AleStatus == 3 ||
                    this.itemsAlert[i].AleStatus == 1 ||
                    this.itemsAlert[i].AtsStatus == 1 */
                    this.itemsAlert[i].AleStatus == 1 ||
                    this.itemsAlert[i].AleStatus == 3 ||
                    this.itemsAlert[i].AtsStatus == 1

                )
                    this.selected.push(i);
            }

            this.itemsAlertPending = this.itemsAlert
            /* this.itemsAlert != null ?
            this.itemsAlert.filter((x) => x.AleStatus == 1) : []; */

        },

        initialize() {
            _sAuth.initialize(this.usrID).then((response) => {
                this.userMenu = response.data.UserMenu;

                this.user = response.data.User;

                console.log("response.data",response.data)

                this.userSAP = response.data.UserSAP;
                this.configUser = response.data.UserConfig;
                localStorage.setItem("UserInfo", JSON.stringify(response.data.User));
                localStorage.setItem(
                    "UserSAPInfo",
                    JSON.stringify(response.data.UserSAP || {})
                );
                this.$nextTick().then(() => {
                    require("signalr");

                    //AGREGA SCRIPT DE HUBS A LA CABERA DE LA APLICACION
                    let labelScript = document.createElement("script");
                    labelScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
                    document.head.appendChild(labelScript);
                    ////EVENTO CUANDO CARGA EL SCRIP DE SIGNALR
                    labelScript.addEventListener("load", () => {
                        $.connection.hub.url = this.$const.URL + "/signalr";

                        var chat = $.connection.notification;

                        chat.client.sendMessageGroup = (not, group) => {

                            let dr = {
                                length: this.lengthItemsAlert,
                                start: 0,
                                filter: {
                                    UsrID: this.$fun.getUserID(),
                                },
                            };
                            _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
                                this.itemsAlert = []
                                this.itemsAlert.push(...r.data.data);
                                if (this.itemsAlert.length > 0) {
                                    this.itemsAlertPending = []
                                    this.itemsAlertPending.push(this.itemsAlert[0]);
                                    this.itemsAlert[0].AleStatus = 3;
                                }
                                this.selectedNotification()
                            });

                            this.$push.create(not.Name, {
                                body: not.Body,
                                icon: "/favicon.ico",
                                link: "www.google.com",
                                timeout: 15000,
                                onClick: function () {
                                    window.focus();
                                    this.close();
                                },
                            });
                        };

                        $.connection.hub.start({
                            jsonp: true
                        }).done((e) => {
                            if (this.$fun.getUserInfo().CtrContract)
                                chat.invoke(
                                    "addToGroup",
                                    this.$fun.getUserInfo().CtrContract.PstID
                                );
                        });
                    });
                });
                if (this.user.PrsDocumentNumber != null)
                    this.getImage(this.user.PrsDocumentNumber);

                if (this.user.CtrContract !== null)
                    this.getImageFirma(this.user.CtrContract.AttachImg)

                if (this.configUser != null) this.GetConfigurationUser(this.configUser);
                else this.initializeConfiguration();
            });
        },

        clickNotification() {

            _sTransaction.check(this.$fun.getUserID()).then(() => {
                this.itemsAlertPending = [];
            });

            let dr = {
                length: 100, // this.lengthItemsAlert,
                start: 0, // this.indexAlert * this.lengthItemsAlert,
                filter: {
                    UsrID: this.$fun.getUserID()
                },
            };

            _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
                this.itemsAlert = []
                this.itemsAlert.push(...r.data.data);
                if (this.itemsAlert.length > 0) {
                    this.itemsAlert.forEach(element => {
                        if (element.ReqDescriptionCtrc !== null) {
                            element.DescriptionCtrcExtract = element.ReqDescriptionCtrc.substring(0, 100)
                            element.LengthDescriptionCtrcExtract = element.DescriptionCtrcExtract.length
                            element.LengthDescriptionCtrc = element.ReqDescriptionCtrc.length
                        }

                    });
                }

                this.itemsAlert_byTrs = _.groupBy(this.itemsAlert, "TrsName");
                console.log("itemsAlertComments_byTrs", this.itemsAlert_byTrs)

            });
            this.selectedNotification()
        },

        clickNotificationComments() {

            let dr = {
                length: 100, // this.lengthItemsAlert,
                start: 0, // this.indexAlert * this.lengthItemsAlert,
                filter: {
                    UsrID: this.$fun.getUserID()
                },
            };

            _sTransaction.paginationcomments(dr, this.$fun.getUserID()).then((r) => {
                this.itemsAlertComments = []
                this.itemsAlertComments.push(...r.data.data);
                if (this.itemsAlertComments.length > 0) {
                    this.itemsAlertComments.forEach(element => {
                        if (element.AleMessage !== null) {
                            element.AleMessageExtract = element.AleMessage.substring(0, 200)
                            element.LengthAleMessageExtract = element.AleMessageExtract.length
                            element.LengthAleMessage = element.AleMessage.length
                        }

                    });
                }

                this.itemsAlertComments_NotRead = _.groupBy(this.itemsAlertComments.filter(x => x.AleStatus == 1), "TrsName");
                this.itemsAlertComments_Read = _.groupBy(this.itemsAlertComments.filter(x => x.AleStatus == 2), "TrsName");

                console.log("itemsAlertComments", this.itemsAlertComments)
                console.log(_.groupBy(this.itemsAlertComments, "TrsName"))
            });

            //this.groupAreaCv = _.groupBy(this.itemsAreacv, "Anioventa");

        },
        getImage(document) {
            _sHelper.getPhotoWorker(document, this.$fun.getUserID()).then((rsp) => {
                const blob = new Blob([rsp.data], {
                    type: "image/jpg",
                });

                let image = blob;
                var reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                    this.personImage = reader.result;
                };
            });
        },

        getImageFirma(PrsFirma) {
            _sHelper.getPhotoWorkerFirma(PrsFirma, this.$fun.getUserID()).then((rsp) => {
                const blob = new Blob([rsp.data], {
                    type: "image/jpg",
                });

                let image = blob;
                var reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                    this.personImageFirma = reader.result;
                };
            });
        },

        configurateActivity() {

            this.timeToken = setInterval(() => {
                if (this.usrID > 0) {
                    let time = localStorage.getItem("TimeTokenCount") - 3000;
                    localStorage.setItem("TimeTokenCount", time);

                    if (localStorage.getItem("TimeTokenCount") < 1) {
                        if (localStorage.getItem("token") != null) {

                            //   this.$fun.alert('Refrescando token','success');
                            _sAuth.refreshToken(this.usrID).then((r) => {
                                localStorage.setItem("TimeTokenCount", 0);
                                localStorage.setItem("TimeTokenCount", localStorage.getItem("TimeToken"));
                                localStorage.setItem("token", "");
                                localStorage.setItem("token", r.data.token);

                            });

                            if (this.extern == 1) {
                                _sPayment.generatedToken(this.$fun.getUserID()).then(resp => {
                                    if (resp.status == 200) {
                                        localStorage.setItem("tokenNiubiz", "");
                                        localStorage.setItem("tokenNiubiz", resp.data);
                                    }
                                })
                            }

                        }
                    } // else clearInterval(this.timeToken);
                }
            }, 3000);

            var t;

            let logoutTimeInactive = () => {
                localStorage.setItem("isIdle", true);
                clearInterval(t);
            };

            let resetTimer = () => {

                clearInterval(t);
                localStorage.setItem("isIdle", false);
                this.timeInactiveShow = 0;
                t = setInterval(() => {
                    if (this.timeInactiveShow < localStorage.getItem("TimeToken")) {
                        this.timeInactiveShow = this.timeInactiveShow + 1000;
                    } else {
                        localStorage.setItem("isIdle", true);
                        this.isInactive = true
                        logoutTimeInactive();
                    }
                }, 1000);
            };

            resetTimer();
            document.onload = resetTimer;
            document.onmousemove = resetTimer;
            document.onmousedown = resetTimer;
            document.ontouchstart = resetTimer;
            document.onclick = resetTimer;
            document.onscroll = resetTimer;
        },

        exit() {
            clearInterval(this.timeToken);
        },
        updateMenu(menusActive) {
            if (menusActive.length > 0) {
                menusActive = [menusActive[0]];
                let route = this.$router.options.routes[1].children.find(
                    (x) => x.name == menusActive[0]
                );
                if (route != undefined) this.$router.push(route.path);
                else {
                    route = this.$router.options.routes.find(
                        (x) => x.name == menusActive[0]
                    );
                    if (route != undefined) this.$router.push(route.path);
                }
            }
        },
        logout() {
            clearInterval(this.timeToken);

            var extern = null;
            extern = localStorage.getItem("extern")

            localStorage.setItem("isIdle", false);
            document.onload = null;
            document.onmousemove = null;
            document.onmousedown = null;
            document.ontouchstart = null;
            document.onclick = null;
            document.onscroll = null;
            localStorage.clear();
            localStorage.removeItem("token");

            this.$router.push("/micuenta");

            /* if (extern == 0) {

            } else {
                this.$router.push("/micuenta");
            } */

            extern = null
        },

        setMode(mode) {
            //Dark
            if (mode) {
                this.bck_toolbar = "background:" + this.stylemodedark;
                this.class_styleCompany = "style-toolbar";
                this.icon_tlb = "color:white";
                this.bck_navigation = this.stylemodedark;
                this.icon_nav = "style-toolbar";
                this.opt_nav = "white";
                this.opt_stylenav = this.icon_tlb;
                this.darkMode = true;
            } else this.initializeConfiguration();

            //Ligth
        },
        getBackground(value) {
            if (value === true) this.bck_main = "background-dark";
            else this.bck_main = "background-light";
            this.setMode(value);
        },
        getToolbar(value) {
            if (value === this.configDefault.bck_toolbarDefault) {
                this.isToolbar = false;
                this.initializeConfiguration();
            } else {
                this.isToolbar = true;
                this.bck_toolbar = "background:" + value;
                this.class_styleCompany = "style-toolbar";
                this.icon_tlb = "color:white";
            }
        },
        getNavigation(value) {
            if (value === this.configDefault.bck_navDefault) {
                this.isNavigation = false;
                this.initializeConfiguration();
            } else {
                this.isNavigation = true;
                this.bck_navigation = value;
                this.icon_nav = "color:white";
                this.opt_nav = "white";
                this.opt_stylenav = "color:white";
                this.darkMode = true;
            }
        },
        getReset(value) {
            this.isNavigation = !value;
            this.isMode = !value;
            this.isToolbar = !value;
            this.initializeConfiguration();
        },

        initializeConfiguration() {
            //configuracion de inicio
            if (!this.isMode) {
                this.bck_main = "background-light";
                this.darkMode = false;
            }

            // toolbar
            if (!this.isToolbar) {

                this.bck_toolbar =
                    "background:" + this.configDefault.bck_toolbarDefault;
                this.class_styleCompany = this.configDefault.class_styleCompanyDefault;
                this.icon_tlb = "color:" + this.configDefault.icon_tlbDefault;
            }

            // navigation
            if (!this.isNavigation) {
                this.bck_navigation = this.configDefault.bck_navDefault;
                this.icon_nav = "color:" + this.configDefault.icon_navDefault;
                this.opt_nav = this.configDefault.opt_navDefault;
                this.opt_stylenav = "color:" + this.configDefault.opt_stylenavDefault;
            }
        },

        GetConfigurationUser(value) {
            if (this.configUser != "") {
                if (
                    this.configUser.CfgToolbar != null &&
                    this.configUser.CfgToolbar != ""
                ) {
                    this.isToolbar = true;
                    this.getToolbar(this.configUser.CfgToolbar);
                } else this.isToolbar = false;

                if (this.configUser.CfgTheme != null) {
                    this.bck_main =
                        this.configUser.CfgTheme == 1 ?
                        "background-dark" :
                        "background-light";
                    this.isMode = true;
                    this.darkMode = this.configUser.CfgTheme == 1;
                } else this.isMode = false;

                if (
                    this.configUser.CfgNavigation != null &&
                    this.configUser.CfgNavigation != ""
                ) {
                    this.isNavigation = true;
                    this.getNavigation(this.configUser.CfgNavigation);

                } else this.isNavigation = false;
            }
        },

        inizializeAlerts() {
            this.itemsAlert = []
            this.itemsAlert = this.$fun.getSecurity().Alerts;

            console.log("ITEM ALERT inizializeAlerts", this.itemsAlert)

            this.selectedNotification()

            //console.log("inizializeAlerts",this.itemsAlert, this.$fun.getSecurity())
            /* for (let i = 0; i < this.itemsAlert.length; i++)
                if (
                    this.itemsAlert[i].AleStatus == 3 ||
                    this.itemsAlert[i].AleStatus == 1 ||
                    this.itemsAlert[i].AtsStatus == 1
                )
                    this.selected = []
            this.selected.push(i);
            this.itemsAlertPending =
                this.itemsAlert != null ?
                this.itemsAlert.filter((x) => x.AleStatus == 1) : []; */
        },

        handleScroll: function (el) {

            /* if (
                el.srcElement.offsetHeight + el.srcElement.scrollTop >=
                el.srcElement.scrollHeight
            ) {
                let dr = {
                    length: this.lengthItemsAlert,
                    start: this.indexAlert * this.lengthItemsAlert,
                    filter: {
                        UsrID: this.$fun.getUserID()
                    },
                };

                _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
                    this.itemsAlert = []
                    this.itemsAlert.push(...r.data.data);
                });

                this.selectedNotification()
            } */
        },
    },
    watch: {
        $route(to, from) {
            this.inizializeAlerts();
        },
    },
    computed: {
        timeShow() {
            return (
                parseInt(
                    Math.round(
                        (localStorage.getItem("TimeToken") - this.timeInactiveShow) / 60000
                    )
                ).toString() + " min"
            );
        },
        isIdle() {
            /* return localStorage.isIdle; */
            return localStorage.getItem("isIdle");
        },
    },
};
</script>

<style scoped>
.background-light {
    background: #eceff1 !important;
    /* background-image: url("https://images.ctfassets.net/hrltx12pl8hq/5KiKmVEsCQPMNrbOE6w0Ot/341c573752bf35cb969e21fcd279d3f9/hero-img_copy.jpg?fit=fill&w=800&h=300"); */
}

.background-dark {
    background: rgb(53, 54, 58) !important;
}

.style-toolbar {
    color: white;
    font-size: 12px !important;
}

.style-font {
    color: white;
    font-size: 12px !important;
}

.style-default {
    font-weight: 400 !important;
    font-size: 12px !important;
}

#tree>>>.v-treeview-node__root {
    border-top: 1px solid #dee2e6;
    margin-bottom: 1px;
    padding: 0.1px;
    overflow: hidden;
    width: 100%;
}
</style>
