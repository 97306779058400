import Service from "../Service";

const resource = "requirementconfig/";

export default {
    list(TrsID, requestID) {
        return Service.post(resource + "list", "", {
            params: { TrsID: TrsID, requestID: requestID },
        });
    },

};