var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('s-crud',{attrs:{"no-full":"","close":"","searchInput":"","title":"OC/OS","filter":_vm.filter,"config":_vm.config},on:{"close":function($event){return _vm.$emit('close')},"doubleClick":_vm.dobleClick},scopedSlots:_vm._u([{key:"TypeOrder",fn:function({ row }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"font-size":"14px"},attrs:{"color":row.TypeOrder == 1 ? 'red' : 'green'}},on),[_vm._v(" "+_vm._s(row.TypeOrder == 1 ? "fas fa-car" : "fas fa-street-view")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.TypeOrder == 1 ? "Compra" : "Servicio"))])])]}},{key:"OrdStatusName",fn:function({ row }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"font-size":"16px"},attrs:{"color":row.OrdStatus == 1
                      ? 'green'
                      : row.OrdStatus == 2
                      ? 'brown'
                      : row.OrdStatus == 3
                      ? 'red'
                      : row.OrdStatus == 4
                      ? 'blue'
                      : row.OrdStatus == 5
                      ? 'brown'
                      : row.OrdStatus == 6
                      ? 'orange'
                      : row.OrdStatus == 8
                      ? 'red'
                      : ''}},on),[_vm._v(" "+_vm._s(row.OrdStatus == 1 ? "far fa-thumbs-up" : row.OrdStatus == 2 ? "fas fa-truck" : row.OrdStatus == 3 ? "far fa-thumbs-down" : row.OrdStatus == 4 ? "fa-regular fa-clock" : row.OrdStatus == 5 ? "fas fa-lock" : row.OrdStatus == 6 ? "fas fa-ban" : row.OrdStatus == 8 ? "fas fa-hands" : "")+" ")]),_c('a',{staticStyle:{"margin-left":"6px","text-decoration":"underline"}},[_vm._v(_vm._s(row.OrdStatusName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.OrdStatusName))])])]}},{key:"CttDocumentNumber",fn:function({ row }){return [_c('a',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.show(row,1)}}},[_vm._v(_vm._s(row.CttDocumentNumber))])]}},{key:"OrdDocumentNumber",fn:function({ row }){return [_c('a',{staticStyle:{"text-decoration":"underline","color":"black"},on:{"click":function($event){return _vm.show(row,2)}}},[_vm._v(_vm._s(row.OrdDocumentNumber))])]}}])})],1)],1),(_vm.viewCotizationDialog)?_c('v-dialog',{attrs:{"persistent":"","width":"100%"},model:{value:(_vm.viewCotizationDialog),callback:function ($$v) {_vm.viewCotizationDialog=$$v},expression:"viewCotizationDialog"}},[_c('v-card',[_c('ord-cuardro-comparativo',{attrs:{"value":_vm.item.CttID},on:{"onClose":function($event){_vm.viewCotizationDialog = false}}})],1)],1):_vm._e(),(_vm.dialogEdit)?_c('v-dialog',{attrs:{"persistent":"","width":"100%"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('order-purchase-edit',{attrs:{"value":_vm.item.OrdID,"order":{}},on:{"onClose":function($event){_vm.dialogEdit = false;}}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }