<template>
<v-card elevation="0" outlined>
    <v-row justify="center" v-show="items.length > 0">
        <v-col cols="12" >
            <div :id="'containerPie' + id"></div>
        </v-col>
    </v-row>
    <v-row justify="center" style="margin:auto" v-show="items.length == 0">
        <v-col cols="12" >
            <div :id="'containerPie' + id">NO SE HA CARGADO DATA PARA GENERAR REPORTE GRAFICO </div>
        </v-col>
    </v-row>
</v-card>

</template>

<script>
import Highcharts from "highcharts";
import exportingInit from 'highcharts/modules/exporting'
import stockInit from 'highcharts/modules/stock'

import exportdataInit from 'highcharts/modules/export-data'
import accessibilityInit from 'highcharts/modules/accessibility'

stockInit(Highcharts)
exportingInit(Highcharts)
exportdataInit(Highcharts)
accessibilityInit(Highcharts)

export default ({
    props: {
        items: {
            type: Array,
            default: []
        },
        plotBackgroundColor: {
            type: String,
            default: null
        },
        plotBorderWidth: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: 'Titulo'
        },

        visibleTitle: {
            type: Boolean,
            default: false
        },

        subtitle: {
            type: String,
            default: 'Subtitulo'
        },

        visibleSubTitle: {
            type: Boolean,
            default: false
        },

        valueSuffix: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            default: null
        },
        colorByPoint: {
            type: Boolean,
            default: true
        },
        cursor: {
            type: String,
            default: 'pointer'
        },
        dataLabels: {
            type: Boolean,
            default: true
        },

        allowPointSelect: {
            type: Boolean,
            default: true
        },

        showInLegend: {
            type: Boolean,
            default: false
        },

        id: {
            type: Number,
            default: 1
        },
        number: {
            type: Boolean,
            default: false
        },

    },
    methods: {
        load() {

            this.items.forEach(element => {
                delete(element.drilldown)
            });

            Highcharts.chart('containerPie' + this.id, {
                chart: {
                    /* plotBackgroundColor: this.plotBackgroundColor,
                    plotBorderWidth: this.plotBorderWidth,
                    plotShadow: null, */
                    type: 'pie',
                    options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
        }
                },
                title: {
                    text: this.visibleTitle== true ? '<span style="font-size:12px; font-weight: bold; color: rgb(36, 64, 147);">' + this.title + '</span><br>' : ''
                },
                subtitle: {
                    text: this.visibleSubTitle== true ? '<span style="font-size:10px; font-weight: bold; color: rgb(36, 64, 147);">' + this.subtitle + '</span><br>' : ''
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat:  !this.number ? ' <span style="color:{point.color}">\u25CF</span> <b>{point.name}: </b>  <b>{point.y:.2f} ' + this.valueSuffix + '</b><br>'
                                                :' <span style="color:{point.color}">\u25CF</span> <b>{point.name}: </b>  <b>{point.y:.2f} ' + this.valueSuffix + '</b><br>'
                },
                accessibility: {
                    point: {
                        valueSuffix: this.valueSuffix
                    }
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: this.allowPointSelect,
                        cursor: this.cursor,
                        dataLabels: {
                            enabled: this.dataLabels,
                            format: !this.number ? '<b></b><span style="font-size:11px; ">{point.name}</b>: </span><br>{point.y:.2f} ' + this.valueSuffix
                                            : '<b></b><span style="font-size:11px; ">{point.name}</b>: </span><br>{point.y} ' + this.valueSuffix
                        },
                        showInLegend: this.showInLegend
                    }
                },
                series: [{
                    name: this.name,
                    colorByPoint: this.colorByPoint,
                    data: this.items,

                }]
            });
        }
    },

    created() {
        /* console.log("al crear",this.items) */
    },

    mounted() {
        this.load()
    },
    watch: {
        items() {
            this.load()
        }

    }

})
</script>

<style>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
    min-width: 50px;
}
</style>
