<template>
<!-- <v-app > -->
<v-container class="justify-center" fill-height style="background: #f8f9fb">
    <!--  <v-app-bar color="white" dense>style="background-image: url('https://acegif.com/wp-content/gif/snwflks-108.gif');"
            <v-toolbar-title ><b style="text-transform: uppercase;">Datos </b></v-toolbar-title>

        </v-app-bar> -->

    <v-row style="margin:auto">
        <v-col cols="12">

            <v-card height="60" dense class="mx-auto my-0" width="100%" elevation="3" style="background:#f8f9fb; border-radius: 10px; position: fixed;z-index: 1;right: 0;">

                <v-card-title style="text-transform: uppercase;font-size:18px; font-weight: bold; color:#234798;margin-left: 2%; ">
                    <v-avatar size="46" style="margin-top: -10px">
                        <img alt="user" src="https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg">
                    </v-avatar>
                    <p class="ml-3 mt-1">
                        Bienvenido al Portal IMP
                    </p>

                </v-card-title>
            </v-card>
        </v-col>
        <v-col lg="6" md="6" cols="6" style="margin-top: 40px">
            <v-card outlined width="100%" style="background:#fff;border-radius: 10px;">

                <v-card-title style="text-transform: none;font-size:16px; font-weight: bold;  margin: 10px 0;">{{items.PayCustomnerName}}</v-card-title>
                <v-divider style="margin-top:-20px"></v-divider>
                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        <v-chip x-small color="info">{{items.PayTypeDocument}}:</v-chip><b> {{items.PayCustomerDocument}}</b>
                        <br>
                        <strong>E-mail:</strong> {{items.PayCustomerEmail}}
                        <br>
                        <strong>Celular:</strong> {{items.PayCustomerTelephone}}

                    </div>
                </v-card-text>
                <!-- <v-divider class="mx-4"></v-divider> -->
            </v-card>
        </v-col>
        <v-col lg="6" md="6" cols="6" style="margin-top: 40px">
            <v-card outlined width="100%" style="background:#fff;border-radius: 10px;">

                <v-card-title style="text-transform:;font-size:18px; font-weight: bold;  margin: 10px 0;letter-spacing: -1px;">Operaciones Comerciales</v-card-title>
                <v-divider style="margin-top:-20px"></v-divider>
                <v-card-text>

                    <div class="my-4 text-subtitle-5" v-for="(item, index) in items.detailsLot" :key="item.PayNumberSale">
                        <v-chip x-small :color="item.PayBatchOperationStatus == 'CV' ? 'success': 'error'">{{item.PayBatchOperationStatus == 'CV' ? 'Activo': 'Devuelto'}}</v-chip> <a @click="searchPaymentSchedule(item)"><b style="color: #234798">Codigo de venta N° {{item.PayNumberSale}}</b></a>
                        <br> <strong>Proyecto: </strong>{{item.PayDraft}} - {{index}}
                        <br><strong>Promotor: </strong>{{item.PayPromoterName}}
                        <br>Lote Nº {{item.PayNumberInmueble}} - {{item.PayStage}}
                        <!-- v-btn x-small :color="'#234798'" rounded dark @click="show= !show" style="text-transform: lowercase;">... </v-btn>
                        <v-card flat>
                            <v-expand-transition>
                                <div v-show="show" >
                                    <v-divider></v-divider>
                                    <v-row justify="center" style="">

                                        <v-col cols="12" lg="12" md="12" class="mt-3 ml-5" v-if="item.PayBatchOperationStatus == 'CV'">
                                            <v-card-text>

                                                <div class="my-4 text-subtitle-1">
                                                    <strong>Fecha Venta: </strong>{{item.PayBatchDeliveryDate}}
                                                    <br><strong>Mes venta: </strong>{{item.PayMonthSaleLot}}
                                                    <br><strong>Año venta: </strong>{{item.PayYearSaleLot}}

                                                </div>
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="12" lg="12" md="12" class="mt-3 ml-5" v-else>
                                            <v-card-text>

                                                <div class="my-4 text-subtitle-1">
                                                    <strong>Fecha Resolucion: </strong>{{item.PayBatchResolutionDate}}
                                                    <br><strong>Mes Resolucion: </strong>{{item.PayMonthResolutionLot}}
                                                    <br><strong>Año Resolucion: </strong>{{item.PayYearResolutionLot}}

                                                </div>
                                            </v-card-text>
                                        </v-col>

                                    </v-row>

                                </div>
                            </v-expand-transition>

                        </v-card> -->
                    </div>
                </v-card-text>

            </v-card>

        </v-col>

        <v-col cols="12" style="margin-top: -30px">
            <v-col cols="12">
                <v-card outlined width="100%" style="background:#fff;border-radius: 10px;">

                    <v-card-title style="text-transform:;font-size:16px; font-weight: bold; color:; margin: 10px 0;">Estado de Cuenta - {{PayNumberSale}}</v-card-title>
                    <v-divider style="margin-top:-20px"></v-divider>
                    <!-- <v-card-text>

                        <div class="my-4 text-subtitle-1">
                            <strong>Proyecto: </strong>
                        </div>
                    </v-card-text> -->

                    <v-tabs v-model="currentItem">
                        <v-tab href="#Pending" @change="clickTab('NO CANCELADO')"> No Cancelado</v-tab>
                        <v-tab href="#Cancel" @change="clickTab('CANCELADO')"> Cancelado</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="currentItem">
                        <v-tab-item :value="'Cancel'">
                            <v-col cols="12" lg="12" md="12" style="margin-bottom:-20px">
                                <v-data-table dense :headers="headers" :items="itemsSchedule" :items-per-page="-1" disable-sort>

                                </v-data-table>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item :value="'Pending'">
                            <v-col cols="12" lg="12" md="12" style="margin-bottom:-20px">
                                <v-data-table dense :headers="headers" :items="itemsSchedule" :footer-props="{

            itemsPerPageOptions: [10, 100, 200],
          }" disable-sort>

                                    <template v-slot:item.pagar="{ item }">
                                        <v-btn elevation="1" v-if="item.estadopagocrono == 'NO CANCELADO' && PayBatchOperationStatus == 'CV' && item.PlnLink == null" x-small @click="clickGenerated(item)">
                                            <i class="fas fa-credit-card" style="color: green"></i>
                                        </v-btn>
                                        <v-btn style="margin-top: 5px; margin-bottom: 5px;" elevation="0" v-if="item.estadopagocrono == 'NO CANCELADO' && PayBatchOperationStatus == 'CV' && item.PlnLink !== null" x-small @click="clickView(item)">
                                            <i class="fas fa-hourglass-end" style="color: #BB8C03"></i>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-tab-item>
                    </v-tabs-items>

                </v-card>

            </v-col>

        </v-col>

    </v-row>
    <v-row></v-row>
    <v-row></v-row>
    <v-row></v-row>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al realizar pago <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="view" v-if="view" width="450" persistent>
        <s-toolbar close @close="view=false"></s-toolbar>
        <v-card outlined>

            <v-card-text>
                <div class="font-weight-bold ml-8 mt-4">
                    Historial
                </div>

                <v-timeline align-top dense>
                    <v-timeline-item small color="info">
                        <div>
                            <div class="font-weight-normal">
                                <strong>{{ 'Proceso iniciado.' }}</strong>
                            </div>
                            <div>{{ itemOne.SecCreateLink}}</div>
                        </div>
                    </v-timeline-item>
                    <v-timeline-item small color="deep-purple lighten-1">
                        <div>
                            <div class="font-weight-normal">
                                <strong>{{ 'Nª Orden:' }}</strong> <b>{{ itemsStateN.orderId}}</b>
                            </div>
                            <div><strong>{{itemOne.PlnDescription}}</strong> <a v-if="itemsStateN.status == 'PENDING'" :href="itemOne.PlnLink" target="_Blank">{{itemOne.PlnLink}}</a></div>
                            <div><strong>{{'Enlace disponible al: '}}</strong> {{ itemOne.PlnExpirationDateTimeMin}}</div>
                            <div><strong>{{'Tiempo restante: '}}</strong> <b>{{ minDifference}} min.</b></div>

                        </div>
                    </v-timeline-item>
                    <v-timeline-item small :color="itemsStateN.status == 'PENDING' ? 'error': 'green'">
                        <div>
                            <div class="font-weight-normal">
                                <strong>{{ 'Estado Pago:' }}</strong>
                            </div>
                            <div><b>{{itemsStateN.status == 'PENDING' ? 'PENDIENTE': 'PROCESADO'}}</b></div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
        </v-card>
        <v-card outlined></v-card>

        <!-- <v-card outlined>
            <v-card-text>
                <div class="my-0 mt-3 text-subtitle-1">
                    <b>Nª Orden: </b>{{itemsStateN.orderId}}
                </div>
                <div class="my-0 text-subtitle-4">
                    <b>Fecha Caducidad: </b>{{itemOne.PlnExpirationDateTimeMin}}
                </div>
                <div class="my-0 text-subtitle-4">
                    <b>Estado: </b>{{itemsStateN.status == 'PENDING' ? 'PENDIENTE': 'CANCELADO'}}
                </div>
                <div class="text--white pt-4">
                    <strong>{{itemOne.PlnDescription}}</strong> <a v-if="itemsStateN.status == 'PENDING'" :href="itemOne.PlnLink" target="_Blank">{{itemOne.PlnLink}}</a>
                </div>
            </v-card-text>
        </v-card> -->
    </v-dialog>

</v-container>
<!-- </v-app> -->
</template>

<script>
import _sPayment from "@/services/Payment/PaymentService";
import _sGenParam from "@/services/General/ParameterService";

//import "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true";

export default {
    components: {},
    data() {
        return {
            currentItem: "tab-Funciones",
            processing: false,
            view: false,
            messageProcessing: "",
            usrName: null,
            items: {},
            show: false,
            itemsSchedule: [],
            headers: [

                {
                    text: "Cuota Nro.",
                    value: "nrocuotacrono"
                },
                {
                    text: "Tipo Cuota",
                    value: "tipocuotacrono"
                },
                {
                    text: "Fec. Pag",
                    value: "fechapagocrono"
                },
                {
                    text: "Saldo Capital",
                    value: "saldocapitalcrono"
                },
                {
                    text: "Interes",
                    value: "interescrono"
                },
                {
                    text: "Monto Cuota",
                    value: "montocuotacrono"
                },
                {
                    text: "Monto Pagado",
                    value: "totalpagadocrono",

                },
                {
                    text: "Estado",
                    value: "estadopagocrono",
                    align: 'center'
                },

                {
                    text: "Opciones",
                    value: "pagar",
                    width: 30
                },
            ],
            state: 'NO CANCELADO',
            PayNumberSale: null,
            tokenNiubiz: null,
            PayBatchOperationStatus: null,
            itemOne: {},
            dayAddLink: 0,
            itemsStateN: {},
            minDifference: 0
        }
    },
    created() {
        this.usrName = localStorage.getItem("UsrName");
        this.tokenNiubiz = localStorage.getItem("tokenNiubiz");
        this.load()
        this.loadParameter()
    },
    methods: {

        loadParameter() {
            _sGenParam
                .search({
                    PrmName: "DiasVenLinkNiubiz"
                }, this.$fun.getUserID())
                .then((resp) => {
                    if (resp.status == 200) {
                        this.dayAddLink = resp.data.PrmValue;
                    }
                });
        },

        load() {
            _sPayment.search(this.usrName, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.items = resp.data
                }
            })
        },

        searchPaymentSchedule(item) {
            this.itemsSchedule = []
            this.PayNumberSale = item.PayNumberSale
            this.PayBatchOperationStatus = item.PayBatchOperationStatus

            this.clickTab(this.state)

        },

        clickTab(item) {
            this.itemsSchedule = []
            this.state = item

            _sPayment.searchSchedule(this.PayNumberSale, this.state, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.itemsSchedule = resp.data
                   /*  console.log(this.itemsSchedule) */
                }
            })
        },

        clickGenerated(item) {

            if (this.items.PayCustomerEmail == null) {
                this.$fun.alert("Error, Correo electronico obligatorio, Comuniquese con IMP para la regularizacion de sus datos.", "warning")
                return
            }

            var i = 0
            this.itemsSchedule.filter(x => x.PlnLink !== null).forEach(element => {
                i = i + 1;
            });
            if (i > 3) {
                this.$fun.alert("Upps... No puede generar mas de 3 pagos", "warning")
                return
            }

            this.$fun.alert("¿Seguro de continuar?", "question").then(val => {
                if (val.value) {
                    this.createdPagoLink(item, localStorage.getItem("tokenNiubiz"));
                }
            })

        },

        createdPagoLink(item, Authorization) {

            var obj = {
                customer: {
                    firstName: this.items.PayCustomnerName,
                    lastName: "",
                    email: this.items.PayCustomerEmail,
                    phoneNumber: this.items.PayCustomerTelephone,
                    documentType: "NATIONAL_ID",
                    documentNumber: this.items.PayCustomerDocument,

                },
                orderType: "SINGLEPAY",
                amount: item.montocuotacrono,
                externalId: this.items.PayCustomerDocument + "_" + this.$fun.getHourLink() + "_" + this.PayNumberSale,
                description: "PAGO POR CONCEPTO DE " + item.tipocuotacrono + ", CUOTA NRO. " + item.nrocuotacrono,
                //expirationDate: this.$fun.getDate() + "T22:59:00-0000",
                expirationDate: this.$fun.addDaysToDate(this.dayAddLink)

            }

            this.messageProcessing = ""
            this.processing = true;
            _sPayment.createdLinkPayment(this.$fun.getUserID(), Authorization, obj).then(resp => {
                    if (resp.status == 200) {

                        var link = JSON.parse(resp.data.PayContent);
                        /* console.log(link) */

                        if (link.status == "FAILED") {
                            this.save(link, obj, item, 'FAILED');
                            this.$fun.alert("Upps, Ocurrio un error intentelo de nuevo. " + link.message, "error")
                            this.processing = false;
                            return
                        } else {
                            window.open(link.link, '_blank');
                            this.save(link, obj, item, 'SUCCESS');

                            //window.open(link.link, '_blank')
                            /* let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
            width=600,height=700,left=300,top=100`; */

                            this.processing = false;

                        }

                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                })
        },

        save(link, obj, item, type) {
            var objLink = {

                PlnNumberSale: this.PayNumberSale,
                PlnCustomerDocument: obj.customer.documentNumber,
                PlnCustomerTelephone: obj.customer.phoneNumber,
                PlnCustomerEmail: obj.customer.email,
                PlnCuotaNro: item.nrocuotacrono,
                PlnCorrelativoEvolta: item.correlativocuocrono,

                PlnAmount: obj.amount,
                PlnDescription: obj.description,
                PlnExpirationDate: obj.expirationDate,
                PlnBatchId: link.batchId,
                PlnExternalId: link.externalId,
                PlnLink: link.link,
                PlnOrderId: link.orderId,
                PlnStatus: link.status,
                PlnOrderType: 'SINGLEPAY',
                SecStatus: type == 'SUCCESS' ? 1 : 0,
                UsrCreateID: this.$fun.getUserID()
            }

            _sPayment.savePagoLink(objLink, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    objLink = {}
                    this.clickTab('NO CANCELADO');
                }
            })
        },

        clickView(item) {
            this.itemOne = item

            var time1, time2;
            time1 = this.$moment(item.PlnExpirationDateTimeMin).format(this.$const.FormatDateTimeDB)
            time2 = this.$moment(this.$fun.getToDateFormatT()).format(this.$const.FormatDateTimeDB)
            this.minDifference = this.$fun.isDifferenceMinutes(time1, time2)

            /* if (this.minDifference <= 0) {
                this.$fun.alert("Genere Link de pago", "warning")
                this.clickTab('NO CANCELADO');
                return
            } */

            if (localStorage.getItem("tokenNiubiz") !== null)

                this.messageProcessing = ""
            this.processing = true;
            _sPayment.stateLinkPayment(this.$fun.getUserID(), localStorage.getItem("tokenNiubiz"), item.PlnOrderId).then(resp => {
                    if (resp.status == 200) {
                        this.processing = false
                        this.itemsStateN = JSON.parse(resp.data)
                        /* console.log("estado pago", this.itemsStateN) */
                        this.view = true
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                })

        },
    },
};
</script>
