import Service from "../Service";

const resource = "definition/";

export default {
    save(def, requestID) {
        return Service.post(resource + "save", def, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    list(requestID) {
        return Service.post(
            resource + "list", {}, {
                params: { requestID: requestID },
            }
        );
    },
    definitiongroupURL() {
        return resource + "definitiongroup";
    },
    definitiongroup(defID, dgrID, requestID) {
        return Service.post(
            this.definitiongroupURL(), {}, {
                params: { defID: defID, dgrID: dgrID, requestID: requestID },
            }
        );
    },
};