<template>
<div>
    <s-toolbar close @close="close()">
        <template v-slot:options>
            <div class="mt-0 mr-3">
                <label><b>Seleccione Empresa:</b></label>
            </div>
            <div class="mt-1 mr-3">
                <s-select-definition :outlined="false" style="border-color: seashell;border-radius: 7px;" @input="inputBussines($event)" :def="1142" return-object v-model="objBussiness">
                </s-select-definition>
            </div>

        </template>
    </s-toolbar>
    <v-card outlined>
        <div class="mt-0 ml-3">
            <v-radio-group dense v-model="radios" style="margin-top:0px;margin-bottom:-12px">
                <v-radio value="1">
                    <template v-slot:label>
                        <div>-<strong class="error--text">Proyecto</strong></div>
                    </template>
                </v-radio>
                <v-radio value="2" style="margin-top: -10px">
                    <template v-slot:label>
                        <div>-<strong class="primary--text">Gasto</strong></div>
                    </template>
                </v-radio>
            </v-radio-group>
        </div>

    </v-card>

    <s-toolbar v-if="radios == 1" :label="'PROYECTO - PARTIDAS - PORCENTAJE'" :acceptAdd="true" add @add="addProyecto()" clear @clear="clearFormProyecto()" remove @removed="removeProyecto()">
    </s-toolbar>
    <v-card outlined v-if="radios == 1">

        <v-row style="margin:auto">
            <v-col lg="12" class="s-col-form" cols="12">
                <c-projects clearable noDefault full label="Proyectos" return-object v-model="itemProyecto.objProjects" :business="typeBusinessDistribution"></c-projects>
            </v-col>

            <v-col lg="8" class="" cols="6">
                <c-partida label="Partida" full clearable noDefault return-object v-model="itemProyecto.objPartida" :business="typeBusinessDistribution"></c-partida>
            </v-col>

            <v-col lg="4" class="" cols="6">
                <s-text label="%" decimal ref="DerPorcentaje" v-model="itemProyecto.DerPorcentaje" />
            </v-col>

        </v-row>
        <v-row style="margin:auto">
            <v-col lg="12" class="" cols="12">
                <v-data-table item-key="DerNumLineTest" dense :items="itemsProyecto" :height="'auto'" :headers="headersDetailTest" class="elevation-0 scroll table table-hover table-condensed" v-model="selectedProyecto" hide-default-footer :items-per-page="-1" disable-sort @click:row="rowClickTest">
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>

    <s-toolbar v-if="radios == 2" :label="'PART.GASTO - PART.COSTO'" :acceptAdd="true" add @add="addGasto()" clear @clear="clearFormGasto()" remove @removed="removeGasto()"></s-toolbar>
    <v-card outlined v-if="radios == 2">

        <v-row style="margin:auto">
            <v-col lg="6" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                <c-type-spent clearable noDefault full label="Part. Gasto" return-object v-model="itemGasto.objGasto"></c-type-spent>
            </v-col>

            <v-col lg="6" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px" class="mt-3">
                <c-center-coste clearable noDefault full label="Part. Costo" return-object v-model="itemGasto.objCosto" :business="typeBusinessDistribution" :areID="AreID"></c-center-coste>
            </v-col>

        </v-row>
        <v-row style="margin:auto">
            <v-col lg="12" class="" cols="12">
                <v-data-table item-key="DerNumLineGasto" dense :items="itemsGasto" :height="'auto'" :headers="headersDetailGasto" class="elevation-0 scroll table table-hover table-condensed" v-model="selectedGasto" hide-default-footer :items-per-page="-1" disable-sort @click:row="rowClickGasto">
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/RequirementService";

//Components
import cProjects from '@/components/Logistics/cProjects.vue';
import cPartida from '@/components/Logistics/cPartida.vue';
import cTypeSpent from '@/components/Logistics/cTypeSpent.vue';
import cCenterCoste from '@/components/Logistics/cCenterCoste.vue';

export default {
    name: "RequirementService",
    components: {
        cProjects,
        cPartida,
        cTypeSpent,
        cCenterCoste
    },

    props: {
        requirement: null
    },

    data() {
        return {
            messageProcessing: "",
            selectedProyecto: [],
            itemsProyecto: [],
            headersDetailTest: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },
                {
                    text: "Proyecto",
                    value: "PrjDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "Partida",
                    value: "PtdDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "%",
                    value: "DerPorcentaje",
                    sortable: false,
                    width: 60,
                    align: "center"
                },
            ],
            itemProyecto: {
                DerNumLineTest: 0,
                DerPorcentaje: 100,
                objProjects: 0,
                objPartida: 0,
            },

            selectedGasto: [],
            itemsGasto: [],
            headersDetailGasto: [{
                    text: "Empresa",
                    value: "TypeBusinessName",
                    sortable: false,
                    width: 100
                },

                {
                    text: "Gasto",
                    value: "SptDescription",
                    sortable: false,
                    width: 200
                },

                {
                    text: "Costo",
                    value: "CceDescription",
                    sortable: false,
                    width: 200
                },
            ],
            itemGasto: {
                DerNumLineGasto: 0,
                objGasto: 0,
                objCosto: 0,
            },
            typeBusinessDistribution: 0,
            objBussiness: null,
            AreID: this.$fun.getUserInfo().CtrContract.AreID,
            radios: '1',

        }

    },
    computed: {},

    created() {
        console.log("requirement", this.requirement)
    },

    watch: {
        radios() {
            this.itemsProyecto = []
            this.itemsGasto = []
        }
    },

    methods: {
        rowClickTest: function (item, row) {
            this.selectedProyecto = [item];
        },
        rowClickGasto: function (item, row) {
            this.selectedGasto = [item];
        },
        inputBussines(item) {
            if (item !== null) {
                console.log("imput", item)
                this.typeBusinessDistribution = item.DedValue
            }
        },

        //******************************************** PROYECTO ********************************************************************

        clearFormProyecto() {
            this.itemProyecto.DerPorcentaje = 100;
            this.itemProyecto.DerNumLineTest = 0;
            this.itemProyecto = {
                DerNumLineTest: 0,
                DerPorcentaje: 100,
                objProjects: 0,
                objPartida: 0,
            }
        },

        addProyecto() {

            if (this.itemProyecto.objProjects == 0 || this.itemProyecto.objProjects == null) {
                this.$fun.alert("Seleccione Proyecto", "warning")
                return
            }

            if (this.itemProyecto.objPartida == 0 || this.itemProyecto.objPartida == null) {
                this.$fun.alert("Seleccione Partida", "warning")
                return
            }

            if (this.itemProyecto.DerPorcentaje == 0 || this.itemProyecto.DerPorcentaje == "") {
                this.$refs.DerPorcentaje.error("Ingrese Porcentaje")
                return
            }

            if (this.itemProyecto.DerPorcentaje > 100) {
                this.$refs.DerPorcentaje.error("Porcentaje no puede superar el 100%")
                return
            }

            if (
                (this.itemsProyecto.find((x) => x.PrjID == this.itemProyecto.objProjects.PrjID) && this.itemsProyecto.find((x) => x.PtdID == this.itemProyecto.objPartida.PtdID))
            ) {
                this.$fun.alert("Proyecto y Partida ya fueron agregadas", "warning");
                return;
            }

            var sumPorc = 0
            if (this.itemProyecto.DerNumLineTest == 0) {

                sumPorc = sumPorc + parseInt(this.itemProyecto.DerPorcentaje);

                this.itemsProyecto.forEach(element => {
                    sumPorc = sumPorc + parseInt(element.DerPorcentaje)
                });

                if (sumPorc > 100) {
                    this.$refs.DerPorcentaje.error("Porcentaje no puede superar el 100%")
                    return
                }

                let item = {
                    DerPorcentaje: this.itemProyecto.DerPorcentaje,

                    //Obj. Proyectos
                    PrjDescription: this.itemProyecto.objProjects == null ? "" : this.itemProyecto.objProjects.PrjDescriptionLong,
                    PrjID: this.itemProyecto.objProjects == null ? "" : this.itemProyecto.objProjects.PrjID,

                    //Obj. Partida
                    PtdDescription: this.itemProyecto.objPartida == null ? "" : this.itemProyecto.objPartida.PtdDescriptionLong,
                    PtdID: this.itemProyecto.objPartida == null ? "" : this.itemProyecto.objPartida.PtdID,
                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription

                };
                this.itemsProyecto.push(item);

                this.selectedProyecto = [item];
            }

            for (let i = 0; i < this.itemsProyecto.length; i++) {
                this.itemsProyecto[i].DerNumLineTest = i + 1;
            }

        },

        removeProyecto() {
            if (this.itemsProyecto.length > 0) {

                this.itemsProyecto = this.itemsProyecto.filter((x) => x != this.selectedProyecto[0]);
                this.selectedProyecto = this.itemsProyecto.length > 0 ? [this.itemsProyecto[0]] : [];

                this.clearFormProyecto()
            }
        },

        //********************************************* END PROYECTO******************************************************************

        //*********************************************GASTO ************************************************************************
        clearFormGasto() {
            this.itemGasto.DerNumLineGasto = 0;
            this.itemGasto = {
                DerNumLineGasto: 0,
                objGasto: 0,
                objCosto: 0,
            }
        },

        addGasto() {

            if (this.itemGasto.objGasto == 0 || this.itemGasto.objGasto == null) {
                this.$fun.alert("Seleccione Part. Gasto", "warning")
                return
            }

            if (this.itemGasto.objCosto == 0 || this.itemGasto.objCosto == null) {
                this.$fun.alert("Seleccione Part. Costo", "warning")
                return
            }

            if (
                (this.itemsGasto.find((x) => x.SptID == this.itemGasto.objGasto.SptID) && this.itemsGasto.find((x) => x.CceID == this.itemGasto.objCosto.CceID))
            ) {
                this.$fun.alert("Gasto y Costo ya fueron agregadas", "warning");
                return;
            }

            console.log(this.itemGasto)

            if (this.itemGasto.DerNumLineGasto == 0) {

                let item = {
                    //Obj. Gastos
                    SptDescription: this.itemGasto.objGasto == null ? "" : this.itemGasto.objGasto.SptDescriptionLong,
                    SptID: this.itemGasto.objGasto == null ? "" : this.itemGasto.objGasto.SptID,

                    //Obj. Costos
                    CceDescription: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceDescription,
                    CceID: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceID,
                    CceCenterCoste: this.itemGasto.objCosto == null ? "" : this.itemGasto.objCosto.CceCenterCoste,

                    TypeBusiness: this.typeBusinessDistribution,
                    TypeBusinessName: this.objBussiness.DedDescription

                };
                this.itemsGasto.push(item);

                this.selectedGasto = [item];
            }

            for (let i = 0; i < this.itemsGasto.length; i++) this.itemsGasto[i].DerNumLineGasto = i + 1;
        },

        removeGasto() {
            if (this.itemsGasto.length > 0) {

                this.itemsGasto = this.itemsGasto.filter((x) => x != this.selectedGasto[0]);
                this.selectedGasto = this.itemsGasto.length > 0 ? [this.itemsGasto[0]] : [];

                this.clearFormGasto()
            }
        },

        //**********************************************FIN GASTO ************************************************************************

        close() {
            this.$emit('close')
            if (this.itemsProyecto.length > 0) {
                this.$emit('data', this.itemsProyecto, this.radios)
            } else if (this.itemsGasto.length > 0) {
                this.$emit('data', this.itemsGasto, this.radios)
            }

        }

    }
};
</script>
