<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <!--CIRCUITO DE APRBACIONES MODAL-->
    <div v-if="dialogCircuit">
        <v-dialog persistent v-model="dialogCircuit" width="70%">
            <s-toolbar color="#5175AF" dark label="Circuitos Asociados a la Operación" @close="dialogCircuit = false" close></s-toolbar>
            <v-card>
                <v-container>
                    <v-row style="">
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <v-data-table dense item-key="AccID" :items="circuits" height="220" :headers="[
                    { value: 'AccID', text: 'ID', width: 30 , align: 'center'},
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" />
                        </v-col>
                        <v-col cols="12" lg="5" md="5">
                            <s-crud height="220" v-if="this.filterLevels.AccID != null" no-toolbar :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="30%">
            <v-card>
                <s-toolbar label="Motivo"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="disapprovedtransaction()">Desaprobar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
    <div v-if="dialogApproved">
        <v-dialog v-model="dialogApproved" width="30%">
            <v-card>
                <s-toolbar label="Conformidad de Aprobación"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-textarea label="Descripciòn Conformidad" autofocus v-model="itemApproved.RqdApprovedMotive" ref="RqdApprovedMotive" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="Approved()">Aprobar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div v-if="dialogProvider">
        <v-dialog class="elevation-0" v-model="dialogProvider" width="100%" persistent transition="dialog-bottom-transition">
            <lgs-provider-new :value="Supplier.SupPK" @close="dialogProvider = false"></lgs-provider-new>
        </v-dialog>
    </div>

    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" dark @save="save()" :save="isEditable" close @close="$emit('close')" :label="'Requerimiento  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName) + ' - '+ (requirement.TypeGenerationName)">
            <v-divider vertical></v-divider>
            <template v-slot:options>

                <div>

                    <v-menu left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn style="text-transform: capitalize;" color="#135a85" dark elevation="0" small class="ma-2" v-bind="attrs" v-on="on">
                                Descargar Anexos y Proc.
                            </v-btn>
                        </template>
                        <v-list dense elevation="0" outlined>
                            <v-divider></v-divider>
                            <v-list-item-group v-model="selectedItem" color="primary">

                                <v-list-item v-for="(item, i) in itemsInfo" :key="i" @click="openFile({name:item.Attach})">
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-list-item-group>
                            <v-divider></v-divider>
                        </v-list>
                    </v-menu>

                </div>
                <div class="mt-0 mr-3">
                    <label><b>Seleccione Empresa:</b></label>
                </div>
                <div class="mt-1 mr-3">
                    <s-select-definition :outlined="false" style="border-color: seashell;border-radius: 7px;" :def="1142" v-model="requirement.typeBusiness">
                    </s-select-definition>
                </div>
            </template>
        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>

                <v-row>

                    <v-col lg="4" md="4" cols="12" v-if="isEditable">
                        <v-card elevation="0" height="auto">
                            <v-row>
                                <v-col lg="12" md="12" cols="12" style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text disabled label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                            <v-col cols="12" lg="12" md="12" style="margin-bottom:-30px;margin-top:-10px">

                                <v-radio-group v-model="radios">
                                    <template v-slot:label>
                                        <div>Seleccione un metodo de generaciòn</div>
                                    </template>
                                    <v-radio value="1">
                                        <template v-slot:label>
                                            <div>- <strong class="error--text">Generar</strong></div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="2" style="margin-top: -10px">
                                        <template v-slot:label>
                                            <div>- <strong class="primary--text">Revisión</strong></div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="3" style="margin-top: -10px">
                                        <template v-slot:label>
                                            <div>- <strong class="warning--text">Adenda</strong></div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>

                                <v-col cols="12" v-if="radios==3" style="margin-top:-30px">
                                    <v-contract :disabled="!isEditable" label="Contratos" v-model="objContract" :TypeBussines=requirement.typeBusiness :AreID="user.CtrContract.AreID"></v-contract>
                                </v-col>

                                <v-col cols="12" v-if="radios==2" style="margin-top:-30px">
                                    <b>Caso el Contrato cuente con Nro. Por favor digitelo.</b>
                                    <s-text label="Nro. de Contrato Externo" autofocus v-model="requirement.ReqNroContractReference" ref="ReqNroContractReference"></s-text>
                                </v-col>
                                <!-- <v-col cols="12" style="margin-top:-30px" v-if="radios==1">
                                    <v-order-purcharse v-model="objOrder" :AreID="user.CtrContract.AreID"></v-order-purcharse>
                                </v-col> -->
                            </v-col>
                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                            <!-- ADJUNTAR CONTRATO, ADENDA, OTROS -->
                            <v-row style="margin-top:10px; margin-bottom: 10px" v-if="radios == 2">
                                <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Adjuntar Contrato | Otros</b>
                                </v-col>
                                <v-col lg="12" cols="12" class="s-col-text">
                                    <v-file-input show-size counter :ref="'idcontrato'" accept=".doc,.docx" :label="'Anexo Contrato | Otros'" v-model="AttachContract">
                                        <template slot="append">
                                            <label style="">(Max. 10 MB)</label>
                                            <v-icon v-if="requirement.AttachContract!==null" color="green" @click="openFile(AttachContract)">mdi-download</v-icon>
                                        </template>
                                    </v-file-input>
                                </v-col>
                            </v-row>

                            <v-row style="margin-top:10px; margin-bottom: 10px">
                                <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Adjuntar Requisitos. <label style="color:red">(*) Obligatorios</label> </b>
                                </v-col>
                                <v-col lg="12" cols="12" class="s-col-text">

                                    <v-row>
                                        <v-col lg="6" cols="6" v-for="(item, index) in itemAttach" :key="item.RqdID">

                                            <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                <template slot="append" v-if="item.RqdRequired == 1">
                                                    <label style="color:red">(*)</label>
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col :lg="isEditable ? 8 : 12" :md="isEditable ? 8 : 12" cols="12">
                        <v-row justify="center">

                            <v-col :lg="isEditable ? 3 : 4" md="4" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <c-select-area v-if="isEditable" disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
                                <s-text label="Área" :disabled="!isEditable" v-if="!isEditable" v-model="requirement.AreName"></s-text>
                            </v-col>
                            <v-col :lg="isEditable ? 3 : 4" md="4" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date disabled label="Fecha Solicitud" :value="requirement.ReqDate" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="4" :cols="isEditable ? 6 : 6" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
                                <s-select-definition :dgr="49" :def="1180" label="Estado" :disabled="!isEditable" v-model="requirement.ReqStatus" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="4" :cols="isEditable ? 6 : 12" style="padding-top: 0px; padding-bottom: 0px">
                                <s-select-definition :def="1158" label="Prioridad" :disabled="!isEditable" v-model="requirement.TypePriority">
                                </s-select-definition>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8">
                                <s-select-transaction v-model="requirement.TypeCircuit" label="Tipo de Solicitud" :typeprocess="2" :disabled="!isEditable" @input="getTypeProcess($event)" />
                            </v-col>
                            <v-col cols="4">
                                <s-select :items="itemsTypeProcess" full autocomplete item-value="TypeProcess" item-text="TypeProcessName" label="Sub Tipo" v-model="requirement.TypeProcess">
                                </s-select>
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col cols="12">
                                <s-text ref="OrdDocumentNumberID" label="Nro. Orden" v-model="requirement.OrdDocumentNumberID" :disabled="!isEditable"></s-text>
                            </v-col>
                        </v-row> -->

                        <v-divider class="mt-3"></v-divider>

                        <v-row>

                            <v-col cols="9" v-if="!isEditable" style="margin-top:10px">
                                <!-- Cuando es Edicion - Archivos adjuntos -->
                                <v-row>
                                    <v-col lg="12" cols="12" class="s-col-text" v-if="requirement.ReqStatus !== 3"><b style="font-family:Calibri;text-decoration: underline;">Documentos Adjuntos</b>
                                        <v-btn v-if="!isEditable && requirement.ReqProcessInitiated == null" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadOtros()">Cargar
                                            <v-icon> mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-file-input v-if="requirement.ReqProcessInitiated == null" style="margin-top:-10px" v-for="(item, index) in itemAttachOtros" :key="item.RqdID" show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                            <template slot="append" v-if="item.RqdRequired == 1">
                                                <label style="color:red">(*)</label>
                                            </template>
                                        </v-file-input>
                                    </v-col>
                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersAttach" :items="itemAttach">
                                                <template v-slot:item.RqdAttach="{ item }">
                                                    <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                    {{item.RqdAttach}}
                                                </template>
                                                <template v-slot:item.delete="{ item }">

                                                    <a v-if="requirement.ReqStatus !==  1 && requirement.ReqProcessInitiated == null && item.UsrCreateID == userID && requirement.ReqStatus !== 3" style="color:red" @click="deleteFile(item)">Eliminar</a>
                                                </template>

                                            </v-data-table>
                                        </v-card>
                                    </v-col>

                                </v-row>
                                <!-- <v-row style="margin:auto" class="mt-7">

                                </v-row> -->
                                <v-divider></v-divider>
                                <v-row style="margin:auto" class="mt-7">
                                    <v-col cols="12">
                                        <h4>{{requirement.TypeGenerationName}}</h4>
                                        <h4>{{requirement.TypeProcessName}}</h4>
                                        <h4>{{requirement.ReqNroContractReference}}</h4>
                                    </v-col>
                                    <!-- <v-card outlined> -->
                                    <v-spacer></v-spacer>
                                    <v-divider vertical></v-divider>
                                    <v-btn title="Continuar" text small @click="approvedtransaction()" v-if="optionApproved">
                                        <v-icon color="green" style="font-size:26px;"> far fa-thumbs-up</v-icon>
                                    </v-btn>
                                    <!--  <v-btn title="Continuar" text small @click="approvedtransaction()" v-if="optionApproved && requirement.ReqProcessInitiated == 1">
                                        <v-icon color="green" style="font-size:26px;"> far fa-thumbs-up</v-icon>
                                    </v-btn> -->
                                    <v-divider vertical></v-divider>

                                    <v-btn title="Rechazar" text small @click="dialogDissaproved = true" v-if="optionApproved">
                                        <v-icon color="red" style="font-size:26px;"> far fa-thumbs-down</v-icon>
                                    </v-btn>
                                    <v-divider vertical></v-divider>
                                    <!--  </v-card> -->

                                    <v-col lg="12" class="pt-0" v-if="transactionApproved != null">
                                        <h2 v-if="transactionApproved.AtsStatus == 3" style="color: var(--v-error-base)" class="text-center">
                                            Desaprobado: {{ transactionApproved.AtsObservation }}
                                        </h2>
                                        <h2 v-if="transactionApproved.AtsStatus == 2" style="color: var(--v-success-base)" class="text-center">Aprobado</h2>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text" v-if="transactionApproved != null">
                                        <!-- {{user.CtrContract.AreID}}{{requirement.AreID}} -->
                                        <v-file-input show-size counter accept=".pdf" :label="'Contrato Final'" v-model="AttachContractFinal" v-if="user.CtrContract.AreID == requirement.AreResponsableID && transactionApproved.AtsStatus == 2">
                                            <template slot="append">
                                                <label style="">(Max. 10 MB)</label>
                                                <v-icon color="info" @click="saveContract(AttachContractFinal)" v-if="user.CtrContract.AreID == requirement.AreResponsableID">mdi-content-save</v-icon>
                                                <v-icon v-if="AttachContractFinal!==null" color="green" @click="openFile(AttachContractFinal)">mdi-download</v-icon>
                                            </template>
                                        </v-file-input>

                                        <v-btn v-if="transactionApproved != null && transactionApproved.AtsStatus == 2" @click="openFile(AttachContractFinal)" elevation="1" class="capitalize" color="info" rounded="" outlined><v-icon v-if="AttachContractFinal!==null" color="green" style="font-size:14px"  >mdi-download</v-icon>Descargar Contrato Firmado</v-btn>

                                        

                                       <!--  {{AttachContractFinal}} -->

                                    </v-col>
                                    <!-- <v-col cols="12">
                                        <s-text label="Contrato Final" v-model="requirement.ReqAttachContractFinal">
                                            <template slot="append">
                                                <v-icon v-if="AttachContractFinal!==null" color="green" @click="openFile(AttachContractFinal)">mdi-download</v-icon>
                                            </template>
                                        </s-text>
                                    </v-col> -->

                                </v-row>

                            </v-col>
                            <v-divider vertical></v-divider>
                            <v-col :cols="!isEditable ? 3 : 12">
                                <v-col cols="12">
                                    <label><b>Datos del Prestador/Proveedor/Ejecutor </b></label>
                                    <v-divider></v-divider>
                                </v-col>

                                <v-row>

                                    <v-col :cols="!isEditable ? 12 : 4">
                                        <s-select-definition label="Tipo Persona" :def="1192" v-model="requirement.TypePerson"></s-select-definition>
                                    </v-col>

                                    <v-divider class="mt-0" vertical></v-divider>

                                    <v-col :cols="!isEditable ? '12' : '8'">
                                        <v-supplier autofocus :disabled="!isEditable" label="Proveedor" v-model="Supplier" :value="Supplier" @editProvider="editProvider()" />
                                        <span v-if="ProviderBloqueado > 0" style="color: red; font-family: Calibri; font-size: 10px">Proveedor en Lista Negra</span>
                                    </v-col>
                                </v-row>

                                <v-divider class="mt-6"></v-divider>

                                <v-row>

                                    <v-col cols="12">
                                        <s-select-definition :def="1207" label="Tipo Fecha" v-model="requirement.TypeDate"></s-select-definition>
                                    </v-col>
                                    <v-col cols="6" v-if="requirement.TypeDate == 1">
                                        <s-date label="Fecha Inicio" v-model="requirement.ReqDateBegin"></s-date>
                                    </v-col>
                                    <v-col cols="6" v-if="requirement.TypeDate == 1">
                                        <s-date label="Fecha Fin" v-model="requirement.ReqDateEnd"></s-date>
                                    </v-col>

                                    <v-col cols="12" style="margin-top:-20px" v-if="requirement.TypeDate == 2">
                                        <s-textarea label="Descripcion de Inicio y Fin de Contrato" v-model="requirement.ReqDescriptionDate" ref="ReqDescriptionDate"></s-textarea>
                                    </v-col>

                                    <v-col cols="12" style="margin-top:-20px">
                                        <s-textarea label="Descripcion de Contrato" v-model="requirement.ReqDescriptionCtrc" ref="ReqDescriptionCtrc"></s-textarea>
                                    </v-col>
                                    <v-col cols="6" style="margin-top:-20px">
                                        <s-text decimal label="Monto Contrato" v-model="requirement.ReqTotal">
                                            <template v-slot:append>
                                                <v-switch hide-details style="margin-top:-9px" label="Inc. Impuesto" v-model="requirement.ReqIncludesIgv" :disabled="!isEditable"></v-switch>
                                            </template>
                                        </s-text>

                                    </v-col>
                                    <v-col cols="6" style="margin-top:-20px">
                                        <s-select-definition label="Moneda" :def="1129" v-model="requirement.TypeCurrency"></s-select-definition>
                                    </v-col>
                                    <v-col cols="12" lg="4" style="margin-top:-10px">
                                        <!-- <v-switch hide-details="" label="Incluye Impuesto" v-model="requirement.ReqIncludesIgv" :disabled="!isEditable"></v-switch> -->
                                        <s-text ref="OrdDocumentNumberID" label="Nro. Requerimiento (En caso no este vinculado a ningun Req. colocar 0)" number v-model="requirement.OrdDocumentNumberID" :disabled="!isEditable"></s-text>
                                    </v-col>
                                </v-row>

                                <v-divider class="mt-2"></v-divider>
                                <v-col cols="8" class="s-col-text">
                                    <h3 v-if="requirement.TypePerson == '1' && isEditable" style="color:red">Adjuntar PDF de DNI actualizado.</h3>
                                    <h3 v-if="requirement.TypePerson == '2' && isEditable" style="color:red">Adjuntar Ficha RUC actualizada y vigencia de poder del representante que firmará - máximo 30 días de antigüedad.</h3>
                                </v-col>

                            </v-col>

                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row style="margin-top:10px; margin-bottom: 10px" v-if="!isEditable && AttachContract.name!==null">
                            <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Adjuntos Contrato | Otros</b>
                            </v-col>
                            <v-col lg="12" cols="12" class="s-col-text">
                                <s-text :label="'Anexo'" :readonly="!isEditable" v-model="AttachContract.name" :outlined="false">
                                    <template slot="append">
                                        <v-icon v-if="AttachContract!==null" color="green" @click="openFile(AttachContract)">mdi-download</v-icon>
                                    </template>
                                </s-text>
                            </v-col>
                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <!-- REVISIONES DE BORRADORES-->
                        <v-row style="margin-top:10px; margin-bottom: 10px" v-if="!isEditable">
                            <v-col v-if="requirement.ReqStatus == 4 && user.CtrContract.AreID !== requirement.AreID && requirement.ReqProcessInitiated == 1" lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri;text-decoration: underline;">Revisiones</b>
                                <v-btn v-if="!isEditable" outlined class="ml-2" elevation="0" color="success" small style="text-transform: initial;" @click="uploadBorrador()">Subir Borrador
                                    <v-icon> mdi-arrow-up</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col lg="4" md="4" cols="12" class="s-col-text" v-if="requirement.ReqStatus == 4 && user.CtrContract.AreID !== requirement.AreID && requirement.ReqProcessInitiated == 1">

                                <!-- ADJUNTAR BORRADORES -->
                                <v-row>
                                    <v-col lg="8" md="8" cols="12" v-for="(item, index) in itemsVersions" :key="item.RqdID">
                                        <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                        </v-file-input>
                                    </v-col>
                                    <v-col lg="4" md="4" cols="12">
                                        <s-select-definition class="mt-2" clearable :outlined="false" :def="1188" add label="Nro. Revision" v-model="TypeRevision"></s-select-definition>
                                    </v-col>

                                </v-row>

                            </v-col>

                            <v-divider vertical></v-divider>
                            <v-col :lg="requirement.ReqStatus == 4 && user.CtrContract.AreID !== requirement.AreID && requirement.ReqProcessInitiated == 1 ? 8 : 12" :md="requirement.ReqStatus == 4 && user.CtrContract.AreID !== requirement.AreID ? 8  :12" cols="12" class="s-col-text">
                                <v-row>
                                    <v-col cols="12">
                                        <v-btn @click="InitiatedProccess()" small elevation="0" outlined style="font-family:;text-transform: capitalize" v-if="(requirement.ReqProcessInitiated !== 1 && user.CtrContract.AreID == requirement.AreResponsableID)">Iniciar Proceso.</v-btn>
                                        <h2 v-if="requirement.ReqProcessInitiated == 1">Proceso de {{requirement.TypeProcessName}} iniciado el: {{$moment(requirement.ReqDateInitiatedProcess).format(this.$const.FormatDateTimeDB)}} por: {{requirement.UsrNameInitiatedProcess}}</h2>
                                        <!-- <h2>
                                            <a v-if="(requirement.Transaction != null && this.requirement.Transaction.PstID == this.user.CtrContract.PstID && requirement.ReqProcessInitiated == 1 && requirement.ReqStatus == 1 && requirement.ReqProcessEnd == null)" style="text-decoration:underline; color:red" @click="EndProcceso()">Terminar Proceso</a>
                                        </h2> -->

                                    </v-col>
                                </v-row>

                                <v-card outlined>

                                    <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="itemsVersionsF" item-key="RchID">
                                        <template v-slot:item.RqdAttach="{ item }">
                                            <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                            {{item.RqdAttach}}
                                        </template>
                                        <template v-slot:item.StatusApproved="{ item, index }">
                                            <v-chip x-small v-if="item.RqdAprroved !== null" color="info">A</v-chip>
                                            <v-btn v-if="index==0 && item.RqdAprroved == null && user.CtrContract.AreID == requirement.AreID" icon @click="dialApproved(item)">
                                                <v-icon :color="item.RqdAprroved == null ? 'error' : 'info'" size="20">fas fa-user-check</v-icon>
                                            </v-btn>
                                            <a v-if="requirement.ReqStatus !==  1 && item.UsrCreateID == userID" style="color:red" @click="deleteFile(item)">Eliminar</a>
                                        </template>

                                        <template v-slot:item.RqdAttachObservations="{ item }">
                                            <v-file-input hide-details="" show-size accept=".doc,.docx" v-model="AttachObservations" v-if="item.RqdAttachObservations==null && user.CtrContract.AreID == requirement.AreID">
                                                <template slot="append" v-if="user.CtrContract.AreID == requirement.AreID">
                                                    <v-icon @click="uploadBorradorObs(item)">mdi-content-save</v-icon>
                                                </template>
                                            </v-file-input>

                                            <s-text :outlined="false" v-else v-model="item.RqdAttachObservations">
                                                <template v-slot:prepend>
                                                    <v-icon color="green" @click="openFile(item.AttachObservations)" v-if="item.RqdAttachObservations!==null">mdi-download</v-icon>
                                                </template>
                                            </s-text>

                                        </template>

                                    </v-data-table>
                                </v-card>
                            </v-col>

                        </v-row>

                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row>

                            <v-col cols="12" style="padding-top: 0px" class="mt-4">
                                <label><b>OBSERVACIONES GENERALES</b></label>
                                <v-btn v-if="!isEditable" elevation="0" small class="ml-2" text color="info" @click="addComments()">Añadir Comentario</v-btn>
                            </v-col>
                            <v-col lg="6" md="6" cols="12" style="padding-top: 0px">
                                <s-textarea v-model="requirement.ReqObservation" ref="ReqObservation" :height="100"></s-textarea>
                            </v-col>
                            <v-col lg="6" md="6" cols="12" style="margin-top:-20px">
                                <v-card outlined>
                                    <v-toolbar dense elevation="0" color="#5175af" dark>
                                        <v-toolbar-title>Comentarios</v-toolbar-title>
                                    </v-toolbar>

                                    <v-list>
                                        <v-list-item-group :active-class="''">

                                            <template v-for="(item, index) in requirement.listComments">

                                                <v-list-item :key="item.RccID">
                                                    <v-list-item-avatar>
                                                        <v-row justify="space-around">
                                                            <v-avatar color="red">
                                                                <span class="white--text text-h5">{{item.NtpName !== undefined ? item.NtpName.split(" ")[0].substring(0,1) : ""}}{{item.NtpName !== undefined ? item.NtpName.split(" ")[1].substring(0,1): ""}}</span>
                                                            </v-avatar>
                                                        </v-row>

                                                    </v-list-item-avatar>

                                                    <v-list-item-content>

                                                        <b>
                                                            <v-list-item-title>{{item.NtpName}}
                                                                <v-spacer></v-spacer> {{item.SecCreate}}
                                                            </v-list-item-title>
                                                        </b>
                                                        <v-divider></v-divider>

                                                        {{item.RccSubtitleExtract}}

                                                        <v-list-group v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                            <template v-slot:activator>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>Ver mas</v-list-item-title>
                                                                </v-list-item-content>
                                                            </template>

                                                            <v-list-item v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                                <v-list-item-content>
                                                                    {{item.RccSubtitle}}
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-group>

                                                        <!-- <v-list-item-title style="font-size: 12px" v-html="item.RccSubtitle"></v-list-item-title> -->

                                                    </v-list-item-content>

                                                    <a style="color: red;font-size:10px" v-if="item.UsrCreateID == $fun.getUserID() && item.SecCreate >= $fun.getDate() && requirement.ReqStatus == 4" @click="deleteComments(item)">Eliminar</a>

                                                </v-list-item>
                                                <v-divider :key="index"></v-divider>
                                            </template>

                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-col>

                        </v-row>

                        <!--  <v-btn @click="createfile()">createfile</v-btn> -->
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Legal/LglRequirementService";
import _sRequirementConfig from "@/services/Legal/LglRequirementconfig";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";
import _sContract from "@/services/Legal/LglContractService.js";
import _sApprovalTransaction from "@/services/Security/ApprovalTransactionService";
import _sSupplier from "@/services/Logistics/SupplierService";

//Components
import vSupplier from "@/views/Logistics/vSupplier.vue";
import vContract from "@/views/Legal/vContract.vue";
import cSelectContract from "@/components/Legal/cSelectContract.vue";
import LgsProviderNew from "@/views/Logistics/LgsProviderNew";
import vOrderPurcharse from '@/views/Legal/vOrderPurcharse.vue';

export default {
    components: {
        vSupplier,
        cSelectContract,
        vContract,
        LgsProviderNew,
        vOrderPurcharse
    },

    props: {
        value: {
            default: 1,
            type: Number
        },
        order: {},
    },

    data() {
        return {
            Supplier: {},
            /* SupplierAll:{}, */
            ProviderBloqueado: 0,
            selectedItem: -1,
            messageProcessing: "",
            /* configCk: {
                extraPlugins: 'autogrow',
                autoGrow_minHeight: 50,
            }, */
            radios: '1',
            attach: [],
            processing: false,
            dialogCircuit: false,
            dialogApproved: false,

            selectedCircuit: [],
            filterLevels: {},
            configLevels: {
                model: {
                    AccID: "int",
                    AlvID: "ID",
                    SecStatus: "boleam",
                    PstID: "int",
                    PstName: "string",
                },
                service: _sApprovalLevel,
                headers: [{
                        text: "Cargo",
                        value: "PstName"
                    },
                    {
                        text: "Nivel",
                        value: "TypeLevelText"
                    },
                ],
            },
            circuits: [],
            objContract: null,
            objOrder: null,
            isUserCreated: true,
            transactionApproved: null,
            dialogDissaproved: false,
            TypePriority: null,
            optionApproved: false,
            requirement: {
                AreID: 0,
                ReqDate: null,
                UsrID: null,
                ReqID: null,
                ReqCommitted: null,
                ReqObservation: "",
                ReqStatus: 0,
                typeBusiness: 1,
                ReqDayExpiration: 1,
                TypePerson: null,
                ReqTotal: 1
            },

            itemAttach: [],
            //itemsContract: [],
            itemsVersions: [],
            itemAttachOtros: [],

            itemsVersionsF: [],
            AttachContract: null,
            AttachContractFinal: null,
            AttachObservations: null,

            headersAttach: [{
                    text: "Decripción",
                    value: "RqdDescription"
                },
                {
                    text: "Archivo",
                    value: "RqdAttach"
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha C.",
                    value: "SecCreate"
                },
                {
                    text: "Eliminar",
                    value: "delete"
                },

            ],
            headersFiles: [{
                    text: "Status",
                    value: "StatusApproved"
                },

                {
                    text: "Rev.",
                    value: "TypeRevisionName"
                },
                {
                    text: "Archivo",
                    value: "RqdAttach",
                },
                {
                    text: "Archivo Observaciones",
                    value: "RqdAttachObservations",
                    width: 300
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha C.",
                    value: "SecCreate"
                },
                {
                    text: "T. Espera(dd)",
                    value: "TypeRevisionStandbyTime"
                },
                {
                    text: "T. Transcurrido(dd:hh:mm:ss)",
                    value: "TypeRevisionStandbyElapsed"
                },
                {
                    text: "Aprobado Por",
                    value: "NtpNameApproved"
                },
                {
                    text: "Fecha Apro.",
                    value: "SecUpdate"
                },

            ],
            TypeRevision: null,
            dialogPreview: false,
            nameFile: "",
            itemsTypePerson: [],
            itemsInfo: [{
                    title: 'ANEXO 1 - DATOS PARA LA ELABORACIÓN DE CONTRATOS',
                    Attach: 'ANEXO 1.docx'
                },
                {
                    title: 'ANEXO 3 - PROCEDIMIENTO PARA EL CONTROL Y CUSTODIA DE CARTAS FIANZAS Y LETRAS DE CAMBIO',
                    Attach: 'ANEXO 3.docx'
                },
                {
                    title: 'ANEXO 4 - DATOS PARA LA ELABORACIÓN DE CONVENIOS DE COOPERACIÓN, TRANSACCIÓN EXTRAJUDICIAL Y OTROS',
                    Attach: 'ANEXO 4.docx'
                },
                {
                    title: 'ANEXO DATOS PARA LA ELABORACIÓN DE UN CONTRATO DE LOCACIÓN DE SERVICIOS',
                    Attach: 'Anexo Datos para la Elaboración de Contrato.docx'
                },
                {
                    title: 'ANEXO_2_PENALIDADES POR ATRASOS E INCUMPLIMIENTOS',
                    Attach: 'ANEXO_2_PENALIDADES POR ATRASOS E INCUMPLIMIENTOS.docx'
                },
                {
                    title: 'ANEXO_PENALIDADES POR ATRASOS E INCUMPLIMIENTOS',
                    Attach: 'ANEXO_PENALIDADES POR ATRASOS E INCUMPLIMIENTOS.docx'
                },
                {
                    title: 'PROCEDIMIENTO PARA LA ELABORACIÓN DE CONTRATOS DE SERVICIOS-OBRAS Y OTROS CONVENIOS',
                    Attach: 'PROCEDIMIENTO PARA LA ELABORACIÓN DE CONTRATOS DE SERVICIOS-OBRAS Y OTROS CONVENIOS.pdf'
                },

            ],

            itemsTypeProcess: [],
            dialogProvider: false,
            itemApproved: null
        }
    },
    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        userID() {
            return this.$fun.getUserID();
        },
        isEditable() {
            return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null;

        },
    },

    created() {
        if (this.value != null) {

            this.initialize();

            this.getConfigVersions()
            this.getConfigOtros()
        } else {
            if (this.order.CttID !== null) {

                this.Supplier = this.order != null ? this.order.sup : {}
                this.requirement.TypePerson = this.order.sup.TypePerson
                this.requirement.ReqTotal = this.order.OrdTotal
                this.requirement.TypeCurrency = this.order.TypeCurrency

            } else {
                //this.order.CttID = null
            }
        }
    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {
        isEditable() {
            this.processing = false;
        },

        Supplier() {
            if (this.Supplier != null && this.isEditable)
                if (this.Supplier.SupID != null) {
                    if (this.order.CttID == null)
                        this.Supplier.SupPK = this.Supplier.PrvID
                    this.ProviderBloqueado = this.Supplier.ProviderBloqueado;
                    console.log(this.Supplier)
                }
        },
        radios() {
            console.log("radios", this.itemAttach)
        }
    },

    methods: {

        initialize() {
            _sRequirement.byID({
                ReqID: this.value
            }, this.$fun.getUserID()).then((r) => {

                r.data.itemsAttach.forEach(element => {
                    element.Attach = {
                        name: element.RqdAttach
                    };
                    element.AttachObservations = {
                        name: element.RqdAttachObservations
                    };
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    element.SecUpdate = element.SecUpdate !== null ? this.$moment(element.SecUpdate).format(this.$const.FormatDateTimeDB) : "";
                    element.AreIDResponsable = r.data.AreResponsableID
                    element.AreIDTrigger = r.data.AreIDTrigger

                });

                r.data.listComments.forEach(element => {
                    element.divider = false
                    element.inset = false
                    element.active = false
                    element.action = 'mdi-tag'
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    element.RccSubtitleExtract = element.RccSubtitle.substring(0, 300)
                    element.RccLengthExtract = element.RccSubtitleExtract.length
                    element.RccLengthSubtitle = element.RccSubtitle.length
                });

                this.AttachContract = {
                    name: r.data.AttachContract
                }
                this.AttachContractFinal = {
                    name: r.data.ReqAttachContractFinal == null ? "" : r.data.ReqAttachContractFinal
                }

                console.log("········", r.data.ReqAttachContractFinal)

                this.requirement = r.data;
                //this.requirement.TypeProcess = this.requirement.TypeProcess.toString();

                let sup = {
                    SupID: this.requirement.SupCode,
                    SupName: this.requirement.CardName,
                };

                this.itemAttach = this.requirement.itemsAttach.filter(x => x.TypeDocument == 1);
                this.itemsVersionsF = this.requirement.itemsAttach.filter(x => x.TypeDocument == 2);

                this.Supplier = sup;

                console.log("al levantar", r.data)

                if (this.requirement.UsrCreateID == this.$fun.getUserID()) this.isUserCreated = true;
                else this.isUserCreated = false;
                this.transactionApproved = this.requirement.Transaction;
                if (this.requirement.Transaction != null)
                    if (this.requirement.Transaction.UsersApproved.length > 0)
                        if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                            if (this.requirement.ReqStatus == 4) this.optionApproved = true;
            });
        },

        //Tipo de Proceso segun Transaccion
        getTypeProcess(item) {
            if (item > 0) {
                _sRequirement.byTrs({
                    TrsID: item
                }, this.$fun.getUserID()).then(resp => {
                    this.itemsTypeProcess = resp.data
                })

                this.getConfigByTrs(item);
            }
        },

        //Listar Adjuntos permitidos por transaccion
        getConfigByTrs(id) {
            if (id > 0) {
                if (this.value == null) {
                    _sRequirementConfig.list(id, this.$fun.getUserID()).then(resp => {
                        this.itemAttach = resp.data

                    })
                }
            }
        },

        //Descargar Archivos
        openFile(url) {
            console.log(url)
            if (url == null) return;
            if (url.name == null) return

            _sGeneral.download(url.name, this.$fun.getUserID(), 5).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        save() {

            if (this.requirement.typeBusiness == null || this.requirement.typeBusiness <= 0) {
                this.$fun.alert("Especifique una Empresa", "warning");
                return
            }

            if (this.requirement.TypeCircuit == null) {
                this.$fun.alert("Seleccione Tipo de Solicitud", "warning");
                return
            }

            if (this.requirement.TypeProcess == null) {
                this.$fun.alert("Seleccione Sub Tipo", "warning");
                return
            }

            if (this.requirement.ReqDescriptionCtrc == null || this.requirement.ReqDescriptionCtrc.length == 0) {
                this.$refs.ReqDescriptionCtrc.error("Ingrese Descripcion")
                return
            }

            if (this.requirement.ReqDescriptionCtrc.length < 20) {
                this.$refs.ReqDescriptionCtrc.error("Cntidad minima de caracteres 20")
                return
            }

            if (this.radios == null) {
                this.$fun.alert("Seleccione un metodo de generaciòn", "warning");
                return
            }

            if (this.radios == 3) {
                if (this.objContract == null) {
                    this.$fun.alert("Ingrese Nro. Contrato al que hace referencia la adenda.", "warning")
                    return
                }

                this.requirement.RecID = this.objContract.RecID;
            } else {
                this.requirement.RecID = null
            }

            /* if (this.radios == 1) {
                this.requirement.OrdID = this.objOrder.OrdID;
            } */

            if (this.radios == 2) {

                var array = []
                array.push({
                    RqdDescription: "Contrato | Otros",
                    RqdRequired: 1,
                    RqdAttach: this.AttachContract,
                    RqdSize: 10485760,
                    RqdAccept: ".doc,.docx",
                })

                if (this.$fun.isValidExtension(array)) {
                    return
                }

                if (this.$fun.isValidTamanio(array)) {
                    return
                }

            }

            if (this.Supplier.SupID == null || this.Supplier.SupID.length == 0) {
                this.$fun.alert("Falta Datos del Prestador/Proveedor/Ejecutor", "warning");
                return;
            }

            if (this.ProviderBloqueado > 0) {
                this.$fun.alert(
                    "Proveedor en Lista negra, no se puede generar Requerimiento. Consulte con el Área Encargada",
                    "error"
                );
                return;
            }

            if (this.requirement.TypeDate == 1) {
                if (this.requirement.ReqDateBegin > this.requirement.ReqDateEnd) {
                    this.$fun.alert("Fecha Fin no puede ser inferior a Fecha de Inicio", "warning");
                    return;
                }

                this.requirement.ReqDescriptionDate = null
            } else {
                if (this.requirement.ReqDescriptionDate == null || this.requirement.ReqDescriptionDate.length == 0) {
                    this.$refs.ReqDescriptionDate.error("Ingrese Descripcion de Fecha Inicio y Fin de Contrato")
                    return
                }
                this.requirement.ReqDateBegin = null
                this.requirement.ReqDateEnd = null

            }

            if (this.requirement.ReqTotal <= 0) {
                this.$fun.alert("Monto debe ser mayor a 0", "warning");
                return;
            }

            if (this.requirement.TypeCurrency <= 0) {
                this.$fun.alert("Seleccione Moneda", "warning");
                return;
            }

            if (this.requirement.OrdDocumentNumberID.length <= 0 || this.requirement.OrdDocumentNumberID.length == null) {
                this.$refs.OrdDocumentNumberID.error("Ingrese Nro. de Requerimiento", "warning");
                return;
            }

            this.requirement.SupCode = this.Supplier.SupID;
            this.requirement.CardName = this.Supplier.SupName;

            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            this.requirement.CttID = this.order.CttID
            this.requirement.TypeGeneration = this.radios
            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.SecStatus = 1;

            this.requirement.itemsAttach = []
            this.requirement.listComments = []

            //Comentarios
            if (this.requirement.ReqObservation.length > 0) {
                this.requirement.listComments = [{
                    RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                    RccTitle: "",
                    RccSubtitle: this.requirement.ReqObservation,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID()
                }]
                this.requirement.ReqObservation = "";
            }

            this.processing = false;

            console.log("save", this.requirement)

            this.$fun.alert("Seguro de continuar con la Solicitud?", "question", false).then((r) => {
                if (r.value) {

                    this.messageProcessing = ""
                    this.processing = true
                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemAttach.length; index++) {
                        if (this.itemAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemAttach[index].RqdID,
                                RqdAttach: this.itemAttach[index].RqdAttach,
                            })
                        }
                    }

                    if (files.length <= 0) {
                        this.$fun.alert("[IMP]. Error debe adjuntar al menos un archivo", "error")
                        this.processing = false
                        return
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {
                        if (resp.status == 200) {
                            this.requirement.itemsAttach = resp.data

                            if (this.requirement.itemsAttach.length > 0)
                                this.requirement.itemsAttach.forEach(element => {
                                    element.TypeDocument = 1
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                            //Si es revision
                            if (this.radios == 2) {

                                files = [];
                                if (this.AttachContract) files.push(this.AttachContract);
                                var formData = new FormData();
                                let i = 0;
                                files.forEach((element) => {
                                    formData.append(i.toString(), element);
                                    i++;
                                });
                                _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {
                                    this.requirement.AttachContract = this.AttachContract != null ? resp.data[0].RqdAttach : "";
                                    this.saveAll(this.requirement)
                                });

                            } else {
                                this.saveAll(this.requirement)
                            }

                        }
                    })
                }
            });
        },

        saveAll(requirement) {
            _sRequirement.save(requirement, this.$fun.getUserID(), 0).then((r) => {
                    if (r.data.length > 1) {
                        this.circuits = r.data;
                        this.dialogCircuit = true;
                        this.messageProcessing = ""
                        this.processing = false
                    } else {
                        this.$fun.alert("Guardado Correctamente", "success");
                        this.$emit("close");
                        this.messageProcessing = ""
                        this.processing = false
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                });
        },

        //********************************** CIRCUITOS DE APROBACION************************************************* */
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit(item) {

            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();

            this.$fun.alert("Seguro de seguir con: " + this.selectedCircuit[0].AccName, "question", false).then((r) => {
                if (r.value) {
                    this.messageProcessing = ""
                    this.processing = true
                    _sRequirement.save(this.requirement, this.$fun.getUserID(), this.selectedCircuit[0].AccID).then((j) => {
                            this.processing = false
                            this.$fun.alert("Guardado Correctamente", "success");
                            this.$emit("close");

                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });
        },

        //********************************** FIN DE CIRCUITOS ***************************************************

        //*********************************************************

        //Iniciar Proceso Elaboracion de Contrato
        InitiatedProccess() {
            console.log(this.requirement)
            this.$fun.alert("¿Seguro de comenzar proceso de Elaboración: " + this.requirement.TypeProcessName + "?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.UsrInitiatedProcess = this.$fun.getUserID()
                    this.requirement.ReqProcessInitiated = 1;
                    this.requirement.UsrUpdateID = this.$fun.getUserID()
                    this.requirement.itemsAttach = []
                    this.requirement.listComments = []

                    _sRequirement.initiatedProcess(this.requirement, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        getConfigVersions() {
            if (this.value !== null) {
                _sRequirementConfig.list(100, this.$fun.getUserID()).then(resp => {
                    this.itemsVersions = resp.data

                })
            }
        },

        getConfigOtros() {
            if (this.value !== null) {
                _sRequirementConfig.list(101, this.$fun.getUserID()).then(resp => {
                    this.itemAttachOtros = resp.data

                })
            }
        },

        //******************************* DETALLE ********************************************* */

        uploadBorrador() {

            console.log(this.requirement)

            this.processing = false
            if (this.requirement.ReqDateInitiatedProcess == null) {
                this.$fun.alert("[IMP] Por favor inicie el proceso. Caso contrato comuniquese con el Àrea Legal.", "warning")
                return
            }

            if (this.$fun.isValidExtension(this.itemsVersions)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemsVersions)) {
                return
            }

            if (this.TypeRevision == null) {
                this.$fun.alert("Seleccione Nro. de Revision", "warning")
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemsVersions.length; index++) {
                if (this.itemsVersions[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemsVersions[index].RqdID,
                        RqdAttach: this.itemsVersions[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existen archivos para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivo?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {
                            if (resp.status == 200) {
                                this.requirement.itemsVersions = resp.data

                                this.requirement.itemsVersions.forEach(element => {
                                    element.ReqID = this.requirement.ReqID
                                    element.SecStatus = 1
                                    element.UsrCreateID = this.$fun.getUserID()
                                    element.TypeDocument = 2
                                    element.TypeRevision = this.TypeRevision
                                    element.AreID = this.requirement.AreID
                                    element.AreIDResponsable = this.requirement.AreResponsableID
                                });

                                if (this.requirement.itemsVersions.length > 0) {
                                    _sRequirement.savefiles(this.requirement.itemsVersions, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                this.initialize()

                                                this.messageProcessing = ""
                                                this.processing = false
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        });
                                }
                            }
                        },
                        (e) => {});
                }
            })

        },

        uploadOtros() {
            console.log("this.requirement.AreIDResponsable", this.requirement.AreResponsableID)

            this.processing = false
            if (this.$fun.isValidExtension(this.itemAttachOtros)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttachOtros)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemAttachOtros.length; index++) {
                if (this.itemAttachOtros[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemAttachOtros[index].RqdID,
                        RqdAttach: this.itemAttachOtros[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existe archivo para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            var PstID = 0;

            this.$fun.alert("¿Seguro de subir Archivo?", "question").then(val => {
                if (val.value) {

                    this.messageProcessing = ""
                    this.processing = true

                    /* if (this.requirement.Transaction !== null) {
                        PstID = this.requirement.Transaction.PstID;
                    }

                    if (PstID == 0) {
                        this.$fun.alert("[IMP] Error PstID no encontrado, comuniquese con Àrea de Sistemas.", "warning")
                        return
                    } */

                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {
                            if (resp.status == 200) {
                                this.requirement.itemAttachOtros = resp.data

                                this.requirement.itemAttachOtros.forEach(element => {
                                    element.ReqID = this.requirement.ReqID
                                    element.AreID = this.requirement.AreID
                                    element.AreIDResponsable = this.requirement.AreResponsableID
                                    element.UsrCreateID = this.$fun.getUserID()
                                    element.TypeDocument = 1
                                    element.SecStatus = 1
                                });

                                if (this.requirement.itemAttachOtros.length > 0) {

                                    _sRequirement.savefiles(this.requirement.itemAttachOtros, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                this.initialize()
                                                this.messageProcessing = ""
                                                this.processing = false
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;

                                        });
                                }
                            }
                        },
                        (e) => {});
                }
            })

        },

        uploadBorradorObs(item) {

            if (this.itemsVersionsF.filter(x => x.RqdAprroved == 1).length > 0) {
                alert('[IMP]Error... Ya existe un Documento aprobado.')
                return
            }

            var array = []
            array.push({
                RqdDescription: "Obs. Borrador",
                RqdRequired: 1,
                RqdAttach: this.AttachObservations,
                RqdSize: 10485760,
                RqdAccept: ".doc,.docx",
            })

            if (this.$fun.isValidExtension(array)) {
                return
            }

            if (this.$fun.isValidTamanio(array)) {
                return
            }

            let files = [];
            if (this.AttachObservations) files.push(this.AttachObservations);
            var formData = new FormData();
            let i = 0;
            files.forEach((element) => {
                formData.append(i.toString(), element);
                i++;
            });

            this.$fun.alert("Seguro de subir archivo ?", "question", false).then(val => {
                if (val.value) {
                    _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {

                        item.RqdAttachObservations = resp.data[0].RqdAttach
                        item.UsrCreateID = this.$fun.getUserID()

                        _sRequirement.saveBorradorObs(item, this.$fun.getUserID()).then((r) => {
                                if (r.status == 200) {
                                    this.initialize()
                                    this.processing = false;
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                    });
                }
            })

        },

        dialApproved(item) {

            this.itemApproved = item
            this.itemApproved.RqdApprovedMotive = "Conforme. Subir a DEC5 para la firma correspondiente."
            this.dialogApproved = true
        },

        Approved(item) {

            console.log(this.itemApproved)

            if (this.itemApproved.RqdApprovedMotive == null || this.itemApproved.RqdApprovedMotive.length <= 0) {
                this.$refs.RqdApprovedMotive.error("Ingrese Motivo de Conformidad")
                return
            }

            //this.requirement.itemsAttach = [this.itemApproved]
            let obj = [this.itemApproved]
            var PstID = 0;

            this.$fun.alert("¿Seguro de dar conformidad a documento Seleccionado ?", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    if (obj.length > 0) {

                        obj[0].RqdAprroved = 1;
                        obj[0].UsrUpdateID = this.$fun.getUserID()

                        /* if (this.requirement.Transaction !== null) {
                            PstID = this.requirement.Transaction.PstID;
                        }

                        if (PstID == 0) {
                            this.$fun.alert("[IMP] Error PstID no encontrado, comuniquese con Área de Sistemas.", "warning")
                            return
                        } */

                        _sRequirement.savefiles(obj, this.$fun.getUserID()).then((r) => {
                                if (r.status == 200) {
                                    this.EndProcceso()
                                    this.processing = false;
                                    this.dialogApproved = false

                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                                this.dialogApproved = false
                                this.initialize()
                            });
                    }

                }
            })
        },

        addComments() {

            if (this.requirement.ReqObservation.length == 0) {
                this.$refs.ReqObservation.error("Digite Comentario")
                return
            }

            this.$fun.alert("¿Seguro de añadir comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [{
                        ReqID: this.requirement.ReqID,
                        RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                        RccTitle: "New Comments",
                        RccSubtitle: this.requirement.ReqObservation,
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID()
                    }]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.messageProcessing = ""
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        deleteComments(item) {

            item.SecStatus = 0
            item.UsrUpdateID = this.$fun.getUserID()

            this.$fun.alert("¿Seguro de eliminar comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [item]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });

                }
            })
        },

        deleteFile(item) {

            this.requirement.itemsAttach = [item]

            let obj = [item]
            var PstID = 0;

            this.$fun.alert("¿Seguro de eliminar documento Seleccionado ?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    if (obj.length > 0) {

                        obj[0].UsrUpdateID = this.$fun.getUserID()
                        obj[0].SecStatus = 0;

                        _sRequirement.savefiles(obj, this.$fun.getUserID(), PstID, this.requirement.ReqID, this.requirement.TypeCircuit).then((r) => {
                                if (r.status == 200) {
                                    this.initialize()
                                    this.processing = false;
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                    }

                }
            })
        },

        approvedtransaction() {

            if (this.user.CtrContract.AreID == this.requirement.AreResponsableID) {
                if (this.itemsVersionsF.length <= 0) {
                    this.$fun.alert("[IMP] Al menos debe adjuntar 1 archivo borrador.", "warning")
                    return
                }

                if (this.itemsVersionsF.filter(x => x.RqdAprroved == 1).length <= 0) {
                    alert('[IMP]Error... No existe un Documento aprobado.')
                    return
                }
            }

            if (this.transactionApproved)
                this.$fun.alert("Seguro de aprobar Documento?", "question", false).then((r) => {
                    if (r.value) {

                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.$fun.alert("Aprobado Correctamente", "success");
                                this.processing = false;
                                this.$emit("close");
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                    }
                });
            else this.$emit("approved");
        },

        disapprovedtransaction() {

            if (this.transactionApproved)

                this.$fun.alert("Seguro de desaprobar Documento?", "question", false).then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.$fun.alert("Documento desaprobado", "success");
                                this.processing = false;
                                this.$emit("close");
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                    }
                });
            else this.$emit("disapproved");
        },
        //********************************** FIN CIRCUITOS DE APROBACION************************************************* */

        editProvider() {
            console.log("editProvider", this.Supplier)
            if (this.Supplier.SupPK > 0) {
                this.dialogProvider = true
            }

        },

        //novaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
        EndProcceso() {

            /*  this.$fun.alert("¿Seguro de terminar proceso, Elaboración de: " + this.requirement.TypeProcessName + "?", "question", false).then(val => {
                 if (val.value) {
                     this.messageProcessing = ""
                     this.processing = true */

            this.requirement.UsrCreateID = this.$fun.getUserID()
            _sRequirement.endProcess(this.requirement, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.initialize()
                        this.processing = false;
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                });

            /*  }
            }) */
        },

        saveContract(attach) {
            if (attach !== null) {
                if (attach.name !== undefined) {
                    console.log(attach)
                    var array = []
                    array.push({
                        RqdDescription: "Contrato Final",
                        RqdRequired: 1,
                        RqdAttach: attach,
                        RqdSize: 31457280,
                        RqdAccept: ".pdf",
                    })

                    if (this.$fun.isValidExtension(array)) {
                        return
                    }

                    if (this.$fun.isValidTamanio(array)) {
                        return
                    }

                    let files = [];
                    if (attach) files.push(attach);
                    var formData = new FormData();
                    let i = 0;
                    files.forEach((element) => {
                        formData.append(i.toString(), element);
                        i++;
                    });

                    this.$fun.alert("Seguro de cargar archivo", "question", false).then((r) => {
                        if (r.value) {
                            this.messageProcessing = ""
                            this.processing = true

                            _sGeneral.uploadfilelegal("123", this.$fun.getUserID(), formData, 1).then(resp => {
                                this.requirement.ReqAttachContractFinal = attach != null ? resp.data[0].RqdAttach : "";

                                console.log(this.requirement.ReqAttachContractFinal)

                                _sRequirement.saveContractFinal(this.requirement, this.$fun.getUserID()).then((j) => {
                                        this.processing = false
                                        this.$fun.alert("Guardado Correctamente", "success");
                                        this.$emit("close");

                                    },
                                    (e) => {
                                        this.messageProcessing = e.response.data.Message;
                                        this.processing = false;
                                    });
                            });

                        }
                    });

                }

            }

        }
    }

};
</script>
