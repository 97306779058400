<template>
<div>
    <v-row>
        <v-col>
            <s-crud no-full close @close="$emit('close')" searchInput @doubleClick="dobleClick" title="Proveedores" :config="config">

            </s-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
import _sSupplier from '@/services/Logistics/SupplierService'
export default {
    name: "RequirementService",
    components: {},
    props: {},

    data: () => ({
        config: {
            model: {
                SupID: "ID",
            },
            service: _sSupplier,
            headers: [{
                    text: "Codigo",
                    value: "SupID",
                    sortable: false
                },
                {
                    text: "Proveedor",
                    value: "SupName",
                    sortable: false
                },
            ],
        },

    }),
    methods: {
        dobleClick(item) {
            this.$emit('dblClick', item)
        }
    },
    created() {},
};
</script>
