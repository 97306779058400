let children = [];

children.push({
    path: "/general/definicion",
    name: "GEN_DEFINITION",
    component: () =>
        import ("../views/General/Definition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Definición", href: "/general/definicion" },
        ],
    },
});


children.push({
    path: "/general/grupodefinicion",
    name: "GEN_DEFINITIONGROUP",
    component: () =>
        import ("../views/General/DefinitionGroup.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Grupo de Definición", href: "/general/grupodefinicion" },
        ],
    },
});

children.push({
    path: "/general/numeracion",
    name: "GEN_NUMERATION",
    component: () =>
        import ("../views/General/Numeration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Numeración de Tabla", href: "/general/numeracion" },
        ],
    },
});



children.push({
    path: "/general/parametro",
    name: "GEN_PARAMETER",
    component: () =>
        import ("../views/General/Parameter.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Parametros", href: "/general/parametro" },
        ],
    },
});

children.push({
    path: "/general/logerrores",
    name: "GEN_LOGERROR",
    component: () =>
        import ("../views/General/LogErrors/Log.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Log de Errores", href: "/general/logerrores" },
        ],
    },
});

children.push({
    path: "/general/area",
    name: "GEN_AREA",
    component: () =>
        import ("../views/General/Area.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Areas", href: "/general/area" },
        ],
    },
});

children.push({
    path: "/general/cargoporpersona",
    name: "GEN_PERSONROL",
    component: () =>
        import ("../views/General/PositionPerson.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Cargo por Persona", href: "/general/cargoporpersona" },
        ],
    },
});

children.push({
    path: "/general/cargo",
    name: "GEN_POSITION",
    component: () =>
        import ("../views/General/Position.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Cargo", href: "/general/cargo" },
        ],
    },
});

children.push({
    path: "/general/numeraciondocumento",
    name: "GEN_NUMERATIONDOCUMENT",
    component: () =>
        import ("../views/General/NumerationDocument.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            {
                text: "Numeración de Documentos",
                href: "/general/numeraciondocumento",
            },
        ],
    },
});

children.push({
    path: "/general/numeraciondocumentolegal",
    name: "GEN_NUMERATIONDOCUMENTLEGAL",
    component: () =>
        import ("../views/General/NumerationDocumentLegal.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            {
                text: "Numeración de Documentos",
                href: "/general/numeraciondocumentolegal",
            },
        ],
    },
});

children.push({
    path: "/general/gpt",
    name: "GEN_GPT",
    component: () =>
        import ("../views/General/ChatGTP.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            {
                text: "Chat GPT",
                href: "/general/gpt",
            },
        ],
    },
});




export { children };