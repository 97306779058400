import Service from "../Service";

const resource = "/approvaltransaction/";

export default {
    approved(objTransaction, requestID) {
        return Service.post(resource + "approved", objTransaction, {
            params: { requestID: requestID },
        });
    },

    cancelApproval(DocEntry, TypeApproval, requestID) {
        return Service.post(resource + "cancelApproval", "", {
            params: { DocEntry: DocEntry, TypeApproval: TypeApproval, requestID: requestID },
        });
    },

    createCircuit(objTransaction, requestID) {
        return Service.post(resource + "createcircuit", objTransaction, {
            params: { requestID: requestID },
        });
    },

    getCircuit(objTransaction, requestID) {
        return Service.post(resource + "getcircuit", objTransaction, {
            params: { requestID: requestID },
        });
    },



};