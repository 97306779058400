<template>
    <div>
        <v-label>
            <b>{{ label }}</b>
        </v-label>
        <v-autocomplete v-if="autocomplete && full"
                        :clearable="clearable"
                        :return-object="returnObject"
                        :items="items"
                        :disabled="disabled"
                        :readonly="readonly"
                        :item-text="itemtext"
                        :value="valueLocal" :ref="'sgeneric'+label"
                        @input="input($event)"
                        :item-value="itemvalue"
                        placeholder="Seleccione opción"
                        hide-details
                        :outlined="outlined"
                        dense
                        
                        :autofocus="autofocus">
            <template v-if="slotItem" v-slot:item="data">
                <slot name="item" :item="data.item"> </slot>
            </template>
            <template v-slot:prepend-inner>
                <slot name="prepend-inner"> </slot>
            </template>
            <template v-slot:append>
            </template>

            <template v-slot:append-outer>
                <v-icon v-if="add" style="cursor:pointer;margin-top:3px;" @click="addEvent()" color="success darken-1">
                    mdi-plus-circle-outline
                </v-icon>
                <slot v-else name="append-outer"> </slot>
            </template>
        </v-autocomplete>

        <v-autocomplete clearable="" :ref="'sgeneric'+label"
                        v-if="autocomplete && !full"
                        :return-object="returnObject"
                        :item-value="itemvalue"
                        :item-text="itemtext"
                        :items="items"
                        :disabled="disabled"
                        :readonly="readonly"
                        no-filter
                        :search-input.sync="search"
                        auto-select-first
                        hide-details
                        :outlined="outlined"
                        dense 
                        @click:clear="items=[]"
                        :value="valueLocal"
                        @input="input($event)"
                        @change="change($event)"
                        placeholder="Digite opción"
                        :clear-icon-cb="onClearClicked()"
                        :autofocus="autofocus">
            <template v-if="slotItem" v-slot:item="data">
                <slot name="item" :item="data.item"> </slot>
            </template>
            <template v-slot:prepend-inner>
                <slot name="prepend-inner"> </slot>
            </template>
            <template v-slot:append>
            </template>

            <template v-slot:append-outer>
                <v-icon v-if="add" style="cursor:pointer;margin-top:3px;" @click="addEvent()" color="success darken-1">
                    mdi-plus-circle-outline
                </v-icon>
                <slot v-else name="append-outer"> </slot>
            </template>
        </v-autocomplete>
        <v-select v-if="!autocomplete"
                  :return-object="returnObject"
                  :item-value="itemvalue"
                  :item-text="itemtext"
                  :disabled="disabled"
                  :readonly="readonly"
                  :items="items"
                  :clearable="clearable"
                  :value="valueLocal"
                  @input="input($event)"
                  @change="change($event)"
                  hide-details :ref="'sgeneric'+label"
                  dense
                  :outlined="outlined"
                  placeholder="Seleccione opción"
                  :clear-icon-cb="onClearClicked()"
                  
                  :autofocus="autofocus">
            <template v-if="slotItem" v-slot:item="data">
                <slot name="item" :item="data.item"> </slot>
            </template>
            <template v-slot:prepend-inner>
                <slot name="prepend-inner"> </slot>
            </template>
            <template v-slot:append>
            </template>

            <template v-slot:append-outer>
                <v-icon v-if="add" style="cursor:pointer;margin-top:3px;" @click="addEvent()" color="success darken-1">
                    mdi-plus-circle-outline
                </v-icon>
                <slot v-else name="append-outer"> </slot>
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: "SelectG",
        components: {},
        props: {
            value: null,
            itemtext: {
                type: String,
                default: "GenDescription",
            },
            itemvalue: {
                type: String,
                default: "GenValue",
            },
            typeInput: {
                type: Boolean,
                default: false,
            },
            noDefault: {
                type: Boolean,
                default: false,
            },
            add: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            onClear:{
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            readonly: {
                type: Boolean,
                default: false,
            },
            returnObject: {
                type: Boolean,
                default: false,
            },
            id: {
                type: String,
                default: "GenValue",
            },
            autocomplete: {
                type: Boolean,
                default: false,
            },
            full: {
                type: Boolean,
                default: false,
            },
            config: {
                type: Object,
                default: null,
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
            text: {
                type: String,
                default: "",
            },
            slotItem: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: "",
            },

            outlined: {
              type: Boolean,
              default: true
            },
        },

  created() {
    if(this.value>0)
    this.tempValue=this.value;
    this.$nextTick().then(() => {
      this.valueLocal = this.value;
      this.$emit("input", this.valueLocal);
    });

    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);
    if (this.text)
      if (this.autocomplete) {
      this.search = this.text;
        this.getAnswer(true);
        return;
      }
    this.load();
  },
  watch: {
   
    valueLocal() {
      this.$emit("input", this.valueLocal);
    },
    value() {

      this.valueLocal = this.value;
      this.$emit("input", this.valueLocal);
    },
    config: {
      handler() {
        this.tempValue = this.value;

        this.valueLocal = 0;
        this.load();
      },
      deep: true,
    },
    text() {
      if (this.autocomplete) if (this.text) if (!this.full)this.search = this.text;
     if(this.text!=null||this.text!='') {this.textNull=false;}
    },
    search() {
      /* console.log('search ', this.search); */
      if (this.search) {
        if(this.search!=this.text)  {this.textNull=true;}
        if(this.search.length<3) this.items=[];  else
        this.debouncedGetAnswer();
      }
    },
  },

  data: () => ({
    tempValue: 0,
    valueLocal: null,
    search: "",
    //ComboBox
    item: null,
    items: [],
    val: "",
    textNull:false,
    //Autocomplete
    isLoading: false,
    model: null,
  }),

  methods: {
    onClearClicked(){

      if(this.onClear){
          this.$emit("onClearClicked")
      }
      
    },
    isFocused(val){
this.$refs['sgeneric'+this.label].isFocused=val;
    },
    focus(){this.$refs['sgeneric'+this.label].focus()},
    getAnswer(first) {
      if (!this.full)
        if (this.search != null)
          if (this.search.length > 2)
            if (this.autocomplete) {
              if (this.config != null) {
                if (this.config.params != null) {
                  this.params = { ...this.config.params };
                  this.params.search = this.search;
                  this.params.requestID = this.$fun.getUserID();
                } else
                  this.params = {
                    search: this.search,
                    requestID: this.$fun.getUserID(),
                  };
              }
              this.$http
                .post(
                  this.config.url,
                  {},
                  {
                    params: this.params,
                  }
                )
                .then((r) => {
                  
                  if (r.status == 200) {
                    
                    this.items = r.data;
                    this.$emit('change', this.items);
                    if (first||(this.text!=null||this.text!='')&&!this.textNull) {             
                      if (!this.returnObject) 
                      this.valueLocal = this.items.length > 0 ? this.items[0][this.itemvalue] : this.valueLocal;
                      else{ this.valueLocal = this.items.length > 0 ? this.items[0] : this.valueLocal;
                                          }
                      this.$emit("input", this.valueLocal);
                      
                    }
                  }
                });
            }
    },
    addEvent() {
      this.$emit("add");
    },
    input(value) {
    //  if(value) this.items=[];
      this.$emit("input", value);
    },
    change(value) {
      this.$emit("change", value);
    },
    load() {
      if (this.autocomplete && !this.full) {


      } else {
        if (this.config != null) {
          this.$http.post(this.config.url, {}, { params: this.config.params }).then((r) => {
            if (r.status == 200) {
             
              this.items = r.data;
              this.$emit('change', this.items);
              if (this.items.length > 0) {
                if ((this.value != 0 && this.value != null) || this.noDefault) {
                  if (this.items.find((x) => x[this.itemvalue] == this.tempValue)) {this.valueLocal = this.tempValue; }
                  else this.valueLocal = this.value;
                  this.$emit("input", this.valueLocal);
                } else {
                  if (!this.returnObject) {
                    if (this.items.find((x) => x == this.tempValue)) { this.valueLocal = this.tempValue;}
                    else this.valueLocal = this.items[0][this.itemvalue];
                  } else 
                  {let obj=null;
                  if(this.tempValue)
                     obj=this.items.find((x) => x[this.itemvalue] == this.tempValue[this.itemvalue]);
                    if (obj) this.valueLocal = obj;
                    else {
                      this.valueLocal = this.items[0];
                      if( this.noDefault) this.valueLocal = null;
                    }
                  }
                  this.$emit("input", this.valueLocal);//alert(this.valueLocal);
                }
              }
         else{this.valueLocal=null;  this.$emit("input", this.valueLocal);}
              this.$emit("updateData",this.items);
            }
          });
        }
      }
    },
    change()
    {
      this.$emit('change', this.items);
      /* console.log('gggggggggggg');
      //console.log(this.items);
      console.log(this.valueLocal);
      console.log(this.value);
      console.log(this.val);
      console.log('ggggggggggggg');
      this.$emit('change', this.itemvalue); */
    }
  },
};
</script>

<style></style>
