let children = [];
children.push({
    path: "/configuracion/circuitosaprobacion",
    name: "SEC_APPROBALCIRCUIT",
    component: () =>
        import ("../views/Security/AutorizeModel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Circuitos Aprobacion", href: "/configuracion/circuitosaprobacion" },
        ],
    },
});
children.push({
    path: "/configuracion/opcion",
    name: "SEC_OPTION",
    component: () =>
        import ("../views/Security/Option.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Opción", href: "/configuracion/opcion" },
        ],
    },
});
children.push({
    path: "/configuracion/usuario",
    name: "SEC_USER",
    component: () =>
        import ("../views/Security/UserOption.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "seguridad", disabled: true },
            { text: "usuarios", href: "/configuracion/usuario" },
        ],
    },
});

children.push({
    path: "/configuracion/aprobacionordenes",
    name: "SEC_APPROBAL_NOTIFICATION",
    component: () =>
        import ("../views/Security/Approval/ApprovalOrder.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Aprobación Ordenes", href: "/configuracion/aprobacionordenes" },
        ],
    },
});

children.push({
    path: "/configuracion/aprobacion/requerimientos",
    name: "LGS_APPROVAL_REQUIREMENT",
    component: () =>
        import ("../views/Security/Approval/ApprovalRequirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Aprobacion Req", href: "/configuracion/aprobacion/requerimientos" },
        ],
    },
});

children.push({
    path: "/seguridad/soporteTI",
    name: "RESOURCE_SUPPORT",
    component: () =>
        import ("../views/Security/OtherSupport.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "seguridad", disabled: true },
            { text: "Soporte TI", href: "/seguridad/soporteTI" },
        ],
    },
});


children.push({
    path: "/seguridad/soporteSigein",
    name: "RESOURCE_SUPPORT_SIGEIN",
    component: () =>
        import ("../views/Security/OtherSupportSigein.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "seguridad", disabled: true },
            { text: "Soporte TI", href: "/seguridad/soporteSigein" },
        ],
    },
});

children.push({
    path: "/seguridad/permisos",
    name: "SEC_PERMISSIONSCIRCUIT",
    component: () =>
        import ("../views/Security/CircuitPermissions.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Permisos", href: "/seguridad/permisos" },
        ],
    },
});



export {
    children
}