<template>
<div>
    <v-row>
        <v-col>
            <s-crud  no-full close @close="$emit('close')" searchInput @doubleClick="dobleClick" title="Contratos" :filter="filter" :config="config">
                <template v-slot:RecAttach="{ row }">
                    <v-icon v-if="row.RecAttach!==null" color="green" @click="openFile({name: row.RecAttach})">mdi-download</v-icon>
                    {{ row.RecAttach }}
                </template>

                <template v-slot:RecCorrelativeAdenda="{ row }">
                    <v-chip small color="error" v-if="row.RecCorrelativeAdenda > 0">
                    {{row.RecCorrelativeAdenda}}
                    <!-- {{row}} -->
                    </v-chip>
                </template>

                
            </s-crud>
        </v-col>
    </v-row>

</div>
</template>

<script>
import _sContract from '@/services/Legal/LglContractService'
import _sGeneral from "@/services/HelperService.js";
export default {
    name: "",
    components: {},
    props: {
        TypeBussines: null,
        AreID:null
    },

    data() {
        return {

            filter: {
                TypeBussines: this.TypeBussines,
                AreID: this.AreID
            },
            config: {
                model: {
                    RecID: "ID",
                    RecAttach:"string",
                    ReqDateBegin: "date",
                    ReqDateEnd : "date",
                    RecCorrelativeAdenda:"",
                    TypeProcessName:"string"
                },
                service: _sContract,
                headers: [{
                        text: "Codigo",
                        value: "RecID",
                        sortable: false
                    },
                    {
                        text: "Proveedor",
                        value: "ProviderName",
                        sortable: false,
                        width: 130
                    },
                    {
                        text: "Tipo",
                        value: "TypeProcessName",
                        sortable: false,
                        width: 130
                    },
                    {
                        text: "Nro. Contracto",
                        value: "ReqNroContract",
                        sortable: false,
                        width: 200,
                        align: "center"
                    },
                    {
                        text: "Descrip. Contracto",
                        value: "ReqDescription",
                        sortable: false
                    },
                     {
                        text: "Nro. Adenda",
                        value: "RecCorrelativeAdenda",
                        sortable: false,
                        align: "center"
                    },
                    {
                        text: "Archivo",
                        value: "RecAttach",
                        sortable: false,
                        width: 300,
                    },
                    {
                        text: "F. Inicio",
                        value: "ReqDateBegin",
                        sortable: false,
                    },
                    {
                        text: "F. Fin",
                        value: "ReqDateEnd",
                        sortable: false,
                    },
                   
                    
                    
                    

                    
                ],
            },
        }

    },
    methods: {
        dobleClick(item) {
            this.$emit('dblClick', item)
        },
        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 5).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },
    },
    created() {},
};
</script>
