<template>
<div>

    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="errors" @close="processing=false;messageProcessing=''" />

    <v-dialog v-if="showLocal" v-model="showLocal" :width="lg ? '1170' : md ? '940' : sm ? '728' : width" persistent :hide-overlay="hideOverlay">
        <v-card>
            <s-toolbar :label="title" :close="close" @close="$emit('close');$router.go(0)"></s-toolbar>

            <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin:auto;margin-top:0px" v-if="this.circuits.length > 1">
                <v-card-text>
                    {{ "Desea asociar Proceso a Circuito: " }}
                    <h4>{{selectedCircuit.length > 0 ? selectedCircuit[0].AccName : '' }}</h4> {{" ?. Caso contrario puede realizar el proceso mas tarde."}}
                </v-card-text>
                <v-card outlined elevation="0" class="pb-4" width="100%">
                    <v-row style="">
                        <v-col cols="12" :lg="this.filterLevels.AccID != null ? 7: 12" :md="this.filterLevels.AccID != null ? 7: 12">
                            <s-toolbar :label="'Circuitos'"></s-toolbar>
                            <v-data-table dense item-key="AccID" :items="circuits" height="auto" :headers="[
                    { value: 'AccID', text: 'ID', width: 50 , align: 'center'},
                    { value: 'AccName', text: 'Circuito' },
                  ]" @dblclick:row="createCircuit" v-model="selectedCircuit" @click:row="rowClickCircuit" :items-per-page="-1" hide-default-footer disable-sort />
                        </v-col>
                        
                        <v-col cols="12" lg="5" md="5">
                            <s-crud noFooter noSearch height="auto" v-if="this.filterLevels.AccID != null" :config="configLevels" :filter="filterLevels" title="Niveles" />
                        </v-col>
                    </v-row>
                </v-card>
            </v-row>
            <v-row v-else>
                <v-card  elevation="0" class="pb-4 pl-4 mt-4">
                    <v-card-text>
                        {{ "Desea asociar Proceso a Circuito: " }}
                        <h4>{{circuits[0].AccName }}</h4> {{" ?. Caso contrario puede realizar el proceso mas tarde."}}
                    </v-card-text>
                    <v-card-actions>
                        <v-col cols="6">
                        
                            <v-btn class="mt-4" variant="gradient" color="success" fullWidth size="lg" @click="associate()">Asociar a Circuito</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn class="mt-4" variant="gradient" color="" fullWidth size="lg" @click="$emit('close');$router.go(0);">Cancelar</v-btn>
                        </v-col>
                    </v-card-actions>
                </v-card>
            </v-row>

           <!--  <vc-footer></vc-footer> -->
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sTransaction from "@/services/Security/ApprovalTransactionService";

export default {
    name: "vcModalDialog",
    props: {
        title: {
            type: String,
            default: "Circuitos de Aprobacion",
        },
        close: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: !localStorage.getItem('persistent'),
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },

        //Tamaños
        xs: {
            type: Boolean,
            default: true,
        },
        sm: {
            type: Boolean,
            default: false,
        },
        md: {
            type: Boolean,
            default: false,
        },
        lg: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 500,
        },
        dialog: false,
        circuits: {
            type: Array,
            default: []
        },
        TrsID: null
    },

    data() {
        return {
            selectedCircuit: [],
            filterLevels: {},
            configLevels: {
                model: {
                    AccID: "int",
                    AlvID: "ID",
                    SecStatus: "boleam",
                    PstID: "int",
                    PstName: "string",
                },
                service: _sApprovalLevel,
                headers: [{
                        text: "Cargo",
                        value: "PstName"
                    },
                    {
                        text: "Nivel",
                        value: "TypeLevelText"
                    },
                ],
            },
            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],
        };
    },
    computed: {
        showLocal: {
            get: function () {
                return this.dialog
            },
            set: function (value) {
                this.$emit('close')
            }
        },
    },

    methods: {
        rowClickCircuit: function (item, row) {
            this.selectedCircuit = [item];
            this.filterLevels = {
                AccID: item.AccID
            };
        },

        createCircuit() {

            /* if (this.serviceTransaction !== null) { */
            this.$fun
                .alert("Desea asociar Requerimiento a Circuito: " + this.selectedCircuit[0].AccName + " ?. Caso contrario puede realizar el proceso mas tarde.", "question")
                .then((r) => {
                    if (r.value) {

                        this.messageProcessing = ""
                        this.processing = true

                        this.selectedCircuit[0].TypeApproval = this.TrsID
                        this.selectedCircuit[0].UsrID = this.$fun.getUserID()
                        this.selectedCircuit[0].UsrCreateID = this.$fun.getUserID()
                        this.selectedCircuit[0].Status = 4
                        this.selectedCircuit[0].SecStatus = 3

                        console.log("save", this.selectedCircuit[0]);

                        _sTransaction
                            .createCircuit(this.selectedCircuit[0], this.$fun.getUserID())
                            .then(
                                (j) => {
                                    this.messageProcessing = ""
                                    this.processing = false;

                                    this.$fun.alert("Guardado Correctamente", "success");
                                    this.$router.go(0)
                                },
                                (e) => {
                                    /* let errors = e.response.data.Errors
                                    let message = e.response.data.Message
                                    let error = e.response.data.errors
                                    this.errors = errors == null ? error == null ? message == null ? "" : message : error.join(',') : errors.join(',');
 */
                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = true;
                                }
                            );
                    }
                });
            /* } else {
                this.$emit('createCircuit', this.selectedCircuit)
            } */
        },

        associate() {

            /* if (this.serviceTransaction !== null) { */
            this.$fun
                .alert("Desea de Continuar: " + this.circuits[0].AccName + " ?.", "question")
                .then((r) => {
                    if (r.value) {

                        this.messageProcessing = ""
                        this.processing = true

                        this.circuits[0].TypeApproval = this.TrsID
                        this.circuits[0].UsrID = this.$fun.getUserID()
                        this.circuits[0].UsrCreateID = this.$fun.getUserID()
                        this.circuits[0].Status = 4
                        this.circuits[0].SecStatus = 3

                        console.log("save", this.circuits[0]);

                        _sTransaction
                            .createCircuit(this.circuits[0], this.$fun.getUserID())
                            .then(
                                (j) => {
                                    this.messageProcessing = ""
                                    this.processing = false;

                                    this.$fun.alert("Guardado Correctamente", "success");
                                    this.$router.go(0)
                                },
                                (e) => {
                                    /* let errors = e.response.data.Errors
                                    let message = e.response.data.Message
                                    let error = e.response.data.errors
                                    this.errors = errors == null ? error == null ? message == null ? "" : message : error.join(',') : errors.join(','); */

                                    this.messageProcessing = e.response.data.Message;
                                    this.processing = true;
                                }
                            );
                    }
                });
            /* } else {
                this.$fun
                    .sweetAlert("Error al intentar asociar circuito.", "warning")
            } */

        }
    },
    destroyed() {},

};
</script>

<style lang="scss" scoped></style>
