<template>
<v-card dense>
    <v-row justify="center" v-show="items.length > 0">
        <v-col cols="12">
            <div :id="'containerBarColumn' + id"></div>
        </v-col>
    </v-row>
    <v-row justify="center" style="margin:auto" v-show="items.length == 0">
        <v-col cols="12">
            <div :id="'containerBarColumn' + id">NO SE HA CARGADO DATA PARA GENERAR REPORTE GRAFICO </div>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
import Highcharts from "highcharts";

import exportingInit from 'highcharts/modules/exporting'
import stockInit from 'highcharts/modules/stock'
import exportdataInit from 'highcharts/modules/export-data'
import accessibilityInit from 'highcharts/modules/accessibility'
import drilldownInit from 'highcharts/modules/drilldown'

stockInit(Highcharts)
exportingInit(Highcharts)
exportdataInit(Highcharts)
accessibilityInit(Highcharts)
drilldownInit(Highcharts)

export default ({
    props: {
        items: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: 'Titulo'
        },

        visibleTitle: {
            type: Boolean,
            default: false
        },

        subtitle: {
            type: String,
            default: 'Subtitulo'
        },
        visibleSubTitle: {
            type: Boolean,
            default: false
        },

        valueSuffix: {
            type: String,
            default: ''
        },
        colorByPoint: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: null
        },
        dataLabels: {
            type: Boolean,
            default: true
        },

        pointZ: {
            type: Boolean,
            default: false
        },
        pointColor: {
            type: Boolean,
            default: false
        },

        id: {
            type: Number,
            default: 1
        },
        number: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        load() {
            Highcharts.chart('containerBarColumn' + this.id, {
                chart: {
                    type: 'column'
                },
                title: {
                    align: 'center',
                    text: this.visibleTitle == true ? '<span style="font-size:12px; font-weight: bold; color: rgb(66, 14, 47);">' + this.title + '</span><br>' : ''
                },
                subtitle: {
                    align: 'left',
                    text: this.visibleSubTitle == true ? '<span style="font-size:10px; font-weight: bold; color: rgb(56, 44, 7);">' + this.subtitle + '</span><br>' : ''
                },

                xAxis: {
                    categories: [
                        '01',
                        '02',
                        '03',
                        '04',
                        '05',
                        '06',
                        '07',
                        '08',
                        '09',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                        '21',
                        '22',
                        '23',
                        '24',
                        '25',
                        '26',
                        '27',
                        '28',
                        '29',
                        '30',
                        '31',
                    ],
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Rainfall (mm)'
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: [{
                    name: '',
                    data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]

                }, {
                    name: 'Q2',
                    data: [83.6, 78.8, 98.5, 93.4, 22]

                }, {
                    name: 'Q3',
                    data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3, 51.2, 54.4]

                }, {
                    name: 'Berlin',
                    data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 39.1, 46.8, 51.1]

                }]

            });
        }
    },

    mounted() {
        this.load()

    },
    watch: {
        items() {
            this.load()
        },

    }

})
</script>

<style>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
    min-width: 50px;
}
</style>
