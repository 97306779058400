import Service from "../Service";

const resource = "orderpurchase/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID, AccID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },

    saveServed(obj, requestID) {
        return Service.post(resource + "saveServed", obj, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byId", parameters, {
            params: { requestID: requestID },
        });
    },

    byParcialID(parameters, requestID) {
        return Service.post(resource + "byParcialID", parameters, {
            params: { requestID: requestID },
        });
    },

    byTypeCurency(obj, requestID) {
        return Service.post("amountgeneratecontract/" + "byTypeCurency", obj, {
            params: { requestID: requestID },
        });
    },

    setStatus(ord, requestID) {
        return Service.post(resource + "setStatus", ord, {
            params: { requestID: requestID },
        });
    },

    declineOrd(ord, requestID) {
        return Service.post(resource + "declineord", ord, {
            params: { requestID: requestID },
        });
    },

    sendMailProvider(obj, requestID) {
        return Service.post(resource + "sendMailProvider", obj, {
            params: { requestID: requestID },
        });
    },

    PDF(dtr, requestID) {
        return Service.post(resource + "pdforderpurcharse", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    excel(dtr, requestID) {
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    saveRegister(obj, requestID) {
        return Service.post(resource + "registercomp", obj, {
            params: { requestID: requestID },
        });
    },

    saveAcceptFile(obj, requestID) {
        return Service.post(resource + "acceptfile", obj, {
            params: { requestID: requestID },
        });
    },



};