<template>
	<div style="padding-top:-2px;margin-top:-2px;">
		<v-label
			><b>{{ label }}</b></v-label
		>
		<v-select
			height="40"
			class="text"
			v-if="!autocomplete"
			ref="text"
			@blur="handleBlur"
			:readonly="readonly"
			:return-object="returnObject"
			:disabled="disabled"
			dense
			placeholder=" "
			:outlined="outlined"
			:dark="dark"
			max="1"
			hide-details="false"
			:items="items"
			:item-value="itemValue"
			:item-text="itemText"
			:autofocus="autofocus"
			aria-autocomplete="off"
			autocomplete="true"
			:maxlength="max"
			:color="color"
			:value="value"
			@click="click()"
			@input="inputText($event)"
			@keyup.enter="enter"
			@keypress="validation($event)"
			:type="type"
			:clearable="clearable"
			@click:clear="clickClear"
		>
		</v-select>
		<v-autocomplete
			v-else
			height="40"
			class="text"
			ref="text"
			@blur="handleBlur"
			:readonly="readonly"
			:return-object="returnObject"
			:disabled="disabled"
			dense
			placeholder=" "
			outlined
			:dark="dark"
			max="1"
			hide-details="false"
			:items="items"
			:item-value="itemValue"
			:item-text="itemText"
			:autofocus="autofocus"
			aria-autocomplete="off"
			autocomplete="true"
			:maxlength="max"
			:color="color"
			:value="value"
			@click="click()"
			@input="inputText($event)"
			@keyup.enter="enter"
			@keypress="validation($event)"
			:type="type"
			:clearable="clearable"
		>
			<template v-if="slotTmp" v-slot:item="data">
                <slot name="item" :item="data.item"> </slot>
            </template>
		</v-autocomplete>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
			items: null,
			value: null,
			outlined: { type: Boolean, default: true },
			color: { type: String, default: "primary" },
			label: { type: String, default: "" },
			itemValue: { type: String, default: "value" },
			itemText: { type: String, default: "text" },
			max: { type: String, default: "256" },
			width: { type: String, default: "default" },
			type: { type: String, default: "text" },
			dark: { type: Boolean, default: false },
			returnObject: { type: Boolean, default: false },
			autofocus: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			readonly: { type: Boolean, default: false },
			number: { type: Boolean, default: false },
			autocomplete: { type: Boolean, default: false },
			decimal: { type: Boolean, default: false },
			clearable: { type: Boolean, default: false },
			search: { type: Boolean, default: false },
			slotTmp: {type: Boolean, default: false}
		},
		watch: {
			config() {},
			value() {
				this.$emit("input", this.value);
			}
		},
		methods: {
			click() {
				this.$emit("click");
			},
			handleBlur() {
				this.$emit("blur");
			},
			focus() {
				this.$refs.text.focus();
			},
			validation(evt) {
				if (this.number || this.type == "number") {
					evt = evt ? evt : window.event;
					var charCode = evt.which ? evt.which : evt.keyCode;
					if (charCode > 31 && (charCode < 48 || charCode > 57)) {
						evt.preventDefault();
					} else {
						return true;
					}
				}

				if (this.decimal) {
					evt = evt ? evt : window.event;
					var charCode = evt.which ? evt.which : evt.keyCode;
					if (
						charCode > 31 &&
						(charCode < 48 || charCode > 57) &&
						charCode != 46
					) {
						evt.preventDefault();
					} else {
						return true;
					}
				}
			},
			inputText(item) {
				this.$emit("input", item);
			},
			clickClear() {
				this.$emit("clickClear");
			},
			enter() {
				this.$emit("keyupEnter");
			},
			getAnswer() {
				this.$emit("input", this.value);
			}
		},
		mounted() {
			this.$emit("input", this.value);
		},
		created() {
			this.debouncedGetAnswer = _.debounce(this.getAnswer, 220);
			this.item = this.value;
		},
		data: () => ({
			//ComboBox
			//
			item: ""
		})
	};
</script>
<style >
</style>
