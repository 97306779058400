<template>
<div>
    <s-text :readonly=readonly :disabled=disabled label="Contratos" @click="dialog=true" v-model="textDescription" :outlined="outlined" />
    <v-dialog width="80%" v-model="dialog" v-if=dialog transition="dialog-bottom-transition">
        <c-contract style="overflow: hidden !important; " @dblClick="dblClick($event)" @close="dialog=false" :TypeBussines="TypeBussines" :AreID="AreID"></c-contract>
    </v-dialog>

</div>
</template>

<script>
import cContract from '@/components/Legal/cContract.vue'
export default {
    components: {
        cContract
    },
    props: {
        value: null,
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outlined:{
            type: Boolean,
            default: true
        },
        TypeBussines: null,
        AreID:null


    },
    data() {
        return {
            contract: null,
            textDescription: '',
            dialog: false,
        };
    },
    methods: {
        dblClick(item) {
            this.contract = item;
            this.$emit('input', item);
            this.textDescription = item.ReqNroContract;
            this.dialog = false;
        }
    },
    created() {

        console.log("eeeeeeeeeeeeeeeeeeeeee",this.value)
        this.contract = this.value;
        if (this.contract != null) {
            this.textDescription = this.contract.ReqNroContract;
        }
    },
    
    watch: {
        value() {
            this.textDescription = this.value.ReqNroContract;

        }
    },
};
</script>
