let children = []

children.push({
    path: "/pagospendientes",
    name: "PAY_SERACHPAYMENT",
    component: () =>
        import ("../views/PaymentEvolta/PaySearchPayment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Pagos", disabled: true },
            { text: "Pagos pendientes", href: "/pagospendientes" },
        ],
    },
});





export { children }