<template>
<div>
    <v-dialog v-if="processing" v-model="processing" :persistent="messageProcessing.length==0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere ...
                <v-progress-linear indeterminate color="white" class="mb-0 mt-2"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error.. <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-card elevation="0" width="300">
        <v-card-title>
            Configuración
        </v-card-title>

        <v-tabs background-color="transparent" grow v-model="tab">
            <v-tab href="#tab-0">
                General
            </v-tab>
            <v-tab href="#tab-1" v-if="user.NtpID > 0">
                Firma
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item :value="'tab-0'">
                <v-card outlined class="text-center">
                    <v-list v-if="user.NtpID > 0" class="mx-auto">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    {{ user.NtpName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ user.NtpPaternalSurname }}
                                    {{ user.NtpMaternalSurname }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-img v-if="personImage != null" contain :src="personImage" height="200"></v-img>
                        <v-list-item v-if="user.CtrContract != null">
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    {{ user.CtrContract == null ? "" : user.CtrContract.PstName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ user.CtrContract == null ? "" : user.CtrContract.AreName }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-card-actions>
                        <v-btn elevation="0" small color="primary" block @click="logout">
                            Cerrar sesión
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-1'" v-if="user.CtrContract !== null">
                <v-card outlined class="text-center">
                    <v-list v-if="user.NtpID > 0" class="mx-auto">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="headline">
                                    <v-row align="center">
                                        <v-col lg="10" cols="10">
                                            <v-file-input :accept="'.png, .jpg'" v-model="AttachImg" label="Firma" hide-details />
                                        </v-col>
                                        <v-col lg="2" cols="2">
                                            <v-btn color="success" class="mt-5" icon x-small @click="openFile(AttachImg)">
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-img v-if="personImageFirma != null" contain :src="personImageFirma" height="200"></v-img>
                    </v-list>
                    <v-card-actions>
                        <v-btn v-if="user.NtpID > 0" elevation="0" small color="#5175af" dark block @click="saveImage()">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</div>
</template>

<script>
import _sGeneral from "@/services/HelperService.js";
import _userService from '@/services/Security/UserService.js'

export default {

    props: {
        activate: {
            type: Boolean,
            default: false,
        },
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
        personImage: null,
        personImageFirma: null
    },
    data: () => ({
        tab: null,
        AttachImg: null,
        deleteFirma: false,
        processing: false,
        messageProcessing: "",
    }),

    mounted() {
        if(this.user.CtrContract !== null)
            this.AttachImg = {
                name: this.user.CtrContract.AttachImg
            };

        this.processing = false
    },

    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 2).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        uploadFile() {
            let files = [];

            if (this.AttachImg) files.push(this.AttachImg);

            var formData = new FormData();
            let i = 0;
            files.forEach((element) => {
                formData.append(i.toString(), element);
                i++;
            });
            _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 4);
        },

        logout() {
            this.$emit("getLogout");
        },

        saveImage() {
            if (this.AttachImg == null) {
                this.$fun.alert("Seleccione una firma", "warning")
                return
            }

            this.processing = true
            _userService.updateUserFirma(this.$fun.getUserID(), this.AttachImg.name).then(resp => {
                    if (resp.status == 200) {
                        this.uploadFile()
                        this.$fun.alert("Actualizado correctamente", "success")
                        this.$router.go(0)
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        }
    },
};
</script>
