import Service from "../Service";

const resource = "payment/";

export default {
    search(Dni, requestID) {
        return Service.post(resource + "search", "", {
            params: { requestID: requestID, Dni: Dni },
        });
    },

    searchSchedule(search, state, requestID) {
        return Service.post(resource + "searchSchedule", "", {
            params: { requestID: requestID, search: search, state: state },
        });
    },

    generatedToken(requestID) {
        return Service.post(resource + "generatedToken", "", {
            params: { requestID: requestID },
        });
    },

    createdLinkPayment(requestID, Authorization, obj) {
        return Service.post(resource + "createdLinkPayment", obj, {
            params: { requestID: requestID, Authorization: Authorization },
        });
    },

    savePagoLink(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    stateLinkPayment(requestID, Authorization, orderId) {
        return Service.post(resource + "stateLinkPayment", {}, {
            params: { requestID: requestID, Authorization: Authorization, orderId: orderId },
        });
    },

    createAccount(obj) {
        return Service.post(resource + "createAccount", obj, {
            params: {},
        });
    }
};