<template>
  <div>
    <s-text
      v-model="valueModel"
      :label="label"
      :readonly="readonly"
      :disabled="disabled"
      :clearable="clearable"
      @close="close"
      :dark="dark"
      @blur="blur"
      @click="click"
      @input="input"
      @keyup.enter="enter"
      type="tel" :outlined=outlined
      :mask="formatMask"
      :placeholder="formatView"
      :search="true"
    >
      <!--  -->
      <template>
        <v-menu
          :disabled="disabled"
          v-if="!noPicker"
          slot="append"
          :close-on-content-click="false"
          v-model="visiblePicker"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mt-1">mdi-calendar</v-icon>
          </template>
          <v-date-picker  v-if="date" locale="es" v-model="valuePicker" :min=min :max=max @input="inputPicker" />
          <v-datetime-picker v-else locale="es" v-model="valuePicker" @input="inputPicker" />
        </v-menu>
      </template>
    </s-text>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "Fecha" },
    min: { type: String, default: "" },
    max: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    nullable: { type: Boolean, default: false },
    outlined: { type: Boolean, default: true },
    dark: { type: Boolean, default: false },
    noPicker: { type: Boolean, default: false },
    value: null,
    type: {
      type: String,
      default: "date",
      validator: function(val) {
        return ["date", "datetime"].indexOf(val) !== -1;
      },
    },
  },
  data() {
    return {
      valueModel: null,
      valuePicker: null,

      visiblePicker: false,
      formatMask: "",
      formatDB: "",
      formatView: "",
      date: false,
      datetime: false,
    };
  },
  created() {
    this.initialize();
    //this.$nextTick(function() {
      if (!this.clearable && !this.nullable&&this.$fun.isEmptyVal(this.value)) {
       
  let value = this.moment();
        this.valuePicker = value.format(this.formatDB);
        this.valueModel = value.format(this.formatView);
//alert(this.valuePicker);
        this.$emit("input", this.valuePicker);
        
      
      } else {
        this.changeValue();
      }
  //  });
  
  },
  computed: {},
  methods: {
    close()
    {
      this.$emit("close");
    },
    initialize() {
      this.date = false;
      this.datetime = false;

      if (this.type === "date") {
        this.date = true;
        this.formatMask = this.$const.FormatDateMask;
        this.formatDB = this.$const.FormatDateDB;
        this.formatView = this.$const.FormatDateView;
      }
      if (this.type === "datetime") {
        this.datetime = true;
        this.formatMask = this.$const.FormatDateTimeMask;
        this.formatDB = this.$const.FormatDateTimeDB;
        this.formatView = this.$const.FormatDateTimeView;
      }
    },
    click(event) {
      this.$emit("click", event);
    },
    blur(event) {
      this.$emit("blur", event);
    },
    enter(event) {
      this.$emit("enter", event);
    },
    inputPicker() {
      this.valueModel = this.moment(this.valuePicker, this.formatDB).format(this.formatView);
      this.$emit("input", this.valuePicker);
      this.visiblePicker = false;
    },
    input() {
      if (this.valueModel != null)
        if ((!this.valueModel.includes("T") || !this.valueModel.includes("-")) && this.valueModel.length >= this.formatMask.length) {         
          let x = this.moment(this.valueModel, this.formatView);
          if (x.isValid()) this.$emit("input", x.format(this.formatDB));
          return;
        }else{
          this.$emit("input", '');
        }
      if (this.clearable) this.$emit("input", null);
    },

    changeValue() {
      if (this.value != null) {
        if (!this.value.includes("T") && !this.value.includes("-") && this.value.length == this.formatMask.length) {
          let val = this.moment(this.value, this.formatView);
          if (val.isValid()) { 
            this.valuePicker = val.format(this.formatDB);
            this.$emit("input", this.valuePicker);
          } else {
            if (this.clearable || this.nullable) {
              this.valuePicker = null;
              this.$emit("input", this.valueModel);
            }
          }
        } else {
          if ((this.value.includes("T") || this.value.includes("-")) && this.value.length >= this.formatMask.length) {       
            this.valueModel = this.moment(this.value, this.formatDB).format(this.formatView);
            this.valuePicker = this.moment(this.value, this.formatDB).format(this.formatDB);
          }
        }
      }else {
        if(!this.clearable){
 let value = this.moment();
        this.valuePicker = value.format(this.formatDB);
        this.valueModel = value.format(this.formatView);
        //alert(this.valuePicker);
        this.$emit("input", this.valuePicker);
        }
      
        
      }
    },
  },
  watch: {
    value() {
      this.changeValue();
    },
    type() {
      this.initialize();
    },
  },
};
</script>
