let children = [];
children.push({
    path: "/logistica/ordenes",
    name: "LGS_ORDER",
    component: () =>
        import ("../views/Logistics/OrderPurchase/Orders.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Orden de Compra", href: "/logistica/ordenes" },
        ],
    },
});
children.push({
    path: "/logistica/documentos",
    name: "LGS_RECEPTIONPAYMENT",
    component: () =>
        import ("../views/Logistics/OrderPurchase/LgsOrderComp.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes", href: "/logistica/documentos" },
        ],
    },
});


children.push({
    path: "/logistica/Requerimientos/Compras",
    name: "LGS_PURCHASE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/LgsRequirementPurchase.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Compras", href: "/logistica/Requerimientos/Compras" },
        ],
    },
});

children.push({
    path: "/logistica/ordenpedido/atencioncompra",
    name: "LGS_PURCHASE_ORDER_ATTENTION",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderPurcharseAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Atencion de Compra", href: "/logistica/ordenpedido/atencioncompra" },
        ],
    },
});


children.push({
    path: "/logistica/panel",
    name: "Lgs_INDEX",
    component: () =>
        import ("../views/Logistics/LgsIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Panel ORD", href: "/logistica/panel" },
        ],
    },
});

children.push({
    path: "/logistica/panelreq",
    name: "LGS_INDEXREQ",
    component: () =>
        import ("../views/Logistics/LgsIndexReq.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Panel REQ", href: "/logistica/panelreq" },
        ],
    },
});

children.push({
    path: "/logistica/atencionordenes",
    name: "LGS_ORDERAttended",
    component: () =>
        import ("../views/Logistics/OrderAttended/OrderAttended.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Orden de Compra", href: "/logistica/atencionordenes" },
        ],
    },
});

children.push({
    path: "/logistica/cotizaciones",
    name: "LGS_COTIZATIONS",
    component: () =>
        import ("../views/Logistics/OrderCotization/OrderCotization.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Cotizaciones", href: "/logistica/cotizaciones" },
        ],
    },
});

children.push({
    path: "/logistica/despacho",
    name: "LGS_ORD_OUTPUT",
    component: () =>
        import ("../views/Logistics/OrdOutput/OrdOutputProduct.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Despacho", href: "/logistica/despacho" },
        ],
    },
});

children.push({
    path: "/logistica/proveedores",
    name: "LGS_PROVIDER",
    component: () =>
        import ("../views/Logistics/LgsProvider.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Proveedores", href: "/logistica/proveedores" },
        ],
    },
});

children.push({
    path: "/logistica/configuracioncentrodecosto",
    name: "LGS_CCOSTEAREA",
    component: () =>
        import ("../views/Logistics/LgsCenterCosteAre.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Centro de Costo", href: "/logistica/configuracioncentrodecosto" },
        ],
    },
});

children.push({
    path: "/logistica/dashboard",
    name: "LGS_DASHBOARD",
    component: () =>
        import ("../views/Logistics/Dashboard/LgsDashboardIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Dashboard", href: "/logistica/dashboard" },
        ],
    },
});

children.push({
    path: "/logistica/articulos",
    name: "LGS_ARTICLES",
    component: () =>
        import ("../views/Logistics/Articles/LgsArticles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Articulos", href: "/logistica/articulos" },
        ],
    },
});

children.push({
    path: "/logistica/bandejaentrada/panel",
    name: "LGS_PANELENTRADA",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderPurcharseAttentionPanel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Bandeja Entrada", href: "/logistica/bandejaentrada/panel" },
        ],
    },
});









/* 
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_LOCATIONS",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos/Servicios",
    name: "LGS_SERVICE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementServices.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Servicios", href: "/logistica/Requerimientos/Servicios" },
        ],
    },
});

children.push({
    path: "/logistica/Requerimientos/Pedido",
    name: "LGS_WAREHOUSE_REQUIREMENT",
    component: () =>
        import ("../views/Logistics/Requirement/RequirementWareHouse.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Pedido", href: "/logistica/Requerimientos/Pedido" },
        ],
    },
});
children.push({
    path: "/logistica/Requerimientos_Servicios/Atención",
    name: "LGS_SERVICE_ATTENTION",
    component: () =>
        import ("../views/Logistics/Requirement/ServiceAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Requerimientos", disabled: true },
            { text: "Servicios", href: "/logistica/Requerimientos_Servicios/Atención" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_REQUIREMENT_ARTICLE",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/consultas/ubicaciones",
    name: "LGS_REQUIREMENT_ORDER",
    component: () =>
        import ("../views/Logistics/ConsultingSAP/Locations.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Consultas SAP", disabled: true },
            { text: "Ubicaciones", href: "/logistica/consultas/ubicaciones" },
        ],
    },
});
children.push({
    path: "/logistica/ordenpedido/atencionpedido",
    name: "LGS_ORDER_STORE_ATTENTION",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderStoreAttention.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Atencion", href: "/logistica/ordenpedido/atencionpedido" },
        ],
    },
});
children.push({
    path: "/logistica/permisos",
    name: "LGS_PERMIT",
    component: () =>
        import ("../views/Logistics/Permits/Permit.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Permisos", href: "/logistica/permisos" },
        ],
    },
});
children.push({
    path: "/logistica/ordenpedido/solicitudpedido",
    name: "LGS_ORDER_STORE_REQUEST",
    component: () =>
        import ("../views/Logistics/OrderStore/OrderStoreRequest.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Ordenes Pedido", disabled: true },
            { text: "Solicitud de Pedido", href: "/logistica/ordenpedido/solicitudpedido" },
        ],
    },
});

children.push({
    path: "/logistica/cotizaciones",
    name: "LGS_QUOTE",
    component: () =>
        import ("../views/Logistics/Quote/Quote.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Cotizaciones", href: "/logistica/cotizaciones" },
        ],
    },
});
children.push({
    path: "/logistica/articulos/asignacion",
    name: "LGS_VARTICLES",
    component: () =>
        import ("../views/Logistics/Articles/VArticles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Articulos", href: "/logistica/articulos/asignacion" },
        ],
    },
});
children.push({
    path: "/logistica/MantenimientoArticulos",
    name: "LGS_MAINTENANCEVARTICLES",
    component: () =>
        import ("../views/Logistics/Articles/MaintenanceArticles.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Logistica", disabled: true },
            { text: "Articulos", href: "/logistica/MantenimientoArticulos" },
        ],
    },
}); */
export { children }