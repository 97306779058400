<template>
<v-app class="app scroll">
    <router-view></router-view>
</v-app>
</template>

<script>
export default {
    name: "App",
    data: () => ({
        timeInactiveShow: 0
    }),
    created() {},

    mounted() {},
    methods: {},
};
</script>

<style scoped>
/*  */
@import "./styles/app.css";
    
</style>
