let children = [];

children.push({
    path: "/marketing/requerimiento",
    name: "MKT_REQUIREMENT",
    component: () =>
        import ("../views/Marketing/Requirement/MktRequirement.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Requerimiento", href: "/marketing/requerimiento" },
        ],
    },
});
children.push({
    path: "/configuracion/circuitosaprobacionmark",
    name: "MKT_APPROBALCIRCUIT",
    component: () =>
        import ("../views/Marketing/MktAutorizeModel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Circuitos Aprobacion", href: "/configuracion/circuitosaprobacionmark" },
        ],
    },
});

children.push({
    path: "/marketing/panel",
    name: "MKT_PANEL",
    component: () =>
        import ("../views/Marketing/Requirement/MktPanel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Panel", href: "/marketing/panel" },
        ],
    },
});
children.push({
    path: "/marketing/panelpubli",
    name: "MKT_PANELPUBLI",
    component: () =>
        import ("../views/Marketing/Panels/MktPanelPubli.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Paneles Publicitarios", href: "/marketing/panelpubli" },
        ],
    },
});

children.push({
    path: "/marketing/mantpanel",
    name: "MKT_MANTPANEL",
    component: () =>
        import ("../views/Marketing/Panels/MktMantPanelPubli.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Marketing", disabled: true },
            { text: "Mant. Paneles Publicitarios", href: "/marketing/mantpanel" },
        ],
    },
});


export {
    children
}