import Service from "../Service";

const resource = "webimp/referrals/";

export default {
    save(obj, requestID) {
        return Service.post(resource + "savereferrals", obj, {
            params: { requestID: requestID },
        });
    },

    definitiongroupURL(defID, dgrID, requestID) {
        return Service.post(resource + "definitiongroupextern", "", {
            params: { defID: defID, dgrID: dgrID, requestID: requestID },
        });
    },


    /* definitiongroupURL() {
        return Service.post(resource + "definitiongroupextern", "", {});
    }, */
};