<template>
<div>
    <!-- <div style="background:white">
        <h2>Search and add a pin</h2>
        <GmapAutocomplete @place_changed='setPlace' />
        <button @click='addMarker'>
            Add
        </button>
    </div>
    <br> -->
    <!-- <gmap-map :options="{
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
    disableDefaultUi: true
    }" :center="center" :zoom="16" style="width: 100%; height: 500px" map-type-id="terrain" ref="mapRef">
        <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :title="m.title" :clickable="true" :draggable="true" @click="center=m.position"></gmap-marker>

    </gmap-map> -->
    <gmap-map :center="center" :zoom="16" style="width: 100%; height: 500px" :map-type-id="vision" ref="mapRef">
         <gmap-marker 
            :key="index" 
            v-for="(m, index) in markers" 
            :position="m.position" 
            :title="m.title" 
            :clickable="true" 
            :draggable="true" 
            @click="center=m.position"></gmap-marker>
        <!-- <GMapCluster> -->

            <!-- <gmap-marker :key="index" :clickable="true" :draggable="true" :title="m.title" v-for="(m, index) in markers" :position="m.position" @click="center=m.position"></gmap-marker> -->
        <!-- </GMapCluster> -->
    </gmap-map>

</div>
</template>

<script>
export default {
    data() {
        return {

            center: {
                lat: this.latitude,
                lng: this.longitude
            },

            /* center: {
                lat: 45.508,
                lng: -73.587
            },
            currentPlace: null,
            markers: [],
            places: [], */

             markers: [{
                 position: {
                     lat: this.latitude,
                     lng: this.longitude
                 },
                 title: this.title
             }],

        }
    },
    mounted() {

        //https://www.google.com/maps/place/5%C2%B010'25.1%22S+80%C2%B036'26.6%22W/@-5.1736297,-80.6095757,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-5.173635!4d-80.607387!5m1!1e4?hl=es
        // At this point, the child GmapMap has been mounted, but
        // its map has not been initialized.
        // Therefore we need to write mapRef.$mapPromise.then(() => ...)
        //this.geolocate();
        var map = null
        this.$refs.mapRef.$mapPromise.then((map) => {
            console.log("map",map)
            
           
            
        })
        
       /*  this.$refs.mapRef.$mapPromise.then((map) => {
            console.log("options", map)
        }) */

        /* console.log("map", this.$refs.mapRef) */
    },
    methods: {
        /* setPlace(place) {
            this.currentPlace = place;
        },
        addMarker() {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({
                    position: marker
                });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
            }
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
            });
        }, */
    },

    props: {
        latitude: Number,
        longitude: Number,
        title: String,
        vision: {
            type:String,
            default: "terrain" //terrain
        }
    }
}
</script>
