<template>
<v-card outlined>
    <v-row justify="center" v-show="items.length > 0">
        <v-col cols="12">
            <div :id="'containerPieDrill' + id"></div>
        </v-col>
    </v-row>
    <v-row justify="center" style="margin:auto" v-show="items.length == 0">
        <v-col cols="12" >
            <div :id="'containerPieDrill' + id">NO SE HA CARGADO DATA PARA GENERAR REPORTE GRAFICO </div>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
import Highcharts from "highcharts";

import exportingInit from 'highcharts/modules/exporting'
import stockInit from 'highcharts/modules/stock'
import exportdataInit from 'highcharts/modules/export-data'
import accessibilityInit from 'highcharts/modules/accessibility'
import drilldownInit from 'highcharts/modules/drilldown'

stockInit(Highcharts)
exportingInit(Highcharts)
exportdataInit(Highcharts)
accessibilityInit(Highcharts)
drilldownInit(Highcharts)

export default ({
    props: {
        items: {
            type: Array,
            default: []
        },
        objDrilldown: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: 'Titulo'
        },

        visibleTitle: {
            type: Boolean,
            default: false
        },

        subtitle: {
            type: String,
            default: 'Subtitulo'
        },
        visibleSubTitle: {
            type: Boolean,
            default: false
        },

        valueSuffix: {
            type: String,
            default: ''
        },
        colorByPoint: {
            type: Boolean,
            default: true
        },
        name: {
            type: String,
            default: null
        },
        dataLabels: {
            type: Boolean,
            default: true
        },
        id:{type: Number, default: 1}
    },
    methods: {
        load() {
            Highcharts.chart('containerPieDrill' + this.id, {
                chart: {
                    type: 'pie'
                },
                title: {
                    text: this.visibleTitle== true ? '<span style="font-size:12px; font-weight: bold; color: rgb(66, 14, 47);">' + this.title + '</span><br>' : ''
                    //text: null
                },
                subtitle: {
                   text: this.visibleSubTitle== true ? '<span style="font-size:10px; font-weight: bold; color: rgb(56, 44, 7);">' + this.subtitle + '</span><br>' : ''
                   //text: 'Click the slices to view versions. Source: <a href="http://statcounter.com" target="_blank">statcounter.com</a>'
                },

                accessibility: {
                    announceNewData: {
                        enabled: false
                    },
                    point: {
                        valueSuffix: this.valueSuffix
                        //valueSuffix: ''
                    }
                },

                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: this.dataLabels,
                            //format: '{point.name}: {point.y}'
                            format: '<b></b><span style="font-size:10px; fill: black; text-decoration:;">{point.name}</b>: <br>{point.y:.2f} ' + this.valueSuffix +'</span> ' 
                            //enabled: true,
                            //format: '{point.name}: {point.y}'
                        }
                    }
                },

                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    /* pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>' */
                    pointFormat: ' <span style="color:{point.color}">\u25CF</span> <b>{point.name}: </b>  <b>{point.y:.2f} ' + this.valueSuffix + '</b><br>'

                    //headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    //pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
                },

                series: [{
                    name: this.name,
                    colorByPoint: this.colorByPoint,
                    data: this.items,
                    
                }],
                drilldown: {
                    series: this.objDrilldown
                }
            });
        }
    },

    mounted() {
        this.load()
    },
    watch: {
        items() {
            this.load()
        }

    }

})
</script>

<style>
.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
    min-width: 50px;
}
</style>
