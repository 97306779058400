<template>
<div>
    <s-text :readonly=readonly :disabled=disabled label="Proveedor" @click="dialogArticle=true" v-model="textDescription" :outlined="outlined" >
    <template v-slot:prepend>
        <v-icon style="font-size:16px;" @click="$emit('editProvider')" color="info">mdi-file-edit-outline</v-icon>
    </template>
    
    </s-text>
    <v-dialog width="80%" v-model="dialogArticle" v-if=dialogArticle transition="dialog-bottom-transition">
        <c-supplier style="overflow: hidden !important; " @dblClick="dblClick($event)" @close="dialogArticle=false" ></c-supplier>
    </v-dialog>

</div>
</template>

<script>
import cSupplier from '@/components/Logistics/cSupplier.vue'
export default {
    components: {
        cSupplier
    },
    props: {
        value: null,
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outlined:{
            type: Boolean,
            default: true
        },


    },
    data() {
        return {
            article: null,
            textDescription: '',
            dialogArticle: false,
        };
    },
    methods: {
        dblClick(item) {
            this.article = item;
            this.$emit('input', item);
            this.textDescription = item.SupName;
            this.dialogArticle = false;
        }
    },
    created() {

        this.article = this.value;
        if (this.article != null) {
            this.textDescription = this.article.SupName;
        }
    },
    
    watch: {
        value() {
            this.textDescription = this.value.SupName;

        }
    },
};
</script>
