<template>
<v-card elevation="0">
    <s-toolbar v-if="visibleTitle" label="Persona" />
    <v-card-text>
        <v-row>
            <v-col class="s-col-form" sm="3" md="3">
                <s-select-definition :def="1155" label="Tipo Documento" v-model="person.GenPerson.TypePersonDocument" />
            </v-col>
            <v-col class="s-col-form" sm="3" md="3">
                <s-text @keyupEnter="txtDocument()" label="Documento*" number @blur="search()" ref="document" :autofocus="person.GenPerson.PrsDocumentNumber.length == 0" v-model="person.GenPerson.PrsDocumentNumber" />
            </v-col>
            <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 1">
                <s-text ref="textNtpName" label="Nombre*" @keyupEnter="save()" v-model="person.NtpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
            </v-col>
            <v-col class="s-col-form" sm="6" md="6" v-if="typeperson == 2">
                <s-text ref="textJrpName" label="Nombre Juridico*" v-model="person.JrpName" :autofocus="person.GenPerson.PrsDocumentNumber.length > 0" />
            </v-col>
        </v-row>

        <v-row v-if="typeperson == 1">
            <v-col class="s-col-form" sm="6" md="6">
                <s-text label="Apellido Paterno*" v-model="person.NtpPaternalSurname" @keyupEnter="save()" />
            </v-col>
            <v-col class="s-col-form" sm="6" md="6">
                <s-text label="Apellido Materno*" @keyupEnter="save()" v-model="person.NtpMaternalSurname" />
            </v-col>
        </v-row>

    </v-card-text>
    <v-card-actions>
        <v-container>
            <v-row>
                <v-col class="s-col-form">
                    <v-btn block text @click="close()">Cancelar</v-btn>
                </v-col>
                <v-col class="s-col-form">
                    <v-btn block color="primary" @click="save()">GUARDAR</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card-actions>
</v-card>
</template>

<script>
import Service from "@/services/General/PersonService";
import _sGeneric from "@/services/General/GenericService";
import SText from "../SText.vue";
export default {
    name: "sAddPerson",
    components: {
        SText
    },
    props: {
        propPerson: {
            type: Object,
            default: null,
        },
        visibleTitle: {
            type: Boolean,
            default: true,
        },
        typeperson: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            editing: false,
            config: {},
            dialog: false,

            person: {
                NtpName: "",
                NtpPaternalSurname: "",
                NtpMaternalSurname: "",
                NtpID: 0,
                GenPerson: {
                    TypePersonDocument: 0,
                    PrsDocumentNumber: "",
                    NatID: 193,
                },
            },
        };
    },
    methods: {

        txtDocument() {
            if (this.typeperson == 1) this.$refs.textNtpName.focus();
            else this.$refs.textJrpName.focus();
        },

        search() {
            this.clear();

            Service.getNaturalPerson(this.person.GenPerson.PrsDocumentNumber, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.editing = true;
                    this.personEdit = r.data;

                    console.log("dddddd", r.data)
                    if (this.personEdit != null) {
                        if (this.personEdit.GenPerson != null) {
                            this.person = {
                                ...this.personEdit
                            };
                            this.person.GenPerson.TypePersonDocument = 1;

                        } else if (this.propPerson.PrsDocumentNumber) this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;

                        if (this.typeperson == 1) this.$refs.textNtpName.focus();
                        else this.$refs.textJrpName.focus();
                    }
                    this.editing = false;
                }
                this.$emit("search", this.person);
            });
        },
        clear() {

            let doc;
            if (this.person.GenPerson.PrsDocumentNumber.length > 0) doc = this.person.GenPerson.PrsDocumentNumber;
            else doc = "";
            this.person = {
                NtpName: "",
                NtpPaternalSurname: "",
                NtpMaternalSurname: "",
                NtpID: 0,
                GenPerson: {
                    TypePersonDocument: 1,
                    PrsDocumentNumber: doc,
                    NatID: 193,
                },
            };
        },
        save() {
      
            this.person.GenPerson.TypePerson = this.typeperson;

            if (this.person.GenPerson.PrsDocumentNumber.trim().length < 6) {
                this.$fun.alert("Numero de Documento Incorrecto", "warning");
                return;
            }
            if (this.person.NtpName.trim().length < 1) {
                this.$fun.alert("Registre Nombre", "warning");
                return;
            }
            if (this.person.NtpPaternalSurname.trim().length < 1) {
                this.$fun.alert("Registre Apellido Paterno", "warning");
                return;
            }
            if (this.person.NtpMaternalSurname.trim().length < 1) {
                this.$fun.alert("Registre Apellido Materno", "warning");
                return;
            }

            this.person.GenPerson.PrsDocumentNumber = this.person.GenPerson.PrsDocumentNumber.trim();
            this.person.NtpName = this.person.NtpName.toUpperCase();
            this.person.NtpPaternalSurname = this.person.NtpPaternalSurname.toUpperCase();
            this.person.NtpMaternalSurname = this.person.NtpMaternalSurname.toUpperCase();

            let p = {
                ...this.person
            };

            Service.savenaturalperson(p, this.$fun.getUserID()).then(() => {
                this.$emit("personSaved", this.person);
                this.$emit("save", this.person);
                this.person.GenPerson.PrsDocumentNumber = "";
                this.clear();
                this.$fun.alert("Guardado Correctamente", "success");
                this.close();
            });
        },
        close() {
            this.$refs.document.focus();
            this.$emit("toclose", 2);
            this.clear();
        },
    },
    watch: {
        propPerson() {
            if (this.propPerson != null) {
                if (this.propPerson.GenPerson != null) {
                    this.person = {
                        ...this.propPerson
                    };

                    this.person.GenPerson.TypePersonDocument = 1;
                } else {
                    this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
                }
            }
        },

    },
    created() {
        if (this.propPerson != null)
            if (this.propPerson.GenPerson != null) {
                this.person = Object.assign(this.propPerson, {});
            } else this.person.GenPerson.PrsDocumentNumber = this.propPerson.PrsDocumentNumber;
    },
};
</script>
