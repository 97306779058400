import Service from "../Service";

const resource = "requirementlegal/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID, AccID) {
        if (!AccID)
            return Service.post(resource + "save", obj, {
                params: { requestID: requestID },
            });
        //Guarda Circuito
        else return Service.post(resource + "saveCircuit", obj, {
            params: { requestID: requestID, AccID: AccID },
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: { requestID: requestID },
        });
    },

    saveBorradorObs(obj, requestID) {
        return Service.post(resource + "saveBorradorObs", obj, {
            params: { requestID: requestID },
        });
    },



    saveComments(obj, requestID) {
        return Service.post(resource + "savecomments", obj, {
            params: { requestID: requestID },
        });
    },

    initiatedProcess(obj, requestID) {
        return Service.post(resource + "initiatedprocess", obj, {
            params: { requestID: requestID },
        });
    },

    endProcess(obj, requestID) {
        return Service.post(resource + "endprocess", obj, {
            params: { requestID: requestID },
        });
    },

    byTrs(obj, requestID) {
        return Service.post("requirementtypecontract/byTrs", obj, {
            params: { requestID: requestID },
        });
    },

    createfile(obj, filename, requestID) {
        return Service.post(resource + "createfile", obj, {
            params: {
                filename: filename,
                requestID: requestID
            },
        });
    },

    saveContractFinal(obj, requestID) {
        return Service.post(resource + "saveContractFinal", obj, {
            params: { requestID: requestID },
        });
    },

};