<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label>
        <b>{{ label }} </b>
    </v-label>

    <div>
        <div v-if="mask.length == 0">
            <v-text-field ref="text" :append-icon="searchIcon ? 'mdi-magnify' : ''" :readonly="readonly" :disabled="disabled" :placeholder="placeholderText" :dark="dark" :autofocus="autofocus" :maxlength="maxlength" :color="color" :type="typeText" :clearable="clearable" @click="click($event)" @click:clear="$emit('close')" @input="input($event)" @keyup.enter="enter($event)" @keypress="validation($event)" @blur="blur($event)" hide-details :outlined="outlined" dense @paste="paste($event)" aria-autocomplete="off" autocomplete="off" :value="valueText" v-model="valueModel" @focus="$emit('focus', $event)" :rules="rules" :min="min">
                <!--  -->
                <template v-slot:prepend>
                    <slot name="prepend"> </slot>
                </template>
                <template v-slot:prepend-inner>
                    <slot name="prepend-inner"> </slot>
                </template>
                <template v-slot:append>
                    <slot name="append"> </slot>
                </template>
                <template v-slot:append-outer>
                    <div v-if="!decimal">
                        <slot name="append-outer"> </slot>
                    </div>
                    <div v-else>
                        <!--  <v-edit-dialog   class="mb-0 pb-0"  large save-text="" cancel-text="" >
                          <v-btn icon x-small color=""><i class="fas fa-calculator"></i></v-btn>
                          <template v-slot:input >
                            <div class="mt-4 title mb-">Calculadora</div>
                          <s-calculator :data=value @getResult='getResult($event)'></s-calculator>
                          </template>               
                        </v-edit-dialog>-->
                    </div>
                </template>
            </v-text-field>
        </div>
        <div v-else>
            <v-text-field ref="text" :readonly="readonly" :outlined="outlined" :disabled="disabled" :placeholder="placeholderText" :dark="dark" :autofocus="autofocus" :maxlength="maxlength" :color="color" :type="typeText" :clearable="clearable" @click:clear="$emit('close')" @click="click($event)" @input="input($event)" @keyup.enter="enter($event)" @keypress="validation($event)" @blur="blur($event)" hide-details dense @paste="paste($event)" aria-autocomplete="off" autocomplete="off" :value="valueText" v-model="valueModel" v-mask="mask" @focus="$emit('focus', $event)" :rules="rules" :min="min">
                <!--  -->
                <template v-slot:prepend-inner>
                    <slot name="prepend-inner"> </slot>
                </template>

                <template v-slot:append>
                    <slot name="append"> </slot>
                </template>
                <template v-slot:append-outer>
                    <slot name="append-outer"> </slot>
                </template>
            </v-text-field>
        </div>
    </div>
</div>
</template>

<script>
export default {
    components: {},
    props: {
        value: "",
        label: {
            type: String,
            default: ""
        },
        maxlength: {
            type: Number,
            default: 256
        },
        type: {
            type: String,
            default: "text"
        },
        searchIcon: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: true
        },
        number: {
            type: Boolean,
            default: false
        },
        decimal: {
            type: Boolean,
            default: false
        },
        date: {
            type: Boolean,
            default: false
        },
        time: {
            type: Boolean,
            default: false
        },
        datetime: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        search: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        mask: {
            type: String,
            default: ""
        },
        min: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            valueModel: "",
            color: "primary",
            rules: []
        };
    },
    computed: {
        placeholderText() {
            let placeholder = this.placeholder;
            if (this.placeholder == "") {
                if (this.decimal) placeholder = "0.00";
            }
            return placeholder;
        },
        typeText() {
            let type = this.type;

            if (this.type.length > 0 && (this.date || this.time || this.datetime))
                type = "tel";
            if (this.number) type = "number";
            return type;
        },
        valueText() {
            this.valueModel = this.value;
            return this.value;
        },
    },

    created() {
        //console.log("itemsDataType", this.itemsDataType);
        this.debouncedGetAnswer = _.debounce(this.getAnswer, 220);
        if (this.value == null) {
            this.valueModel = "";
            if (this.decimal) this.valueModel = 0;
            this.$emit("input", this.valueModel);
        }
    },
    methods: {
        setColor(color) {
            this.color = color ? color : "primary";
        },
        error(msg) {
            this.$fun.alert(msg, "warning");
            this.focus();

            this.setColor("error");
        },
        getResult(result) {
            this.valueModel = result;
        },
        paste(evt) {
            if (this.decimal) {
                return evt.preventDefault();
            }
            this.$emit("paste", evt);
            
        },
        click() {
            this.$emit("click");
        },
        blur(event) {
            this.$emit("blur", event);
        },
        focus() {
            this.$refs.text.focus();
        },
        validation(evt) {

            //27-11-2021
            /* if (this.itemsDataType.number || this.type == "number") {
              evt = evt ? evt : window.event;
              var charCode = evt.which ? evt.which : evt.keyCode;
              if (charCode > 31 && (charCode < 48 || charCode > 57))
                evt.preventDefault();
              else return true;
            } */
            //****************************************************************************** */

            if (this.number || this.type == "number") {
                if (this.min == 0) {
                    this.rules = [
                        v => (v && v >= this.min) || "El número tiene que ser mayor a 0",
                    ]
                }
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                    evt.preventDefault();
                else return true;
            }

            if (this.decimal) {
                evt = evt ? evt : window.event;
                var charCode = evt.which ? evt.which : evt.keyCode;

                if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
                    evt.preventDefault();
                } else if (this.value != null) {
                    if (charCode == 46 && this.value.includes(".")) {
                        return evt.preventDefault();
                    } else {
                        if (!this.filter(this.value) && this.value.includes(".")) {
                            return evt.preventDefault();
                        }
                    }
                }

                return true;
            }
            this.$emit("keypress");
        },
        input(val) {
            if (!this.search) {
                this.$emit("input", val);
            } else this.debouncedGetAnswer();
        },

        filter(__val__) {
            var preg = /^([0-9]+\.?[0-9]{0,5})$/;
            if (preg.test(__val__) === true) {
                return true;
            } else {
                return false;
            }

        },

        enter() {
            this.$emit("keyupEnter", this.valueModel);
        },
        getAnswer() {
            this.$emit("input", this.valueModel);
        },
    },
    watch: {
        value() {
            this.color = "primary";
        },
    },
};
</script>

<style></style>
