<template>
<v-container>
    <v-row>
        <s-toolbar label="Lista de Aprobaciones"></s-toolbar>
    </v-row>
    <v-row>
        <v-col class="pt-0 pb-0 pr-0 pl-0">
            <v-data-table class="elevation-0 scroll table table-hover table-condensed"  disable-sort dense :items="itemsLevels" hide-default-footer :headers="headers" :items-per-page="-1">

                <!-- <template v-slot:item.AtsStatusName="{item}">
                    <v-chip x-small :color="item.AtsStatus==2 ?'success':item.AtsStatus==1?'':'error' ">
                        {{item.AtsStatus==3?'Desaprobado':item.AtsStatusName}}</v-chip>
                </template> -->

                <template v-slot:item.AtsStatusName="{item}">
                    <v-chip x-small :color="item.AtsStatusColor">
                        {{item.AtsStatusName}}</v-chip>
                </template>
                <template v-slot:item.AlvDayTranscurridoText="{item}">
                    <v-chip v-if="item.AlvDayNotifications > 0 && item.AtsStatus == 1" x-small :color="item.AlvDayTranscurrido >= item.AlvDayNotifications ? 'error' : 'success'">
                        {{item.AlvDayTranscurridoText}}
                    </v-chip>
                </template>

                

            </v-data-table>
        </v-col>

    </v-row>
</v-container>
</template>

<script>
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
export default {
    name: "RequirementService",
    components: {},
    props: {
        DocEntry: null,
        TypeObject: null,
    },
    data: () => ({

        headers: [{
                text: 'Cargo',
                value: 'PstName'
            },
            {
                text: 'Estado',
                value: 'AtsStatusName',
                width: 150
            },
            {
                text: 'Fecha',
                value: 'TypeLevelText',
                width: 150
            },
            {
                text: 'Observación',
                value: 'AtsObservation'
            },
            {
                text: 'Proceso',
                value: 'AlvProcessName'
            },
            {
                text: 'Tiempo respuesta (días)',
                value: 'AlvDayNotifications',
                width: 70,
                align: "center"
            },
            /* {
                text: 'Fec. Inicio',
                value: 'SecCreateTrs',
                width: 100
            }, */
            {
                text: 'Tiempo transcurrido [DD:HH:MM:SS]',
                value: 'AlvDayTranscurridoText',
                width: 70
            }
        ],
        itemsLevels: [],
    }),
    methods: {

    },
    watch: {
        DocEntry() {
            if (this.DocEntry !== '' || this.DocEntry !== null)
                _sApprovalLevel.byDocument({
                    DocEntry: this.DocEntry,
                    TypeApproval: this.TypeObject
                }, this.$fun.getUserID()).then((r) => {
                    this.itemsLevels = r.data;
                });
        }
    },
    created() {
        if (this.DocEntry !== '' || this.DocEntry !== null)
            _sApprovalLevel.byDocument({
                DocEntry: this.DocEntry,
                TypeApproval: this.TypeObject
            }, this.$fun.getUserID()).then((r) => {
                r.data.forEach(element => {
                    if(element.SecCreateTrs !== null){
                        element.SecCreateTrs = this.$moment(element.SecCreateTrs).format(this.$const.FormatDateTimeDB)
                    }

                   
                });
                this.itemsLevels = r.data;
            })

    },
    mounted() {},
};
</script>
