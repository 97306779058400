import Vue from "vue";
import Vuex from "vuex";
import Swal from "sweetalert2";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    TimeToken: 0,
    TimeInactive:       3600000/2,
    TimeRefreshInactive:2000000/2,
    OptionsUser:[],isIdle:false
  },
  getters: {
    alertTop: (state) => (msg, type) => {
      type = type || "info";
      var timer = 2500;
      var ok = false;
      var cancel = false;
      switch (type) {
        case "error":
          var timer = null;
          ok = true;
          break;
        case "warning":
          var timer = 5000;
          break;
        case "info":
          var timer = 3000;
          break;
        case "question":
          var timer = null;
          ok = true;
          cancel = true;
          break;
      }
      var toast = null;
      toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: ok,
        showCancelButton: cancel,
        timer: timer,
      });
      return toast.fire({ icon: type, title: msg });
    },

    alertError() {},
  },
  mutations: {
    setTimeToken(state, n) {
      state.TimeToken = n;
    },
  },
  actions: {},
  actions: {},
  modules: {},
});
