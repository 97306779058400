import Service from "../Service";

const resource = "user/";

export default {
    save(usr, requestID) {
        return Service.post(resource + "save", usr, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    copyAllow(usr, usrs, requestID) {
        return Service.post(resource + "copyallow", usrs, {
            params: { usrID: usr.UsrID, requestID: requestID },
        });
    },

    updateUserFirma(usrID, photo) {
        return Service.post(resource + "updatefirma", "", {
            params: { usrID: usrID, photo: photo },
        });
    },
};