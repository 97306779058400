import Service from "../Service";

const resource = "person/";

export default {
  savenaturalperson(ntp, requestID) {
    return Service.post(resource + "create", ntp, {
      params: { usrID: requestID },
    });
  },
  savejuridicperson(jrp, requestID) {
    return Service.post(resource + "createjuridic", jrp, {
      params: { usrID: requestID },
    });
  },

  pagination(parameters) {
    return Service.post(resource + "persons", parameters);
  },
  personSearch(person, requestID) {
    return Service.post(resource + "personsearch", person, {
      params: { requestID: requestID },
    });
  },

  getNaturalPerson(document, requestID) {
    return Service.post(
      resource + "naturalpersonr",
      {},
      {
        params: { document: document, requestID: requestID },
      }
    );
  },

  getPerson(PrsID, TypePerson, requestID) {
    return Service.post(
      resource + "personByID",
      {},
      {
        params: { PrsID: PrsID, TypePerson: TypePerson, requestID: requestID },
      }
    );
  },

  getNaturalPersonDriver(obj, requestID) {
    return Service.post(resource + "naturalpersondriver", obj, {
      params: { requestID: requestID },
    });
  },
};
