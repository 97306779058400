import Service from "../Service";

const resource = "requirementpermit/";

export default {
    users(requestID, AreID = null) {

        return Service.post(
            resource + "user", {}, {
                params: { AreID: AreID, requestID: requestID },
            }
        );
    },

    byArea(obj, requestID) {
        return Service.post(
            resource + "byarea", obj, {
                params: { requestID: requestID },
            }
        );
    },

    pagination(dtr, requestID) {
        return Service.post(
            resource + "Pagination",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },

    save(dtr, requestID) {
        return Service.post(
            resource + "save",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },

    saveGasto(dtr, requestID) {
        return Service.post(
            resource + "savegasto",
            dtr, {
                params: { requestID: requestID },
            }
        );
    },
};