import Service from "../Service";

const resource = "requirementcontract/";

export default {

    listContract(parameters, requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
};