import Service from "../Service";

const resource = "lgsrequirementconfig/";

export default {
    list(objectType, requestID) {
        return Service.post(resource + "list", "", {
            params: { objectType: objectType, requestID: requestID },
        });
    },

};