let children = []

children.push({
    path: "/registrovisita",
    name: "VST_REG_VISITS",
    component: () =>
        import ("../views/Visits/VstNewVisits.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Visitas", disabled: true },
            { text: "Registro Visita", href: "/registrovisita" },
        ],
    },
});

children.push({
    path: "/registrocanal",
    name: "VST_REG_CHANNEL",
    component: () =>
        import ("../views/Visits/VstRegChannel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Visitas", disabled: true },
            { text: "Registro Canal", href: "/registrocanal" },
        ],
    },
});

children.push({
    path: "/registroasesor",
    name: "VST_REG_ ADVISER",
    component: () =>
        import ("../views/Visits/VstRegAdviser.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Visitas", disabled: true },
            { text: "Registro Asesor", href: "/registroasesor" },
        ],
    },
});





export { children }