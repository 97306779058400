let children = []

children.push({
    path: "/documentos/buscardocumentos",
    name: "COB_SEARCH_CUSTOMER",
    component: () =>
        import ("../views/Collection/CobSearchCustomer.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Documentos", disabled: true },
            { text: "Buscar Documentos", href: "/documentos/buscardocumentos" },
        ],
    },
});

export { children }