<template>
<div>
    <v-row>
        <v-col>
            <s-crud no-full close @close="$emit('close')" searchInput @doubleClick="dobleClick" title="OC/OS" :filter="filter" :config="config">
                <!-- <template v-slot:RecAttach="{ row }">
                    <v-icon v-if="row.RecAttach!==null" color="green" @click="openFile({name: row.RecAttach})">mdi-download</v-icon>
                    {{ row.RecAttach }}
                </template> -->
                <template v-slot:TypeOrder="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="row.TypeOrder == 1 ? 'red' : 'green'" style="font-size: 14px">
                                {{ row.TypeOrder == 1 ? "fas fa-car" : "fas fa-street-view" }}
                            </v-icon>
                        </template>
                        <span>{{ row.TypeOrder == 1 ? "Compra" : "Servicio" }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:OrdStatusName="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="
                    row.OrdStatus == 1
                      ? 'green'
                      : row.OrdStatus == 2
                      ? 'brown'
                      : row.OrdStatus == 3
                      ? 'red'
                      : row.OrdStatus == 4
                      ? 'blue'
                      : row.OrdStatus == 5
                      ? 'brown'
                      : row.OrdStatus == 6
                      ? 'orange'
                      : row.OrdStatus == 8
                      ? 'red'
                      : ''
                  " style="font-size: 16px">
                                {{
                    row.OrdStatus == 1
                      ? "far fa-thumbs-up"
                      : row.OrdStatus == 2
                      ? "fas fa-truck"
                      : row.OrdStatus == 3
                      ? "far fa-thumbs-down"
                      : row.OrdStatus == 4
                      ? "fa-regular fa-clock"
                      : row.OrdStatus == 5
                      ? "fas fa-lock"
                      : row.OrdStatus == 6
                      ? "fas fa-ban"
                      : row.OrdStatus == 8
                      ? "fas fa-hands"
                      : ""
                  }}
                            </v-icon>
                            <a style="margin-left: 6px; text-decoration: underline">{{ row.OrdStatusName }}</a>
                        </template>
                        <span>{{ row.OrdStatusName }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:CttDocumentNumber="{ row }">
                    <a style="text-decoration: underline" @click="show(row,1)">{{row.CttDocumentNumber}}</a>
                </template>
                <template v-slot:OrdDocumentNumber="{ row }">
                    <a style="text-decoration: underline;color:black" @click="show(row,2)">{{row.OrdDocumentNumber}}</a>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <v-dialog v-if="viewCotizationDialog" v-model="viewCotizationDialog" persistent width="100%">
        <v-card>
            <ord-cuardro-comparativo @onClose="viewCotizationDialog = false" :value="item.CttID"></ord-cuardro-comparativo>
        </v-card>
    </v-dialog>

    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <order-purchase-edit @onClose="dialogEdit = false;" :value="item.OrdID" :order="{}"  />
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import _sGeneral from "@/services/HelperService.js";

import OrdCuardroComparativo from "@/views/Logistics/OrderCotization/OrdCuardroComparativo";
import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit";
export default {
    name: "",
    components: {
        OrdCuardroComparativo,
        OrderPurchaseEdit
    },
    props: {
        AreID:null
    },

    data() {
        return {

            filter: {
                BeginDate: null,
                EndDate: null,
                OrdStatus: null,
                SupCode: null,
                UsrCreateID: null,
                AreID: this.AreID
            },
            config: {
                model: {
                    OrdID: "ID",
                    OrdDate: "date",
                    OrdStatusName: "",
                    CttDocumentNumber: "string",
                    OrdDocumentNumber: "string"
                },
                service: _sOrderPurchase,
                headers: [{
                        text: "ID",
                        value: "OrdID",
                        sortable: false,
                    },
                    {
                        text: "Tipo",
                        value: "TypeOrder",
                        sortable: false,
                    },
                    {
                        text: "Empresa",
                        value: "typeBusinessName",
                        width: 100,
                        sortable: false,
                    },
                    {
                        text: "Apl. Letra",
                        value: "OrdApplyLetters",
                        sortable: false,
                    },

                    {
                        text: "Nro. Documento",
                        value: "OrdDocumentNumber",
                        width: 120,
                        sortable: false,
                    },
                    {
                        text: "Nro. Doc. Cotización",
                        value: "CttDocumentNumber",
                        width: 110,
                        sortable: false,
                    },
                    {
                        text: "Fecha",
                        value: "OrdDate",
                        sortable: false,
                    },

                    {
                        text: "Total (Inc. IGV)",
                        value: "OrdTotal",
                        sortable: false,
                    },
                    {
                        text: "Proveedor",
                        value: "SupName",
                        sortable: false,
                    },
                    {
                        text: "Usuario",
                        value: "NtpFullName",
                        sortable: false,
                    },
                    {
                        text: "Fecha Act.",
                        value: "SecUpdate",
                        sortable: false,
                    },
                    {
                        text: "Estado",
                        value: "OrdStatusName",
                        sortable: false,
                        align: "left",
                        width: 120,
                    },
                ],
            },
            viewCotizationDialog: false,
            item: {},
            dialogEdit:false
        }

    },
    methods: {
        dobleClick(item) {
            this.$emit('dblClick', item)
        },
        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 5).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        show(item, op) {
            this.item = item;
            if (op == 1) {
                this.viewCotizationDialog = true;
            }

            if (op == 2) {
                this.dialogEdit = true;
            }

        },
    },
    created() {},
};
</script>
