<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>
    <div v-if="dialogDissaproved">
        <v-dialog v-model="dialogDissaproved" width="30%">
            <v-card>
                <s-toolbar label="Motivo"></s-toolbar>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-text label="Motivo de Desaprobación" autofocus v-model="transactionApproved.AtsObservation" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn color="primary" block small @click="disapproved()">Desaprobar</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>

    <div>

        <s-toolbar close :label="'Cuadro Comparativo. Cotizaciòn Nro. ' + cotizationLocal.CttDocumentNumber" @close="$emit('onClose')" />
        <!-- <s-toolbar  
            close 
            :label="'Cuadro Comparativo. Cotizaciòn Nro. ' + cotizationLocal.CttDocumentNumber" 
            @close="$emit('onClose')" 
            :approve="optionApproved" 
            :transactionApproved="transactionApproved" 
            :save="cotizationLocal.CtpIDganador==null && !optionApproved" @save="save()" /> -->

        <div>
            <v-card outlined width="100%" style="margin-top: 10px">
                <v-card-title class="ml-3" style="font-size:11px">
                    <b>Leyenda</b>
                    <v-divider vertical class="ml-2"></v-divider>
                    <ul>
                        <li>
                            <v-chip fab :color="'#f5f5dc'" x-small>
                            </v-chip> = Participante con menor precio.
                        </li>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <!-- <li>
                            <v-chip fab :color="'red'" dark small>
                                {{cotizationLocal.ExchangeRateCurrency}}
                            </v-chip> = Tipo Cambio Referencia.
                        </li> -->
                    </ul>
                    <br>

                </v-card-title>
            </v-card>
        </div>
        <v-card>
            <v-col>
                <v-row>

                    <v-divider vertical></v-divider>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h3>ITEMS REQUERIDOS</h3>

                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-card outlined>
                                    <v-data-table class="elevation-0 table table-hover table-condensed" style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="CtdID" :height="'auto'" hide-default-footer>

                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-2"></v-divider>
                        <v-divider class="mt-2"></v-divider>

                    </v-col>
                    <v-col :lg="itemsParticipants.length == 1 ? '12': '4'" cols="12" class="" v-for="(item) in itemsParticipants" :key="item.CtpID">

                        <v-row>
                            <v-col>

                                <v-card outlined width="100%" style="margin-top:10px; border-color:#4eca8a" :color="minPrice == item.item.OrdTotalSoles ? '#f5f5dc' : ''">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h3>{{item.CtpAlias}} - {{item.CtpName}} con Nro. Doc. {{item.CtpRuc}}</h3>
                                        <!-- <h3 v-if="minPrice == item.item.OrdTotalSoles" class="ml-2" style="color: "></h3> -->
                                    </v-card-title>
                                </v-card>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-data-table class="elevation-0 table table-hover table-condensed" outlined disable-sort dense hide-default-footer :items-per-page="-1" :headers="headers" :items="item.item.details" item-key="CpdID">

                                    </v-data-table>
                                </v-card>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px;">
                                        <v-col lg="12">
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>V. NETO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form">
                                                    <h4 class="text-right">
                                                        <!-- {{orderLocal.OrdTotalNeto}} -->
                                                        <h4 class="text-right">
                                                            {{
                              item.item.OrdTotalNeto >= 1000 
                              ? item.item.OrdTotalNeto.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                              : item.item.OrdTotalNeto.toFixed(2)
                          }}
                                                        </h4>
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row >
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>COSTOS DIRECTOS</b></v-label>
                                                </v-col>
                                                <v-col lg="6" cols="6" class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdDirectCosts >= 1000 
                              ? item.item.OrdDirectCosts.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdDirectCosts == null ? 0 : item.item.OrdDirectCosts.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row >
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <v-label><b>GTO GENERALES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdGeneralgto >= 1000 
                              ? item.item.OrdGeneralgto.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdGeneralgto == null ? 0 : item.item.OrdGeneralgto.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <v-label><b>UTILIDADES</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdUtilities >= 1000 
                              ? item.item.OrdUtilities.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdUtilities == null ? 0 : item.item.OrdUtilities.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <v-label><b>DESCUENTO</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form" style="margin-top:-10px">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdPolicy >= 1000 
                              ? item.item.OrdPolicy.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdPolicy == null ? 0 : item.item.OrdPolicy.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row>
                                                <v-col lg="6" class="s-col-form">
                                                    <v-label><b>SUB TOTAL</b></v-label>
                                                </v-col>
                                                <v-col lg="6" class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdSubTotal >= 1000 
                              ? item.item.OrdSubTotal.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdSubTotal.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col class="s-col-form">
                                                    <v-label><b>IMPUESTO</b></v-label>
                                                </v-col>
                                                <v-col class="s-col-form">
                                                    <h4 class="text-right">
                                                        {{ item.item.OrdTaxAmount >= 1000
                              ? item.item.OrdTaxAmount.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdTaxAmount.toFixed(2)}}
                                                    </h4>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="mt-4 mb-4"></v-divider>
                                            <v-row>
                                                <v-col class="s-col-form">
                                                    <v-label><b>TOTAL</b></v-label>
                                                </v-col>
                                                <v-col style="color: #812121;border-radius: 10px;outline-style: ;" class="s-col-form">

                                                    <h2 style="transform: scale(1.0);" class="text-right">

                                                        {{item.item.TypeCurrencyHelper}} {{item.item.OrdTotal >= 1000
                              ? item.item.OrdTotal.toLocaleString("es-MX", {
                                    minimumFractionDigits: 2
                                })
                              : item.item.OrdTotal.toFixed(2) }}
                                                    </h2>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-card-title>

                                </v-card>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <!-- <v-card-title style="margin-top: -10px; margin-bottom: -10px;"> -->
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Archivo adjunto
                                    </v-card-title>
                                </v-card>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <!-- <v-card-title style="margin-top: -10px; margin-bottom: -10px;"> -->
                                    <v-col lg="12" md="12" cols="12" class="s-col-text">

                                        <v-row style="margin-top: -15px">
                                            <v-col lg="10" cols="10">

                                                <!-- <v-file-input label="Anexo 02" v-model="item.item.Attach1" hide-details /> -->
                                                <b>{{item.item.Attach1}}</b>
                                            </v-col>
                                            <v-col lg="2" cols="2">
                                                <v-btn color="success" class="mt-5" icon x-small @click="openFile({name: item.item.Attach1})">
                                                    Descargar<v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <!--  </v-card-title> -->
                                </v-card>

                            </v-col>
                        </v-row>

                    </v-col>

                </v-row>
                <v-row>

                    <v-divider vertical></v-divider>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h2>EVALUACIÓN TÉCNICA</h2>
                                    </v-card-title>
                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>

                    <v-col cols="12">
                        <table id="customers" style="width: 100%">
                            <thead>
                                <tr style="text-align:center">
                                    <td scope="col"><b><h3>Participante</h3></b></td>
                                    <td scope="col"><h3>Moneda</h3></td>
                                    <td scope="col"><h3>Total</h3></td>
                                    <!-- <td scope="col"><h3>Total (USD)</h3></td> -->
                                    <td scope="col"><h3>Tipo Credito - 20%.</h3></td>
                                    <td scope="col"><h3>Forma Pago - 5%.</h3></td>
                                    <td scope="col"><h3>T. entrega (días) | 10%.</h3></td>
                                    <td scope="col"><h3>Garantia - 5%.</h3></td>
                                    <td scope="col"><h3>Precio - 60%.</h3></td>
                                    <!-- <td scope="col">Extra</td> -->
                                    <td scope="col"><h3>Evaluación Total - 100%</h3></td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(item, i) in itemsParticipants">
                                    <td>
                                        <h3>{{item.CtpName}}</h3>
                                    </td>
                                    <td style="text-align:center">
                                        <h3>{{item.item.TypeCurrencyAbbreviation}}</h3>
                                    </td>
                                    <td style="text-align:right">
                                        <h2 style="transform: scale(1.0);">{{item.item.OrdTotal > 1000 ? item.item.OrdTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                }) : item.item.OrdTotal.toFixed(2)}} </h2>
                                    </td>
                                   <!--  <td style="text-align:right">
                                        <h2 style="transform: scale(1.0);">{{item.item.OrdTotalSoles > 1000 ? item.item.OrdTotalSoles.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                }) : item.item.OrdTotalSoles.toFixed(2)}} </h2>
                                    </td> -->

                                    <td style="text-align:center"> 
                                        
                                         <b>
                                            <h2>{{item.item.TypeCreditoValue}}%</h2>
                                        </b>
                                        {{item.item.TypeCreditoName}}
                                    </td>
                                    <td style="text-align:center">
                                        <b>
                                            <h2>{{item.item.TypeFormaPagoValue}}%</h2>
                                        </b>
                                        {{item.item.TypeFormaPagoName}}
                                    </td>
                                    <td style="text-align:center">
                                        <b>
                                            <h2>{{item.item.PrvDeliveryTimeValue}}%</h2>
                                        </b>
                                        {{item.item.PrvDeliveryTime}}
                                    </td>
                                    <td style="text-align:center">
                                        <b>
                                            <h2>{{item.item.PrvTimeGarantiaValue}}%</h2>
                                        </b>
                                        {{item.item.PrvTimeGarantia}}
                                    </td>
                                    <td style="text-align:right">
                                        <!-- <h2 style="transform: scale(1.0);">{{item.item.OrdTotal}} </h2>| --> <b>
                                            <h2>{{item.item.OrdTotalValue}}%</h2>
                                        </b>
                                    </td>
                                    <!-- <td>
                                        {{item.item.ValueExtra}}
                                    </td> -->
                                    <td style="text-align:right">
                                        <!-- {{item.item.ganador}} -->
                                        <b>
                                            <h2>{{item.item.TotalPuntaje}}%</h2>
                                        </b>
                                        <!-- <b v-if="item.item.ganador !== null" style="color: blue">GANADOR</b> -->

                                    </td>
                                </tr>

                            </tbody>

                        </table>
                    </v-col>

                    <v-col lg="6" md="6" cols="12" v-if="cotizationLocal.CttStatus == 4 && itemsParticipants.length > 1">
                        <v-col class="pr-0 pl-0">
                            <v-row>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h3>Si no esta de acuerdo con la decisión emitida por el Sistema, Por favor seleccione al Participante Ganador y click en Grabar.</h3>
                                        <v-btn elevation="0" small class="ml-3" color="info" @click="saveWinner(1, $fun.getUserID())">Grabar</v-btn>
                                    </v-card-title>
                                </v-card>

                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h2 style="color:red">Participantes. </h2><br>

                                        <v-col class="d-flex" cols="12" sm="12" v-if="cotizationLocal.CttStatus == 4 && itemsParticipants.length > 1">
                                            <v-select return-object single-line persistent-hint :items="itemsParticipants" label="Ganador" item-text="CtpName" item-value="CtpID" @input="input($event)" v-model="objGanador" autofocus></v-select>
                                            <v-checkbox style="font-size: 20px" label="Solicitar Reajuste Cotización." v-model="reajPrice"></v-checkbox>
                                        </v-col>

                                    </v-card-title>
                                    <s-textarea label="Comentario" class="ml-2 mr-2" style="margin-top:-30px" v-model="cotizationLocal.CttObservation"></s-textarea>

                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col :lg="cotizationLocal.CttStatus == 4 && itemsParticipants.length > 1 ? 6 : 12" :md="cotizationLocal.CttStatus == 4 && itemsParticipants.length > 1 ? 6 : 12" cols="12">
                        <v-col class="pr-2 pl-2">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        Ganador segun:.
                                    </v-card-title>
                                    <v-data-table dense hide-default-footer :headers="headersWinners" :items="itemsWinners" :items-per-page="-1">
                                        <template v-slot:item.CwnReajustePrice="{item}">
                                            <v-chip x-small :color="item.CwnReajustePrice ? 'success' : 'error'">
                                                {{item.CwnReajustePrice ? 'Si' : 'No'}}
                                            </v-chip>

                                        </template>
                                        <template v-slot:item.UsrName="{item}">
                                            {{item.UsrCreateID == 42 ? 'SISTEMA' : item.UsrName}}

                                        </template>
                                        <template v-slot:item.CwnDate="{item}">
                                            {{$moment(item.CwnDate).format($const.FormatDateTimeDB)}}

                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>

                </v-row>
                <v-row>

                </v-row>
                <v-row>
                    <v-col lg="12" cols="12">
                        <v-col class="pr-2 pl-2">
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                        <h2 style="color:red">Resultados Finales. </h2><br>
                                        <v-divider></v-divider>
                                        <h4></h4>
                                    </v-card-title>

                                    <v-divider></v-divider>
                                    <v-divider class="mb-4"></v-divider>

                                    <v-btn title="Aprobar Cotización" text small @click="approved()" v-if="optionApproved || approvedTrs">
                                        <v-icon color="green" style="font-size:46px;"> far fa-thumbs-up</v-icon>
                                    </v-btn>

                                    <v-btn title="Rechazar Cotización" text small @click="dialogDissaproved = true" v-if="optionApproved || approvedTrs">
                                        <v-icon color="red" style="font-size:46px;"> far fa-thumbs-down</v-icon>
                                    </v-btn>

                                    <v-divider class="mt-3"></v-divider>
                                    <v-divider></v-divider>

                                    <!-- <v-card outlined width="100%" style="margin-top:10px">
                                        <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                            <h3>Participante Ganador - Sistema: {{itemGanador.CtpName}} con Ruc Nro. {{itemGanador.CtpRuc}}.</h3>
                                        </v-card-title>
                                    </v-card> -->
                                    <v-card outlined width="100%" style="margin-top:10px" v-if="cotizationLocal.CttStatus == 4 && itemsParticipants.length > 1">
                                        <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                            <h3 style="color:blue">Participante Ganador - Usuario: {{itemGanadorUsuario.CtpName}} con Ruc Nro. {{itemGanadorUsuario.CtpRuc}}.</h3>
                                        </v-card-title>
                                    </v-card>

                                    <!-- <ckeditor :config="configCk" v-model="cotizationLocal.CttObservation"></ckeditor> -->
                                </v-card>
                            </v-row>
                        </v-col>

                    </v-col>
                </v-row>
            </v-col>
        </v-card>

    </div>
</div>
</template>

<script>
//Services
import _sCotization from "@/services/Logistics/CotizationService";
import _sGeneral from "@/services/HelperService.js";
import _sApprovalTransaction from "@/services/Security/ApprovalTransactionService";

export default {
    components: {},
    props: {
        value: {},
    },
    data() {
        return {
            processing: false,
            messageProcessing: "",

            selectedSAP: [],
            itemsSAP: [],
            headersSAP: [{
                    text: "Descripción",
                    value: "CtdDescription",
                    sortable: false,
                    width: 640,
                },
                {
                    text: "Cant",
                    value: "CtdQuantity",
                    sortable: false,
                },

                {
                    text: "Und",
                    value: "UnitMeasurement",
                    sortable: false,
                },
                {
                    text: "Comentario",
                    value: "CtdObservation",
                    sortable: false,
                },
            ],

            itemsParticipants: [],
            headers: [{
                    text: "Description",
                    value: "CtdDescription",
                   
                },

                {
                    text: "Cant. Stock.",
                    value: "OddQuantity"
                },

                {
                    text: "Precio Uni.",
                    value: "OddUnitPrice"
                },
                {
                    text: "V. Neto",
                    value: "OddAmount",
                    
                },
                {
                    text: "Comentario",
                    value: "EtdObservation",
                    
                },

            ],

            cotizationLocal: {
                CttDayExpirationAditional: 0
            },
            Attach1: null,
            configCk: {
                extraPlugins: 'autogrow',
                autoGrow_minHeight: 50,
            },
            itemGanador: {},
            itemGanadorUsuario: {},
            radios: null,
            objGanador: {
                CtpID: 0
            },
            transactionApproved: null,
            optionApproved: false,
            approvedTrs: false,
            dialogDissaproved: false,
            minPrice: 0,
            headersWinners: [{
                    text: "Participante",
                    value: "CtpName",
                    sortable: false,
                },
                {
                    text: "Comentario",
                    value: "CwnComments",
                    sortable: false,
                },
                {
                    text: "Reajuste Cot.",
                    value: "CwnReajustePrice",
                    sortable: false,
                },
                {
                    text: "Registrado por",
                    value: "UsrName",
                    sortable: false,
                },
                {
                    text: "F. Creación",
                    value: "CwnDate",
                    sortable: false,
                },
            ],
            itemsWinners: [],
            reajPrice: false

        }
    },

    computed: {
        isEditable() {
            //return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null || (this.requirement.ReqStatus == 3 && this.isUserCreated == true);
            return this.cotizationLocal.CttStatus == 1 || this.cotizationLocal.CttStatus == 4;

        },
    },

    watch: {
        radios() {
            console.log("radiosssssssss", this.radios)
        },

        isEditable() {
            console.log(this.isEditable)
            this.processing = false;
        },

        'cotizationLocal.CttObservation'() {
            this.transactionApproved.AtsObservation = this.cotizationLocal.CttObservation
        },

        transactionApproved() {
            if (this.transactionApproved != null)
                if (this.transactionApproved.UsersApproved.length > 0)
                    if (this.transactionApproved.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                        if (this.transactionApproved.OrdStatus == 4) {
                            this.approvedTrs = true;
                        }
        },
    },

    created() {
        console.log(this.value)
        this.loadMain()

    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 4).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        //***************************************** Item Detalle ******************************************************************** */
        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        loadMain() {
            if (this.value != null) {
                _sCotization
                    .byID({
                        CttID: this.value,
                        getDataProvider: 1
                    }, this.$fun.getUserID())
                    .then((r) => {

                        this.cotizationLocal = r.data
                        //this.reajustePrice = this.cotizationLocal.CttReajustePrice
                        this.itemsParticipants = r.data.itemsParticipants.filter(x => x.CtpStatus == 2)
                        this.itemsWinners = r.data.listWinners

                        this.itemsParticipants.sort((a, b) =>
                            a.item.OrdTotalSoles - b.item.OrdTotalSoles
                        );

                        /* this.itemsParticipants.map((item) => {
                            if(item.item.TypeCurrency == 2){
                                item.item.OrdTotalSoles = item.item.OrdTotal * this.cotizationLocal.ExchangeRateCurrency
                            }else if(item.item.TypeCurrency == 1){
                                item.item.OrdTotalSoles = item.item.OrdTotal
                            }
                            return item;
                        }) */

                        this.minPrice = Math.min(...this.itemsParticipants.map(x => x.item.OrdTotalSoles))

                        if (this.cotizationLocal.CtpIDganador == null) {

                            this.itemGanador = this.itemsParticipants.find(x => x.item.TotalPuntaje == Math.max(...this.itemsParticipants.map(x => x.item.TotalPuntaje)));
                            this.itemGanadorUsuario = this.itemsParticipants.find(x => x.item.TotalPuntaje == Math.max(...this.itemsParticipants.map(x => x.item.TotalPuntaje)));

                            this.saveWinner(0, 42)

                        } else {

                            this.itemGanador = this.itemsParticipants.find(x => x.ganador == 1);
                            this.itemGanadorUsuario = this.itemsParticipants.find(x => x.ganador == 1);

                        }
                        this.objGanador.CtpID = this.itemGanador.CtpID
                        this.itemsSAP = r.data.items

                        this.transactionApproved = this.cotizationLocal.Transaction;
                        if (this.cotizationLocal.Transaction != null)
                            if (this.cotizationLocal.Transaction.UsersApproved.length > 0)
                                if (this.cotizationLocal.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                                    if (this.cotizationLocal.CttStatus == 4) this.optionApproved = true;

                    });
            }
        },

        input(item) {
            this.itemGanadorUsuario = item
        },

        reajustePrice() {
            this.cotizationLocal.SecStatus = 10
            this.cotizationLocal.UsrUpdateID = this.$fun.getUserID()
            _sCotization.save(this.cotizationLocal, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {}
            });
        },

        saveWinner(CwnMode = 1, UsrCreateID = null) {

            this.itemGanadorUsuario.CttID = this.cotizationLocal.CttID
            this.itemGanadorUsuario.SecStatus = 1
            this.itemGanadorUsuario.CwnComments = this.cotizationLocal.CttObservation
            this.itemGanadorUsuario.CwnMode = CwnMode
            this.itemGanadorUsuario.CwnReajustePrice = this.reajPrice
            this.itemGanadorUsuario.UsrCreateID = UsrCreateID

            _sCotization.saveGanador(this.itemGanadorUsuario, this.$fun.getUserID()).then(resp => {
                if (resp.status == 200) {
                    this.loadMain()
                }
            })
        },

        approved() {

            if (this.transactionApproved)
                this.$fun.alert("Seguro de aprobar Documento?", "question", false).then((r) => {
                    if (r.value) {

                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 2;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                if (rq.status == 200) {
                                    this.reajustePrice();
                                    this.$fun.alert("Aprobado Correctamente", "success");
                                    this.processing = false;
                                    this.$emit("onClose");
                                }

                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("approved");
        },

        disapproved() {

            if (this.transactionApproved)

                this.$fun.alert("Seguro de desaprobar Documento?", "question", false).then((r) => {
                    if (r.value) {
                        this.messageProcessing = ""
                        this.processing = true;
                        this.transactionApproved.AtsStatus = 3;
                        this.transactionApproved.UsrCreateID = this.$fun.getUserID();

                        _sApprovalTransaction.approved(this.transactionApproved, this.$fun.getUserID()).then((rq) => {
                                this.$fun.alert("Documento desaprobado", "success");
                                this.processing = false;
                                this.$emit("close");
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                    }
                });
            else this.$emit("disapproved");
        },

    }
};
</script>

<style>
#customers {
    font-family: 'Calibri';
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}
</style>
