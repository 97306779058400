import "@fortawesome/fontawesome-free/css/all.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
/* */


Vue.use(Vuetify);

import es from "vuetify/lib/locale/es";
import "@mdi/font/css/materialdesignicons.min.css"; 
export default new Vuetify({
    lang: {
        locales: { es },
        current: "es",
    },
    theme: {
        options: {
            customProperties: true,
            iconfont: "fa",
        },
        themes: {
            light: {
                // primary: "#2955C8",009358
                primary: "#1a5cff",
                accent: "#0464ff",
                success: "#4ECA8A",
                secondary: "#FAFBFC",
                error: "#DD3B62",
                warning: "#F7B924",
                campaing: "black",
            },
        },
    },

    icons: {
        iconFont: "md",
    }
});