<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <v-label><b>{{ label }}</b></v-label>
    <section>
        <v-file-input id="mydiv" ref="myid" @change="validated()" :accept="accept" @click:clear="clear()" v-model="files" prepend-icon="" show-size small-chips color="primary" prepend-inner-icon="mdi-paperclip" dense hide-details outlined>
            <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 2" color="primary" dark label x-small>
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>

        <xlsx-read :file="files">
            <xlsx-json v-show="headers" @parsed="changeData($event)">
                <template #default="{collection}">
                    <div>
                        <v-data-table disable-sort disable-filtering dense :headers="headers" :items="convertCollection(collection)" />
                    </div>
                </template>
            </xlsx-json>
        </xlsx-read>
    </section>
    <v-row v-show="headers">
        <v-spacer></v-spacer>
        <v-col lg="6">
            <v-btn elevation="0" style="text-transform: none;" dark :color="'#5175AF'" small block @click="clickSave()">Cargar Datos</v-btn>
        </v-col>
    </v-row>
</div>
</template>

<script>
import {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
} from "vue-xlsx";

export default {
    components: {
        XlsxRead,
        XlsxTable,
        XlsxSheets,
        XlsxJson,
        XlsxWorkbook,
        XlsxSheet,
        XlsxDownload,
    },
    props: {
        value: null,
        headers: null,
        label: {
            type: String,
            default: "Seleccione Archivo"
        },
        accept: {
            type: String,
            default: "*"
        }
    },

    data() {
        return {
            items: [],
            file: null,
            files: null,
            selectedSheet: null,
            sheetName: null,
            sheets: [{
                name: "SheetOne",
                data: [{
                    c: 2
                }]
            }],
            collection: [{
                a: 1,
                b: 2
            }],
            itemsAll: [],
        };
    },

    methods: {
        clear() {
            this.files = [];
            this.convertCollection([])
            this.$emit("clear");
            this.$emit("input", null);
        },
        changeData(data) {
            this.$emit("changeData", data);
            this.$emit("input", data);
        },

        clickSave() {
            this.$emit("loaded", this.items);
            this.$emit("collection", this.itemsAll);
        },

        convertCollection(collection) {

            var list = [];

            if (collection)

                list = collection.map((item) => {
                    let obj = {};
                    this.headers.forEach((element) => {
                        if (element.date) obj[element.value] = this.$moment(new Date((item[element.column] - (25567 + 1)) * 86400 * 1000)).format("YYYY-MM-DD");
                        else
                            obj[element.value] = item[element.column];
                    });
                    return obj;
                });
            else list = [];

            if (collection !== null) {
                if (collection.length != 0) {
                    this.items = list;
                    this.itemsAll = collection
                } else {
                    this.items = null;
                    this.itemsAll =null
                    list = null;
                }
            }
            return list;
        },

        validated() {
            if (this.files !== null) {
                let archivo = this.files.name;

                if (archivo !== null) {

                    var extension = archivo.substring(archivo.lastIndexOf('.'), archivo.length);

                    if (this.$refs.myid.$attrs.accept.split(',').indexOf(extension) < 0) {

                        alert('Archivo inválido. No se permite la extensión ' + extension);
                        this.files = [];
                        return
                    }

                }
            }
        }
    },
};
</script>
