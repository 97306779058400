import Service from "../Service";

const resource = "article/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    insertStock(obj, requestID) {
        return Service.post(resource + "insertStock", obj, {
            params: { requestID: requestID },
        });
    },

};