
let children = []

children.push({
    path: "/creep/generarcreep",
    name: "CRP_GENERATED_CREEP",
    component: () =>
        import ("../views/Creep/CrpGeneratedCreep.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Creep", disabled: true },
            { text: "Generar Creep", href: "/creep/generarcreep" },
        ],
    },
});





export { children }