<template>
<div>

    <vc-loading :processing="processing" :messageProcessing="messageProcessing" :errors="messageProcessing" @close="processing=false;messageProcessing=''" />
    <vc-modal-circuit close :circuits="circuits" :TrsID="TrsID" :dialog="dialogCircuit" @close="dialogCircuit = false" :width="circuits.length > 1 ? 940 : 500" />

    <!--  <v-dialog v-model="dialogApprovalRequirement" v-if="dialogApprovalRequirement" width="60%" style="display: inline-table;">
        <ApprovalLevelsDocument :DocEntry="requirement.ReqID" :TypeObject="requirement.TrsID" />
    </v-dialog> -->

    <v-card class="mt-2">

        <s-toolbar style="font-family: Calibri;" dark @save="save()" :save="isEditable" :approve="optionApproved" close @close="$emit('close')" :transactionApproved="transactionApproved" :label="'Solicitud  ' + (requirement.ReqID == null ? '' : requirement.ReqDocumentNumber + ' - ' + requirement.UsrName)">

        </s-toolbar>

        <v-divider></v-divider>

        <v-card outlined>
            <v-col>

                <v-row>

                    <v-col lg="4" md="4" cols="12">
                        <v-card elevation="0" height="auto">

                            <v-row>
                                <v-col style="padding-top: 0px; padding-bottom: 16px">
                                    <s-text readonly label="Usuario" :value="requirement.ReqID == null ? user.NtpFullName : requirement.UsrName"></s-text>
                                </v-col>
                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 10px" horizontal></v-divider>

                            <v-row style="margin-top: 10px; margin-bottom: 10px">
                                <v-col lg="12" cols="12" class="s-col-text"><b style="font-family: Calibri">Adjuntos</b>
                                    <v-btn v-if="!isEditable" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadFile()">Subir Archivos
                                        <v-icon> mdi-arrow-up</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col lg="12" cols="12" class="s-col-text">
                                    <v-row>
                                        <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                            <v-file-input show-size counter :ref="'attach' + item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                            </v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col lg="12" md="12" cols="12" class="s-col-text">
                                    <v-card outlined>
                                        <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="requirement.itemsAttach">
                                            <template v-slot:item.RqdAttach="{ item }">
                                                <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                {{item.RqdAttach}}
                                            </template>
                                            <template v-slot:item.delete="{ item }">

                                                    <a v-if="requirement.ReqStatus ==  4 && item.UsrCreateID == userID" style="color:red" @click="deleteFile(item)">Eliminar</a>
                                                </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>

                            </v-row>

                            <v-divider style="margin-top:10px; margin-bottom: 10px" horizontal></v-divider>

                            <v-row>
                                <v-col cols="12" style="padding-top: 0px" class="mt-4">
                                    <label><b>Comentarios</b></label>
                                    <v-btn v-if="!isEditable" elevation="0" small class="ml-2" text color="info" @click="addComments()">Añadir Comentario</v-btn>
                                </v-col>
                                <v-col cols="12" style="padding-top: 0px">
                                    <s-textarea v-model="Comments" ref="Comments" :height="100"></s-textarea>
                                </v-col>
                            </v-row>
                            <v-divider style="margin-top:10px; margin-bottom: 10px" horizontal></v-divider>

                            <v-row v-if="!isEditable">
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-toolbar dense elevation="0" color="#7a8ca9" dark>
                                            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                                            <v-toolbar-title>Comentarios</v-toolbar-title>
                                        </v-toolbar>

                                        <v-list>
                                            <v-list-item-group :active-class="''">

                                                <template v-for="(item, index) in requirement.listComments">

                                                    <v-list-item :key="item.RccID">
                                                        <v-list-item-avatar>
                                                            <!--  <v-img :src="item.RccAvatar"></v-img> -->
                                                            <v-row justify="space-around">
                                                                <v-avatar color="red">
                                                                    <span class="white--text text-h5">{{item.NtpName !== undefined ? item.NtpName.split(" ")[0].substring(0,1) : ""}}{{item.NtpName !== undefined ? item.NtpName.split(" ")[1].substring(0,1) : ""}}</span>
                                                                </v-avatar>
                                                            </v-row>

                                                        </v-list-item-avatar>

                                                        <v-list-item-content>

                                                            <b>
                                                                <v-list-item-title>{{item.NtpName}}
                                                                    <v-spacer></v-spacer> {{item.SecCreate}}
                                                                </v-list-item-title>
                                                            </b>
                                                            <v-divider></v-divider>

                                                            {{item.RccSubtitleExtract}}

                                                            <v-list-group v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                                <template v-slot:activator>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title>Ver mas</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </template>

                                                                <v-list-item v-if="item.RccLengthSubtitle > item.RccLengthExtract">

                                                                    <v-list-item-content>
                                                                        {{item.RccSubtitle}}
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list-group>

                                                            <!-- <v-list-item-title style="font-size: 12px" v-html="item.RccSubtitle"></v-list-item-title> -->

                                                        </v-list-item-content>

                                                        <a style="color: red;font-size:10px" v-if="item.UsrCreateID == $fun.getUserID() && item.SecCreate >= $fun.getDate() && requirement.ReqStatus == 4" @click="deleteComments(item)">Eliminar</a>

                                                    </v-list-item>
                                                    <v-divider :key="index"></v-divider>
                                                </template>

                                            </v-list-item-group>
                                        </v-list>

                                    </v-card>
                                </v-col>

                            </v-row>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col :lg="isEditable ? 8 : 8" :md="isEditable ? 8 : 8" cols="12">
                        <v-row>

                            <v-col cols="6" v-if="!isEditable">
                                <b>ESTADO PROCESO:</b>
                                <h2 style="color: red"> {{requirement.AlvProcessName == null ? "PROCESO TERMINADO" : requirement.AlvProcessName}}</h2>
                                <h4 style="color: ">{{requirement.AlvDescription}}</h4>
                            </v-col>
                            <v-divider style="margin-top:-10px; margin-bottom: 0px" vertical></v-divider>
                            <v-col cols="6" v-if="!isEditable">
                                <b>PROCESO SIGUIENTE:</b>
                                <h2 style="color: red"> {{requirement.AlvProcessNameNext == null ? "-" : requirement.AlvProcessNameNext}}</h2>
                                <h4 style="color: ">{{requirement.AlvDescriptionNext}}</h4>
                            </v-col>
                            <v-divider style="margin-top:-10px; margin-bottom: 0px" vertical></v-divider>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <c-select-area v-if="isEditable" disabled label="Área" :text="user.CtrContract.AreName" v-model="requirement.AreID" />
                                <s-text label="Área" :readonly="!isEditable" v-if="!isEditable" v-model="requirement.AreName"></s-text>
                            </v-col>
                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date disabled label="Fecha Solicitud" :value="requirement.ReqDate" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px" v-if="isEditable">
                                <s-select-definition :dgr="53" :def="1208" label="Estado" :disabled="!isEditable" v-model="requirement.ReqStatus" />
                            </v-col>

                            <v-col :lg="isEditable ? 3 : 4" md="6" cols="6" style="padding-top: 0px; padding-bottom: 0px">
                                <s-select-definition :def="1209" label="Prioridad" :readonly="!isEditable" v-model="requirement.TypePriority">
                                </s-select-definition>
                            </v-col>

                            <v-col md="3" cols="3" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date :disabled="!isEditable" label="Fecha Necesidad" :value="requirement.ReqNeed" ref="ReqNeed" v-model="requirement.ReqNeed" />
                            </v-col>

                            <v-col md="9" cols="9" style="padding-top: 0px; padding-bottom: 0px">
                                <s-text :readonly="!isEditable" label="Motivo" v-model="requirement.ReqReason" ref="ReqReason" />
                            </v-col>
                            <!-- <v-col md="3" cols="3" style="padding-top: 0px; padding-bottom: 0px">
                                <v-switch :disabled="!isEditable" label="Comunica imp" v-model="requirement.ComuniqueImp" dense hide-details ref="ComuniqueImp" />
                            </v-col> -->
                        </v-row>

                        <v-divider style="margin-top:30px; margin-bottom: 0px" horizontal></v-divider>
                        <v-divider style="margin-top:5px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row v-if="value > 0 && (user.CtrContract.AreID == requirement.AreResponsableID)">
                            <v-col md="3" cols="3" style="padding-top: 0px; padding-bottom: 0px">
                                <s-date clearable label="Fecha de Entrega" :value="requirement.ReqDateDeliver" ref="ReqDateDeliver" v-model="requirement.ReqDateDeliver" />
                            </v-col>
                            <v-col md="3" cols="3" style="padding-top: 0px; padding-bottom: 0px">
                                <v-btn x-small color="success" class="capitalize" @click="clickdelivery()" v-if="requirement.ReqProcessInitiated !== 1">Aplicar</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <h3 v-if="requirement.ReqDeliverUsrAuthorize !== null">Entrega de Producto el: {{$moment(requirement.ReqDateDeliver).format(this.$const.FormatDateDB)}}</h3>
                            </v-col>
                        </v-row>

                        <v-row style="margin-top:20px" v-if="value > 0">

                            <v-col cols="6" class="text-center">
                                Tiempo Restante
                                <h1 style="transform: scale(0.8);">{{requirement.ReqTimeLeft}}</h1>
                            </v-col>
                            <v-col cols="6" class="text-center">
                                Tiempo Transcurrido
                                <h1 style="transform: scale(0.8);">{{requirement.ReqTimeElapsed}}</h1>
                            </v-col>
                        </v-row>

                        <v-divider style="margin-top:20px; margin-bottom: 0px" horizontal></v-divider>
                        <v-divider style="margin-top:20px; margin-bottom: 0px" horizontal></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-btn @click="InitiatedProccess()" small elevation="0" outlined style="font-family:;text-transform: capitalize" v-if="(requirement.ReqProcessInitiated !== 1 && user.CtrContract.AreID == requirement.AreResponsableID) && requirement.ReqDeliverUsrAuthorize !== null">Iniciar Proceso.</v-btn>
                                <h2 v-if="requirement.ReqProcessInitiated == 1">Proceso iniciado el: {{$moment(requirement.ReqDateInitiatedProcess).format(this.$const.FormatDateTimeDB)}} por: {{requirement.UsrNameInitiatedProcess}}</h2>
                                <!-- <h2>
                                            <a v-if="(requirement.Transaction != null && this.requirement.Transaction.PstID == this.user.CtrContract.PstID && requirement.ReqProcessInitiated == 1 && requirement.ReqStatus == 1 && requirement.ReqProcessEnd == null)" style="text-decoration:underline; color:red" @click="EndProcceso()">Terminar Proceso</a>
                                        </h2> -->

                            </v-col>
                        </v-row>
                        <v-divider style="margin-top:10px; margin-bottom: 0px" horizontal></v-divider>

                        <v-row>

                            <v-col cols="12" style="padding-top: 0px" class="mt-4">
                                <label><b>Descripciòn del Producto a Solicitar:</b></label>
                                <!-- <v-btn v-if="!isEditable" elevation="0" small class="ml-2" text color="info" @click="addComments()">Añadir Comentario</v-btn> -->
                            </v-col>
                            <v-col cols="12" style="padding-top: 0px">
                                <!-- <ckeditor v-model="requirement.ReqObservation" :config="configCk"></ckeditor> -->
                                <v-card elevation="4">
                                    <s-textarea v-model="requirement.ReqObservation" ref="ReqObservation" :height="150"></s-textarea>
                                </v-card>

                            </v-col>

                        </v-row>

                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-card>

</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Marketing/MktRequirementService";
import _sRequirementConfig from "@/services/Logistics/LgsRequirementconfig";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";

//components
import ApprovalLevelsDocument from "@/views/Security/Circuit/Levels/ApprovalLevelsDocument";

export default {
    components: {
        ApprovalLevelsDocument
    },

    props: {
        value: {
            default: 1,
            type: Number
        },
    },

    data() {
        return {

            requirement: {
                AreID: 0,
                ReqDate: null,
                UsrID: null,
                ReqID: null,
                ReqObservation: "",
                ReqReason: "",
                ReqStatus: 0,
                ComuniqueImp:false
            },

            itemAttach: [],
            headersFiles: [{
                    text: "Archivo",
                    value: "RqdAttach"
                },
                {
                    text: "Creado Por",
                    value: "NtpName"
                },
                {
                    text: "Fecha",
                    value: "SecCreate"
                },
                {
                    text: "x",
                    value: "delete"
                },
                
            ],
            listComments: [

            ],
            Comments: "",

            //Loading
            processing: false,
            messageProcessing: "",
            errors: [],

            //Circuitos
            circuits: [],
            dialogCircuit: false,
            TrsID: null,

            //Transac
            transactionApproved: null,
            optionApproved: false,

        }

    },
    computed: {
        user() {
            return this.$fun.getUserInfo();
        },
        userID() {
            return this.$fun.getUserID();
        },
        isEditable() {
            return this.requirement.ReqStatus == 5 || this.requirement.ReqID == null;

        },
    },

    created() {

        if (this.value != null) {
            this.initialize()

        }

        this.getConfig()

    },

    mounted() {
        if (this.value != null) {
            this.processing = true;
        }
    },

    watch: {
        isEditable() {
            this.processing = false;
        },
    },

    methods: {
        initialize() {
            _sRequirement.byID({
                ReqID: this.value
            }, this.$fun.getUserID()).then((r) => {

                r.data.itemsAttach.forEach(element => {
                    element.Attach = {
                        name: element.RqdAttach
                    };
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                });
                r.data.listComments.forEach(element => {
                    element.divider = false
                    element.inset = false
                    element.active = false
                    element.action = 'mdi-tag'
                    element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    element.RccSubtitleExtract = element.RccSubtitle.substring(0, 300)
                    element.RccLengthExtract = element.RccSubtitleExtract.length
                    element.RccLengthSubtitle = element.RccSubtitle.length
                });

                this.requirement = r.data;

                this.transactionApproved = this.requirement.Transaction;
                if (this.requirement.Transaction != null)
                    if (this.requirement.Transaction.UsersApproved.length > 0)
                        if (this.requirement.Transaction.UsersApproved.find((x) => x.UsrID == this.$fun.getUserID()))
                            if (this.requirement.ReqStatus == 4) this.optionApproved = true;

            });
        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 9).then((r) => {
                
                this.$fun.download(r.data, url.name);
            });
        },

        uploadFile() {

            this.$fun.uploadFiles(this.itemAttach, 8).then(resp => {
                if (resp.data !== null && resp.data !== "No cargo ningún archivo") {
                    resp.data.forEach((element) => {
                        element.ReqID = this.requirement.ReqID;
                        element.SecStatus = 1;
                        element.UsrCreateID = this.$fun.getUserID();
                    });

                    this.requirement.itemsAttach = resp.data;

                    if (resp.data.length > 0) {
                        _sRequirement.savefiles(this.requirement.itemsAttach, this.$fun.getUserID()).then((r) => {
                                if (r.status == 200) {
                                    //this.$emit("close");
                                    this.initialize();
                                    this.processing = false;
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = true;
                            });
                    }

                }
            });
        },

        deleteFile(item) {

            this.requirement.itemsAttach = [item]

            let obj = [item]

            console.log(obj)

            this.$fun.alert("¿Seguro de eliminar documento Seleccionado ?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    if (obj.length > 0) {

                        obj[0].UsrUpdateID = this.$fun.getUserID()
                        obj[0].SecStatus = 0;

                        _sRequirement.savefiles(obj, this.$fun.getUserID()).then((r) => {
                                if (r.status == 200) {
                                    this.initialize()
                                    this.processing = false;
                                }
                            },
                            (e) => {
                                this.messageProcessing = e.response.data.Message;
                                this.processing = false;
                            });
                    }

                }
            })
        },

        getConfig() {
            _sRequirementConfig.list(51, this.$fun.getUserID()).then(resp => {
                this.itemAttach = resp.data

            })

        },

        save() {

            if (this.requirement.ReqObservation == null || this.requirement.ReqObservation.length <= 0) {
                this.$refs.ReqObservation.error('Ingrese Descripcion')
                return
            }

            if (this.requirement.ReqReason == null || this.requirement.ReqReason.length <= 0) {
                this.$refs.ReqReason.error('Ingrese Motivo')
                return
            }

            if (this.requirement.ReqReason.length <= 10) {
                this.$refs.ReqReason.error('[Motivo] Minimo 10 caracteres.')
                return
            }

            this.requirement.UsrID = this.$fun.getUserID();
            this.requirement.UsrCreateID = this.$fun.getUserID();
            this.requirement.UsrUpdateID = this.$fun.getUserID();
            this.requirement.SecStatus = 1;

            this.requirement.itemsAttach = []
            this.requirement.listComments = []

            if (this.Comments.length > 0) {
                this.requirement.listComments = [{
                    RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                    RccTitle: "",
                    RccSubtitle: this.Comments,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID()
                }]
                this.Comments = "";
            }

            console.log("save", this.requirement)

            this.$fun.alert("Seguro de continuar con la Solicitud?", "question", false).then((r) => {
                if (r.value) {
                    this.$fun.uploadFiles(this.itemAttach, 8).then(
                        (resp) => {
                            if (resp.status == 200) {
                                this.requirement.UsrID = this.$fun.getUserID();
                                this.requirement.UsrCreateID = this.$fun.getUserID();
                                this.requirement.UsrUpdateID = this.$fun.getUserID();
                                this.requirement.SecStatus = 1;

                                if (resp.data !== null && resp.data !== "No cargo ningún archivo") {
                                    resp.data.forEach((element) => {
                                        element.ReqID = this.requirement.ReqID;
                                        element.SecStatus = 1;
                                        element.UsrCreateID = this.$fun.getUserID();
                                    });

                                    this.requirement.itemsAttach = resp.data;
                                }

                                this.messageProcessing = ""
                                this.processing = true

                                _sRequirement
                                    .save(this.requirement, this.$fun.getUserID(), 0)
                                    .then((r) => {
                                            if (r.status == 200) {
                                                console.log("datos del guardado", r.data);

                                                if (r.data.length > 0) {
                                                    this.circuits = r.data;
                                                    this.TrsID = 51
                                                    this.dialogCircuit = true;
                                                } else {
                                                    this.messageProcessing = "";
                                                    this.processing = false;

                                                    this.$fun.alert("Guardado Correctamente", "success");
                                                    this.$emit("close");

                                                }
                                            }

                                        },
                                        (e) => {
                                            this.errors = e.response.data.Message
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        })

                            }
                        });
                }
            });
        },

        //Comments

        addComments() {

            if (this.Comments.length == 0) {
                /* this.$fun.alert("Digite Comentario", "warning") */
                this.$refs.Comments.error("Digite Comentario")
                return
            }

            this.$fun.alert("¿Seguro de añadir comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [{
                        ReqID: this.requirement.ReqID,
                        RccAvatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
                        RccTitle: "New Comments",
                        RccSubtitle: this.Comments,
                        SecStatus: 1,
                        UsrCreateID: this.$fun.getUserID()
                    }]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        deleteComments(item) {

            item.SecStatus = 0
            item.UsrUpdateID = this.$fun.getUserID()

            this.$fun.alert("¿Seguro de eliminar comentario?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.listComments = [item]

                    _sRequirement.saveComments(this.requirement.listComments, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        //Iniciar Proceso Elaboracion de Contrato
        InitiatedProccess() {
            console.log(this.requirement)
            this.$fun.alert("¿Seguro de comenzar proceso ? ", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.UsrInitiatedProcess = this.$fun.getUserID()
                    this.requirement.ReqProcessInitiated = 1;
                    this.requirement.UsrUpdateID = this.$fun.getUserID()
                    this.requirement.itemsAttach = null
                    this.requirement.listComments = null

                    _sRequirement.initiatedProcess(this.requirement, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        },

        clickdelivery() {

            if (this.requirement.ReqDateDeliver == null) {
                this.$fun.alert("Fecha de Entrega no puede ser nulo.", "warning")
                return
            }

            if (this.$moment(this.requirement.ReqDateDeliver).format(this.$const.FormatDateDB) < this.$moment(this.requirement.ReqDate).format(this.$const.FormatDateDB)) {
                this.$fun.alert("Fecha de Entrega no puede menor a Fecha de Solicitud.", "warning")
                return
            }

            console.log(this.requirement)
            this.$fun.alert("¿Aplicar Cambios ? ", "question", false).then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    this.requirement.ReqProcessInitiated = null
                    this.requirement.ReqDeliverUsrAuthorize = this.$fun.getUserID()
                    this.requirement.UsrUpdateID = this.$fun.getUserID()
                    this.requirement.itemsAttach = null
                    this.requirement.listComments = null

                    _sRequirement.delivery(this.requirement, this.$fun.getUserID()).then((r) => {
                            if (r.status == 200) {
                                this.initialize()
                                this.processing = false;
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });

                }
            })
        }

    }

};
</script>
